
import React, { useRef, useState, useEffect } from 'react';
import './ScreenPDFOutputOR.css';
import { AudioInput, AudioOutput, Config, SOW, VideoInput, VideoOutput } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import PDFOutput, { PDFOutputResults } from '../../../Components/PDFOutput/PDFOutput';
import ScreenORContents from '../ScreenORContents/ScreenORContents';
import ScreenOREquipmentBoomLayout from '../ScreenOREquipmentBoomLayout/ScreenOREquipmentBoomLayout';
import ScreenORExecutiveSummary from '../ScreenORExecutiveSummary/ScreenORExecutiveSummary';
import ScreenORInputOutputSchedule from '../ScreenORInputOutputSchedule/ScreenORInputOutputSchedule';
import ScreenORRoomLayout from '../ScreenORRoomLayout/ScreenORRoomLayout';
import ScreenORTouchscreenLayout from '../ScreenORTouchscreenLayout/ScreenORTouchscreenLayout';
import ScreenOrProjectRoadmap from '../ScreenOrProjectRoadmap/ScreenOrProjectRoadmap';
import ScreenPDFViewer from '../../ScreenPDFViewer/ScreenPDFViewer';
import ScreenSignaturePage from '../../ScreenSignaturePage/ScreenSignaturePage';
import ScreenCoverPage from '../../ScreenCoverPage/ScreenCoverPage';
interface ScreenPDFOutputORProps {
	currentSOW: SOW;
	onPDFComplete: (pdfData: PDFOutputResults) => void;
}
export default function ScreenPDFOutputOR({ currentSOW, onPDFComplete }: ScreenPDFOutputORProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setPrintingPDF: ActionCreators.setPrintingPDF,
	}, dispatch);



	const objectType = (obj: VideoInput | VideoOutput | AudioInput | AudioOutput) => {
		if ((obj as VideoOutput).outputsButtonText != undefined) {
			return "Video Output";
		}
		if ((obj as VideoOutput).icon != undefined) {
			return "Video Input";
		}
		if ((obj as AudioInput).inputsTPButtonText != undefined) {
			return "Audio Input";
		}
		if ((obj as AudioOutput).outputsTPButtonText != undefined) {
			return "Audio Output";
		}
		return "";
	}



	return (
		<>
			<div className='Screen ScreenPDFOutputOR'>
				<PDFOutput
					readyToGenerate={true}
					onGenerationComplete={(pdfData) => {
						onPDFComplete(pdfData);
						// AC.setPrintingPDF(false);

						// //save pdf as file
						// const a = document.createElement('a');
						// a.href = URL.createObjectURL(pdfData);
						// a.download = 'sow.pdf';
						// a.click();

					}}>
					<ScreenCoverPage
						hospitalName={currentSOW.hospitalName}
						leftText='Operating Room Integration Statement of Work'
						title1="Operating Room Integration"
						title2='Statement of Work'
					/>
					<ScreenORContents sow={currentSOW} forPDF={true} />
					<ScreenORExecutiveSummary sow={currentSOW} forPDF={true} />
					<ScreenOrProjectRoadmap sow={currentSOW} forPDF={true} />
					{currentSOW.configs.map((config, index) => {
						let rows = [...config.video.inputs, ...config.video.outputs, ...config.audio.inputs, ...config.audio.outputs]

						let pages: (VideoInput | VideoOutput | AudioInput | AudioOutput)[][] = [];
						let page: (VideoOutput | VideoInput | AudioInput | AudioOutput)[] = [];
						let i = 0;
						let lastObjectType = "";

						let header_height = 75;
						let row_height = 27;
						let max_height = 1400;

						rows.forEach((row) => {
							let type = objectType(row);
							if (i >= max_height) {
								pages.push(page);
								page = [];
								i = header_height;
							}
							if (type != lastObjectType) {
								i += header_height
							}
							lastObjectType = type;
							page.push(row);
							i += row_height;
						});
						if (page.length > 0) {
							pages.push(page);
						}

						let maxLetter = "A";
						config.roomLayout.sceneObjects.forEach((obj) => {
							if (obj.name.length > maxLetter.length) {
								maxLetter = obj.name;
							}
						});

						let labelPairs: { min: string, max: string }[] = [];
						//we want to break up the rooms into groups of 4 so we'll add a pair for each group

						let filteredObjects = config.roomLayout.sceneObjects.filter((obj) => { return obj.letter != "" && obj.labels });
						let numberedPairs: { minIndex: number, maxIndex: number }[] = [];

						let startI = 0;
						for (let i = 0; i < filteredObjects.length; i++) {
							let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
							let letter = alphabet[i];
							let count = 0;
							let sceneObject = filteredObjects.filter((obj) => { return obj.letter == letter })[0];
							while (sceneObject && (count + sceneObject.labels.length <= 15)) {
								count += sceneObject.labels.length;
								i++;
								if (i >= filteredObjects.length) {
									break;
								}
								letter = alphabet[i];
								sceneObject = filteredObjects.filter((obj) => { return obj.letter == letter })[0];
								if (!sceneObject) {
									break;
								}
							}
							labelPairs.push({
								min: alphabet[startI],
								max: alphabet[i - 1]
							});
							numberedPairs.push({
								minIndex: startI,
								maxIndex: i - 1
							});
							startI = i;
						}


						console.log(labelPairs, numberedPairs);

						return <>
							{labelPairs.map((pair, index) => {
								return <ScreenORRoomLayout
									sow={currentSOW}
									forPDF={true}
									config={config}
									minLabel={pair.min}
									maxLabel={pair.max}
									minIndex={numberedPairs[index].minIndex}
									maxIndex={numberedPairs[index].maxIndex}
									key={"room_" + config.id + "_" + pair.min} />
							})}

							{config.video.system != "AIR" && config.video.system != "4KBR" && (
								<ScreenORTouchscreenLayout sow={currentSOW} forPDF={true} config={config} key={"touchscreen_" + config.id} />
							)}
							{pages.map((page, index) => {
								return <ScreenORInputOutputSchedule sow={currentSOW} forPDF={true} config={config} rows={page} key={"in_out_" + config.id + "_" + index} />
							})}
							{(config.boomVendor == 'None' || config.boomVendor == '' || config.boomVendor == undefined) ? (
								<></>
							) : (
								<ScreenOREquipmentBoomLayout sow={currentSOW} forPDF={true} config={config} key={"boom_" + config.id} />
							)}
						</>
					})}
					<ScreenSignaturePage />
				</PDFOutput>
			</div>
		</>
	);
}