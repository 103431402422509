
import React, { useRef, useState, useEffect } from 'react';
import './ScreenSignaturePage.css';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { Screens } from '../../Redux/Reducers';
interface ScreenSignaturePageProps{
}
export default function ScreenSignaturePage({  }: ScreenSignaturePageProps) {

  return (
    <div className='Screen ScreenSignaturePage'>
			<div className='leftBarBottom' />
   		<div className='storzLogo'></div>
			<div className='line name'>Name</div>
			<div className='line signature'>Signature</div>
			<div className='line date'>Date</div>
    </div>
  );
}