import React, { useEffect, useState } from 'react';
import "./Dropdown.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';


interface DropdownProps {
  options: string[];
  labels?: string[];
  onSelect?: (optionName: string, index:number) => void;
  defaultValue?: string;
  disabled?: boolean;
  className?: string;
}

const Dropdown: React.FC<DropdownProps> = ({ options, labels, onSelect, defaultValue, disabled, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultValue ?? options[0]);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (defaultValue) {
      setSelectedOption(defaultValue);
    }
  }, [defaultValue]);

  const toggleDropdown = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
    if(isOpen){
      headerRef.current && headerRef.current.focus();
    }
  };

  const handleSelect = (option: string,index:number) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect && onSelect(option,index);
  };

  const [headerWidth, setHeaderWidth] = useState(0);
  const [listWidth, setListWidth] = useState(0);
  useEffect(() => {
    if (headerRef.current && listRef.current) {
      let maxItemWidth = 0;
      listRef.current.querySelectorAll(".dropdown-item").forEach((item) => {
        maxItemWidth = Math.max(maxItemWidth, item.clientWidth);
      });
      setListWidth(maxItemWidth);
      setHeaderWidth(headerRef.current.clientWidth)
    }
  }, [headerRef, listRef, listWidth]);

  const shouldShowListAbove = () => {
    //get the bounding rect of the headerref. if it is greater that 540px from the top, show the list above
    if(headerRef.current){
      let rect = headerRef.current.getBoundingClientRect();
      if(rect.top > 540){
        return true;
      }
    }
    return false;
  }

  return (
    <div className={`Dropdown ${className ?? ""} ${disabled ? "disabled" : ""}  ${defaultValue==""&&!disabled&&"unfilled"}`} onClick={toggleDropdown}>
      <div className="dropdown-header" 
        ref={headerRef} 
        style={{ minWidth: listWidth }}
        onKeyDown={(e) =>{
          if(e.key == "tab"){
            setIsOpen(false);
          }
        }}
        onBlur={() =>{
          setIsOpen(false);
        }}>
        {labels?labels[options.indexOf(selectedOption)]:selectedOption}
        <span className="arrow"><FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} /></span>
      </div>
      <input type='text'
        onFocus={()=>{setIsOpen(true)}} 
        className='focusGetter' 
        onKeyDown={(e) =>{
        if(e.key == "Tab"){
          setIsOpen(false);
          return;
        }
        e.preventDefault();
      }}/>
      {isOpen && (
        <>
          <div className='dropdown-backdrop' onClick={toggleDropdown}></div>
        </>
      )}
      <div className={`dropdown-list ${shouldShowListAbove()?"above":""}`} ref={listRef} style={{ minWidth: headerWidth, opacity: isOpen ? 1 : 0, pointerEvents: isOpen ? "all" : "none", height: isOpen ? "unset" : 0 }}>
        {options.map((option, index) => (

          <div key={index} className={`row ${option == defaultValue ? "selected" : ""}`}>
            <div className={`dropdown-item`}
              onClick={() => handleSelect(option,index)}>
              {labels?labels[index]:option}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
