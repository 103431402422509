

import React, { useRef, useState, useEffect } from 'react';
import './TouchscreenInputOutputRow.css';
import { Config, VideoInput, VideoOutput } from '../../../Model/SOW';
import { INPUT_ICONS, OUTPUT_ICONS } from '../../../Model/DropdownOptions';
interface TouchscreenInputOutputRowProps{
	videoInput?: VideoInput;
	videoOutput?: VideoOutput;
}
export default function TouchscreenInputOutputRow({ videoInput, videoOutput}: TouchscreenInputOutputRowProps) {
  const icon1 = OUTPUT_ICONS.find((icon) => icon.name === (videoInput?(videoInput!.icon):(videoOutput!.icon)));
  const [icon, setIcon] = useState<{
      name: string;
      icon: string;
  }>(icon1!);
  useEffect(() => {
    if(videoInput){
      const icon1 = INPUT_ICONS.find((icon) => icon.name === (videoInput!.icon));
      setIcon(icon1!);
    }else if(videoOutput){
      const icon1 = OUTPUT_ICONS.find((icon) => icon.name === (videoOutput!.icon));
      setIcon(icon1!);
    }
  }, [videoInput, videoOutput]);
  return (
    <div className={`TouchscreenInputOutputRow ${videoInput?"input":"output"}`}>
      {videoInput && (
        <>
        <div className={`icon`} style={{backgroundImage:`url(${icon?.icon})`}} /> {videoInput.inputsTPButtonText}
        </>
       )}
      {videoOutput && (
        <>
        <div className={`icon`} style={{backgroundImage:`url(${icon?.icon})`}} /> {videoOutput.outputsButtonText}
        </>
        )}
    </div>
  );
}