
import React, { useRef, useState, useEffect } from 'react';
import './ScreenORBegin.css';
import { Config, SOW } from '../../../Model/SOW';
import { shortDate, sowDate } from '../../../Utils/Formatting';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import { blankConfig, blankDepartment } from '../../../Utils/BlankObjects';
import { KarlStorzUser } from '../../../Model/KarlStorzUser';
import { CollectionReference, DocumentData, Query, collection, onSnapshot, query, where } from 'firebase/firestore';
import { SalesRegion } from '../../../Model/SalesRegion';
import { sharedFirestore } from '../../../Utils/SharedFirebase';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const uuid = require('uuid');

interface ScreenORBeginProps {
}
export default function ScreenORBegin({ }: ScreenORBeginProps) {
	const loggedInUser = useSelector((state: { loggedInUser: KarlStorzUser }) => state.loggedInUser)
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
	}, dispatch);

	const [hospitalName, setHospitalName] = useState<string>('')

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [])

	const [salesRegions, setSalesRegions] = useState([] as SalesRegion[]);
	const [selectedRegionID, setSelectedReginID] = useState("");
	const salesRegionsCollectionRef = collection(sharedFirestore, "SalesRegions");

	useEffect(() => {

		let q: Query<DocumentData, DocumentData> | CollectionReference<DocumentData, DocumentData>;
		q = query(salesRegionsCollectionRef, where("id", "in", loggedInUser.salesRegionIDs));
		if (loggedInUser.isAdmin) {
			q = salesRegionsCollectionRef;
		}

		const unsubscribeSalesRegions = onSnapshot(q, (querySnapshot) => {
			let salesRegions = [] as SalesRegion[];
			querySnapshot.forEach((doc) => {
				let docData = doc.data();
				let salesRegion: SalesRegion = {
					id: doc.id,
					name: docData.name,
					sowIDs: docData.sowIDs,
				}
				salesRegions.push(salesRegion);
			});
			if (salesRegions.length == 1) {
				setSelectedReginID(salesRegions[0].id);
			}
			if (salesRegions.length > 0) {
				setSalesRegions(salesRegions);
			}
		});
		return () => {
			unsubscribeSalesRegions();
		}
	}, [])

	return (
		<div className='Screen ScreenORBegin'>
			<div className='storzLogo'></div>
			<div className='inputs'>
				<div className='header'>
					{hospitalName == "" ? "What hospital or clinic is this for?" : "Save this SOW as:"}
				</div>
				<input type='text'
					placeholder={hospitalName == "" ? "Enter Hospital Name" : "SOW Name"}
					ref={inputRef} />
				{salesRegions.length > 1 && <>
					{/* <Dropdown 
						options={["", ...salesRegions.map((salesRegion) => {
							return salesRegion.id;
						})]}
						labels={["No Sales Region Selected", ...salesRegions.map((salesRegion) => {
							return salesRegion.name;
						})]}
						onSelect={(value)=>{
							setSelectedReginID(value);
						}} /> */}
					<div className='row'>
						<select
							value={selectedRegionID}
							onChange={(e) => {
								setSelectedReginID(e.target.value);
							}}>
							<option value="">No Sales Region Selected</option>
							{salesRegions.map((salesRegion) => {
								return <option value={salesRegion.id}>{salesRegion.name}</option>
							})}
						</select>
						<span className="arrow"><FontAwesomeIcon icon={faChevronDown} /></span>
					</div>
				</>}
				<div className={`buttonSubmit ${inputRef.current?.value == "" ? "disabled" : ""}`}
					onClick={() => {
						if (inputRef.current!.value != "" && selectedRegionID != "") {
							if (hospitalName == "") {
								setHospitalName(inputRef.current!.value)

								inputRef.current!.value += " " + shortDate(new Date());
							} else {
								let sowName = inputRef.current!.value
								let config = blankConfig();
								let sow: SOW = {
									hospitalName: hospitalName,
									name: sowName,
									dateCreated: sowDate(new Date()),
									id: uuid.v4(),
									salesRegionID: selectedRegionID,
									type: 'OR Room Integration',
									departments: [],
									leftOverDepartment: blankDepartment(),
									configs: [config] as Config[],
									dateInitiate: "",
									dateDeploy: "",
									dateConfigure: "",
									dateActivate: "",
									dateTrain: "",
									dateSuccess: "",
								}
								AC.setSOW(sow);
								AC.setScreen(Screens.ORConfigSetup)
							}
						}
					}}>{hospitalName == "" ? "Submit" : "Save & Continue"}
				</div>
			</div>
			<div className='buttonCancel' onClick={() => {
				AC.setScreen(Screens.Landing);
			}}>Cancel</div>
		</div>
	);
}