
import React, { useEffect, useRef, useState } from 'react';
import { SceneObject } from '../../../Model/SceneObject';
import './DraggableSceneObject.css';
import { faAnchor, faArrows, faArrowsLeftRight, faBars, faCheck, faChevronDown, faCircleNodes, faEye, faEyeSlash, faHamburger, faLock, faPlus, faRotate, faTrash, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetBoxSizeFromTypeSubType } from '../../../Utils/GetBoxSizeFromTypeSubType';

interface DraggableSceneObjectProps {
	roomScale: number;
	sceneObject: SceneObject;
	selectedID: string;
	canRotate: boolean;
	canDrag: boolean;
	canHide: boolean;
	canDelete: boolean;
	canFlip: boolean;
	canLock: boolean;
	canScale: boolean;
	minLabel?: string;
	maxLabel?: string;
	hidingHiddenObjects?: boolean;
	onSelect: (id: string) => void;
	onDelete: (id: string) => void;
	onChange: (id: string, changes: Partial<SceneObject>) => void;
}


const eventsToRemove: ((e: MouseEvent | TouchEvent) => void)[] = [];


export const DraggableSceneObject: React.FC<DraggableSceneObjectProps> = ({
	roomScale,
	sceneObject,
	selectedID,
	canRotate,
	canDrag,
	canDelete,
	canFlip,
	canHide,
	canLock,
	canScale,
	minLabel,
	maxLabel,
	hidingHiddenObjects,
	onSelect,
	onDelete,
	onChange
}) => {

	const [interactionMode, setInteractionMode] = useState<'move' | 'rotate' | 'scale' | "">('');
	const [parentElement, setParentElement] = useState<HTMLElement | null>(null);
	const buttonRotateRef = useRef<HTMLButtonElement>(null);
	const buttonScaleTLRef = useRef<HTMLDivElement>(null);
	const buttonScaleTRRef = useRef<HTMLDivElement>(null);
	const buttonScaleBLRef = useRef<HTMLDivElement>(null);
	const buttonScaleBRRef = useRef<HTMLDivElement>(null);
	const buttonMoveRef = useRef<HTMLButtonElement>(null);
	const buttonDeleteRef = useRef<HTMLButtonElement>(null);
	const draggableSceneObjectRef = useRef<HTMLDivElement>(null);

	var onMouseUp = (e: MouseEvent | TouchEvent) => {
	}
	var onDragStart = (e: MouseEvent | TouchEvent) => {
	}
	var onDrag = (e: MouseEvent | TouchEvent) => {

	}

	const [appScale, setAppScale] = useState(1);

	useEffect(() => {
		const handleResize = () => {
			let width = window.innerWidth;
			let height = window.innerHeight;
			//fit it so that 1920x1080 will fit on the screen
			let scale = Math.min(width / 1920, height / 1080);

			setAppScale(scale);
		}
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => {
			window.removeEventListener('resize', handleResize);
		}
	}, []);

	useEffect(() => {
		setInteractionMode("move");
	}, [selectedID]);


	useEffect(() => {
		if (draggableSceneObjectRef.current) {
			let parent = (draggableSceneObjectRef.current as HTMLElement).parentElement!;
			while (!parent.classList.contains('DraggableScene') &&
				!parent.classList.contains('DraggableSceneObject')) {
				parent = parent.parentElement!;
			}
			setParentElement(parent);
		}
	}, [draggableSceneObjectRef.current]);


	const [startDistance, setStartDistance] = useState(0);
	const [startScale, setStartScale] = useState(sceneObject.scale);
	const [startAngle, setStartAngle] = useState(0);


	useEffect(() => {

		for (let i = 0; i < eventsToRemove.length; i++) {
			let event = eventsToRemove[i];
			parentElement?.removeEventListener('mousemove', event);
			parentElement?.removeEventListener('mousemove', event);
			parentElement?.removeEventListener('mouseup', event);
			draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
			parentElement?.removeEventListener('touchmove', event);
			parentElement?.removeEventListener('touchmove', event);
			parentElement?.removeEventListener('touchend', event);
			draggableSceneObjectRef.current?.removeEventListener('touchstart', event);
		}

		const getDistanceToCenter = (e: MouseEvent | TouchEvent) => {
			const rect = draggableSceneObjectRef.current!.getBoundingClientRect();
			const centerX = rect.left + rect.width / 2;
			const centerY = rect.top + rect.height / 2;
			let clientX, clientY = 0;
			if ('touches' in e) {
				clientX = e.touches[0].clientX;
				clientY = e.touches[0].clientY;
			} else {
				clientX = e.clientX;
				clientY = e.clientY;
			}
			const diffX = clientX - centerX;
			const diffY = clientY - centerY;
			return Math.sqrt(diffX * diffX + diffY * diffY);
		}

		const getAngleIndDegrees = (e: MouseEvent | TouchEvent) => {
			const rect = draggableSceneObjectRef.current!.getBoundingClientRect();
			const centerX = rect.left + rect.width / 2;
			const centerY = rect.top + rect.height / 2;
			let clientX, clientY = 0;
			if ('touches' in e) {
				clientX = e.touches[0].clientX;
				clientY = e.touches[0].clientY;
			} else {
				clientX = e.clientX;
				clientY = e.clientY;
			}
			const diffX = clientX - centerX;
			const diffY = clientY - centerY;
			let angleInRad = Math.atan2(diffY, diffX);
			let angleInDeg = angleInRad * 180 / Math.PI;
			if (angleInDeg < 0) angleInDeg = 360 + angleInDeg;

			return angleInDeg;
		}

		const shouldScaleDrag = (e: MouseEvent | TouchEvent) => {

			let clientX, clientY = 0;
			if ('touches' in e) {
				clientX = e.touches[0].clientX;
				clientY = e.touches[0].clientY;
			} else {
				clientX = e.clientX;
				clientY = e.clientY;
			}

			if (buttonScaleTLRef.current) {
				const rect = buttonScaleTLRef.current.getBoundingClientRect();
				if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {
					return true;
				}
			}
			if (buttonScaleTRRef.current) {
				const rect = buttonScaleTRRef.current.getBoundingClientRect();
				if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {
					return true;
				}
			}
			if (buttonScaleBLRef.current) {
				const rect = buttonScaleBLRef.current.getBoundingClientRect();
				if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {
					return true;
				}
			}
			if (buttonScaleBRRef.current) {
				const rect = buttonScaleBRRef.current.getBoundingClientRect();
				if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {
					return true;
				}
			}
			return false;
		}

		const scaleDragStart = (e: MouseEvent | TouchEvent) => { // Scale
			e.stopPropagation();
			setStartScale(sceneObject.scale)

			let startDistance = getDistanceToCenter(e) * (1 + (roomScale));

			let clientX, clientY = 0;
			if ('touches' in e) {
				clientX = e.touches[0].clientX;
				clientY = e.touches[0].clientY;
			} else {
				clientX = e.clientX;
				clientY = e.clientY;
			}


			setStartDistance(startDistance);

			parentElement?.removeEventListener('mousemove', onDrag);
			parentElement?.removeEventListener('touchmove', onDrag);
			for (let i = 0; i < eventsToRemove.length; i++) {
				let event = eventsToRemove[i];
				parentElement?.removeEventListener('mousemove', event);
				parentElement?.removeEventListener('mouseup', event);
				parentElement?.removeEventListener('touchmove', event);
				parentElement?.removeEventListener('touchend', event);
				parentElement!.removeEventListener('mouseout', event);
				parentElement!.removeEventListener('touchcancel', event);
				// draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
			}

			onDrag = (e: MouseEvent | TouchEvent) => { // Scale
				e.stopPropagation();

				if((e as any).buttons !== undefined && (e as any).buttons == 0){
					console.log("mouse up");
					onMouseUp(e);
					return;
				}

				if (interactionMode == "") {
					return;
				}

				let distance = getDistanceToCenter(e) * (1 + (roomScale));
				let scale = startScale * distance / startDistance;
				//let scale = 8 * distance / parentElement!.clientWidth;
				scale = Math.min(Math.max(scale, 0.2), 20);
				setStartScale(scale);
				onChange(sceneObject.id, { scale: scale });
			}
			parentElement!.addEventListener('mousemove', onDrag);
			parentElement!.addEventListener('touchmove', onDrag);
			parentElement!.addEventListener('mouseup', onMouseUp);
			eventsToRemove.push(onDrag);
		}

		const getScaledXY = (e: MouseEvent | TouchEvent) => {

			let clientX, clientY = 0;
			if ('touches' in e) {
				clientX = e.touches[0].clientX;
				clientY = e.touches[0].clientY;
			} else {
				clientX = e.clientX;
				clientY = e.clientY;
			}
			let scale = 1;

			let parent = parentElement;
			while (parent) {
				let transform = "";
				const computedStyle = window.getComputedStyle(parent);
				if (computedStyle) {
					const computedTransform = computedStyle.transform;
					if (computedTransform) {
						transform = computedTransform;
					}
				}
				if (transform) {
					// parse the format 'matrix(0.5, 0, 0, 0.5, 0, 0)'
					const scaleMatch = transform.match(/matrix\((.*), 0, 0, (.*), 0, 0\)/);
					if (scaleMatch) {
						scale *= parseFloat(scaleMatch[1]);
					}
				}
				if (parent.parentElement) {
					parent = parent.parentElement!;
				} else {
					break;
				}
			}

			let x = clientX - parentElement!.offsetLeft * scale;// - startX
			let y = clientY - parentElement!.offsetTop * scale;// - startY;


			x = x / scale;
			y = y / scale;

			let percentX = x / parentElement!.clientWidth * 100;
			let percentY = y / parentElement!.clientHeight * 100;


			return {
				clientX,
				clientY,
				scale,
				x,
				y,
				percentX,
				percentY
			}

		}


		if (sceneObject.locked) {
			onDrag = (e: MouseEvent | TouchEvent) => {
				e.stopPropagation();
			}
			onDragStart = (e: MouseEvent | TouchEvent) => {
				e.stopPropagation();
			}
		} else {
			if (interactionMode === 'move') {
				onDrag = (e: MouseEvent | TouchEvent) => {
					e.stopPropagation();

				}
				onDragStart = (e: MouseEvent | TouchEvent) => {
					e.stopPropagation();

					let clientX, clientY = 0;
					
					let posData = getScaledXY(e);
					clientX = posData.clientX;
					clientY = posData.clientY;

					let initialPercentX = posData.percentX;
					let initialPercentY = posData.percentY;

					let offsetX = sceneObject.left - initialPercentX;
					let offsetY = sceneObject.top - initialPercentY;

					//if x and y are within the bounds of the buttonRotateRef then don't move. change to rotate and return
					if (buttonRotateRef.current) {
						const rect = buttonRotateRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							setInteractionMode("rotate");
							return;
						}
					}
					//same for scale
					if (shouldScaleDrag(e)) {
						scaleDragStart(e);
						return;
					}

					if (buttonDeleteRef.current) {
						const rect = buttonDeleteRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							onDelete(sceneObject.id);
							return;
						}
					}

					onDrag = (e: MouseEvent | TouchEvent) => {
						e.stopPropagation();

						if((e as any).buttons !== undefined && (e as any).buttons == 0){
							console.log("mouse up");
							onMouseUp(e);
							return;
						}

						let clientX, clientY = 0;
						let posData = getScaledXY(e);
						clientX = posData.clientX;
						clientY = posData.clientY;

						let x = posData.x;
						let y = posData.y;

						let percentX = posData.percentX + offsetX;
						let percentY = posData.percentY + offsetY;

						if (percentX > 100 || percentX < 0 || percentY > 100 || percentY < 0) {
							return;
						}

						if (sceneObject.minLeft) {
							percentX = Math.max(percentX, sceneObject.minLeft);
						}
						if (sceneObject.maxLeft) {
							percentX = Math.min(percentX, sceneObject.maxLeft);
						}
						if (sceneObject.minTop) {
							percentY = Math.max(percentY, sceneObject.minTop);
						}
						if (sceneObject.maxTop) {
							percentY = Math.min(percentY, sceneObject.maxTop);
						}

						percentX = Math.min(100, Math.max(0, percentX));
						percentY = Math.min(100, Math.max(0, percentY));

						onChange(sceneObject.id, { left: percentX, top: percentY });

					}

					parentElement!.addEventListener('mousemove', onDrag);
					parentElement!.addEventListener('mouseup', onMouseUp);
					parentElement!.addEventListener('touchmove', onDrag);
					parentElement!.addEventListener('touchend', onMouseUp);
				}
			} else if (interactionMode === 'rotate') {
				onDrag = (e: MouseEvent | TouchEvent) => { // Rotate
					e.stopPropagation();
				}
				onDragStart = (e: MouseEvent | TouchEvent) => { // Rotate
					e.stopPropagation();

					if((e as any).buttons !== undefined && (e as any).buttons == 0){
						console.log("mouse up");
						onMouseUp(e);
						return;
					}

					let startAngleInDeg = getAngleIndDegrees(e);
					let clientX, clientY = 0;
					if ('touches' in e) {
						clientX = e.touches[0].clientX;
						clientY = e.touches[0].clientY;
					} else {
						clientX = e.clientX;
						clientY = e.clientY;
					}

					//if x and y are within the bounds of the buttonMoveRef then don't move. change to rotate and return
					if (buttonMoveRef.current) {
						const rect = buttonMoveRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							setInteractionMode("move");
							return;
						}
					}
					//same for scale
					if (shouldScaleDrag(e)) {
						scaleDragStart(e);
						return;
					}

					if (buttonDeleteRef.current) {
						const rect = buttonDeleteRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							onDelete(sceneObject.id);
							return;
						}
					}

					let startRotation = sceneObject.rotation

					setStartAngle(startAngleInDeg);

					onDrag = (e: MouseEvent | TouchEvent) => { // Rotate
						e.stopPropagation();

						let angleInDeg = getAngleIndDegrees(e);

						let deltaAngle = (angleInDeg - startAngleInDeg);

						onChange(sceneObject.id, { rotation: startRotation + deltaAngle });
					}

					parentElement!.addEventListener('mousemove', onDrag);
					parentElement!.addEventListener('mouseup', onMouseUp);
					parentElement!.addEventListener('touchmove', onDrag);
					parentElement!.addEventListener('touchend', onMouseUp);
				}
			}
		}


		onMouseUp = (e: MouseEvent | TouchEvent) => {
			e.preventDefault();
			console.log("mouse up");
			let tempInteractionMode = interactionMode;
			setInteractionMode("");
			setTimeout(() => {
				setInteractionMode(tempInteractionMode);
			}, 10)
			parentElement?.removeEventListener('mousemove', onDrag);
			parentElement?.removeEventListener('mouseup', onMouseUp);
			parentElement?.removeEventListener('touchmove', onDrag);
			parentElement?.removeEventListener('touchend', onMouseUp);
			parentElement!.removeEventListener('mouseout', onMouseUp);
			parentElement!.removeEventListener('touchcancel', onMouseUp);
			for (let i = 0; i < eventsToRemove.length; i++) {
				let event = eventsToRemove[i];
				parentElement?.removeEventListener('mousemove', event);
				parentElement?.removeEventListener('mouseup', event);
				parentElement?.removeEventListener('touchmove', event);
				parentElement?.removeEventListener('touchend', event);
				parentElement!.removeEventListener('mouseout', event);
				parentElement!.removeEventListener('touchcancel', event);
				// draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
			}
		}


		eventsToRemove.push(onDrag);
		eventsToRemove.push(onMouseUp);
		eventsToRemove.push(onDragStart);


		if (selectedID == sceneObject.id && !sceneObject.locked && interactionMode != "") {
			draggableSceneObjectRef.current?.addEventListener('mousedown', onDragStart);
			draggableSceneObjectRef.current?.addEventListener('touchstart', onDragStart);
		}
		return () => {
			if (parentElement) {
				for (let i = 0; i < eventsToRemove.length; i++) {
					let event = eventsToRemove[i];
					parentElement?.removeEventListener('mousemove', event);
					parentElement?.removeEventListener('mousemove', event);
					parentElement?.removeEventListener('mouseup', event);
					draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
					parentElement?.removeEventListener('touchmove', event);
					parentElement?.removeEventListener('touchmove', event);
					parentElement?.removeEventListener('touchend', event);
					parentElement!.removeEventListener('mouseout', event);
					parentElement!.removeEventListener('touchcancel', event);
					draggableSceneObjectRef.current?.removeEventListener('touchstart', event);
				}
			}

		}
	}, [selectedID, draggableSceneObjectRef.current, sceneObject.locked, interactionMode, parentElement]);


	const handleSelect = (event: { stopPropagation: () => void; }) => {
		event.stopPropagation();
		if (selectedID != sceneObject.id) {
			onSelect(sceneObject.id);
		}
	}

	const handleDelete = (event: { stopPropagation: () => void; }) => {
		event.stopPropagation();
		if (sceneObject.addedInRoom) {
			onDelete(sceneObject.id);
		} else {
			onChange(sceneObject.id, { type: "any", subtype: "any" });
		}
	}


	const toggleLocked = () => {
		onChange(sceneObject.id, { locked: !sceneObject.locked });
		setInteractionMode("");
	}
	const toggleVisible = () => {
		onChange(sceneObject.id, { visible: !sceneObject.visible });
	}

	const toggleFlipped = () => {
		onChange(sceneObject.id, { flipped: !flipped });
		setInteractionMode("");
	}

	const [flipped, setFlipped] = useState(sceneObject.flipped);
	useEffect(() => {
		setFlipped(sceneObject.flipped);
	}, [sceneObject.flipped]);

	const [objectBoxSize, setObjectBoxSize] = useState(0);

	const bgPercentage = 1;

	let objectScale = (1 + (roomScale * -0.5)) * sceneObject.scale;
	let boxSize = (objectScale < 1 ? objectBoxSize : (objectScale * objectBoxSize));
	let secretSize = objectScale > 1 ? (boxSize * bgPercentage) : (boxSize * objectScale);
	let buttonScale = 1 //+ (1/objectScale * 0.5);

	//let objectBoxSize = GetBoxSizeFromTypeSubType(sceneObject.type, sceneObject.subtype);
	useEffect(() => {
		const img = new Image();
		img.src = window.getComputedStyle(draggableSceneObjectRef.current!).backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2')
		//set a listener for when the image has loaded
		img.onload = function () {
			//set the state of the background-image to the loaded image
			setObjectBoxSize(img.width * 400 / 2316); // using the bed as the measuring stick.
		}
	}, [sceneObject.type, sceneObject.subtype]);

	const stopPropagation = (e: { stopPropagation: () => void; }) => {
		e.stopPropagation();
	}

	return (
		<div
			className={`DraggableSceneObject ${sceneObject.type} ${sceneObject.subtype} ${flipped ? 'flipped' : ''} ${selectedID == sceneObject.id ? 'selected' : ''} ${(selectedID == sceneObject.id && sceneObject.locked == false) && `${interactionMode == "move" ? "moving" : ""} ${interactionMode == "rotate" ? "rotating" : ""}`} ${(sceneObject.visible == false && hidingHiddenObjects) ? "hidden" : ""}`}
			onClick={handleSelect}
			ref={draggableSceneObjectRef}
			style={{
				transform: `translate(-50%, -50%) rotate(${sceneObject.rotation}deg) scale(${(flipped ? -1 : 1)}, 1)`,
				left: `${sceneObject.left}%`,
				top: `${sceneObject.top}%`,
				width: `${Math.max(200, secretSize / bgPercentage)}px`,
				height: `${Math.max(200, secretSize / bgPercentage)}px`,
				backgroundSize: `${secretSize}px`,
				opacity: `${sceneObject.visible ? 1 : 0.25}`,
			}}>
			<div className="content">
				{sceneObject.name}{objectScale}
			</div>

			{sceneObject.children.map(child => {
				return <DraggableSceneObject
					key={child.id}
					roomScale={roomScale}
					sceneObject={child}
					selectedID={selectedID}
					canRotate={canRotate}
					canDrag={canDrag}
					canHide={canHide}
					canDelete={canDelete}
					canFlip={canFlip}
					canLock={canLock}
					canScale={canScale}
					minLabel={minLabel}
					maxLabel={maxLabel}
					hidingHiddenObjects={hidingHiddenObjects}
					onSelect={onSelect}
					onDelete={onDelete}
					onChange={onChange}
				/>
			})}

			{(sceneObject.letter != "" && (!minLabel || sceneObject.letter >= minLabel) && (!maxLabel || sceneObject.letter <= maxLabel)) && (
				<div className="letter"
					style={{
						transform: `translate(-50%, -50%) rotate(${(flipped ? 1 : -1) * sceneObject.rotation}deg) scale(${(flipped ? -1 : 1)}, 1)`
					}}>{sceneObject.letter}</div>
			)}

			{selectedID == sceneObject.id &&
				<>
					{canDrag && !sceneObject.locked && !sceneObject.noDrag && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${(flipped ? 1 : -1) * sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							onClick={() => setInteractionMode("move")}
							ref={buttonMoveRef}
							className={`buttonMove ${interactionMode == "move" ? "selected" : ""}`}></button>
					)}
					{canRotate && !sceneObject.locked && !sceneObject.noRotation && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${(flipped ? 1 : -1) * sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							onClick={() => {
								setInteractionMode("rotate")
							}}
							className={`buttonRotate ${interactionMode == "rotate" ? "selected" : ""}`}
							ref={buttonRotateRef}></button>
					)}
					{canScale && !sceneObject.locked && !sceneObject.noScale && (
						// <button
						// 	style={{ transform: `translate(-50%, -50%) rotate(${-sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
						// 	onClick={() => setInteractionMode("scale")}
						// 	className={`buttonScale ${interactionMode == "scale" ? "selected" : ""}`}
						// 	ref={buttonScaleRef}></button>
						<>
							<div ref={buttonScaleTLRef} className='scalePaddle top left'></div>
							<div ref={buttonScaleTRRef} className='scalePaddle top right'></div>
							<div ref={buttonScaleBLRef} className='scalePaddle bottom left'></div>
							<div ref={buttonScaleBRRef} className='scalePaddle bottom right'></div>
						</>
					)}
					{canFlip && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${(flipped ? 1 : -1) * sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							className='buttonFlip'
							onClick={toggleFlipped}></button>
					)}
					{canDelete && !sceneObject.locked && !sceneObject.noDelete && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${(flipped ? 1 : -1) * sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							className='buttonDelete'
							ref={buttonDeleteRef}
							onClick={handleDelete}></button>
					)}
					{canHide && !sceneObject.locked && !sceneObject.noHide && (
						<button className='buttonHide' onClick={toggleVisible}>
							{sceneObject.visible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
						</button>
					)}
					{canLock && (
						<button className='buttonLock' onClick={toggleLocked}>
							{sceneObject.locked ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUnlock} />}
						</button>
					)}
				</>
			}
		</div>
	);
}