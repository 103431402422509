
import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationTabContent.css';
import { Config } from '../../../Model/SOW';
import { BOOM_VENDORS } from '../../../Model/DropdownOptions';
import ConfigurationVideoArea from '../ConfigurationVideoArea/ConfigurationVideoArea';
import ConfigurationAudioArea from '../ConfigurationAudioArea/ConfigurationAudioArea';
import Dropdown from '../../Dropdown/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
interface ConfigurationTabContentProps {
	config: Config;
	configNumber: number;
	onConfigChange: (config: Config) => void;
	onConfigDeleted: (config: Config) => void;
}
export default function ConfigurationTabContent({ config, configNumber, onConfigChange, onConfigDeleted }: ConfigurationTabContentProps) {
	const [userChanged, setUserChanged] = useState<boolean>(false);
	const userChangedRef = useRef(userChanged);
	useEffect(() => {
		userChangedRef.current = userChanged;
	}, [userChanged]);

	const [boomVendor, setBoomVendor] = useState(config.boomVendor);
	const [refreshingBottom, setRefreshingBottom] = useState<boolean>(false);
	const [operatingRoomNumbers, setOperatingRoomNumbers] = useState(config.operatingRoomNumbers);
	const [configName, setConfigName] = useState(config.configName??("Config " + configNumber));
	const [videoSystem, setVideoSystem] = useState(config.video.system);
	useEffect(() => {
		setRefreshingBottom(true);
	},[videoSystem])
	useEffect(() => {
		if(refreshingBottom){
			setRefreshingBottom(false);
		}
	},[refreshingBottom])

	const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);
	const updateTimeoutRef = useRef(updateTimeout);
	useEffect(() => {
		updateTimeoutRef.current = updateTimeout;
	}, [updateTimeout]);

	useEffect(() => {
		if(userChangedRef.current){
			if(updateTimeoutRef.current){
				clearTimeout(updateTimeoutRef.current);
			}
			let updateTimeout = setTimeout(() => {
				config.configName = configName;
				onConfigChange(config);
			}, 500);
			setUpdateTimeout(updateTimeout);
			return () => {
				clearTimeout(updateTimeout);
			}
		}
	}, [configName]);

	const deleteConfigName = () => {
		// the first 22 characters of the config name + "..."
		if (configName.length > 22) {
			return configName.substring(0, 22) + "...";
		}
		return configName;
	}

	return (
		<div className='ConfigurationTabContent'>
			<div className='body'>
				<div className='tabContentHeader'>

					<div className='col'>
						<div className='label'>Configuration Name</div>
						<input type='text'
							className={`configNameInput ${configName == "" ? "unfilled" : ""}`}
							placeholder='Enter a Configuration Name'
							defaultValue={configName}
							onChange={(e) => {
								setUserChanged(true);
								setConfigName(e.target.value);
								// config.configName = e.target.value;
								// onConfigChange(config);
							}}></input>
					</div>

					<div className='col'>
						<div className='label'>Operating Room Number(s)</div>
						<input type='text'
							className={`orNumberInput ${operatingRoomNumbers == "" ? "unfilled" : ""}`}
							placeholder='Enter OR Room Number(s)'
							defaultValue={operatingRoomNumbers}
							onChange={(e) => {
								setOperatingRoomNumbers(e.target.value);
								config.operatingRoomNumbers = e.target.value;
								onConfigChange(config);
							}}></input>
					</div>

					<div className='col'>
						<div className='label'>Boom Vendor</div>
						<Dropdown
							options={BOOM_VENDORS}
							defaultValue={boomVendor}
							onSelect={(option) => {
								setBoomVendor(option);
								config.boomVendor = option;
								onConfigChange(config);
							}}></Dropdown>
					</div>

					{configNumber > 1 && (
						<div className='buttonDelete'
							onClick={() => {
								onConfigDeleted(config)
							}}><FontAwesomeIcon icon={faTrash} /> Delete {deleteConfigName()??("Config "+configNumber)}</div>
					)}
				</div>
				<ConfigurationVideoArea
					config={config}
					onChange={updatedConfig => {
						onConfigChange(updatedConfig);
						setVideoSystem(updatedConfig.video.system);
					}}></ConfigurationVideoArea>
				{!refreshingBottom && <>
					<ConfigurationAudioArea
						config={config}
						onChange={updatedConfig => {
							onConfigChange(updatedConfig);
						}}></ConfigurationAudioArea>
				</>}

			</div>
		</div>
	);
}