export function pad2(input:number){
	return `${input<10?"0":""}${input}`
}

export function shortDate(date:Date){
  return `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
}

export function sowDate(date:Date){
	return shortDate(date)
}