

import React, { useRef, useState, useEffect } from 'react';
import './PopupSelectSubtype.css';
import { SOW } from '../../../Model/SOW';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
interface PopupSelectSubtypeProps {
	type: string;
	subtype: string;
	onSave: (subtype: string) => void;
	onDismiss: () => void;
}
export default function PopupSelectSubtype({ type, subtype, onSave, onDismiss }: PopupSelectSubtypeProps) {
	const [selectedSubtype, setSelectedSubtype] = useState(subtype ?? "");

	const anesMachineSubtypes = [
		{ className: "anesthesiaMachine", label: "Anesthesia Machine" },
		{ className: "noImage", label: "No Image" },
	]

	const anesBoomSubtypes = [
		{ className: "anesthesiaBoom", label: "Anesthesia Boom" },
		{ className: "straightDropAnesBoom", label: "Straight Drop Anes Boom" },
		{ className: "vpaBoom", label: "VPA Boom" },
		{ className: "noImage", label: "No Image" },
	]

	const boomSubtypes = [
		{ className: "boomMonLight", label: "EQ Boom, w/Mon & Light" },
		{ className: "boomLight", label: "EQ Boom, w/Light" },
		{ className: "boomMon", label: "EQ Boom, w/Monitor" },
		{ className: "eqBoom", label: "Equipment Boom" },
		{ className: "vpaBoom", label: "VPA Boom" },
		{ className: "noShelves", label: "Equipment Boom No Shelves" },
		{ className: "noImage", label: "No Image" },
	]

	const spindleSubtypes = [
		{ className: "standaloneLight", label: "Standalone Light" },
		{ className: "standaloneMonitor", label: "Standalone Monitor" },
		{ className: "lrgMonitorCarrier", label: "Large Monitor Carrier" },
		{ className: "oneLight", label: "1 Light" },
		{ className: "oneMonitor", label: "1 Monitor" },
		{ className: "oneLightOneMonitor", label: "1 Light & 1 Monitor" },
		{ className: "oneLightTwoMonitors", label: "1 Light & 2 Monitors" },
		{ className: "twoLightsOneMonitor", label: "2 Lights & 1 Monitor" },
		{ className: "twoLightsTwoMonitors", label: "2 Lights & 2 Monitors" },
		{ className: "noImage", label: "No Image" },
	]

	const bedSubtypes = [
		{ className: "patientOnBed1", label: "Patient on Bed" },
		{ className: "patientOnBed2", label: "Patient on Bed" },
		{ className: "emptyBed", label: "Empty Bed" },
		{ className: "angioBed", label: "Angio Bed" },
		{ className: "fixedFlouro", label: "Fixed Flouro" },
		{ className: "noImage", label: "No Image" },

	];

	const touchScreenSubtypes = [
		{ className: "wallMounted", label: "Wall Mounted Touchscreen" },
		{ className: "wallMountedWithKeyboard", label: "Wall Mounted Touchscreen w/Keyboard" },
		{ className: "deskMountedWithKeyboard", label: "Desk Mounted Touchscreen w/Keyboard" },
		{ className: "keyboard", label: "Keyboard" },
		{ className: "noImage", label: "No Image" },
	]

	const computerSubtypes = [
		{ className: "AIDA", label: "AIDA" },
		{ className: "wallMounted", label: "Wall Mounted Computer" },
		{ className: "deskMounted", label: "Desk Mounted Computer w/Keyboard" },
		{ className: "wallMountedWithKeyboard", label: "Wall Mounted Computer w/Keyboard" },
		{ className: "deskMountedWithKeyboard", label: "Desk Mounted Computer w/Keyboard" },
		{ className: "keyboard", label: "Keyboard" },
		{ className: "noImage", label: "No Image" },
	]

	const displaySubTypes = [
		{ className: "wallDisplay55", label: "55\" Wall Display" },
		{ className: "wallDisplay65", label: "65\" Wall Display" },
		{ className: "wallDisplay75", label: "75\" Wall Display" },
		{ className: "wallDisplayWCamera55", label: "55\" Wall Display w/Camera" },
		{ className: "wallDisplayWCamera65", label: "65\" Wall Display w/Camera" },
		{ className: "wallDisplayWCamera75", label: "75\" Wall Display w/Camera" },
		{ className: "noImage", label: "No Image" },
	]

	const deskSubTypes = [
		{ className: "largeLDesk", label: "Large \"L\" Desk" },
		{ className: "shortLDesk", label: "Short \"L\" Desk" },
		{ className: "straightDesk", label: "Straight Desk" },
		{ className: "narrowDesk", label: "Narrow Desk" },
		{ className: "extraNarrowDesk", label: "Extra Narrow Desk" },
		{ className: "noImage", label: "No Image" },
	]

	const rackSubTypes = [
		{ className: "rack1", label: "" },
		{ className: "rack2", label: "" },
		{ className: "noImage", label: "No Image" },
	]

	const roomCameraSubTypes = [
		{ className: "roomCamera", label: "Camera-PTZ" },
		{ className: "cameraPTZCeiling", label: "Camera-PTZ-Ceiling-Mount" },
		{ className: "noImage", label: "No Image" },
	]

	const doorSubTypes = [
		{ className: "single", label: "Single Door" },
		{ className: "double", label: "Double Door" },
		{ className: "noImage", label: "No Image" },
	]

	const robotSubTypes = [
		{ className: "surgeonConsole", label: "Surgeon Console" },
		{ className: "equipmentTower", label: "Equipment Tower" },
		{ className: "davinciLeft", label: "DaVinci Left" },
		{ className: "davinciRight", label: "DaVinci Right" },
		{ className: "davinciStraight", label: "DaVinci Straight" },
		{ className: "noImage", label: "No Image" },
	]
	const ceilingEquipment = [
		{ className: "speaker", label: "Speaker" },
		{ className: "tomsCamera", label: "TOMS Camera" },
		{ className: "cameraPTZCeiling", label: "Camera-PTZ-Ceiling-Mount" },
		{ className: "noImage", label: "No Image" },
	]

	const wallPlateSubTypes = [
		{ className: "data", label: "Wall Data" },
		{ className: "inputOther", label: "Wall Input Other" },
		{ className: "inputVideo", label: "Wall Input Video" },
		{ className: "powerDual", label: "Wall Power Dual" },
		{ className: "powerQuad", label: "Wall Power Quad" },
		{ className: "noImage", label: "No Image" },
	];

	let allSubtypes = [
		...boomSubtypes,
		...spindleSubtypes,
		...bedSubtypes,
		...touchScreenSubtypes,
		...displaySubTypes,
		...deskSubTypes,
		...rackSubTypes,
		...doorSubTypes,
		...robotSubTypes,
		...ceilingEquipment,
		...computerSubtypes,
		...roomCameraSubTypes,
		...anesBoomSubtypes,
		...wallPlateSubTypes,
		...anesMachineSubtypes
	]

	let options = [] as { className: string, label: string }[];
	let title = "";
	switch (type) {
		case "anesMachine":
			options = anesMachineSubtypes;
			title = "Anesthesia Machines"
			break;
		case "anesBoom":
			options = anesBoomSubtypes;
			title = "Anesthesia Booms"
			break;
		case "boom":
			options = boomSubtypes;
			title = "Equipment Booms"
			break;
		case "spindle":
			options = spindleSubtypes;
			title = "Monitor Carriers and Lights"
			break;
		case "bed":
			options = bedSubtypes;
			title = "Beds"
			break;
		case "touchscreen":
			options = touchScreenSubtypes;
			title = "Touchscreens"
			break;
		case "display":
			options = displaySubTypes;
			title = "Wall Displays / Room Cameras"
			break;
		case "desk":
			options = deskSubTypes;
			title = "Nursing Desks"
			break;
		case "rack":
			options = rackSubTypes;
			title = "Racks"
			break;
		case "door":
			options = doorSubTypes;
			title = "Doors"
			break;
		case "robot":
			options = robotSubTypes;
			title = "Robots"
			break;
		case "ceilingEquipment":
			options = ceilingEquipment;
			title = "Ceiling Equipment"
			break;
		case "computer":
			options = computerSubtypes;
			title = "Computers"
			break;
		case "camera":
			options = roomCameraSubTypes;
			title = "Room Cameras"
			break;
		case "wallPlate":
			options = wallPlateSubTypes;
			title = "Wall Plates"
			break;
	}




	return (
		<div className={`PopupSelectSubtype`}>
			<div className='backgroundDismiss' onClick={() => {
				onDismiss();
			}} />
			<div className='popup'>
				<div className='header'>
					<div className='buttonCancel' onClick={() => {
						//writeCSS();
						onDismiss();
					}}>Cancel</div>
					<div className={`buttonSave ${selectedSubtype == "" ? "disabled" : ""}`} onClick={(e) => {
						e.stopPropagation();
						onSave(selectedSubtype);
					}}>Done</div>
				</div>
				<div className='options'>
					<div className='title'>{title}</div>
					{options.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedSubtype(option.className);
						}}
							onDoubleClick={(e) => {
								e.stopPropagation();
								onSave(option.className);
							}}>
							<div className={`tile ${type} ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}