
import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationVideoInputs.css';
import { Config, VideoInput } from '../../../Model/SOW';
import VideoInputRow from '../VideoInputRow/VideoInputRow';
import { blankVideoInput } from '../../../Utils/BlankObjects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import DraggableTableBody from '../../DraggableTableBody/DraggableTableBody';
import { INPUTS, INPUTS_OFE } from '../../../Model/DropdownOptions';
interface ConfigurationVideoInputsProps {
	config: Config;
	onChange: (config: Config) => void;
}
export default function ConfigurationVideoInputs({ config, onChange }: ConfigurationVideoInputsProps) {
	const [hasOFESignalType, setHasOFESignalType] = useState<boolean>(false);
	const [inputsOrder, setInputsOrder] = useState<string[]>([]);
	useEffect(() => {
		let newOrder: string[] = [];
		let hasOFESignalType = false
		config.video.inputs.forEach((input) => {
			newOrder.push(input.id);
			if(INPUTS_OFE.includes(input.inputsTPButtonText)) {
				hasOFESignalType = true;
			}
			if(INPUTS.includes(input.inputsTPButtonText) == false) {
				hasOFESignalType = true;
			}
		});
		let different = false;
		if (newOrder.length != inputsOrder.length) {
			different = true;
		} else {
			for (let i = 0; i < newOrder.length; i++) {
				if (newOrder[i] != inputsOrder[i]) {
					different = true;
					break;
				}
			}
		}
		if (different) {
			setInputsOrder(newOrder);
		}
		setHasOFESignalType(hasOFESignalType);
	}, [config.video.inputs]);

	let checkForOFE = (config:Config) => {
		let hasOFESignalType = false
		config.video.inputs.forEach((input) => {
			if(INPUTS_OFE.includes(input.inputsTPButtonText)) {
				hasOFESignalType = true;
			}
			if(INPUTS.includes(input.inputsTPButtonText) == false) {
				hasOFESignalType = true;
			}
		});
		setHasOFESignalType(hasOFESignalType);
	};

	return (
		<div className={`ConfigurationVideoInputs`}>
			<div className='header'>Video Inputs</div>
			<table className='videoInputsTable'>
				<thead>
					{inputsOrder.length > 0 && (
						<tr>
							<th>Button Location</th>
							<th>Inputs (Button Text)</th>
							<th>Icon</th>
							<th>Signal Originates</th>
							{hasOFESignalType && <th>O.F.E. Signal Type</th>}
							<th>KVM?</th>
						</tr>
					)}
				</thead>
				<DraggableTableBody
					childClassName={'VideoInputRow'}
					keyForIndex={(index: number) => {
						return config.video.inputs[index].id;
					}}
					orderedItems={inputsOrder}
					onOrderUpdated={(newOrderedItems) => {
						//newordereditems contains the new order for the ids of the inputs
						let newInputs: VideoInput[] = newOrderedItems.map((id, index) => {
							let input = config.video.inputs.find((input) => {
								return input.id == id;
							})!;
							input.buttonLocation = (index + 1) + "";
							return input;
						});
						config.video.inputs = newInputs;
						setInputsOrder(newOrderedItems);
						onChange(config);
					}}>
					{config.video.inputs.map((input, index) => {
						return <VideoInputRow
							videoInput={input}
							key={input.id}
							hasOFESignalType={hasOFESignalType}
							onDelete={(obj) => {
								let index = config.video.inputs.indexOf(obj);
								config.video.inputs.splice(index, 1);
								onChange(config);
								checkForOFE(config);

								let newOrder: string[] = [];
								config.video.inputs.forEach((input) => {
									newOrder.push(input.id);
								});
								setInputsOrder(newOrder);
							}}
							onChange={(videoInput) => {
								config.video.inputs[index] = videoInput;
								onChange(config);
								checkForOFE(config);
							}}></VideoInputRow>
					})}
				</DraggableTableBody>
			</table>
			<div className='buttonAdd'
				onClick={(e) => {
					if(config.video.inputs.length < 56){ //set to max 56 as per erics request
						let blankVideo = blankVideoInput();
						blankVideo.buttonLocation = (config.video.inputs.length + 1) + "";
						config.video.inputs.push(blankVideo);
	
						let newOrder: string[] = [];
						config.video.inputs.forEach((input) => {
							newOrder.push(input.id);
						});
						setInputsOrder(newOrder);
	
						onChange(config);

						(e.target as HTMLDivElement).scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
					}
				}}><FontAwesomeIcon icon={faPlusCircle} /> Add Video Input</div>
		</div>
	);
}