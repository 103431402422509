import { FC, useEffect, useState } from "react";
import { User } from "firebase/auth";
import { sharedFirebaseAuth } from "../Utils/SharedFirebase";
import { AuthContext } from "./AuthContext";

export interface AuthProviderProps {
    children: JSX.Element;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);

  
  useEffect(() => {
    const unsubscribe = sharedFirebaseAuth.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });
    return unsubscribe
  }, []);

  return (
    <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
  );
};