
import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationVideoOutputs.css';
import { Config, VideoInput, VideoOutput } from '../../../Model/SOW';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { blankVideoInput, blankVideoOutput } from '../../../Utils/BlankObjects';
import VideoOutputRow from '../VideoOutputRow/VideoOutputRow';
import DraggableTableBody from '../../DraggableTableBody/DraggableTableBody';
import VideoInputRow from '../VideoInputRow/VideoInputRow';
import { INPUTS_OFE } from '../../../Model/DropdownOptions';
interface ConfigurationVideoOutputsProps {
	config: Config;
	onChange: (config: Config) => void;
}
export default function ConfigurationVideoOutputs({ config, onChange }: ConfigurationVideoOutputsProps) {
	const [hasOFESignalType, setHasOFESignalType] = useState<boolean>(false);
	const [hasDisplay, setHasDisplay] = useState<boolean>(false);
	const [hasWallDisplay, setHasWallDisplay] = useState<boolean>(false);

	const recalcualteHasInfo = (config:Config) => {
		let hasDisplay = false;
		let hasWallDisplay = false;
		let hasOFESignalType = false;
		config.video.outputs.forEach((output) => {
			if(INPUTS_OFE.includes(output.outputsButtonText)) {
				hasOFESignalType = true;
			}
			if (output.icon == "Surgical Display") {
				hasDisplay = true;
			}
			if (output.icon == "Wall Display") {
				hasWallDisplay = true;
			}
		});
		setHasOFESignalType(hasOFESignalType);
		setHasDisplay(hasDisplay);
		setHasWallDisplay(hasWallDisplay);
	}
	useEffect(() => {
		recalcualteHasInfo(config);
	}, [config.video.outputs]);

	const [outputsOrder, setOutputsOrder] = useState<string[]>([]);
	useEffect(() => {
		let newOrder: string[] = [];
		config.video.outputs.forEach((output) => {
			newOrder.push(output.id);
		});
		setOutputsOrder(newOrder);
	}, [config.video.outputs]);

	return (
		<div className={`ConfigurationVideoOutputs`}>
			<div className='header'>Video Outputs</div>
			<table className='videoOutputsTable'>
				<thead>
					{outputsOrder.length > 0 && (
						<tr>
							<th>Button Location</th>
							<th>Outputs (Button Text)</th>
							<th>Icon</th>
							<th>Signal Location</th>
							{hasOFESignalType &&<th>O.F.E. Signal Type</th>}
							{hasDisplay && <th>Surgical Display Size</th>}
							{hasWallDisplay && <th>Wall Display Size</th>}
						</tr>
					)}
				</thead>
				<DraggableTableBody
					childClassName={'VideoOutputRow'}
					keyForIndex={(index: number) => {
						return config.video.outputs[index].id;
					}}
					orderedItems={outputsOrder}
					onOrderUpdated={(newOrderedItems) => {
						//newordereditems contains the new order for the ids of the outputs
						let newOutputs: VideoOutput[] = newOrderedItems.map((id, index) => {
							let input = config.video.outputs.find((input) => {
								return input.id == id;
							})!;
							input.buttonLocation = (index + 1) + "";
							return input;
						});
						config.video.outputs = newOutputs;
						setOutputsOrder(newOrderedItems);
						onChange(config);
					}}>
					{config.video.outputs.map((output, index) => {
						return <VideoOutputRow
							videoOutput={output}
							key={output.id}
							hasOFESignalType={hasOFESignalType}
							onDelete={(obj) => {
								let index = config.video.outputs.indexOf(obj);
								config.video.outputs.splice(index, 1);
								onChange(config);
								recalcualteHasInfo(config);
								
								let newOrder: string[] = [];
								config.video.outputs.forEach((output) => {
									newOrder.push(output.id);
								});
								setOutputsOrder(newOrder);
							}}
							onChange={(videoOutput) => {
								config.video.outputs[index] = videoOutput;
								onChange(config);
								recalcualteHasInfo(config);
							}}
							hasDisplay={hasDisplay}
							hasWallDisplay={hasWallDisplay}></VideoOutputRow>
					})}
				</DraggableTableBody>
			</table>
			<div className='buttonAdd'
				onClick={(e) => {
					if(config.video.outputs.length < 40){//set to max 40 as per erics request
						let blankVideo = blankVideoOutput();
						blankVideo.buttonLocation = (config.video.outputs.length + 1) + "";
						config.video.outputs.push(blankVideo);
						onChange(config);
	
	
						let newOrder: string[] = [];
						config.video.outputs.forEach((output) => {
							newOrder.push(output.id);
						});
						setOutputsOrder(newOrder);
						(e.target as HTMLDivElement).scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
					}

				}}><FontAwesomeIcon icon={faPlusCircle} /> Add Video Output</div>
		</div>
	);
}