
import React, { useEffect, useRef, useState } from 'react';
import { SceneObject } from '../../../Model/SceneObject';
import './DraggableScene.css';
import { faAnchor, faArrows, faBars, faCheck, faCircleNodes, faHamburger, faLock, faPlus, faRotate, faTrash, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DraggableSceneObject } from '../DraggableSceneObject/DraggableSceneObject';

interface DraggableSceneProps {
	className?: string;
	roomScale?: number;
	sceneObjects: SceneObject[];
	canRotate: boolean;
	canScale: boolean;
	canFlip: boolean;
	canDrag: boolean;
	canDelete: boolean;
	canHide: boolean;
	canLock: boolean;
	minLabel?: string;
	maxLabel?: string;
	hidingHiddenObjects?: boolean;
	backgroundURL?: string;
	backgroundImageHeight?: number;
	onChange: (sceneObjects:SceneObject[]) => void;
	onSelectSceneObject: (sceneObjectID:string) => void;
}

let parentElement:HTMLElement|null = null;

export const DraggableScene: React.FC<DraggableSceneProps> = ({
	className,
	sceneObjects,
	roomScale,
	canRotate,
	canScale,
	canFlip,
	canDrag,
	canDelete,
	canHide,
	canLock,
	minLabel,
	maxLabel,
	hidingHiddenObjects,
	backgroundURL,
	backgroundImageHeight,
	onSelectSceneObject,
	onChange
}) => {

	const draggableSceneRef = useRef<HTMLDivElement>(null);
  const [selectedID, setSelectedID] = React.useState<string>('');
	const [sortedSceneObjects, setSortedSceneObjects] = React.useState<SceneObject[]>(sceneObjects);

	const sceneObjectListsChanged = (a: SceneObject[], b: SceneObject[]) => {
		if(a.length !== b.length){
			return true;
		}
		for(let i = 0; i < a.length; i++){
			let aObject = a[i];
			let bObject = b[i];
			for(let key in aObject){
				if(key == "children"){
					continue;
				}
				if((aObject as any)[key] !== (bObject as any)[key]){
					return true;
				}
			}
		}
		return false;
	}

	useEffect(() => {
		if(selectedID !== ""){
			let sortedIDs = sortedSceneObjects.map((obj) => obj.id);
			// move selectedID to the top
			sortedIDs.splice(sortedIDs.indexOf(selectedID), 1);
			sortedIDs.push(selectedID);

			let tempSortedSceneObjects = [...sceneObjects];
			//sort keeps the normal order, but bubbles the selected object to the top
			tempSortedSceneObjects.sort((a, b) => {
				let aIndex = sortedIDs.indexOf(a.id);
				let bIndex = sortedIDs.indexOf(b.id);
				return aIndex - bIndex;
			});
			let afterSortedIDs = tempSortedSceneObjects.map((obj) => obj.id);
			let sortOrderChanged = false;
			for(let i = 0; i < sortedIDs.length; i++){
				if(sortedIDs[i] !== afterSortedIDs[i]){
					sortOrderChanged = true;
					break;
				}
			}
			if(sceneObjectListsChanged(tempSortedSceneObjects, sceneObjects) || sortOrderChanged){
				setSortedSceneObjects(tempSortedSceneObjects);
				onChange(tempSortedSceneObjects);
			}
		}
	}, [sceneObjects, selectedID]);

	useEffect(() => {
		onSelectSceneObject(selectedID);
	}, [selectedID]);

	const selectOrDeselectClosest = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {

		//if they clicked in a floating button then cancel
		let clientX, clientY = 0;
		clientX = e.clientX;
		clientY = e.clientY;
		let buttons = draggableSceneRef.current!.getElementsByTagName("button");
		for(let i = 0; i < buttons.length; i++){
			let button = buttons[i];
			const rect = button.getBoundingClientRect();
			if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {
				return;
			}
		}


		// get the x and y of the click in the percentage of the width and height of the target
		const target = e.target as HTMLElement;
		const rect = target.getBoundingClientRect();
		const x = (clientX - rect.left) / rect.width * 100;
		const y = (clientY - rect.top) / rect.height * 100;

		// loook through the sortedSceneObjects and find the closest one to the click that is less than 10% away
		let closestSceneObject: SceneObject|null = null;
		let closestDistance = 20;
		for(let s in sortedSceneObjects){
			let sceneObject = sortedSceneObjects[s];
			let distance = Math.sqrt(Math.pow(sceneObject.left - x, 2) + Math.pow(sceneObject.top - y, 2));
			if(distance < closestDistance && sceneObject.type !== "none" && sceneObject.subtype !== "none"){
				closestSceneObject = sceneObject;
				closestDistance = distance;
			}
			for(let c in sceneObject.children){
				let child = sceneObject.children[c];
				let distance = Math.sqrt(Math.pow(child.left - x, 2) + Math.pow(child.top - y, 2));
				if(distance < closestDistance && child.type !== "none" && child.subtype !== "none"){
					closestSceneObject = child;
					closestDistance = distance;
				}
			}
		}

		if(closestSceneObject){
			setSelectedID(closestSceneObject.id);
		}else{
			setSelectedID("");
		}

	}


	return (
		<div className={`DraggableScene ${className??""}`}
			ref={draggableSceneRef}
			style={(className == "image" && backgroundURL) ? 
				{
					backgroundImage: `url(${backgroundURL})`,
					height:backgroundImageHeight,
					top: (196 + (800 - backgroundImageHeight!)/2)
				} : {}}
			onClick={(e) => selectOrDeselectClosest(e)}>
			{sortedSceneObjects.map((sceneObject,index) => {
				return <DraggableSceneObject
					key={`scene-object-${sceneObject.id}-${index}`}
					roomScale={roomScale??1}
					sceneObject={sceneObject}
					selectedID={selectedID}
					canRotate={canRotate}
					canScale={canScale}
					canFlip={canFlip}
					canDrag={canDrag}
					canDelete={canDelete}
					canHide={canHide}
					canLock={canLock}
					minLabel={minLabel}
					maxLabel={maxLabel}
					hidingHiddenObjects={hidingHiddenObjects}
					onSelect={(id) => {
						setSelectedID(id);
					}}
					onChange={(id: string, changes: Partial<SceneObject>) => {
						let index = sceneObjects.findIndex((obj) => obj.id === id);
						let sceneObject:SceneObject|null = null;
						if(index != -1){
							sceneObject = sceneObjects[index];
						}else{
							//gotta go through their children
							for(let obj of sceneObjects){
								if(obj.children){
									let childIndex = obj.children.findIndex((obj) => obj.id === id);
									if(childIndex != -1){
										sceneObject = obj.children[childIndex];
										break;
									}
								}
							}
						}
						if(sceneObject){
							for(var k in changes){
								(sceneObject as any)[k] = (changes as any)[k];
							}
							let newSceneObjects = [...sceneObjects];
							onChange(newSceneObjects);
						}
					}}
					onDelete={(id) => {
						let index = sceneObjects.findIndex((obj) => obj.id === id);
						let newSceneObjects = [...sceneObjects];
						if(index != -1){
							newSceneObjects.splice(index, 1);
						}else{
							//gotta go through their children
							for(let sceneObject of newSceneObjects){
								if(sceneObject.children){
									let childIndex = sceneObject.children.findIndex((obj) => obj.id === id);
									if(childIndex != -1){
										sceneObject.children.splice(childIndex, 1);
									}
								}
							}
						}
						onChange(newSceneObjects);
					}}
				/>
			})}
		</div>
	);
}