

import React, { useRef, useState, useEffect } from 'react';
import './ConfigHeader.css';
import { Config, SOW } from '../../../Model/SOW';
import Dropdown from '../../Dropdown/Dropdown';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
interface ConfigHeaderProps {
	children?: React.ReactNode;
	sow: SOW;
	selectedConfig: Config;
	forPDF: boolean;
}
export default function ConfigHeader({ children, sow, selectedConfig, forPDF }: ConfigHeaderProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
	}, dispatch);

	return (
		<div className={`ConfigHeader`}>
			{sow.hospitalName}<br />
			OR Room#: {selectedConfig?.operatingRoomNumbers}
			{forPDF ? (
				<div className='configLabel'></div>
			) : (
				<>
						{children}
				</>
			)}
		</div>
	);
}