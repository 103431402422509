
import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationAudioInputs.css';
import { AudioInput, Config, VideoInput } from '../../../Model/SOW';
import AudioInputRow from '../AudioInputRow/AudioInputRow';
import { blankAudioInput } from '../../../Utils/BlankObjects';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import DraggableTableBody from '../../DraggableTableBody/DraggableTableBody';
import VideoInputRow from '../VideoInputRow/VideoInputRow';
interface ConfigurationAudioInputsProps {
	config: Config;
	onChange: (config: Config) => void;
}
export default function ConfigurationAudioInputs({ config, onChange }: ConfigurationAudioInputsProps) {
	const [userChanged, setUserChanged] = useState<boolean>(false);
	const [inputsOrder, setInputsOrder] = useState<string[]>([]);
	useEffect(() => {
		if (userChanged) {
			let newOrder: string[] = [];
			config.audio.inputs.forEach((input) => {
				newOrder.push(input.id);
			});
			setInputsOrder(newOrder);

		}
	}, [userChanged, config.audio.inputs]);

	return (
		<div className={`ConfigurationAudioInputs`}>
			<div className='header'>Audio Inputs</div>
			<table className='videoOutputsTable'>
				<thead>
					{inputsOrder.length > 0 && (
						<tr>
							<th>Button Location</th>
							<th>Audio Input (Button Text)</th>
							<th>Signal Originates</th>
						</tr>
					)}
				</thead>
				<DraggableTableBody
					childClassName={'AudioInputRow'}
					keyForIndex={(index: number) => {
						return config.audio.inputs[index].id;
					}}
					orderedItems={inputsOrder}
					onOrderUpdated={(newOrderedItems) => {
						//newordereditems contains the new order for the ids of the inputs
						let newInputs: AudioInput[] = newOrderedItems.map((id, index) => {
							let input = config.audio.inputs.find((input) => {
								return input.id == id;
							})!;
							input.buttonLocation = (index + 1) + "";
							return input;
						});
						config.audio.inputs = newInputs;
						setInputsOrder(newOrderedItems);
						onChange(config);
						setUserChanged(true);
					}}>
					{config.audio.inputs.map((input, index) => {
						return <AudioInputRow
							audioInput={input}
							key={input.id}
							onDelete={(obj) => {
								let index = config.audio.inputs.indexOf(obj);
								config.audio.inputs.splice(index, 1);
								onChange(config);
								setUserChanged(true);

								let newOrder: string[] = [];
								config.audio.inputs.forEach((input) => {
									newOrder.push(input.id);
								});
								setInputsOrder(newOrder);
							}}
							onChange={(audioInput) => {
								config.audio.inputs[index] = audioInput;
								onChange(config);
								setUserChanged(true);
							}}></AudioInputRow>
					})}
				</DraggableTableBody>
			</table>
			<div className='buttonAdd'
				onClick={(e) => {
					if (config.audio.inputs.length < 10) {
						let blankAudio = blankAudioInput();
						blankAudio.buttonLocation = (config.audio.inputs.length + 1) + "";
						config.audio.inputs.push(blankAudio);
						onChange(config);

						let newOrder: string[] = [];
						config.audio.inputs.forEach((input) => {
							newOrder.push(input.id);
						});
						setInputsOrder(newOrder);
						setUserChanged(true);
						(e.target as HTMLDivElement).scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
					}

				}}><FontAwesomeIcon icon={faPlusCircle} /> Add Audio Input</div>
		</div>
	);
}