import React, { useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import "./ScreenPDFViewer.css"
import { pdfjs } from 'react-pdf';
import { PDFOutputResults } from '../../Components/PDFOutput/PDFOutput';
import { sendEmail } from '../../Utils/EmailSaveAndLoad';
import { SOW } from '../../Model/SOW';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


interface ScreenPDFViewerProps {
  sow:SOW;
  pdfData: PDFOutputResults;
  onDismiss: () => void;
}

const ScreenPDFViewer: React.FC<ScreenPDFViewerProps> = ({ pdfData, sow, onDismiss }) => {
  const [numPages, setNumPages] = React.useState<number | null>(null);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  // const url = URL.createObjectURL(pdfData);
  return (
    <div className="Screen ScreenPDFViewer">
      <div className='header'>
        <div className='buttonBack' onClick={e=>{e.stopPropagation(); onDismiss()}}>Back</div>
        <div className='buttonEmail' onClick={() =>{

          sendEmail(pdfData.pdfData, sow.name);
          
          setTimeout(() => {
            onDismiss();
          }, 1000);

        }}><FontAwesomeIcon icon={faDownload} /> Download</div>
      </div>
      <div className='pdfArea'>
        <div className='content'>
          {pdfData.imageDataURLs.map((imageDataURL, index) => {
            return <div className='pdfPage' key={index} style={{backgroundImage:`url(${imageDataURL})`}} />
          })}
          {/* <Document
            file={url}
            
            onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages || 0), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document> */}
        </div>
      </div>
    </div>
  );
};

export default ScreenPDFViewer;
