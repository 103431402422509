import React, { useEffect, useRef, useState } from 'react';
import { SceneObject } from '../../../Model/SceneObject';
import './BoomDraggableScene.css';
import { faAnchor, faArrows, faBars, faCheck, faCircleNodes, faHamburger, faLock, faPlus, faRotate, faTrash, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DraggableSceneObject } from '../DraggableSceneObject/DraggableSceneObject';
import { BoomDraggableSceneObject } from '../BoomDraggableSceneObject/BoomDraggableSceneObject';

interface BoomDraggableSceneProps {
	className?: string;
	roomScale?: number;
	sceneObjects: SceneObject[];
	canRotate: boolean;
	canScale: boolean;
	canFlip: boolean;
	canDrag: boolean;
	canDelete: boolean;
	canHide: boolean;
	canLock: boolean;
	hidingHiddenObjects?: boolean;
	onChange: (sceneObjects:SceneObject[]) => void;
}

let parentElement:HTMLElement|null = null;

export const BoomDraggableScene: React.FC<BoomDraggableSceneProps> = ({
	className,
	sceneObjects,
	roomScale,
	canRotate,
	canScale,
	canFlip,
	canDrag,
	canDelete,
	canHide,
	canLock,
	hidingHiddenObjects,
	onChange
}) => {

  const [selectedID, setSelectedID] = React.useState<string>('');
	const [sortedSceneObjects, setSortedSceneObjects] = React.useState<SceneObject[]>(sceneObjects);

	const sceneObjectListsChanged = (a: SceneObject[], b: SceneObject[]) => {
		if(a.length !== b.length){
			return true;
		}
		for(let i = 0; i < a.length; i++){
			let aObject = a[i];
			let bObject = b[i];
			for(let key in aObject){
				if((aObject as any)[key] !== (bObject as any)[key]){
					return true;
				}
			}
		}
		return false;
	}

	useEffect(() => {
		if(selectedID !== ""){
			let sortedIDs = sortedSceneObjects.map((obj) => obj.id);
			// move selectedID to the top
			sortedIDs.splice(sortedIDs.indexOf(selectedID), 1);
			sortedIDs.push(selectedID);

			let tempSortedSceneObjects = [...sceneObjects];
			//sort keeps the normal order, but bubbles the selected object to the top
			tempSortedSceneObjects.sort((a, b) => {
				let aIndex = sortedIDs.indexOf(a.id);
				let bIndex = sortedIDs.indexOf(b.id);
				return aIndex - bIndex;
			});
			let afterSortedIDs = tempSortedSceneObjects.map((obj) => obj.id);
			let sortOrderChanged = false;
			for(let i = 0; i < sortedIDs.length; i++){
				if(sortedIDs[i] !== afterSortedIDs[i]){
					sortOrderChanged = true;
					break;
				}
			}
			if(sceneObjectListsChanged(tempSortedSceneObjects, sceneObjects) || sortOrderChanged){
				setSortedSceneObjects(tempSortedSceneObjects);
				onChange(tempSortedSceneObjects);
			}
		}
	}, [sceneObjects, selectedID]);


	return (
		<div className={`BoomDraggableScene ${className??""}`}
			onClick={() => setSelectedID("")}>
			{sortedSceneObjects.map((sceneObject) => {
				return <BoomDraggableSceneObject
					key={`scene-object-${sceneObject.id}`}
					roomScale={roomScale ?? 1}
					sceneObject={sceneObject}
					selected={sceneObject.id == selectedID}
					canRotate={canRotate}
					canFlip={canFlip}
					canDrag={canDrag}
					canDelete={canDelete}
					canHide={canHide}
					canLock={canLock}
					hidingHiddenObjects={hidingHiddenObjects}
					onSelect={(id) => {
						setSelectedID(id);
					} }
					onChange={(id: string, changes: Partial<SceneObject>) => {
						let index = sceneObjects.findIndex((obj) => obj.id === id);
						let sceneObject = sceneObjects[index];
						if (sceneObject) {
							for (var k in changes) {
								(sceneObject as any)[k] = (changes as any)[k];
							}
							let newSceneObjects = [...sceneObjects];
							onChange(newSceneObjects);
						}
					} }
					onDelete={(id) => {
						let index = sceneObjects.findIndex((obj) => obj.id === id);
						let newSceneObjects = [...sceneObjects];
						newSceneObjects.splice(index, 1);
						onChange(newSceneObjects);
					} } 
					canDropDown={false} 
					onDropDown={function (id: string): void {
					} }				/>
			})}
		</div>
	);
}