
import React, { useRef, useState, useEffect } from 'react';
import './VideoOutputRow.css';
import { VideoInput, VideoOutput } from '../../../Model/SOW';
import { OUTPUT_ICONS, INPUTS_OFE, LOCATIONS, OUTPUTS, OUTPUTS_OFE, SIGNALS, SURGICAL_DISPLAY_SIZES, WALL_DISPLAY_SIZES } from '../../../Model/DropdownOptions';
import getDefaultLocation from '../../../Utils/GetDefaultLocation';
import ComboBox from '../../ComboBox/ComboBox';
import DropdownWithIcons from '../../DropdownWithIcons/DropdownWithIcons';
import Dropdown from '../../Dropdown/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
interface VideoOutputRowProps {
  videoOutput: VideoOutput;
  hasDisplay: boolean;
  hasWallDisplay: boolean;
  hasOFESignalType: boolean;
  onChange: (videoOutput: VideoOutput) => void;
  onDelete: (videoOutput: VideoOutput) => void;
}
export default function VideoOutputRow({ videoOutput, hasDisplay, hasWallDisplay, hasOFESignalType, onChange, onDelete }: VideoOutputRowProps) {
  const [userChanged, setUserChanged] = useState<boolean>(false);
  const [signalLocation, setSignalLocation] = useState(videoOutput.signalLocation);
  const [outputsButtonText, setOutputButtonText] = useState(videoOutput.outputsButtonText);
  const [icon, setIcon] = useState(videoOutput.icon);
  const [ofeSignalType, setOfeSignalType] = useState(videoOutput.ofeSignalType);
  const [displaySize, setDisplaySize] = useState(videoOutput.displaySize);

  const deleteButton = (
    <div 
      className='buttonDelete' 
      onClick={() => {
        onDelete(videoOutput);
      }}></div>
  )

  useEffect(() => {
    if (userChanged) {

      if (videoOutput.signalLocation != signalLocation ||
        videoOutput.outputsButtonText != outputsButtonText ||
        videoOutput.icon != icon ||
        videoOutput.ofeSignalType != ofeSignalType ||
        videoOutput.displaySize != displaySize) {
        onChange({
          ...videoOutput,
          signalLocation,
          outputsButtonText,
          icon,
          ofeSignalType,
          displaySize
        } as VideoOutput);
      }
    }
  }, [userChanged, signalLocation, outputsButtonText, icon, ofeSignalType, displaySize]);

  return (
    <>
      <td>
        <input type='text' className='buttonLocation' disabled={true} value={videoOutput.buttonLocation} />
        <div className='dragIndicator'><FontAwesomeIcon icon={faBars} /></div>
      </td>
      <td>
        <ComboBox
          className='outputsButtonText'
          options={OUTPUTS}
          value={outputsButtonText}
          onChange={(value: string) => {
            setOutputButtonText(value);
            let updatedVideoOutput = { ...videoOutput, outputsButtonText: value };
            let defaultLocation = getDefaultLocation(value);
            if (defaultLocation != "") {
              updatedVideoOutput.signalLocation = defaultLocation;
              setSignalLocation(defaultLocation);
            }
            if (INPUTS_OFE.indexOf(value) === -1) {
              setOfeSignalType("");
            }
            setUserChanged(true);
          }} ></ComboBox>
      </td>
      <td>

        <DropdownWithIcons
          options={OUTPUT_ICONS}
          className='iconsDrop'
          defaultValue={videoOutput.icon}
          onSelect={(option: string) => {
            setIcon(option);
            setUserChanged(true);
          }}></DropdownWithIcons>
      </td>
      <td>
        <ComboBox className='selectLocation'
          value={signalLocation}
          options={LOCATIONS}
          onChange={(value) => {
            setSignalLocation(value);
            setUserChanged(true);
          }}></ComboBox>
          {hasWallDisplay==false && hasDisplay==false &&  hasWallDisplay==false && deleteButton}
      </td>
      {hasOFESignalType && <td>
        <ComboBox
          className='signalType'
          options={SIGNALS}
          value={videoOutput.ofeSignalType}
          disabled={OUTPUTS_OFE.indexOf(outputsButtonText) === -1}
          onChange={(optionName) => {
            setOfeSignalType(optionName);
            setUserChanged(true);
          }}></ComboBox>
        {hasWallDisplay==false && hasDisplay==false && deleteButton}
      </td>}
      {hasDisplay && <td>
        {icon == "Surgical Display" && (
          <ComboBox
            className='displaySize surgical'
            options={SURGICAL_DISPLAY_SIZES}
            value={displaySize}
            disabled={false}
            onChange={(optionName) => {
              setDisplaySize(optionName);
              setUserChanged(true);
            }}></ComboBox>
        )}
        {hasWallDisplay==false && deleteButton}
      </td>}
      {hasWallDisplay && <td>
        {icon == "Wall Display" && (
          <ComboBox
            className='displaySize wall'
            options={WALL_DISPLAY_SIZES}
            value={displaySize}
            disabled={false}
            onChange={(optionName) => {
              setDisplaySize(optionName);
              setUserChanged(true);
            }}></ComboBox>
        )}
        {deleteButton}
      </td>}

    </>
  );
}