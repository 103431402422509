// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { browserLocalPersistence, getAuth, indexedDBLocalPersistence, initializeAuth, signInWithEmailAndPassword } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD-6jlwE9zsSUMqxtxUvawFefEKfV_uruQ",
  authDomain: "karlstorz-dynamicsow.firebaseapp.com",
  projectId: "karlstorz-dynamicsow",
  storageBucket: "karlstorz-dynamicsow.appspot.com",
  messagingSenderId: "12594962486",
  appId: "1:12594962486:web:6816b978d27df5a3333897"
};

// Initialize Firebase
export const sharedFirebaseApp = initializeApp(firebaseConfig);
// get firestore
export const sharedFirestore = getFirestore(sharedFirebaseApp);

export const sharedFirebaseAuth = initializeAuth(sharedFirebaseApp, {
  persistence: [browserLocalPersistence],
});