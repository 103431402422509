import React, { useRef, useState, useEffect } from 'react';
import './ScreenLanding.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { Screens } from '../../Redux/Reducers';
import { sharedFirebaseAuth } from '../../Utils/SharedFirebase';
import { KarlStorzUser } from '../../Model/KarlStorzUser';
interface ScreenLandingProps {
}
export default function ScreenLanding({ }: ScreenLandingProps) {
	const loggedInUser = useSelector((state: { loggedInUser: KarlStorzUser }) => state.loggedInUser)
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
	}, dispatch);

	return (
		<div className='Screen ScreenLanding'>
			<div className='overlay'></div>
			<div className='storzLogo'></div>
			<div className='buttonLogout' onClick={() => {
				sharedFirebaseAuth.signOut();

			}}>Log out</div>
			<div className="menu">
				<div className='header'>Create New SOW</div>
				<div className='row'>
					<div className='buttonCreateSOW'
						onClick={() => {
							AC.setScreen(Screens.ORBegin);
						}}>
						Operating Room Integration
					</div>
					<div className='buttonCreateSOW streamConnect'
						onClick={() => {
							AC.setScreen(Screens.StreamConnectBegin);
						}}>
						StreamConnect<sup>®</sup>
						<div className='smaller'>Enterprise Collaboration Software</div>
					</div>
				</div>
				<div className='buttonLoad' onClick={() => {
					AC.setScreen(Screens.LoadSOW);
				}}>
					Open Existing SOW
				</div>
				{loggedInUser.isAdmin && <>
					<div className='buttonLoad'
						onClick={() => {
							AC.setScreen(Screens.Admin);
						}}>
						Administrator Portal</div>
				</>}
			</div>
		</div>
	);
}