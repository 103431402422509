
import React, { useRef, useState, useEffect } from 'react';
import './ScreenOREquipmentBoomLayout.css';
import { Config, SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';

import leftBar from '../../../Images/ui/left_bar@2x.png';
import leftBarTop from '../../../Images/ui/left_bar_top@2x.png';
import leftBarBottom from '../../../Images/ui/left_bar_bottom@2x.png';
import ConfigHeader from '../../../Components/Components_OR/ConfigHeader/ConfigHeader';
import { DraggableScene } from '../../../Components/Components_OR/DraggableScene/DraggableScene';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { SceneObject } from '../../../Model/SceneObject';
import PopupAddBoomEquipment from '../../../Components/Components_OR/PopupAddBoomEquipment/PopupAddBoomEquipment';
import { blankSceneObject } from '../../../Utils/BlankObjects';
import PopupConfirm from '../../../Components/PopupConfirm/PopupConfirm';
import { BoomDraggableScene } from '../../../Components/Components_OR/BoomDraggableScene/BoomDraggableScene';

interface ScreenOREquipmentBoomLayoutProps {
	forPDF?: boolean;
	config?: Config;
	sow: SOW;
}
export default function ScreenOREquipmentBoomLayout({ sow, forPDF, config }: ScreenOREquipmentBoomLayoutProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
	}, dispatch);



	const [reloading, setReloading] = useState(false);
	useEffect(() => {
		if (reloading) {
			setTimeout(() => {
				setReloading(false);
			}, 100);
		}
	}, [reloading]);

	const [showingBoomEquipmentPopup, setShowingBoomEquipmentPopup] = useState(false);
	const [showingConfirmPopup, setShowingConfirmPopup] = useState(false);

	const [roomScale, setRoomScale] = useState(1);

	const [boomClassName, setBoomClassName] = useState("");
	const [selectedConfig, setSelectedConfig] = useState(config ?? sow.configs[0]);
	const [sceneObjects, setSceneObjects] = useState(selectedConfig.equipmentBoomLayout.sceneObjects);
	useEffect(() => {
		setBoomClassName(selectedConfig.boomVendor.replace(/ /g, ''));
		setSceneObjects(selectedConfig.equipmentBoomLayout.sceneObjects);
		setReloading(true);
	}, [selectedConfig]);


	useEffect(() => {
		switch (boomClassName) {
			case "DraegerAmbia":
				setRoomScale(1.1);
				break;
			case "StrykerS-Series":
				setRoomScale(1.1);
				break;
			case "SterisHarmonyAIR":
				setRoomScale(1.15);
				break;
			case "HillromFCS":
				setRoomScale(1.35);
				break;
			case "Skytron4CMC":
				setRoomScale(1.35);
				break;
			case "Skytron4VBC":
				setRoomScale(1);
				break;
			case "GetingeModuevo":
				setRoomScale(0.75);
				break;
			case "Amico":
				setRoomScale(1.35);
				break;
		}
	}, [boomClassName]);

	const shelfTopBottom = (shelf: number) => {

		let shelfTop = 0;
		let shelfBottom = 200;

		switch (boomClassName) {
			case "DraegerAmbia":
				switch (shelf) {
					case 1:
						shelfTop = 0;
						shelfBottom = 23;
						break;
					case 2:
						shelfTop = 23;
						shelfBottom = 63;
						break;
					case 3:
						shelfTop = 63;
						shelfBottom = 99;
						break;
				}
				break;

			case "StrykerS-Series":
				switch (shelf) {
					case 1:
						shelfTop = 0;
						shelfBottom = 39;
						break;
					case 2:
						shelfTop = 39;
						shelfBottom = 65;
						break;
					case 3:
						shelfTop = 65;
						shelfBottom = 94;
						break;
				}
				break;

			case "SterisHarmonyAIR":
				switch (shelf) {
					case 1:
						shelfTop = 0;
						shelfBottom = 22;
						break;
					case 2:
						shelfTop = 22;
						shelfBottom = 54;
						break;
					case 3:
						shelfTop = 54;
						shelfBottom = 82;
						break;
				}
				break;

			case "HillromFCS":
				switch (shelf) {
					case 1:
						shelfTop = 0;
						shelfBottom = 26;
						break;
					case 2:
						shelfTop = 26;
						shelfBottom = 60;
						break;
					case 3:
						shelfTop = 60;
						shelfBottom = 90;
						break;
				}
				break;

			case "Skytron4CMC":
				switch (shelf) {
					case 1:
						shelfTop = 0;
						shelfBottom = 40;
						break;
					case 2:
						shelfTop = 40;
						shelfBottom = 65;
						break;
					case 3:
						shelfTop = 65;
						shelfBottom = 98;
						break;
				}
				break;

			case "Skytron4VBC":
				switch (shelf) {
					case 1:
						shelfTop = 0;
						shelfBottom = 18;
						break;
					case 2:
						shelfTop = 18;
						shelfBottom = 40;
						break;
					case 3:
						shelfTop = 40;
						shelfBottom = 70;
						break;
					case 4:
						shelfTop = 70;
						shelfBottom = 98;
						break;
				}
				break;

			case "GetingeModuevo":
				switch (shelf) {
					case 1:
						shelfTop = 0;
						shelfBottom = 35;
						break;
					case 2:
						shelfTop = 35;
						shelfBottom = 62;
						break;
					case 3:
						shelfTop = 62;
						shelfBottom = 88;
						break;
				}
				break;

			case "Amico":
				switch (shelf) {
					case 1:
						shelfTop = 0;
						shelfBottom = 40;
						break;
					case 2:
						shelfTop = 40;
						shelfBottom = 62;
						break;
					case 3:
						shelfTop = 62;
						shelfBottom = 92;
						break;
				}
				break;

		}

		return { top: shelfTop, bottom: shelfBottom };
	}

	const boomDisclaimer = () => {
		switch (boomClassName) {
			case "DraegerAmbia":
				return "(Shown with 18\" Shelves)";
			case "StrykerS-Series":
				return "(Shown with 27\" Shelves - 30\" rail to rail)";
			case "SterisHarmonyAIR":
				return "(Shown with 25\" Shelves)";
			case "HillromFCS":
				return "(Shown with 17\" Shelves)";
			case "Skytron4CMC":
				return "(Shown with 22\" Shelves)";
			case "Skytron4VBC":
				return "(Shown with 22\" Shelves)";
			case "GetingeModuevo":
				return <>(Shown as Mezzo w/ 19" Shelves)<br />your configuration may vary slightly</>;
			case "Amico":
				return "(Shown with 18\" Shelves)";
		}
		return "";
	}


	const shelfEquipment = (shelf: number) => {
		let shelfSceneObjects: SceneObject[] = [];

		let topBottom = shelfTopBottom(shelf);

		shelfSceneObjects = sceneObjects.filter((object) => {
			return object.left > 25 && object.top > topBottom.top && object.top < topBottom.bottom;
		});

		return shelfSceneObjects;
	}

	const isEquipmentFloating = () => {
		return false;
	}

	const shelfLabels = (shelfNumber: number) => {
		let shelfSceneObjects = shelfEquipment(shelfNumber);
		return <>{shelfSceneObjects.map((object, index) => {
			return <div className="shelfEquipment" key={"shelf" + shelfNumber + "_" + index}>
				{object.name}
			</div>
		})}</>
	}


	const currentScreen = useSelector((state: { screen: Screens }) => state.screen)
	const currentConfigIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)


	let dropdownItems: { configIndex: number, screen: Screens, label: string, nextLabel?: string }[] = [
	];

	for (let i = 0; i < sow.configs.length; i++) {
		if (sow.configs[i].boomVendor != "None" &&
			sow.configs[i].boomVendor != "" &&
			sow.configs[i].boomVendor != undefined) {
			dropdownItems.push({ configIndex: i, screen: Screens.OREquipmentBoomLayout, label: `Equipment Boom Layout ${i + 1}` });
		}
	}

	let dropdownItemIndex = dropdownItems.findIndex((item) => {
		return item.screen == currentScreen && item.configIndex == currentConfigIndex;
	});

	const [nextDropdownItem, setNextDropdownItem] = useState(dropdownItems[dropdownItemIndex + 1]);
	const [cancelDropdownItem, setCancelDropdownItem] = useState(dropdownItems[dropdownItemIndex + 1]);


	return (
		<>
			<div className='Screen ScreenOREquipmentBoomLayout'>
				<div className='leftBarTop' />
				<div className='verticalLabel'>Equipment Boom Layout</div>
				{reloading == false && (
					<>
						<div className='boomArea'>
							<div className={`shelf shelf1 ${boomClassName}`} style={{ bottom: (100 - shelfTopBottom(1).bottom) + "%" }} >{shelfLabels(1)}</div>
							<div className={`shelf shelf2 ${boomClassName}`} style={{ bottom: (100 - shelfTopBottom(2).bottom) + "%" }} >{shelfLabels(2)}</div>
							<div className={`shelf shelf3 ${boomClassName}`} style={{ bottom: (100 - shelfTopBottom(3).bottom) + "%" }} >{shelfLabels(3)}</div>
							<div className={`shelf shelf4 ${boomClassName}`} style={{ bottom: (100 - shelfTopBottom(4).bottom) + "%" }} >{shelfLabels(4)}</div>
						</div>
						<div className={`boomHeader ${boomClassName}`}>Your Equipment Configuration</div>
						<div className={`boomLabel ${boomClassName}`}>{selectedConfig.boomVendor}</div>
						<div className={`boomDisclaimer ${boomClassName}`}>{boomDisclaimer()}</div>
						<div className='disclaimer'>This representation is for coordinating equipment placement on the boom so that low voltage plate placement can be determined.  For a drawing of your exact boom purchased, please refer to your boom vendor's drawings.</div>
						<BoomDraggableScene
							className={boomClassName}
							sceneObjects={selectedConfig.equipmentBoomLayout.sceneObjects}
							onChange={(newSceneObjects) => {
								let config = sow.configs.find((config) => config.id === selectedConfig.id);
								if (config) {
									let refreshing = config.equipmentBoomLayout.sceneObjects.length !== newSceneObjects.length;
									config.equipmentBoomLayout.sceneObjects = newSceneObjects;
									AC.setSOW(sow);
									if (refreshing) {
										setReloading(true);
									}
								}
								setSceneObjects(newSceneObjects);
							}}
							roomScale={roomScale}
							canScale={false}
							canRotate={false}
							canFlip={false}
							canDrag={forPDF == false}
							canDelete={forPDF == false}
							canHide={false}
							canLock={false} />
					</>
				)}
				{!showingBoomEquipmentPopup && (
					<ConfigHeader
						sow={sow}
						forPDF={forPDF ?? false}
						selectedConfig={selectedConfig} ></ConfigHeader>
				)}
				{!forPDF && <>
					<div className="buttonAdd" onClick={() => {
						setShowingBoomEquipmentPopup(true);
					}}><div className='circle'><FontAwesomeIcon icon={faPlus} /></div> Add Product(s) to shelves</div>
				</>}

			</div>
			{!forPDF && !showingBoomEquipmentPopup && (
				<NavigationHeader
					sow={sow}
					selectedConfig={selectedConfig}
					nextButtonText={() => {
						if (sow.configs.length > currentConfigIndex + 1) {
							return "Room Layout"
						}
						return ""
					}}
					configs={sow.configs.filter((config) => (config.boomVendor !== undefined && config.boomVendor !== "" && config.boomVendor !== "None"))}
					onNext={(screen, configIndex) => {
						if (isEquipmentFloating() || selectedConfig.equipmentBoomLayout.sceneObjects.length == 0) {
							setShowingConfirmPopup(true);
							setCancelDropdownItem({ configIndex: configIndex, screen: screen, label: "" });
						} else {
							if (screen == Screens.ORBegin) {
								AC.setCurrentConfigIndex(currentConfigIndex + 1);
								AC.setScreen(Screens.ORRoomLayout);
							} else {
								AC.setCurrentConfigIndex(configIndex);
								AC.setScreen(screen);
							}
						}
					}} />
			)}
			{showingBoomEquipmentPopup && (
				<PopupAddBoomEquipment
					onAddEquipment={equipment => {
						let config = sow.configs.find((config) => config.id === selectedConfig.id);
						if (config) {
							equipment.forEach((item, i) => {
								let newSceneObject = blankSceneObject();
								newSceneObject.name = item.name;
								newSceneObject.type = item.type;
								newSceneObject.left = 20;
								newSceneObject.top = (i + 2) / (equipment.length + 4) * 100;
								config!.equipmentBoomLayout.sceneObjects.push(newSceneObject);
							})
							AC.setSOW(sow);
						}
						setShowingBoomEquipmentPopup(false);
						setReloading(true);
					}}
					onDismiss={() => {
						setShowingBoomEquipmentPopup(false);
					}} />
			)}

			{showingConfirmPopup && (
				<PopupConfirm
					text={"Boom shelves are empty or equipment hasn't been placed."}
					cancelText={"Continue anyway"}
					confirmText={"Go back & add products"}
					onCancel={() => {
						setShowingConfirmPopup(false);
						if (cancelDropdownItem.screen == Screens.ORBegin) {
							AC.setCurrentConfigIndex(currentConfigIndex + 1);
							AC.setScreen(Screens.ORRoomLayout);
						} else {
							AC.setCurrentConfigIndex(cancelDropdownItem.configIndex);
							AC.setScreen(cancelDropdownItem.screen);
						}
					}}
					onConfirm={() => {
						setShowingConfirmPopup(false);
					}} />
			)}
		</>
	);
}