
import React, { useRef, useState, useEffect } from 'react';
import './ScreenStreamConnectBegin.css';
import { Config, Department, SOW } from '../../../Model/SOW';
import { shortDate, sowDate } from '../../../Utils/Formatting';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import { blankDepartment } from '../../../Utils/BlankObjects';
import { KarlStorzUser } from '../../../Model/KarlStorzUser';
import { CollectionReference, DocumentData, Query, collection, onSnapshot, query, where } from 'firebase/firestore';
import { SalesRegion } from '../../../Model/SalesRegion';
import { sharedFirestore } from '../../../Utils/SharedFirebase';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const uuid = require('uuid');

interface ScreenStreamConnectBeginProps {
}
export default function ScreenStreamConnectBegin({ }: ScreenStreamConnectBeginProps) {
  const loggedInUser = useSelector((state: { loggedInUser: KarlStorzUser }) => state.loggedInUser)
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
	}, dispatch);

	const [hospitalName, setHospitalName] = useState<string>('')

	const [duplicateName, setDuplicateName] = useState<boolean>(false)

	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [])

	const [salesRegions, setSalesRegions] = useState([] as SalesRegion[]);
	const [selectedRegionID, setSelectedReginID] = useState("");
	const salesRegionsCollectionRef = collection(sharedFirestore, "SalesRegions");

	useEffect(() => {

		let q: Query<DocumentData, DocumentData> | CollectionReference<DocumentData, DocumentData>;
		q = query(salesRegionsCollectionRef, where("id", "in", loggedInUser.salesRegionIDs));
		if (loggedInUser.isAdmin) {
			q = salesRegionsCollectionRef;
		}

		const unsubscribeSalesRegions = onSnapshot(q, (querySnapshot) => {
			let salesRegions = [] as SalesRegion[];
			querySnapshot.forEach((doc) => {
				let docData = doc.data();
				let salesRegion: SalesRegion = {
					id: doc.id,
					name: docData.name,
					sowIDs: docData.sowIDs,
				}
				salesRegions.push(salesRegion);
			});
			if (salesRegions.length == 1) {
				setSelectedReginID(salesRegions[0].id);
			}
			if (salesRegions.length > 0) {
				setSalesRegions(salesRegions);
			}
		});
		return () => {
			unsubscribeSalesRegions();
		}
	}, [])

	return (
		<div className='Screen ScreenStreamConnectBegin'>
			<div className='storzLogo'></div>
			<div className='inputs'>
				<div className='header'>
					{hospitalName == "" ? "What hospital or clinic is this for?" : "Save this SOW as:"}
				</div>
				<input type='text'
					placeholder={hospitalName == "" ? "Enter Hospital Name" : "SOW Name"}
					ref={inputRef} />

				{salesRegions.length > 1 && <>
					<div className='row'>
						<select
							value={selectedRegionID}
							onChange={(e) => {
								setSelectedReginID(e.target.value);
							}}>
							<option value="">No Sales Region Selected</option>
							{salesRegions.map((salesRegion) => {
								return <option value={salesRegion.id}>{salesRegion.name}</option>
							})}
						</select>
						<span className="arrow"><FontAwesomeIcon icon={faChevronDown} /></span>
					</div>
				</>}
				{/* {hospitalName == "" && <>
				<div className={`button ${duplicateName ? "selected" : ""}`} onClick={() => {
						setDuplicateName(!duplicateName);
					}}>Save SOW with same name as above</div>
				</>} */}
				<div className={`buttonSubmit ${inputRef.current?.value == "" ? "disabled" : ""}`}
					onClick={() => {
						if (inputRef.current!.value != "" && selectedRegionID != "") {
							let sowName = inputRef.current!.value
							let department = blankDepartment();
							department.name = "Main OR";
							let sow: SOW = {
								hospitalName: sowName,
								salesRegionID: selectedRegionID,
								name: sowName + " " + shortDate(new Date()),
								dateCreated: sowDate(new Date()),
								id: uuid.v4(),
								type: 'StreamConnect Integration',
								configs: [] as Config[],
								departments: [department] as Department[],
								leftOverDepartment: blankDepartment(),
								dateInitiate: "",
								dateDeploy: "",
								dateConfigure: "",
								dateActivate: "",
								dateTrain: "",
								dateSuccess: "",
							}
							AC.setSOW(sow);
							AC.setScreen(Screens.StreamConnectSystemSetup)
						}
					}}>{hospitalName == "" ? "Submit" : "Save & Continue"}
				</div>
			</div>
			<div className='buttonCancel' onClick={() =>{
				AC.setScreen(Screens.Landing);
			}}>Cancel</div>
		</div>
	);
}