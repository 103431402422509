
import React, { useRef, useState, useEffect } from 'react';
import './ScreenORContents.css';
import { SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import contentsImg from '../../../Images/ui/contents@2x.png'

import leftBar from '../../../Images/ui/left_bar@2x.png';
import leftBarTop from '../../../Images/ui/left_bar_top@2x.png';
import leftBarBottom from '../../../Images/ui/left_bar_bottom@2x.png';
import Dropdown from '../../../Components/Dropdown/Dropdown';

interface ScreenORContentsProps {
	forPDF?: boolean;
	sow: SOW;
}
export default function ScreenORContents({ sow, forPDF }: ScreenORContentsProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
	}, dispatch);

	const currentConfigIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)

	const [showDropdown, setShowDropdown] = useState(false);

	const dropdownItems = [
		"Configuration Setup",
		"Contents",
		"Executive Summary",
		"Project Roadmap",
		"Room Layout",
		"Touchscreen Layout",
		"Input/Output Schedule",
		"Equipment Boom Layout",
	];


	const dropdownItemClicked = (index: number) => {
		switch (index) {
			case 0:
				AC.setScreen(Screens.ORConfigSetup);
				break;
			case 1:
				AC.setScreen(Screens.ORContents);
				break;
			case 2:
				AC.setScreen(Screens.ORExecutiveSummary);
				break;
			case 3:
				AC.setScreen(Screens.ORProjectRoadMap);
				break;
			case 4:
				AC.setCurrentConfigIndex(currentConfigIndex);
				AC.setScreen(Screens.ORRoomLayout);
				break;
			case 5: {
				let found = false;
				let configIndex = 0;
				for (let i = 0; i < sow.configs.length; i++) {
					if (sow.configs[i].video.system != "AIR" && sow.configs[i].video.system != "4KBR") {
						found = true;
						configIndex = i;
					}
				}

				if (found) {
					AC.setCurrentConfigIndex(configIndex);
					AC.setScreen(Screens.ORTouchscreenLayout);
				}
				break;
			}
			case 6:
				AC.setCurrentConfigIndex(currentConfigIndex);
				AC.setScreen(Screens.ORInputOutputSchedule);
				break;
			case 7: {
				let found = false;
				let configIndex = 0;
				for (let i = 0; i < sow.configs.length; i++) {
					if (sow.configs[i].boomVendor !== "" && sow.configs[i].boomVendor !== "None") {
						found = true;
						configIndex = i;
						break;
					}
				}

				if (found) {
					AC.setCurrentConfigIndex(configIndex);
					AC.setScreen(Screens.OREquipmentBoomLayout);
				}
				break;
			}
		}
	};

	return (
		<>
			<div className='Screen ScreenORContents'>
				<div className='contentsImage' />
				<div className='leftBar' />
				<div className='verticalLabel'>Contents</div>
				<div className='menu'>
					<div className='button executiveSummary' onClick={() => dropdownItemClicked(2)}></div>
					<div className='button projectRoadmap' onClick={() => dropdownItemClicked(3)}></div>
					<div className='button roomLayout' onClick={() => dropdownItemClicked(4)}></div>
					<div className='button touchscreenLayout' onClick={() => dropdownItemClicked(5)}></div>
					<div className='button inputOutputSchedule' onClick={() => dropdownItemClicked(6)}></div>
					<div className='button equipmentBoomLayout' onClick={() => dropdownItemClicked(7)}></div>

				</div>
			</div>
			{!forPDF && (
				<>
					<NavigationHeader
						sow={sow}
						selectedConfig={sow.configs[currentConfigIndex]} />
				</>
			)}
		</>
	);
}