
import React, { useRef, useState, useEffect } from 'react';
import './ScreenLoadSOW.css';
import { deleteSOW } from '../../Utils/EmailSaveAndLoad';
import { SOW } from '../../Model/SOW';
import LoadSOWRow from '../../Components/LoadSOWRow/LoadSOWRow';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import ActionCreators, { setScreen } from '../../Redux/ActionCreators';
import { Screens } from '../../Redux/Reducers';
import PopupConfirm from '../../Components/PopupConfirm/PopupConfirm';
import { KarlStorzUser } from '../../Model/KarlStorzUser';
import { CollectionReference, DocumentData, Query, collection, onSnapshot, query, where } from 'firebase/firestore';
import { sharedFirestore } from '../../Utils/SharedFirebase';
import { CircularProgress } from '@mui/material';
interface ScreenLoadSOWProps {
}
export default function ScreenLoadSOW({ }: ScreenLoadSOWProps) {
	const loggedInUser = useSelector((state: { loggedInUser: KarlStorzUser }) => state.loggedInUser)
	const sowsCollectionRef = collection(sharedFirestore, "SOWs");
	useEffect(() => {
		let q:Query<DocumentData, DocumentData>|CollectionReference<DocumentData, DocumentData>;
		q = query(sowsCollectionRef, where("salesRegionID", "in", loggedInUser.salesRegionIDs));
		if(loggedInUser.isAdmin) {
			q = sowsCollectionRef;
		}
		let unsubscribeSOWs = onSnapshot(q, (querySnapshot) => {
			let sows = [] as SOW[];
			querySnapshot.forEach((doc) => {
				let docData = doc.data();
				let sow: SOW = docData as SOW;
				sows.push(sow);
			});
			setSOWs(sows);
			setLoadingSOWs(false);
		});
		return () => {
			unsubscribeSOWs();
		}
	}, [])
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
	}, dispatch);

	const searchTextRef = useRef<HTMLInputElement>(null);
	const [showORIntegration, setShowORIntegration] = useState<boolean>(true);
	const [showStreamConnect, setShowStreamConnect] = useState<boolean>(true);
	const [sows, setSOWs] = useState<SOW[]>([]);

	const [selectedSOW, setSelectedSOW] = useState<SOW | null>(null);
	const [showingDeletePopup, setShowingDeletePopup] = useState<boolean>(false);

	const [sortByName, setSortByName] = useState<boolean>(false);
	const [sortReversed, setSortReversed] = useState<boolean>(true);

	const [reloading, setReloading] = useState<boolean>(true);
	const [loadingSOWs, setLoadingSOWs] = useState<boolean>(true);

	// useEffect(() => {
	// 	if (reloading) {
	// 		loadSOWsData().then((sows) => {
	// 			setSOWs(sows);
	// 		});
	// 		setReloading(false);
	// 	}
	// }, [reloading])

	useEffect(() => {
		filterSOWs();
	}, [sows, showORIntegration, showStreamConnect, sortByName, sortReversed])

	const [filteredSOWs, setFilteredSOWs] = useState<SOW[]>([]);
	const filterSOWs = () => {
		let searchText = searchTextRef.current?.value;

		let filteredSOWs = sows.filter((sow) => {
			if (searchText && !sow.name.toLowerCase().includes(searchText.toLowerCase())) return false;
			if (!showORIntegration && sow.type == "OR Room Integration") return false;
			if (!showStreamConnect && sow.type == "StreamConnect Integration") return false;
			return true;
		})

		if (sortByName) {
			filteredSOWs.sort((a, b) => {
				if (sortReversed) {
					return b.name.localeCompare(a.name);
				} else {
					return a.name.localeCompare(b.name);
				}
			})
		} else {
			filteredSOWs.sort((a, b) => {
				if (sortReversed) {
					return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
				} else {
					return new Date(a.dateCreated).getTime() - new Date(b.dateCreated).getTime();
				}
			})
		}

		if (filteredSOWs.length > 0 && selectedSOW) {
			let found = filteredSOWs.find((sow) => {
				return sow.id == selectedSOW.id;
			}) !== undefined;
			if (!found) {
				setSelectedSOW(null);
			}
		}

		setFilteredSOWs(filteredSOWs);
	}

	return (
		<>
			<div className='Screen ScreenLoadSOW'>
				<div className='storzLogo'></div>
				<div className='leftSide'>
					<input type='text' ref={searchTextRef} placeholder='Search' onChange={filterSOWs} />

					<div className={`button ${showORIntegration && !showStreamConnect ? "selected" : ""}`} onClick={() => {
						setShowStreamConnect(false);
						setShowORIntegration(true);
					}}><div className='whiteRing' />Operating Room Integration</div>

					<div className={`button ${showStreamConnect && !showORIntegration ? "selected" : ""}`} onClick={() => {
						setShowStreamConnect(true);
						setShowORIntegration(false);
					}}><div className='whiteRing' />StreamConnect<sup>®</sup> Integration</div>

					<div className={`button ${showStreamConnect && showORIntegration ? "selected" : ""}`} onClick={() => {
						setShowStreamConnect(true);
						setShowORIntegration(true);
					}}><div className='whiteRing' />Both</div>
				</div>
				<div className='rightSide'>

					{loadingSOWs ? <>
						<CircularProgress />
					</> : <>
						<table className='sowsTable'>
							<thead>
								<tr>
									<th onClick={() => {
										let reversed = false;
										if (sortByName) {
											reversed = !sortReversed;
										}
										setSortByName(true);
										setSortReversed(reversed);
									}}>Name{
											sortByName ? <div className='sortIcon' /> : ""
										}</th>
									<th onClick={() => {
										let reversed = false;
										if (!sortByName) {
											reversed = !sortReversed;
										}
										setSortByName(false);
										setSortReversed(reversed);
									}}>Date{
											!sortByName ? <div className='sortIcon' /> : ""
										}</th>
								</tr>
							</thead>
							<tbody>
								{filteredSOWs.map((sow, i) => {
									return <LoadSOWRow
										key={sow.id}
										sow={sow}
										selected={selectedSOW?.id == sow.id}
										onSelect={(sow) => {
											setSelectedSOW(sow);
										}}
										onDelete={(sow) => {
											// deleteSOW(sow).then(() =>{
											// 	setReloading(true);
											// });
											setShowingDeletePopup(true);
										}} />
								})}
							</tbody>
						</table>
					</>}
				</div>
				<div className='buttonCancel' onClick={() => {
					AC.setScreen(Screens.Landing);
				}}>Cancel</div>
				<div className={`buttonOpen ${selectedSOW == null ? "disabled" : ""}`} onClick={() => {
					if (selectedSOW) {
						AC.setSOW(selectedSOW);
						if (selectedSOW.type == "OR Room Integration") {
							AC.setScreen(Screens.ORConfigSetup);
						} else {
							AC.setScreen(Screens.StreamConnectSystemSetup);
						}
					}
				}}>Open</div>
			</div >
			{showingDeletePopup && (
				<PopupConfirm
					text={<>Delete <b>{selectedSOW?.name}</b></>}
					cancelText={"Don't Delete"}
					confirmText={"Yes, Delete"}
					onCancel={() => {
						setShowingDeletePopup(false);
					}}
					onConfirm={() => {
						deleteSOW(selectedSOW!).then(() => {
							setReloading(true);
						});
						setShowingDeletePopup(false);
						setSelectedSOW(null);
					}}
				/>
			)}
		</>
	);
}