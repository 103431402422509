
import React, { useRef, useState, useEffect } from 'react';
import './ScreenORExecutiveSummary.css';
import { SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import ExecutiveSummaryRow from '../../../Components/Components_OR/ExecutiveSummaryRow/ExecutiveSummaryRow';

import leftBar from '../../../Images/ui/left_bar@2x.png';
import leftBarTop from '../../../Images/ui/left_bar_top@2x.png';
import leftBarBottom from '../../../Images/ui/left_bar_bottom@2x.png';
import Dropdown from '../../../Components/Dropdown/Dropdown';

interface ScreenORExecutiveSummaryProps {
	forPDF?: boolean;
	sow: SOW;
}
export default function ScreenORExecutiveSummary({ sow, forPDF }: ScreenORExecutiveSummaryProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
	}, dispatch);
	
  const currentConfigIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)
	return (
		<>
			<div className='Screen ScreenORExecutiveSummary'>
				<div className='leftBarTop' />
				<div className='verticalLabel'>Executive Summary</div>
				<div className='text1'>
					This Statement of Work is designed to align everyone's understanding of the upcoming operating room integration project that you have purchased from KARL STORZ.
				</div>
				<div className='text2'>
					The slides in this SoW must be reviewed by surgical service leads prior to installation to ensure the integration systems are installed and configured as you desire.
				</div>
				<div className='text3'>
					High Level Overview of the Integration Features Sold for Each Operating Room
				</div>
				<div className='tableBackground' />
				<div className='executiveSummaryTable'>
					{sow.configs.map((config, configIndex) => {
						return <ExecutiveSummaryRow config={config} key={configIndex} />;
					})}
				</div>
				<div className='disclaimer'>
					<span className='red'>*</span> These solutions require IT involvement
				</div>
			</div>
			{!forPDF && (
				<>
					<NavigationHeader
						selectedConfig={sow.configs[currentConfigIndex]}
						sow={sow} />
				</>
			)}
		</>
	);
}