

import React, { useRef, useState, useEffect } from 'react';
import './ScreenStreamConnectDates.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faTrash, faX } from '@fortawesome/free-solid-svg-icons';
interface ScreenStreamConnectDatesProps {
	sow: SOW;
}
export default function ScreenStreamConnectDates({ sow }: ScreenStreamConnectDatesProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
	}, dispatch);

	const currentConfigIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)

	const [userChanged, setUserChanged] = useState<boolean>(false);
	const [dateInitiate, setDateInitiate] = useState<string>(sow.dateInitiate);
	const [dateDeploy, setDateDeploy] = useState<string>(sow.dateDeploy);
	const [dateConfigure, setDateConfigure] = useState<string>(sow.dateConfigure);
	const [dateActivate, setDateActivate] = useState<string>(sow.dateActivate);
	const [dateTrain, setDateTrain] = useState<string>(sow.dateTrain);
	const [dateSuccess, setDateSuccess] = useState<string>(sow.dateSuccess);

	useEffect(() => {
		if (userChanged) {
			sow.dateActivate = dateActivate;
			sow.dateConfigure = dateConfigure;
			sow.dateDeploy = dateDeploy;
			sow.dateInitiate = dateInitiate;
			sow.dateSuccess = dateSuccess;
			sow.dateTrain = dateTrain;
			AC.setSOW(sow);
		}
	}, [userChanged, dateActivate, dateConfigure, dateDeploy, dateInitiate, dateSuccess, dateTrain]);

	function formattedDate(d:Date){
		return ("0"+(d.getMonth()+1)).slice(-2) + "/" + ("0" + d.getDate()).slice(-2) + "/" + d.getFullYear();
	}
	
	return (
		<div className='Screen ScreenStreamConnectDates'>
			<div className='contentsImage' />
			<div className='leftBar' />
			<div className='verticalLabel'>System Setup</div>
			<div className='configSetupheader'>
				{sow.hospitalName}
			</div>

			<div className='body'>

				<div className='buttonClearDates' onClick={() => {
					setDateActivate("");
					setDateConfigure("");
					setDateDeploy("");
					setDateInitiate("");
					setDateSuccess("");
					setDateTrain("");
					setUserChanged(true);
				}}><FontAwesomeIcon icon={faTrash} /> Clear Dates</div>

				<h1>Project Roadmap Dates</h1>
				<div className='inputs'>
					<div className='input'>
						<div className='label'>Initiate</div>
						<DatePicker
							value={dateInitiate}
							//format should be mm/dd/yy
							dateFormat={"MM/dd/yyyy"}
							onChange={(date: Date) => {
								setDateInitiate(formattedDate(date));
								if (dateDeploy == "" || dateDeploy === undefined) {
									//set the date to the initiate date + 30
									let newDate = new Date(date);
									newDate.setDate(newDate.getDate() + 30);
									setDateDeploy(formattedDate(newDate));
								}
								if (dateConfigure == "" || dateConfigure === undefined) {
									//set the date to the initiate date + 60
									let newDate = new Date(date);
									newDate.setDate(newDate.getDate() + 60);
									setDateConfigure(formattedDate(newDate));
								}
								if (dateActivate == "" || dateActivate === undefined) {
									//set the date to the initiate date + 90
									let newDate = new Date(date);
									newDate.setDate(newDate.getDate() + 90);
									setDateActivate(formattedDate(newDate));
								}
								if (dateTrain == "" || dateTrain === undefined) {
									//set the date to the initiate date + 120
									let newDate = new Date(date);
									newDate.setDate(newDate.getDate() + 120);
									setDateTrain(formattedDate(newDate));
								}
								if (dateSuccess == "" || dateSuccess === undefined) {
									//set the date to the initiate date + 150
									let newDate = new Date(date);
									newDate.setDate(newDate.getDate() + 150);
									setDateSuccess(formattedDate(newDate));
								}
								setUserChanged(true);
							}} />
							<div className='calendarIcon' style={{pointerEvents:(dateInitiate!=""?"all":"none")}} onClick={()=>setDateInitiate("")}><FontAwesomeIcon icon={dateInitiate == ""?faCalendar:faX}/></div>
					</div>

					<div className='input'>
						<div className='label'>Deploy</div>
						<DatePicker
							value={dateDeploy}
							onChange={(date: Date) => {
								setDateDeploy(formattedDate(date));
								setUserChanged(true);
							}} />
							<div className='calendarIcon' style={{pointerEvents:(dateDeploy!=""?"all":"none")}} onClick={()=>setDateDeploy("")}><FontAwesomeIcon icon={dateDeploy == ""?faCalendar:faX}/></div>
					</div>

					<div className='input'>
						<div className='label'>Configure</div>
						<DatePicker
							value={dateConfigure}
							onChange={(date: Date) => {
								setDateConfigure(formattedDate(date));
								setUserChanged(true);
							}} />
							<div className='calendarIcon' style={{pointerEvents:(dateConfigure!=""?"all":"none")}} onClick={()=>setDateConfigure("")}><FontAwesomeIcon icon={dateConfigure == ""?faCalendar:faX}/></div>
					</div>

					<div className='input'>
						<div className='label'>Activate</div>
						<DatePicker
							value={dateActivate}
							onChange={(date: Date) => {
								setDateActivate(formattedDate(date));
								setUserChanged(true);
							}} />
							<div className='calendarIcon' style={{pointerEvents:(dateActivate!=""?"all":"none")}} onClick={()=>setDateActivate("")}><FontAwesomeIcon icon={dateActivate == ""?faCalendar:faX}/></div>
					</div>

					<div className='input'>
						<div className='label'>Train</div>
						<DatePicker
							value={dateTrain}
							onChange={(date: Date) => {
								setDateTrain(formattedDate(date));
								setUserChanged(true);
							}} />
							<div className='calendarIcon' style={{pointerEvents:(dateTrain!=""?"all":"none")}} onClick={()=>setDateTrain("")}><FontAwesomeIcon icon={dateTrain == ""?faCalendar:faX}/></div>
					</div>

					<div className='input'>
						<div className='label'>Success</div>
						<DatePicker
							value={dateSuccess}
							onChange={(date: Date) => {
								setDateSuccess(formattedDate(date));
								setUserChanged(true);
							}} />
							<div className='calendarIcon' style={{pointerEvents:(dateSuccess!=""?"all":"none")}} onClick={()=>setDateSuccess("")}><FontAwesomeIcon icon={dateSuccess == ""?faCalendar:faX}/></div>
					</div>
				</div>
			</div>
			<NavigationHeader
				sow={sow} />
		</div>
	);
}