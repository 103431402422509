
import React, { useRef, useState, useEffect } from 'react';
import './LoadSOWRow.css';
import { SOW } from '../../Model/SOW';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
interface LoadSOWRowProps {
	sow: SOW;
	selected: boolean;
	onSelect: (sow: SOW) => void;
	onDelete: (sow: SOW) => void;
}
export default function LoadSOWRow({ sow, selected, onSelect, onDelete }: LoadSOWRowProps) {
	return (
		<tr className={`LoadSOWRow ${selected ? "selected" : ""}`}
			onClick={() => {
				onSelect(sow);
			}}>
			<td>
				{sow.name}</td>
			<td>
				{sow.dateCreated}
				{selected && (
					<div className='buttonDelete' onClick={(e) => {
						e.stopPropagation();
						onDelete(sow);
					}}></div>
				)}
			</td>
		</tr>
	);
}