
import React, { useRef, useState, useEffect } from 'react';
import './AudioInputRow.css';
import { AudioInput, VideoInput } from '../../../Model/SOW';
import { AUDIO_INPUTS, INPUTS, INPUTS_OFE, LOCATIONS } from '../../../Model/DropdownOptions';
import getDefaultLocation from '../../../Utils/GetDefaultLocation';
import ComboBox from '../../ComboBox/ComboBox';
import Dropdown from '../../Dropdown/Dropdown';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface AudioInputRowProps {
  audioInput: AudioInput
  onChange: (audioInput: AudioInput) => void;
  onDelete: (audioInput: AudioInput) => void;
}
export default function AudioInputRow({ audioInput, onChange, onDelete }: AudioInputRowProps) {
  const [userChanged, setUserChanged] = useState<boolean>(false);
  const [localSignalOriginates, setLocalSignalOriginates] = useState(audioInput.signalOriginates);
  const [localInputsTPButtonText, setLocalInputsTPButtonText] = useState(audioInput.inputsTPButtonText);

  useEffect(() => {
    if (userChanged) {
      if (audioInput.signalOriginates != localSignalOriginates ||
        audioInput.inputsTPButtonText != localInputsTPButtonText) {

        audioInput.signalOriginates = localSignalOriginates;
        audioInput.inputsTPButtonText = localInputsTPButtonText;

        onChange(audioInput);
      }
    }
  }, [userChanged, localSignalOriginates, localInputsTPButtonText]);
  return (
    <>
      <td>
        <input type='text' className='buttonLocation' disabled={true} value={audioInput.buttonLocation} />
        <div className='dragIndicator'><FontAwesomeIcon icon={faBars} /></div>
      </td>
      <td>
        <ComboBox
          className='inputsTPButtonText'
          options={AUDIO_INPUTS}
          value={audioInput.inputsTPButtonText}
          onChange={(value: string) => {
            let updatedVideoInput = { ...audioInput, inputsTPButtonText: value };
            let defaultLocation = getDefaultLocation(value);
            if (defaultLocation != "") {
              updatedVideoInput.signalOriginates = defaultLocation;
              setLocalSignalOriginates(defaultLocation);
            }
            setLocalInputsTPButtonText(value);
            setUserChanged(true);
          }} ></ComboBox>
      </td>
      <td>
        <ComboBox
          className='signalOriginates'
          value={localSignalOriginates}
          options={LOCATIONS}
          onChange={(value) => {
            setLocalSignalOriginates(value);
            setUserChanged(true);
          }}></ComboBox>
        <div
          className='buttonDelete'
          onClick={() => {
            onDelete(audioInput);
          }}></div>
      </td>
    </>
  );
}