import React, { useEffect, useState } from 'react';
import './Toggle.css';

type ToggleProps = {
  value?: boolean;
  className?: string;
  onChange: (newValue: boolean) => void;
};

const Toggle: React.FC<ToggleProps> = ({ value, className, onChange }) => {
	const [localValue, setLocalValue] = useState<boolean|undefined>(value);

  const [sliderScale, setSliderScale] = useState<number>(1);

	useEffect(() => {
		setLocalValue(value);
    setSliderScale(1);
	}, [value]);

  const handleYesClick = () => {
    onChange(true);
		setLocalValue(true);
    setSliderScale(1);
  };

  const handleNoClick = () => {
    onChange(false);
		setLocalValue(false);
    setSliderScale(1);
  };

  return (
    <div className={`Toggle ${localValue === undefined  && "unfilled"} ${className??""}`}>
      <div className={`slider ${localValue !== undefined ? (localValue ? 'yes' : 'no') : ''}`}
        style={{transition: "transform 0.1s ease-in-out", transform: `scale(${sliderScale})`, opacity: sliderScale*sliderScale}}
      ></div>
      <button 
        onMouseDown={() => setSliderScale(0.5)}
        onMouseUp={() => setSliderScale(1)}
        onClick={handleYesClick} 
        className={`yes ${(localValue !== undefined && localValue) ? 'selected' : ''} ${localValue === undefined &&"undefined"}`}
      >
        Yes
      </button>
      <button 
        onMouseDown={() => setSliderScale(0.8)}
        onMouseUp={() => setSliderScale(1)}
        onClick={handleNoClick} 
        className={`no ${(localValue !== undefined && !localValue) ? 'selected' : ''} ${localValue === undefined &&"undefined"}`}
      >
        No
      </button>
    </div>
  );
};

export default Toggle;
