import React, { useEffect, useState } from 'react';
import { KarlStorzUser } from '../../../Model/KarlStorzUser';
import { SalesRegion } from '../../../Model/SalesRegion';
import "./AdminUserSalesRegionPopup.css";

interface AdminUserSalesRegionPopupProps {
	user: KarlStorzUser;
	salesRegions: SalesRegion[];
	onChange: (updatedSalesRegions: SalesRegion[]) => void;
	onDismiss: () => void;
}


const AdminUserSalesRegionPopup: React.FC<AdminUserSalesRegionPopupProps> = ({
	user,
	salesRegions,
	onChange,
	onDismiss,
}) => {
	const [selectedSalesRegions, setSelectedSalesRegions] = useState<string[]>(user.salesRegionIDs);

	const [sortedSalesRegions, setSortedSalesRegions] = useState(salesRegions);
	useEffect(() => {
		let sortedSalesRegions = [...salesRegions];
		sortedSalesRegions.sort((a, b) => {
			if (a.name.toLowerCase() < b.name.toLowerCase()) {
				return -1;
			}
			return 1;
		});
		setSortedSalesRegions(sortedSalesRegions);
	}, [salesRegions]);

	const handleSalesRegionChange = (event: React.ChangeEvent<HTMLInputElement>, salesRegion: SalesRegion) => {
		if (event.target.checked) {
			setSelectedSalesRegions((prevSelectedSalesRegions) => [...prevSelectedSalesRegions, salesRegion.id]);
		} else {
			setSelectedSalesRegions((prevSelectedSalesRegions) =>
				prevSelectedSalesRegions.filter((prevSelectedSalesRegion) => prevSelectedSalesRegion !== salesRegion.id)
			);
		}
	};

	const handleSubmit = () => {
		onChange(salesRegions.filter((salesRegion) => selectedSalesRegions.includes(salesRegion.id)));
	};

	return (
		<div className='AdminUserSalesRegionPopup'>
			<div className='content'>
				<div className='header'>
					<div className='buttons'>
						<div className='button' onClick={() => {
							onDismiss();
						}}>Cancel</div>
						<div className='button' onClick={handleSubmit}>Save</div>
					</div>
					<h2>Set region(s) for</h2>
					<div className='email'>{user.email}</div>
				</div>
				<ul>
					{sortedSalesRegions.map((salesRegion) => (
						<li key={salesRegion.id}>
							<label>
								<input
									type="checkbox"
									checked={selectedSalesRegions.some((selectedSalesRegion) => selectedSalesRegion === salesRegion.id)}
									onChange={(event) => handleSalesRegionChange(event, salesRegion)}
								/>
								{salesRegion.name}
							</label>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default AdminUserSalesRegionPopup;
