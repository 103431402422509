
import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationRoomLayout.css';
import { SOW } from '../../../Model/SOW';
import { DraggableScene } from '../DraggableScene/DraggableScene';
import { SceneObject } from '../../../Model/SceneObject';
interface ConfigurationRoomLayoutProps {
	sceneObjects: SceneObject[];
	roomType: string;
	backgroundURL?: string;
	backgroundImageHeight?: number;
	roomScale: number;
	forPDF: boolean;
	minLabel?: string;
	maxLabel?: string;
	onSelectSceneObject(sceneObjectID: string): void;
	onUpdated(sceneObjects: SceneObject[]): void;
}
export default function ConfigurationRoomLayout({ sceneObjects, roomScale, roomType, backgroundURL,backgroundImageHeight, minLabel, maxLabel, forPDF, onSelectSceneObject, onUpdated }: ConfigurationRoomLayoutProps) {
	const [reloading, setReloading] = useState<boolean>(false);
	useEffect(() => {
		if (reloading == true) {
			setReloading(false);
		}
	}, [reloading]);

	return (
		<div className={`ConfigurationRoomLayout`}>
			{reloading == false && (

				<DraggableScene
					roomScale={roomScale}
					className={roomType}
					backgroundURL={backgroundURL}
					backgroundImageHeight={backgroundImageHeight}
					sceneObjects={sceneObjects}
					canScale={forPDF == false}
					canRotate={forPDF == false}
					canDrag={forPDF == false}
					canDelete={forPDF == false}
					canFlip={forPDF == false}
					canHide={false}
					canLock={false}
					minLabel={minLabel}
					maxLabel={maxLabel}
					onSelectSceneObject={(sceneObjectID: string) => {
						onSelectSceneObject(sceneObjectID);
					}}
					onChange={(updatedSceneObjects: SceneObject[]) => {
						let reload = false;
						if(sceneObjects.length != updatedSceneObjects.length){
							reload = true;
						}
						onUpdated(updatedSceneObjects);
						setReloading(reload);
					}}
				></DraggableScene>
			)}
			<div className='disclaimer'>Not to scale. For coordination only, not for construction.</div>
		</div>
	);
}