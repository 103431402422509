
import React, { useRef, useState, useEffect } from 'react';
import './ScreenNoSalesRegion.css';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { Screens } from '../../Redux/Reducers';
import { sharedFirebaseApp, sharedFirebaseAuth } from '../../Utils/SharedFirebase';
interface ScreenNoSalesRegionProps{
}
export default function ScreenNoSalesRegion({  }: ScreenNoSalesRegionProps) {

  return (
    <div className='Screen ScreenNoSalesRegion'>
      <div className='popup'>
        <div className='content'>
          <div className='header'>You have not yet been added to a sales region.<br/>Please talk to your administrator.</div>
          <div className='button' onClick={() => {
            //log out
            sharedFirebaseAuth.signOut();
          }}>Okay</div>
        </div>
      </div>
    </div>
  );
}