
import React, { useRef, useState, useEffect } from 'react';
import './ScreenORRoomLayout.css';
import { Config, SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import Dropdown from '../../../Components/Dropdown/Dropdown';
import ConfigurationRoomLayout from '../../../Components/Components_OR/ConfigurationRoomLayout/ConfigurationRoomLayout';


import leftBar from '../../../Images/ui/left_bar@2x.png';
import leftBarTop from '../../../Images/ui/left_bar_top@2x.png';
import leftBarBottom from '../../../Images/ui/left_bar_bottom@2x.png';
import ConfigurationRoomList from '../../../Components/Components_OR/ConfigurationRoomList/ConfigurationRoomList';
import { SceneObject } from '../../../Model/SceneObject';
import { saveSOW } from '../../../Utils/EmailSaveAndLoad';
import { Slider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRectangleTimes, faSquare } from '@fortawesome/free-solid-svg-icons';
import ConfigHeader from '../../../Components/Components_OR/ConfigHeader/ConfigHeader';
import PopupRoomImageUpload from '../../../Components/PopupRoomImageUpload/PopupRoomImageUpload';

interface ScreenORRoomLayoutProps {
	forPDF?: boolean;
	config?: Config;
	minLabel?: string;
	maxLabel?: string;
	minIndex?: number;
	maxIndex?: number;
	sow: SOW;
	onReadyForPDF?: () => void;
}
export default function ScreenORRoomLayout({ sow, forPDF, minLabel, maxLabel, minIndex, maxIndex, config, onReadyForPDF }: ScreenORRoomLayoutProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
	}, dispatch);

	const [selectedConfig, setSelectedConfig] = useState(config ?? sow.configs[0]);
	const [selectedSceneObjectID, setSelectedSceneObjectID] = useState("");
	const [sceneObjects, setSceneObjects] = useState(selectedConfig.roomLayout.sceneObjects);
	const [roomScale, setRoomScale] = useState(selectedConfig.roomLayout.scale);
	const [roomType, setRoomType] = useState(selectedConfig.roomLayout.type);

	const [showRoomImageUploader, setShowRoomImageUploader] = useState(false);

	const [loading, setLoading] = useState(false);
	useEffect(() => {
		setSceneObjects(selectedConfig.roomLayout.sceneObjects);
		setRoomScale(selectedConfig.roomLayout.scale);
		setRoomType(selectedConfig.roomLayout.type);
		setLoading(true);
		if(selectedConfig.roomLayout.backgroundURL === undefined){
			onReadyForPDF?.();
		}else{
			const img = new Image();
			img.onload = () => {
				onReadyForPDF?.();
			}
			img.src = selectedConfig.roomLayout.backgroundURL;
		}
	}, [selectedConfig])

	useEffect(() => {
		if (loading) {
			setTimeout(() => {
				setLoading(false);
			}, 100);
		}
	}, [loading])


	return (
		<>
			<div className={`Screen ScreenORRoomLayout ${forPDF ? "forPDF" : ""}`}>
				<div className='leftBarTop' />
				<div className='verticalLabel'>Room Layout</div>
				<ConfigHeader
					sow={sow}
					forPDF={forPDF ?? false}
					selectedConfig={selectedConfig}>
					<>
						{loading == false && (
							<div className='row'>
								<Slider
									className='slider'
									defaultValue={selectedConfig.roomLayout.scale}
									min={0}
									max={1.55}
									step={0.01}
									classes={{
										root: 'sliderRoot',
										rail: 'sliderRail',
										track: 'sliderTrack',
										thumb: 'sliderThumb',
									}}
									onChange={(e, value) => {
										setRoomScale(value as number);
										let config = sow.configs.find((config) => {
											return config.id == selectedConfig.id;
										});
										if (config) {
											config.roomLayout.scale = value as number;
											AC.setSOW(sow);
										}
									}} />
								<button className={`buttonImage ${roomType == "image" ? "selected" : ""}`}

									onClick={() => {
										let config = sow.configs.find((config) => {
											return config.id == selectedConfig.id;
										});
										if (config) {
											if (roomType == "image" || config.roomLayout.backgroundURL === undefined) {
												setShowRoomImageUploader(true);
											} else {
												setRoomType("image");
												config.roomLayout.type = "image";
												AC.setSOW(sow);
											}
										}
									}}></button>
								<button className={`buttonSquare ${roomType == "square" ? "selected" : ""}`}

									onClick={() => {
										setRoomType("square");
										let config = sow.configs.find((config) => {
											return config.id == selectedConfig.id;
										});
										if (config) {
											config.roomLayout.type = "square";
											AC.setSOW(sow);
										}
									}}></button>
								<button className={`buttonRectangle ${roomType == "rectangle" ? "selected" : ""}`}

									onClick={() => {
										setRoomType("rectangle");
										let config = sow.configs.find((config) => {
											return config.id == selectedConfig.id;
										});
										if (config) {
											config.roomLayout.type = "rectangle";
											AC.setSOW(sow);
										}
									}}></button>
							</div>
						)}
					</>
				</ConfigHeader>

				{loading == false && (
					<>

						<ConfigurationRoomLayout
							sceneObjects={sceneObjects}
							backgroundURL={selectedConfig.roomLayout.backgroundURL}
							backgroundImageHeight={selectedConfig.roomLayout.backgroundImageHeight}
							onUpdated={(updatedSceneObjects: SceneObject[]) => {
								let config = sow.configs.find((config) => {
									return config.id == selectedConfig.id;
								});
								if (config) {
									config.roomLayout.sceneObjects = updatedSceneObjects;
									AC.setSOW({ ...sow } as SOW);
									if (sceneObjects.length != updatedSceneObjects.length) {
										setSelectedConfig(config);
										setSceneObjects(updatedSceneObjects);
										setLoading(true);
									}
								}
							}}
							onSelectSceneObject={(sceneObjectID: string) => {
								setSelectedSceneObjectID(sceneObjectID);
							}}
							forPDF={forPDF ?? false}
							minLabel={minLabel}
							maxLabel={maxLabel}
							roomType={roomType}
							roomScale={roomScale}></ConfigurationRoomLayout>

						<ConfigurationRoomList
							sceneObjects={sceneObjects}
							forPDF={forPDF ?? false}
							minLabel={minLabel}
							maxLabel={maxLabel}
							minIndex={minIndex}
							maxIndex={maxIndex}
							selectedSceneObjectID={selectedSceneObjectID}
							onUpdated={(updatedSceneObjects: SceneObject[]) => {
								let config = sow.configs.find((config) => {
									return config.id == selectedConfig.id;
								});
								if (config) {
									config.roomLayout.sceneObjects = updatedSceneObjects;
									AC.setSOW({ ...sow } as SOW);
									setSelectedConfig(config);
									setSceneObjects(updatedSceneObjects);
									setLoading(true);
								}
							}} />
					</>
				)}


			</div>
			{!forPDF && (
				<NavigationHeader
					selectedConfig={selectedConfig}
					sow={sow} />
			)}

			{showRoomImageUploader && <>
				<PopupRoomImageUpload
					onCancel={() => {
						setShowRoomImageUploader(false);
					}}
					onUploadComplete={(url: string,height:number) => {
						let config = sow.configs.find((config) => {
							return config.id == selectedConfig.id;
						});
						if (config) {
							config.roomLayout.backgroundURL = url;
							config.roomLayout.backgroundImageHeight = height;
							config.roomLayout.type = "image";
							AC.setSOW(sow);
							setShowRoomImageUploader(false);
							setRoomType("image");
						}
					}} />
			</>}
		</>
	);
}