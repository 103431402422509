
import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationTab.css';
import { Config } from '../../../Model/SOW';
interface ConfigurationTabProps {
	config: Config;
	configNum: number;
	selected: boolean;
	canDuplicate: boolean;
	onSelect: (config: Config) => void;
	onDuplicate: (config: Config) => void;
	onChange: (config: Config) => void;
}
export default function ConfigurationTab({ config, configNum, canDuplicate, selected, onSelect, onDuplicate, onChange }: ConfigurationTabProps) {
	const [modifyingName, setModifyingName] = useState<boolean>(false);
	const modifyingNameRef = useRef(modifyingName);
	useEffect(() => {
		modifyingNameRef.current = modifyingName;
	}, [modifyingName]);

	const inputRef = useRef<HTMLInputElement>(null);

	const [configName, setConfigName] = useState<string>(config.configName??("Config " + configNum));
	

	useEffect(() => {
		setConfigName(config.configName??("Config " + configNum));
	}, [config, config.configName]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [modifyingName])
	return (
		<>
			<div className={`ConfigurationTab ${selected ? "selected" : ""}`}
				onDoubleClick={() => {
					setModifyingName(true);
				}}
				onClick={(e) => {
					e.stopPropagation();
					onSelect(config);
				}}>
				{modifyingName ? <>
					<input type='text'
						ref={inputRef}
						defaultValue={configName} onBlur={(e) => {
							config.configName = e.target.value;
							onChange(config)
							setModifyingName(false);
						}} onKeyDown={(e) => {
							if (e.key == "Enter") {
								e.preventDefault();
								e.stopPropagation();
								config.configName = e.currentTarget.value;
								onChange(config)
								setModifyingName(false);
							}
						}} />
				</> : <>
					{configName}
				</>}
			</div>
			{selected && canDuplicate && <>
				<div className='buttonDuplicate' onClick={(e) => {
					e.stopPropagation();
					onDuplicate(config);
				}} />
			</>}
		</>
	);
}