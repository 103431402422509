
import React, { useRef, useState, useEffect } from 'react';
import './ScreenAdmin.css';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { Screens } from '../../Redux/Reducers';
import { collection, deleteDoc, doc, onSnapshot, setDoc } from 'firebase/firestore';
import { sharedFirebaseAuth, sharedFirestore } from '../../Utils/SharedFirebase';
import { KarlStorzUser } from '../../Model/KarlStorzUser';
import { SalesRegion } from '../../Model/SalesRegion';
import AdminSalesRegionRow from '../../Components/Admin/AdminSalesRegionRow/AdminSalesRegionRow';
import AdminUserRow from '../../Components/Admin/AdminUserRow/AdminUserRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import AdminUserSalesRegionPopup from '../../Components/Admin/AdminUserSalesRegionPopup/AdminUserSalesRegionPopup';

const uuid = require('uuid');


interface ScreenAdminProps {
}
export default function ScreenAdmin({ }: ScreenAdminProps) {
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen: ActionCreators.setScreen,
  }, dispatch);


  const usersCollectionRef = collection(sharedFirestore, "Users");
  const salesRegionsCollectionRef = collection(sharedFirestore, "SalesRegions");

  const [salesRegions, setSalesRegions] = useState([] as SalesRegion[]);
  const [salesRegionToDelete, setSalesRegionToDelete] = useState<SalesRegion | null>(null);
  const [userToDelete, setUserToDelete] = useState<KarlStorzUser | null>(null);
  const [karlStorzUsers, setKarlStorzUsers] = useState([] as KarlStorzUser[]);

  const [popupUser, setPopupUser] = useState<KarlStorzUser | null>(null);

  useEffect(() => {
    //subscribe to the users collection
    const unsubscribeUsers = onSnapshot(usersCollectionRef, (querySnapshot) => {
      let users = [] as KarlStorzUser[];
      querySnapshot.forEach((doc) => {
        let docData = doc.data();
        let user: KarlStorzUser = {
          id: doc.id,
          email: docData.email,
          isAdmin: docData.isAdmin,
          salesRegionID: docData.salesRegionID,
          salesRegionIDs: docData.salesRegionIDs ? docData.salesRegionIDs : [docData.salesRegionID],
        }
        let emaiParts = user.email.split('@');
        if (emaiParts[1].toLowerCase() == 'karlstorz.com') {
          users.push(user);
        }
      });

      users.sort((a, b) => {
        let emailPartsA = a.email.split('@');
        let emaiPartsB = b.email.split('@');
        if(emailPartsA[1].toLowerCase() < emaiPartsB[1].toLowerCase()){
          return 1;
        }
        if(emailPartsA[1].toLowerCase() > emaiPartsB[1].toLowerCase()){
          return -1;
        }
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return -1;
        }
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      if (users.length > 1) {
        setKarlStorzUsers(users);
      }
    });

    //subscribe to the sales regions collection
    const unsubscribeSalesRegions = onSnapshot(salesRegionsCollectionRef, (querySnapshot) => {
      let salesRegions = [] as SalesRegion[];
      querySnapshot.forEach((doc) => {
        let docData = doc.data();
        let salesRegion: SalesRegion = {
          id: doc.id,
          name: docData.name,
          sowIDs: docData.sowIDs,
        }
        salesRegions.push(salesRegion);
      });

      salesRegions.sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      if (salesRegions.length > 0) {
        setSalesRegions(salesRegions);
      }
    });
    return () => {
      unsubscribeUsers();
      unsubscribeSalesRegions();
    }
  }, [])

  return (
    <>
      <div className='Screen ScreenAdmin'>
        <div className='storzLogo'></div>
        <div className='buttonBack' onClick={() => {
          AC.setScreen(Screens.Landing);
        }}><FontAwesomeIcon icon={faArrowLeft} /> Back</div>
        <div className='content'>
          <div className='buttonLogout' onClick={() => {
            sharedFirebaseAuth.signOut();

          }}>Log out</div>
          <h1>Administrator Portal</h1>
          <div className='row'>
            <div className='leftSide'>

              <h2>Users</h2>
              <table className='usersArea'>
                <thead>
                  <tr>
                    <th>Admin</th>
                    <th>Email</th>
                    <th>Sales Region</th>
                  </tr>
                </thead>
                <tbody>
                  {karlStorzUsers.map((user) => {
                    return <AdminUserRow 
                      user={user}
                      salesRegions={salesRegions}
                      key={user.id} 
                      onDelete={(user) => {
                        setUserToDelete(user);
                      }}
                      editUserRegions={(user: KarlStorzUser)=>{
                        setPopupUser(user);
                      } } ></AdminUserRow>
                  })}
                </tbody>
              </table>
            </div>
            <div className='rightSide'>
              <h2>Sales Regions</h2>
              <div className='salesRegionsArea'>
                {salesRegions.map((salesRegion) => {
                  return <AdminSalesRegionRow salesRegion={salesRegion} key={salesRegion.id} onDelete={(salesRegion) => {
                    setSalesRegionToDelete(salesRegion);
                  }} />
                })}
                <div className='buttonAdd' onClick={() => {

                  //create a new sales region
                  let salesRegion: SalesRegion = {
                    id: uuid.v4(),
                    name: '',
                    sowIDs: [],
                  }
                  // Add a new document with a generated id.
                  const salesRegionsCollectionRef = collection(sharedFirestore, "SalesRegions");
                  let docRef = doc(salesRegionsCollectionRef, salesRegion.id);
                  ///save that doc
                  setDoc(docRef, salesRegion);

                }}><div className='circle'>+</div> New Region</div>
              </div>
            </div>
          </div>
        </div>
        {salesRegionToDelete && <div className='popup'>
          <div className='content'>
            <div className='header'>Delete {salesRegionToDelete.name}?</div>
            <div className='buttons'>
              <div className='button' onClick={() => {
                setSalesRegionToDelete(null);
              }}>No, Cancel</div>
              <div className='button' onClick={() => {
                //delete the sales region			
                let docRef = doc(sharedFirestore, "SalesRegions", salesRegionToDelete.id);
                deleteDoc(docRef);
                setSalesRegionToDelete(null);
              }}>Yes, Delete</div>
            </div>
          </div>
        </div>}
        {userToDelete && <div className='popup'>
          <div className='content'>
            <div className='header'>Delete {userToDelete.email}?</div>
            <div className='buttons'>
              <div className='button' onClick={() => {
                setUserToDelete(null);
              }}>No, Cancel</div>
              <div className='button' onClick={() => {
                //delete the user			
                let docRef = doc(sharedFirestore, "Users", userToDelete.id);
                deleteDoc(docRef);
                setUserToDelete(null);
              }}>Yes, Delete</div>
            </div>
          </div>
        </div>}
      </div>
      {popupUser && <>
        <AdminUserSalesRegionPopup
          user={popupUser}
          salesRegions={salesRegions}
          onChange={(updatedSalesRegions) => {
            //update the user
            let docRef = doc(sharedFirestore, "Users", popupUser.id);
            setDoc(docRef, { salesRegionIDs: updatedSalesRegions.map((salesRegion) => salesRegion.id) }, { merge: true });
            setPopupUser(null);
          }}
          onDismiss={() => {
            setPopupUser(null);
          }}
        />
      </>}
    </>
  );
}