
import React, { useEffect, useRef, useState } from 'react';
import { SceneObject } from '../../../Model/SceneObject';
import './StrategyMapDraggableSceneObject.css';
import { faAnchor, faArrows, faArrowsLeftRight, faBars, faCheck, faChevronDown, faCircleNodes, faEye, faEyeSlash, faHamburger, faLock, faPlus, faRotate, faTrash, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GetBoxSizeFromTypeSubType } from '../../../Utils/GetBoxSizeFromTypeSubType';

interface StrategyMapDraggableSceneObjectProps {
	roomScale: number;
	sceneObject: SceneObject;
	selectedID: string;
	canRotate: boolean;
	canDrag: boolean;
	canHide: boolean;
	canDelete: boolean;
	canFlip: boolean;
	canLock: boolean;
	canScale: boolean;
	minLabel?: string;
	maxLabel?: string;
	hidingHiddenObjects?: boolean;
	onSelect: (id: string) => void;
	onDelete: (id: string) => void;
	onChange: (id: string, changes: Partial<SceneObject>) => void;
}


const eventsToRemove: ((e: MouseEvent | TouchEvent) => void)[] = [];


export const StrategyMapDraggableSceneObject: React.FC<StrategyMapDraggableSceneObjectProps> = ({
	roomScale,
	sceneObject,
	selectedID,
	canRotate,
	canDrag,
	canDelete,
	canFlip,
	canHide,
	canLock,
	canScale,
	minLabel,
	maxLabel,
	hidingHiddenObjects,
	onSelect,
	onDelete,
	onChange
}) => {

	const [interactionMode, setInteractionMode] = useState<'move' | 'rotate' | 'scale' | 'edit' | "">('');
	const [parentElement, setParentElement] = useState<HTMLElement | null>(null);
	const buttonRotateRef = useRef<HTMLButtonElement>(null);
	const buttonScaleRef = useRef<HTMLButtonElement>(null);
	const buttonMoveRef = useRef<HTMLButtonElement>(null);
	const buttonDeleteRef = useRef<HTMLButtonElement>(null);
	const draggableSceneObjectRef = useRef<HTMLDivElement>(null);

	var onMouseUp = (e: MouseEvent | TouchEvent) => {
	}
	var onDragStart = (e: MouseEvent | TouchEvent) => {
	}
	var onDrag = (e: MouseEvent | TouchEvent) => {

	}

	useEffect(() => {
		setInteractionMode("move");
	}, [selectedID]);


	useEffect(() => {
		if (draggableSceneObjectRef.current) {
			let parent = (draggableSceneObjectRef.current as HTMLElement).parentElement!;
			while (!parent.classList.contains('StrategyMapDraggableScene') &&
				!parent.classList.contains('StrategyMapDraggableSceneObject')) {
				parent = parent.parentElement!;
			}
			setParentElement(parent);
		}
	}, [draggableSceneObjectRef.current]);

	const [labelWidth, setLabelWidth] = useState(0);
	const intputNameRef = useRef<HTMLInputElement>(null);
	const inputLetterRef = useRef<HTMLInputElement>(null);

	const [startDistance, setStartDistance] = useState(0);
	const [startScale, setStartScale] = useState(sceneObject.scale);
	const [startAngle, setStartAngle] = useState(0);


	useEffect(() => {

		for (let i = 0; i < eventsToRemove.length; i++) {
			let event = eventsToRemove[i];
			parentElement?.removeEventListener('mousemove', event);
			parentElement?.removeEventListener('mousemove', event);
			parentElement?.removeEventListener('mouseup', event);
			draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
			parentElement?.removeEventListener('touchmove', event);
			parentElement?.removeEventListener('touchmove', event);
			parentElement?.removeEventListener('touchend', event);
			draggableSceneObjectRef.current?.removeEventListener('touchstart', event);
		}

		const getDistanceToCenter = (e: MouseEvent | TouchEvent) => {
			const rect = draggableSceneObjectRef.current!.getBoundingClientRect();
			const centerX = rect.left + rect.width / 2;
			const centerY = rect.top + rect.height / 2;
			let clientX, clientY = 0;
			if ('touches' in e) {
				clientX = e.touches[0].clientX;
				clientY = e.touches[0].clientY;
			} else {
				clientX = e.clientX;
				clientY = e.clientY;
			}
			const diffX = clientX - centerX;
			const diffY = clientY - centerY;
			return Math.sqrt(diffX * diffX + diffY * diffY);
		}

		const getAngleIndDegrees = (e: MouseEvent | TouchEvent) => {
			const rect = draggableSceneObjectRef.current!.getBoundingClientRect();
			const centerX = rect.left + rect.width / 2;
			const centerY = rect.top + rect.height / 2;
			let clientX, clientY = 0;
			if ('touches' in e) {
				clientX = e.touches[0].clientX;
				clientY = e.touches[0].clientY;
			} else {
				clientX = e.clientX;
				clientY = e.clientY;
			}
			const diffX = clientX - centerX;
			const diffY = clientY - centerY;
			let angleInRad = Math.atan2(diffY, diffX);
			let angleInDeg = angleInRad * 180 / Math.PI;
			if (angleInDeg < 0) angleInDeg = 360 + angleInDeg;

			return angleInDeg;
		}


		if (sceneObject.locked) {
			onDrag = (e: MouseEvent | TouchEvent) => {
				e.stopPropagation();
			}
			onDragStart = (e: MouseEvent | TouchEvent) => {
				e.stopPropagation();
			}
		} else {
			if (interactionMode === 'move') {
				onDrag = (e: MouseEvent | TouchEvent) => {
					e.stopPropagation();
					
				if((e as any).buttons !== undefined && (e as any).buttons == 0){
						console.log("mouse up");
						onMouseUp(e);
						return;
					}
	

					let clientX, clientY = 0;
					if ('touches' in e) {
						clientX = e.touches[0].clientX;
						clientY = e.touches[0].clientY;
					} else {
						clientX = e.clientX;
						clientY = e.clientY;
					}


					// let x = clientX - parentElement!.offsetLeft;// - startX
					// let y = clientY - parentElement!.offsetTop;// - startY;

					let scale = 1;

					let parent = parentElement;
					while (parent) {
						let transform = "";
						const computedStyle = window.getComputedStyle(parent);
						if (computedStyle) {
							const computedTransform = computedStyle.transform;
							if (computedTransform) {
								transform = computedTransform;
							}
						}
						if (transform) {
							// parse the format 'matrix(0.5, 0, 0, 0.5, 0, 0)'
							const scaleMatch = transform.match(/matrix\((.*), 0, 0, (.*), 0, 0\)/);
							if (scaleMatch) {
								scale *= parseFloat(scaleMatch[1]);
							}
						}
						if (parent.parentElement) {
							parent = parent.parentElement!;
						} else {
							break;
						}
					}

					let x = clientX - parentElement!.offsetLeft * scale;// - startX
					let y = clientY - parentElement!.offsetTop * scale;// - startY;


					x = x / scale;
					y = y / scale;



					let percentX = x / parentElement!.clientWidth * 100;
					let percentY = y / parentElement!.clientHeight * 100;

					if (percentX > 100 || percentX < 0 || percentY > 100 || percentY < 0) {
						return;
					}

					if (sceneObject.minLeft) {
						percentX = Math.max(percentX, sceneObject.minLeft);
					}
					if (sceneObject.maxLeft) {
						percentX = Math.min(percentX, sceneObject.maxLeft);
					}
					if (sceneObject.minTop) {
						percentY = Math.max(percentY, sceneObject.minTop);
					}
					if (sceneObject.maxTop) {
						percentY = Math.min(percentY, sceneObject.maxTop);
					}
					percentX = Math.min(100,Math.max(0,percentX));
					percentY = Math.min(100,Math.max(0,percentY));

					onChange(sceneObject.id, { left: percentX, top: percentY });

				}
				onDragStart = (e: MouseEvent | TouchEvent) => {
					e.stopPropagation();

					let clientX, clientY = 0;
					if ('touches' in e) {
						clientX = e.touches[0].clientX;
						clientY = e.touches[0].clientY;
					} else {
						clientX = e.clientX;
						clientY = e.clientY;
					}

					//if x and y are within the bounds of the buttonRotateRef then don't move. change to rotate and return
					if (buttonRotateRef.current) {
						const rect = buttonRotateRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							setInteractionMode("rotate");
							return;
						}
					}
					//same for scale
					if (buttonScaleRef.current) {
						const rect = buttonScaleRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							setInteractionMode("scale");
							return;
						}
					}

					if(buttonDeleteRef.current){
						const rect = buttonDeleteRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							onDelete(sceneObject.id);
							return;
						}
					}

					parentElement!.addEventListener('mousemove', onDrag);
					parentElement!.addEventListener('mouseup', onMouseUp);
					parentElement!.addEventListener('touchmove', onDrag);
					parentElement!.addEventListener('touchend', onMouseUp);
				}
			} else if (interactionMode === 'rotate') {
				onDrag = (e: MouseEvent | TouchEvent) => { // Rotate
					e.stopPropagation();
				}
				onDragStart = (e: MouseEvent | TouchEvent) => { // Rotate
					e.stopPropagation();

					let startAngleInDeg = getAngleIndDegrees(e);
					let clientX, clientY = 0;
					if ('touches' in e) {
						clientX = e.touches[0].clientX;
						clientY = e.touches[0].clientY;
					} else {
						clientX = e.clientX;
						clientY = e.clientY;
					}

					//if x and y are within the bounds of the buttonMoveRef then don't move. change to rotate and return
					if (buttonMoveRef.current) {
						const rect = buttonMoveRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							setInteractionMode("move");
							return;
						}
					}
					//same for scale
					if (buttonScaleRef.current) {
						const rect = buttonScaleRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							setInteractionMode("scale");
							return;
						}
					}

					if(buttonDeleteRef.current){
						const rect = buttonDeleteRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							onDelete(sceneObject.id);
							return;
						}
					}

					let startRotation = sceneObject.rotation

					setStartAngle(startAngleInDeg);

					onDrag = (e: MouseEvent | TouchEvent) => { // Rotate
						e.stopPropagation();

						let angleInDeg = getAngleIndDegrees(e);

						let deltaAngle = (angleInDeg - startAngleInDeg);
						console.log("heff	");

						console.log(startRotation, startAngleInDeg, angleInDeg, deltaAngle);

						onChange(sceneObject.id, { rotation: startRotation + deltaAngle });
					}

					parentElement!.addEventListener('mousemove', onDrag);
					parentElement!.addEventListener('mouseup', onMouseUp);
					parentElement!.addEventListener('touchmove', onDrag);
					parentElement!.addEventListener('touchend', onMouseUp);
				}
			} else if (interactionMode === 'scale') {
				onDrag = (e: MouseEvent | TouchEvent) => { // Scale
					e.stopPropagation();

					let distance = getDistanceToCenter(e) * (1 + (roomScale * 2));
					let deltaDistance = distance - startDistance;
					//let scale = startScale + deltaDistance / 100;
					let scale = 8 * distance / parentElement!.clientWidth;
					scale = Math.min(Math.max(scale, 0.2), 20);
					setStartScale(scale);
					onChange(sceneObject.id, { scale: scale });
				}
				onDragStart = (e: MouseEvent | TouchEvent) => { // Scale
					e.stopPropagation();
					setStartScale(sceneObject.scale)

					let distance = getDistanceToCenter(e);

					let clientX, clientY = 0;
					if ('touches' in e) {
						clientX = e.touches[0].clientX;
						clientY = e.touches[0].clientY;
					} else {
						clientX = e.clientX;
						clientY = e.clientY;
					}

					//if x and y are within the bounds of the buttonMoveRef then don't move. change to rotate and return
					if (buttonMoveRef.current) {
						const rect = buttonMoveRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							setInteractionMode("move");
							return;
						}
					}
					//same for scale
					if (buttonRotateRef.current) {
						const rect = buttonRotateRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							setInteractionMode("rotate");
							return;
						}
					}

					if(buttonDeleteRef.current){
						const rect = buttonDeleteRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							onDelete(sceneObject.id);
							return;
						}
					}

					setStartDistance(distance);
					parentElement!.addEventListener('mousemove', onDrag);
					parentElement!.addEventListener('mouseup', onMouseUp);
					parentElement!.addEventListener('touchmove', onDrag);
					parentElement!.addEventListener('touchend', onMouseUp);
				}
			}
		}


		onMouseUp = (e: MouseEvent | TouchEvent) => {
			e.preventDefault();
			parentElement?.removeEventListener('mousemove', onDrag);
			parentElement?.removeEventListener('mouseup', onMouseUp);
			parentElement?.removeEventListener('touchmove', onDrag);
			parentElement?.removeEventListener('touchend', onMouseUp);
			for (let i = 0; i < eventsToRemove.length; i++) {
				let event = eventsToRemove[i];
				parentElement?.removeEventListener('mousemove', event);
				parentElement?.removeEventListener('mouseup', event);
				parentElement?.removeEventListener('touchmove', event);
				parentElement?.removeEventListener('touchend', event);
				// draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
			}
		}


		eventsToRemove.push(onDrag);
		eventsToRemove.push(onMouseUp);
		eventsToRemove.push(onDragStart);


		if (selectedID == sceneObject.id && !sceneObject.locked && interactionMode != "") {
			draggableSceneObjectRef.current?.addEventListener('mousedown', onDragStart);
			draggableSceneObjectRef.current?.addEventListener('touchstart', onDragStart);
		}
		return () => {
			if (parentElement) {
				for (let i = 0; i < eventsToRemove.length; i++) {
					let event = eventsToRemove[i];
					parentElement?.removeEventListener('mousemove', event);
					parentElement?.removeEventListener('mousemove', event);
					parentElement?.removeEventListener('mouseup', event);
					draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
					parentElement?.removeEventListener('touchmove', event);
					parentElement?.removeEventListener('touchmove', event);
					parentElement?.removeEventListener('touchend', event);
					draggableSceneObjectRef.current?.removeEventListener('touchstart', event);
				}
			}

		}
	}, [selectedID, draggableSceneObjectRef.current, sceneObject.locked, interactionMode, parentElement]);


	const handleSelect = (event: { stopPropagation: () => void; }) => {
		event.stopPropagation();
		if (selectedID != sceneObject.id) {
			onSelect(sceneObject.id);
		}
	}

	const handleDelete = (event: { stopPropagation: () => void; }) => {
		event.stopPropagation();
		onDelete(sceneObject.id);
	}


	const toggleLocked = () => {
		onChange(sceneObject.id, { locked: !sceneObject.locked });
		setInteractionMode("");
	}
	const toggleVisible = () => {
		onChange(sceneObject.id, { visible: !sceneObject.visible });
	}

	const toggleFlipped = () => {
		onChange(sceneObject.id, { flipped: !flipped });
		setInteractionMode("");
	}

	const [flipped, setFlipped] = useState(sceneObject.flipped);
	useEffect(() => {
		setFlipped(sceneObject.flipped);
	}, [sceneObject.flipped]);

	const [objectBoxWidth, setObjectBoxWidth] = useState(0);
	const [objectBoxHeight, setObjectBoxHeight] = useState(0);

	const bgPercentage = 1;

	let objectScale = (1 + (roomScale * -0.5)) * sceneObject.scale;
	let buttonScale = 1 //+ (1/objectScale * 0.5);

	//let objectBoxSize = GetBoxSizeFromTypeSubType(sceneObject.type, sceneObject.subtype);
	useEffect(() => {
		const img = new Image();
		img.src = window.getComputedStyle(draggableSceneObjectRef.current!).backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
		//set a listener for when the image has loaded
		img.onload = function () {
			//set the state of the background-image to the loaded image

			let magicScaleNumber = 0.5;

			setObjectBoxWidth(img.width * magicScaleNumber);
			setObjectBoxHeight(img.height * magicScaleNumber);
		}
	}, [sceneObject, sceneObject.type, sceneObject.subtype]);

	const stopPropagation = (e: { stopPropagation: () => void; }) => {
		e.stopPropagation();
	}

	return (
		<div
			id={sceneObject.id}
			className={`StrategyMapDraggableSceneObject ${sceneObject.type} ${sceneObject.subtype} ${sceneObject.subsubtype??""} ${flipped ? 'flipped' : ''} ${selectedID == sceneObject.id ? 'selected' : ''} ${(selectedID == sceneObject.id && sceneObject.locked == false && interactionMode == "move") ? "moving" : ""} ${interactionMode == "rotate" ? "rotating" : ""} ${(sceneObject.visible == false && hidingHiddenObjects) ? "hidden" : ""}`}
			onClick={handleSelect}
			onDoubleClick={(sceneObject.type == "label")?()=>{setInteractionMode("edit")}:undefined}
			ref={draggableSceneObjectRef}
			style={{
				transform: `translate(-50%, -50%) rotate(${sceneObject.rotation}deg) scale(${(flipped ? -1 : 1)}, 1)`,
				left: `${sceneObject.left}%`,
				top: `${sceneObject.top}%`,
				width: (objectBoxWidth==0?"":`${objectBoxWidth}px`),
				height: (objectBoxHeight==0?"":`${objectBoxHeight}px`),
				backgroundSize:  (objectBoxHeight==0?"":`${Math.max(objectBoxHeight, objectBoxWidth)}px`),
				opacity: `${sceneObject.visible ? 1 : 0.25}`,
			}}>
			<div className="content">
				{sceneObject.name}{objectScale}
			</div>

			{sceneObject.type == "label" && <>
				{(selectedID == sceneObject.id && interactionMode == "edit") ? <>
					<input 
						className='editName' 
						type='text'
						style={{minWidth:labelWidth}}
						placeholder='Edit Name'
						value={sceneObject.name} 
						onKeyPress={(e) => {if(e.key == "Enter"){setInteractionMode("")}}}
						onChange={(e) => onChange(sceneObject.id, { name: e.target.value })} 
						onFocus={(e) =>{e.target.select()}} />
					<input 
						className='editLetter' 
						type='text'
						style={{minWidth:labelWidth}}
						placeholder='Edit Text'
						onKeyPress={(e) => {if(e.key == "Enter"){setInteractionMode("")}}}
						value={sceneObject.letter} 
						onChange={(e) => onChange(sceneObject.id, { letter: e.target.value })} 
						onFocus={(e) =>{e.target.select()}} />
				</> : <>
					<div className="labelName" ref={intputNameRef}>{sceneObject.name}</div>
					<div className="labelLetter" ref={inputLetterRef}>{sceneObject.letter}</div>
				</>}
			</>}

			{sceneObject.children.map(child => {
				return <StrategyMapDraggableSceneObject
					key={child.id}
					roomScale={roomScale}
					sceneObject={child}
					selectedID={selectedID}
					canRotate={canRotate}
					canDrag={canDrag}
					canHide={canHide}
					canDelete={canDelete}
					canFlip={canFlip}
					canLock={canLock}
					canScale={canScale}
					minLabel={minLabel}
					maxLabel={maxLabel}
					hidingHiddenObjects={hidingHiddenObjects}
					onSelect={onSelect}
					onDelete={onDelete}
					onChange={onChange}
				/>
			})}

			{(sceneObject.letter != "" && (!minLabel || sceneObject.letter >= minLabel) && (!maxLabel || sceneObject.letter <= maxLabel)) && (
				<div className="letter"
					style={{
						transform: `translate(-50%, -50%) rotate(${(flipped ? 1 : -1) * sceneObject.rotation}deg) scale(${(flipped ? -1 : 1)}, 1)`
					}}>{sceneObject.letter}</div>
			)}

			{selectedID == sceneObject.id &&
				<>
					{canDrag && !sceneObject.locked && !sceneObject.noDrag && interactionMode != "edit" && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${-sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							onClick={() => setInteractionMode("move")}
							ref={buttonMoveRef}
							className={`buttonMove ${interactionMode == "move" ? "selected" : ""}`}></button>
					)}
					{canRotate && !sceneObject.locked && !sceneObject.noRotation && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${-sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							onClick={() => {
								setInteractionMode("rotate")
							}}
							className={`buttonRotate ${interactionMode == "rotate" ? "selected" : ""}`}
							ref={buttonRotateRef}></button>
					)}
					{sceneObject.type == "label" && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${-sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							onClick={() => {
								if(interactionMode == "edit"){
									onSelect("");
								}else{
									setLabelWidth(Math.max(inputLetterRef.current!.clientWidth, intputNameRef.current!.clientWidth));
									setInteractionMode("edit")
								}
							}}
							className={`buttonEdit ${interactionMode == "edit" ? "selected" : ""}`}
							ref={buttonRotateRef}></button>
					)}
					{canScale && !sceneObject.locked && !sceneObject.noScale && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${-sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							onClick={() => setInteractionMode("scale")}
							className={`buttonScale ${interactionMode == "scale" ? "selected" : ""}`}
							ref={buttonScaleRef}></button>
					)}
					{canFlip && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${-sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							className='buttonFlip'
							onClick={toggleFlipped}></button>
					)}
					{canDelete && !sceneObject.locked && !sceneObject.noDelete && sceneObject.type != "label" && (
						<button
							style={{ transform: `translate(-50%, -50%) rotate(${-sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (buttonScale)}, ${buttonScale})` }}
							className='buttonDelete'
							ref={buttonDeleteRef}
							onClick={handleDelete}></button>
					)}
					{canHide && !sceneObject.locked && !sceneObject.noHide && (
						<button className='buttonHide' onClick={toggleVisible}>
							{sceneObject.visible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
						</button>
					)}
					{canLock && (
						<button className='buttonLock' onClick={toggleLocked}>
							{sceneObject.locked ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUnlock} />}
						</button>
					)}
				</>
			}
		</div>
	);
}