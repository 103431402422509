import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadImageToFirebase from '../../Utils/UploadImageToFirebase';
import "./PopupRoomImageUpload.css";
interface UploadProps {
    onCancel: () => void;
    onUploadComplete: (url: string, height: number) => void;
}

const PopupRoomImageUpload: React.FC<UploadProps> = ({ onCancel, onUploadComplete }) => {
    const [url, setUrl] = useState<string>(''); // URL of the uploaded image
    const [imageHeight, setImageHeight] = useState<number>(0); // Height of the uploaded image
    const [fileName, setFileName] = useState<string>(''); // Name of the uploaded file
    const onDrop = useCallback((acceptedFiles: File[]) => {
        const file = acceptedFiles[0]; // We take the first file if multiple are dropped
        if (file) {
            //if its not an image then return
            if (!file.type.startsWith('image')) {
                alert('Please select an image file');
                return;
            }
            setFileName(file.name);
            UploadImageToFirebase(file)
                .then(({ url, height }) => {
                    setUrl(url);
                    setImageHeight(height);
                    //onUploadComplete(url);
                })
                .catch((error) => {
                    console.error('Error uploading file:', error);
                    alert('Failed to upload image');
                });
        }
    }, [onUploadComplete]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false     // Allow only one file to be dropped
    });

    return (
        <div className='PopupRoomImageUpload'>
            <div className='body'>
                <div className='header'>
                    <div className='buttonCancel' onClick={() => {
                        onCancel();
                    }}>Cancel</div>
                    <div className='buttonDone' onClick={() => {
                        if (url == '') {
                            onCancel();
                        } else {
                            onUploadComplete(url, imageHeight);
                        }
                    }}>Done</div>
                </div>
                <h1>Upload an image of the room's floorplan to use as the backdrop</h1>
                <div {...getRootProps()} className='dropArea'>

                    <input {...getInputProps()} />
                    {
                        isDragActive ?
                            <p>Drop the image here...</p> :
                            <>
                                <p>Drag and drop file or <span className='blue'>Browse</span></p>
                            </>
                    }
                    <div className='smallerText'>Supported file types: .jpg, .jpeg, .png</div>
                </div>

                {url != '' && <>
                    <div className='uploadedImage'>
                        <div className='label'
                            style={{ backgroundImage: `url(${url})` }}>
                            {fileName}
                        </div>
                        <div className='removeButton' onClick={() => {
                            setUrl('');
                        }} />
                    </div>
                </>}
            </div>
        </div>
    );
}

export default PopupRoomImageUpload;
