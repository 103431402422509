

import React, { useRef, useState, useEffect } from 'react';
import './StrategyMapLineBackground.css';
import { SceneObject } from '../../../Model/SceneObject';
import StrategyMapLine from '../StrategyMapLine/StrategyMapLine';
interface StrategyMapLineBackgroundProps {
  sceneObjects: SceneObject[];
}
export default function StrategyMapLineBackground({ sceneObjects }: StrategyMapLineBackgroundProps) {

	const [streamConnectSceneObject, setStreamConnectSceneObject] = useState<SceneObject | undefined>(undefined);
	const [padSceneObjects, setPadSceneObjects] = useState<SceneObject[]>([]);

	useEffect(() => {
		let streamConnectSceneObject = sceneObjects.find((sceneObject) => {
			return sceneObject.type == 'streamConnect';
		});
		if(!streamConnectSceneObject){
			setStreamConnectSceneObject(undefined);
			return;
		}
		setStreamConnectSceneObject(streamConnectSceneObject);

		let padSceneObjects = sceneObjects.filter((sceneObject) => {
			return sceneObject.type == 'pad';
		});
		setPadSceneObjects(padSceneObjects);

	}, [sceneObjects]);


  return (
    <div className='StrategyMapLineBackground'>
			{streamConnectSceneObject && <>
				{padSceneObjects.map((padSceneObject, index) => {
					let color = {
						"red": "#c01000",
						"green": "#7bb241",
						"blue": "#094067",
					}
					let key:"red"|"green"|"blue" = padSceneObject.subtype.includes("red") ? "red" : padSceneObject.subtype.includes("green") ? "green" : "blue";

					return <StrategyMapLine
						key={index}
						isDotted = {true}
						percentLeft1={streamConnectSceneObject.left}
						percentTop1={streamConnectSceneObject.top}
						percentLeft2={padSceneObject.left}
						percentTop2={padSceneObject.top}
						color={(color[key]) as string} />
				
				})}
			</>}
    </div>
  );
}