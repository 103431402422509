

import React, { useRef, useState, useEffect } from 'react';
import './DepartmentTabContent.css';
import { Config, Department } from '../../../Model/SOW';
import { BOOM_VENDORS, PURCHASE_UPGRADE } from '../../../Model/DropdownOptions';
import Dropdown from '../../Dropdown/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ResetableToggle from '../ResetableToggle/ResetableToggle';
import DepartmentLicensingArea from '../DepartmentLicensingArea/DepartmentLicensingArea';
interface DepartmentTabContentProps {
	department: Department;
	departmentNumber: number;
	onDepartmentChange: (department: Department) => void;
	onDepartmentDeleted: (department: Department) => void;
}
export default function DepartmentTabContent({ department, departmentNumber, onDepartmentChange, onDepartmentDeleted }: DepartmentTabContentProps) {
	const [userChanged, setUserChanged] = useState<boolean>(false);
	const [departmentName, setDepartmentName] = useState<string>(department.name);
	const [operatingRoomNumbers, setOperatingRoomNumbers] = useState(department.operatingRoomNumbers);
	const [licensingVideoOnDemand, setLicensingVideoOnDemand] = useState(department.licensingVideoOnDemand);
	const [licensingEHRLINC, setLicensingEHRLINC] = useState(department.licensingEHRLINC);
	const [licensingLive, setLicensingLive] = useState(department.licensingLive);
	const [licensingRoomToRoom, setLicensingRoomToRoom] = useState(department.licensingRoomToRoom);
	const [licensingTOMS, setLicensingTOMS] = useState(department.licensingTOMS);
	const [licensingDashboards, setLicensingDashboards] = useState(department.licensingDashboards);

	const [refreshingBottom, setRefreshingBottom] = useState<boolean>(false);
	useEffect(() => {
		if (refreshingBottom) {
			setTimeout(() => {
				setRefreshingBottom(false);
			}, 100);
		}
	}, [refreshingBottom]);

	const [cleared, setCleared] = useState<boolean>(false);

	useEffect(() => {
		if (userChanged) {
			if (cleared) {
				department.name = "";
				department.operatingRoomNumbers = "";
				department.licensingDashboards = "";
				department.licensingEHRLINC = "";
				department.licensingLive = "";
				department.licensingRoomToRoom = "";
				department.licensingTOMS = "";
				department.licensingVideoOnDemand = "";
				onDepartmentChange(department);
				setTimeout(() => {
					setCleared(false);
				}, 100);
				return;
			}
			department.name = departmentName;
			department.operatingRoomNumbers = operatingRoomNumbers;
			department.licensingDashboards = licensingDashboards;
			department.licensingEHRLINC = licensingEHRLINC;
			department.licensingLive = licensingLive;
			department.licensingRoomToRoom = licensingRoomToRoom;
			department.licensingTOMS = licensingTOMS;
			department.licensingVideoOnDemand = licensingVideoOnDemand;

			department.vodQuantityAIDA = licensingVideoOnDemand!=""?department.vodQuantityAIDA:0;
			department.vodQuantityTP = licensingVideoOnDemand!=""?department.vodQuantityTP:0;
			department.ehrLINCWorklist = licensingEHRLINC!=""?department.ehrLINCWorklist:false;
			department.ehrLINCArchive = licensingEHRLINC!=""?department.ehrLINCArchive:false;
			department.liveEncoderQuantity = licensingLive!=""?department.liveEncoderQuantity:0;
			department.roomToRoomDecoderQuantity = licensingRoomToRoom!=""?department.roomToRoomDecoderQuantity:0;
			department.tomsCameraQuantity = licensingTOMS!=""?department.tomsCameraQuantity:0;
			department.tomsPlateQuantity = licensingTOMS!=""?department.tomsPlateQuantity:0;
			department.tomsPofEQuantity = licensingTOMS!=""?department.tomsPofEQuantity:0;
			department.dashboardsBiomed = licensingDashboards!=""?department.dashboardsBiomed:false;
			department.dashboardsDevice = licensingDashboards!=""?department.dashboardsDevice:false;

			onDepartmentChange(department);
		}
	}, [userChanged, operatingRoomNumbers, licensingDashboards, licensingEHRLINC, licensingLive, licensingRoomToRoom, licensingTOMS, licensingVideoOnDemand, departmentName, cleared]);

	return (
		<div className='DepartmentTabContent'>
			{!cleared && (
				<div className='body'>
					<div className='tabContentHeader'>

						<div className='col'>
							<div className='label'>Department Name</div>
							<input type='text'
								className={`orNumberInput ${departmentName == "" ? "unfilled" : ""}`}
								placeholder='Department Name'
								defaultValue={departmentName}
								onBlur={(e) => {
									setDepartmentName(e.target.value);
									setUserChanged(true);
								}}
								onChange={(e) => {
									setDepartmentName(e.target.value);
									setUserChanged(true);
								}}></input>
						</div>
						<div className='col'>
							<div className='label'>Operating Room Number(s)</div>
							<input type='text'
								className={`orNumberInput ${operatingRoomNumbers == "" ? "unfilled" : ""}`}
								placeholder='Enter OR Room Number(s)'
								defaultValue={operatingRoomNumbers}
								onBlur={(e) => {
									setOperatingRoomNumbers(e.target.value);
									setUserChanged(true);
								}}
								onChange={(e) => {
									setOperatingRoomNumbers(e.target.value);
									setUserChanged(true);
								}}></input>
						</div>

						<h1 className='fullWidth'>Licensing</h1>

						<div className='col'>
							<div className='label'>Video On Demand</div>
							<ResetableToggle
								options={PURCHASE_UPGRADE}
								value={licensingVideoOnDemand}
								onChange={(option: string) => {
									if (option == "Purchase" || option == "Upgrade" || option == "Existing" || option == "") {
										setLicensingVideoOnDemand(option);
										setUserChanged(true);
										setRefreshingBottom(true);
									}
								}}></ResetableToggle>
						</div>

						<div className='col'>
							<div className='label'><i>ehr</i>LINC</div>
							<ResetableToggle
								options={PURCHASE_UPGRADE}
								value={licensingEHRLINC}
								onChange={(option: string) => {
									if (option == "Purchase" || option == "Upgrade" || option == "Existing" || option == "") {
										setLicensingEHRLINC(option);
										setUserChanged(true);
										setRefreshingBottom(true);
									}
								}}></ResetableToggle>
						</div>

						<div className='col'>
							<div className='label'>Live</div>
							<ResetableToggle
								options={PURCHASE_UPGRADE}
								value={licensingLive}
								onChange={(option: string) => {
									if (option == "Purchase" || option == "Upgrade" || option == "Existing" || option == "") {
										setLicensingLive(option);
										setUserChanged(true);
										setRefreshingBottom(true);
									}
								}}></ResetableToggle>
						</div>

						<div className='col'>
							<div className='label'>Room To Room</div>
							<ResetableToggle
								options={PURCHASE_UPGRADE}
								value={licensingRoomToRoom}
								onChange={(option: string) => {
									if (option == "Purchase" || option == "Upgrade" || option == "Existing" || option == "") {
										setLicensingRoomToRoom(option);
										setUserChanged(true);
										setRefreshingBottom(true);
									}
								}}></ResetableToggle>
						</div>

						<div className='col'>
							<div className='label'>TOMS</div>
							<ResetableToggle
								options={PURCHASE_UPGRADE}
								value={licensingTOMS}
								onChange={(option: string) => {
									if (option == "Purchase" || option == "Upgrade" || option == "Existing" || option == "") {
										setLicensingTOMS(option);
										setUserChanged(true);
										setRefreshingBottom(true);
									}
								}}></ResetableToggle>
						</div>

						<div className='col'>
							<div className='label'>Dashboards</div>
							<ResetableToggle
								options={PURCHASE_UPGRADE}
								value={licensingDashboards}
								onChange={(option: string) => {
									if (option == "Purchase" || option == "Upgrade" || option == "Existing" || option == "") {
										setLicensingDashboards(option);
										setUserChanged(true);
										setRefreshingBottom(true);
									}
								}}></ResetableToggle>
						</div>

						{departmentNumber > 1 && (
							<div className='buttonDelete'
								onClick={() => {
									onDepartmentDeleted(department)
								}}><FontAwesomeIcon icon={faTrash} /> Delete Department {departmentNumber}</div>
						)}
						<div className='buttonClear' onClick={() => {
							setCleared(true);
							setUserChanged(true);
							setLicensingDashboards("");
							setLicensingEHRLINC("");
							setLicensingLive("");
							setLicensingRoomToRoom("");
							setLicensingTOMS("");
							setLicensingVideoOnDemand("");
							setDepartmentName("");
							setOperatingRoomNumbers("");
						}}>Clear form</div>
					</div>
					{(licensingDashboards != "" || licensingEHRLINC != "" || licensingLive != "" || licensingRoomToRoom != "" || licensingTOMS != "" || licensingVideoOnDemand != "") && !refreshingBottom && (
						<DepartmentLicensingArea
							department={department}
							licensingVideoOnDemand={licensingVideoOnDemand}
							licensingEHRLINC={licensingEHRLINC}
							licensingLive={licensingLive}
							licensingRoomToRoom={licensingRoomToRoom}
							licensingTOMS={licensingTOMS}
							licensingDashboards={licensingDashboards}
							onChange={(updatedDepartment: Department) => {
								onDepartmentChange(updatedDepartment);
							}}></DepartmentLicensingArea>
					)}

					{/* <ConfigurationVideoArea
					config={config}
					onChange={updatedConfig => {
						onConfigChange(updatedConfig);
					}}></ConfigurationVideoArea>
				<ConfigurationAudioArea
					config={config}
					onChange={updatedConfig => {
						onConfigChange(updatedConfig);
					}}></ConfigurationAudioArea> */}

				</div>
			)}
		</div>
	);
}