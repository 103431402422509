
import React, { useRef, useState, useEffect } from 'react';
import './StreamConnectTopologyDiagram.css';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import { Department, SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
interface StreamConnectTopologyDiagramProps {
  sow: SOW;
  currentDepartmentIndex: number;
  forPDF?: boolean;
  onReadyForPDF: () => void;
}
export default function StreamConnectTopologyDiagram({ sow, currentDepartmentIndex, forPDF, onReadyForPDF }: StreamConnectTopologyDiagramProps) {
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen: ActionCreators.setScreen,
    setSOW: ActionCreators.setSOW,
    setCurrentDepartmentIndex: ActionCreators.setCurrentDepartmentIndex,
  }, dispatch);

  const COLUMN_OFFSET = -450;
  const COLUMN_WIDTH = 102;

  const screenRef = useRef<HTMLDivElement>(null);

  const [refreshingNavigationHeader, setRefreshingNavigationHeader] = useState<boolean>(false);
  const [showingPacsSansPopup, setShowingPacsSansPopup] = useState(false);

  const department = sow.departments[currentDepartmentIndex];
  const secondDepartment = currentDepartmentIndex < sow.departments.length - 1 ? sow.departments[currentDepartmentIndex + 1] : null;

  const [isSanArchive, setIsSanArchive] = useState<boolean>(department.isSanArchive == true);

  const [maxColumns, setMaxColumns] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      onReadyForPDF();
    }, 100);
    if(!screenRef.current){
      return;
    }
    const department = sow.departments[currentDepartmentIndex];
    const secondDepartment = currentDepartmentIndex < sow.departments.length - 1 ? sow.departments[currentDepartmentIndex + 1] : null;
    let localMaxColumns = screenRef.current.querySelectorAll('.columnArea.department1 .column').length;
    localMaxColumns = Math.max(2, localMaxColumns);
    if (secondDepartment) {
      localMaxColumns = Math.max(localMaxColumns, screenRef.current.querySelectorAll('.columnArea.department2 .column').length);
    }
    let department1Column = screenRef.current.querySelector('.columnArea .column:nth-child(1)') as HTMLElement;
    if (!department1Column) {
      return;
    }
    //get the left position on the screne of the column
    let department1ColumnLeft = department1Column.getBoundingClientRect().left;
    let lastFeatureElem = screenRef.current.querySelector('.feature:last-child') as HTMLElement | null;
    if (!lastFeatureElem) {
      return;
    }
    let department1Width = lastFeatureElem.getBoundingClientRect().right - department1ColumnLeft;
    let scale = 1;
    let parent = department1Column.parentElement;

    while (parent) {
      let transform = "";
      const computedStyle = window.getComputedStyle(parent);
      if (computedStyle) {
        const computedTransform = computedStyle.transform;
        if (computedTransform) {
          transform = computedTransform;
        }
      }
      if (transform) {
        // parse the format 'matrix(0.5, 0, 0, 0.5, 0, 0)'
        const scaleMatch = transform.match(/matrix\((.*), 0, 0, (.*), 0, 0\)/);
        if (scaleMatch) {
          scale *= parseFloat(scaleMatch[1]);
        }
      }
      if (parent.parentElement) {
        parent = parent.parentElement!;
      } else {
        break;
      }
    }
    if(forPDF){
      scale = 1;
    }
    department1ColumnLeft = department1ColumnLeft / scale + 58 - (COLUMN_OFFSET + localMaxColumns * COLUMN_WIDTH);
    department1Width = department1Width / scale;

    setBlueLine1Left(department1ColumnLeft);
    setBlueLine1Width(department1Width - 141);

    if (secondDepartment) {
      let department2Column = screenRef.current.querySelector('.columnArea.department2 .column:nth-child(1)') as HTMLElement;
      if (department2Column) {
        //get the left position on the screne of the column
        let department2ColumnLeft = department2Column.getBoundingClientRect().left;
        //get the count of columns
        let columnCount = screenRef.current.querySelectorAll('.columnArea.department2 .column').length;
        let department2Width = (columnCount - 1) * 125 + 20;
        department2ColumnLeft = department2ColumnLeft / scale + 58 - (COLUMN_OFFSET + localMaxColumns * COLUMN_WIDTH);
        //department2Width = department2Width / scale;
        setBlueLine2Left(department2ColumnLeft);
        setBlueLine2Width(department2Width);
      }
    }
    if (localMaxColumns != maxColumns) {
      setMaxColumns(localMaxColumns);
    }
    setImageAreaLeft((COLUMN_OFFSET + localMaxColumns * COLUMN_WIDTH));
  }, [currentDepartmentIndex, sow, maxColumns]);

  const [blueLine1Left, setBlueLine1Left] = useState(0);
  const [blueLine1Width, setBlueLine1Width] = useState(0);
  const [blueLine2Left, setBlueLine2Left] = useState(0);
  const [blueLine2Width, setBlueLine2Width] = useState(0);

  function getIcons(department: Department | null) {
    let departmentIcons: string[] = [];
    if (department == null) return departmentIcons;
    if (department.licensingVideoOnDemand != "") {
      if (department.vodQuantityAIDA > 0) {
        departmentIcons.push('captureBella');
      }
      if (department.vodQuantityTP > 0) {
        departmentIcons.push('captureTP');
      }
    }
    if (department.licensingEHRLINC != "" && department.ehrLINCWorklist) {
      departmentIcons.push('worklist');
    }
    // if (department.licensingLive != "") {
    if (department.liveEncoderQuantity > 0) {
      departmentIcons.push('encoder');
    }
    if (department.licensingRoomToRoom != "") {
      departmentIcons.push('decoder');
    }
    if (department.licensingTOMS != "") {
      if (department.tomsCameraQuantity > 0) {
        departmentIcons.push('toms');
      }
      departmentIcons.push('timer');
    }
    // if (department.licensingDashboards != "") {
    //   departmentIcons.push('timer');
    // }
    return departmentIcons;
  }

  function getColumns(department: Department | null) {
    let icons = getIcons(department);
    let columns: string[][] = [];

    let vodColumn: string[] = [];
    let liveR2RColumn: string[] = [];
    let tomsColumn: string[] = [];

    if (icons.includes('captureBella') || icons.includes('captureTP')) {
      columns.push(vodColumn);
    }
    if (icons.includes('encoder') || icons.includes('decoder')) {
      columns.push(liveR2RColumn);
    }
    if (icons.includes('toms') || icons.includes('timer')) {
      columns.push(tomsColumn);
    }
    if (icons.includes("captureBella")) {
      vodColumn.push('captureBella');
    }
    if (icons.includes("captureTP")) {
      vodColumn.push('captureTP');
    }
    if (icons.includes("worklist")) {
      vodColumn.push('worklist');
    }
    if (icons.includes("encoder")) {
      liveR2RColumn.push('encoder');
    }
    if (icons.includes("decoder")) {
      liveR2RColumn.push('decoder');
    }
    if (icons.includes("toms")) {
      tomsColumn.push('toms');
    }
    if (icons.includes("timer")) {
      tomsColumn.push('timer');
    }

    if (vodColumn.length > 2) {
      let secondVodColumn: string[] = [];
      let top2 = vodColumn.splice(1, 2);
      secondVodColumn.push(top2[0]);
      secondVodColumn.push(top2[1]);
      columns.splice(1, 0, secondVodColumn);
    }

    return columns;
  }

  let departmentColumns: string[][] = getColumns(department);
  let secondDepartmentColumns: string[][] = getColumns(secondDepartment);

  const [imageAreaLeft, setImageAreaLeft] = useState((COLUMN_OFFSET + maxColumns * COLUMN_WIDTH));

  return (
    <>
      <div className='Screen StreamConnectTopologyDiagram' ref={screenRef}>
        <div className='clip'>
          <div className='leftBarTop' />
          <div className='verticalLabel pos2'><div className='smaller'>StreamConnect<sup>®</sup></div>Topology Diagram</div>

          <div className='text1'>The arrangement of various StreamConnect<sup>®</sup> devices and modules on your network.</div>

          <div className='imageArea' style={{ left: imageAreaLeft }}>

            <div className='background' >
              <div className='textBacking'>{department.name}</div>

              <div className='columnArea department1'>
                {departmentColumns.map((column, index) => {
                  return <div
                    key={"dept1_" + index}
                    className='column'>
                    <div className='orLabel'>{department.operatingRoomNumbers}</div>
                    <div className='iconContainer'>
                      {column.map((icon) => {
                        return <div className={`icon ${icon}`} />
                      })}
                    </div>
                  </div>
                })}
                {/* {departmentIcons.map((icon, index) => {
                let nextIcon = index < departmentIcons.length - 1 ? departmentIcons[index + 1] : null;
                if (index % 2 == 1) {
                  return <></>
                }
                return <div
                  key={"dept1_" + index}
                  className='column'>
                  <div className='orLabel'>{department.operatingRoomNumbers}</div>
                  <div className='iconContainer'>
                    <div className={`icon ${icon}`} />
                    {nextIcon && <div className={`icon ${nextIcon}`} />}
                  </div>
                </div>
              })} */}
              </div>
            </div>

            <div className='blueLine' style={{ left: blueLine1Left, width: blueLine1Width }} />

            <div className='featureLicenses'>
              <div className='textBacking long'>Feature Licenses</div>
              {(department.licensingTOMS != "" || (secondDepartment && secondDepartment.licensingTOMS != "")) && <>
                <div className='feature toms1' />
                <div className='feature toms2' />
              </>}
              {(department.licensingRoomToRoom != "" || (secondDepartment && secondDepartment.licensingRoomToRoom != "")) && <>
                <div className='feature r2r1' />
                <div className='feature r2r2' />
              </>}
              {(department.licensingLive != "" || (secondDepartment && secondDepartment.licensingLive != "")) && <>
                <div className='feature live1' />
                <div className='feature live2' />
              </>}
              {(department.licensingDashboards != "" || (secondDepartment && secondDepartment.licensingDashboards != "")) && <>
                <div className='feature dash1' />
              </>}
              {(department.licensingVideoOnDemand != "" || (secondDepartment && secondDepartment.licensingVideoOnDemand != "")) && <>
                <div className='feature vod1' />
              </>}
            </div>

            <div className='dataCenter'></div>
            <div className='dataCenter_no_arrow'></div>


            {secondDepartment && <>
              <div className='background department2'>
                <div className='textBacking short'>{secondDepartment.name}</div>

                <div className='columnArea department2'>
                  {secondDepartmentColumns.map((column, index) => {
                    return <div
                      key={"dept2_" + index}
                      className='column'>
                      <div className='orLabel'>{secondDepartment.operatingRoomNumbers}</div>
                      <div className='iconContainer'>
                        {column.map((icon) => {
                          return <div className={`icon ${icon}`} />
                        })}
                      </div>
                    </div>
                  })}
                  {/* {secondDepartmentIcons.map((icon, index) => {
                  let nextIcon = index < secondDepartmentIcons.length - 1 ? secondDepartmentIcons[index + 1] : null;
                  if (index % 2 == 1) {
                    return <></>
                  }
                  return <div
                    key={"dept2_" + index}
                    className='column'>
                    <div className='orLabel'>{secondDepartment.operatingRoomNumbers}</div>
                    <div className='iconContainer'>
                      <div className={`icon ${icon}`} />
                      {nextIcon && <div className={`icon ${nextIcon}`} />}
                    </div>
                  </div>
                })} */}
                </div>
              </div>
              <div className='blueLine department2' style={{ left: blueLine2Left, width: blueLine2Width }} />
              <div className='arrow_bottom' />
            </>}


            <div className={`archive ${isSanArchive ? 'san' : ''}`} />

            {showingPacsSansPopup && (
              <>
                <div className='dismissPopup' onClick={() => setShowingPacsSansPopup(false)} />
                <div className='popup'>
                  <div className={`option ${!isSanArchive ? 'selected' : ''}`} onClick={() => {
                    department.isSanArchive = false;
                    setShowingPacsSansPopup(false);
                    AC.setSOW(sow);
                    setIsSanArchive(false);
                  }}>PACS Archive</div>
                  <div className={`option ${isSanArchive ? 'selected' : ''}`} onClick={() => {
                    department.isSanArchive = true;
                    setShowingPacsSansPopup(false);
                    AC.setSOW(sow);
                    setIsSanArchive(true);
                  }}>SAN, NAS Archive</div>
                </div>
              </>
            )}
            {!forPDF && (
              <div className={`buttonShowPopup ${showingPacsSansPopup ? "active" : ""}`} onClick={() => setShowingPacsSansPopup(true)} />
            )}
          </div>

          {!forPDF && !refreshingNavigationHeader && (
            <>
              <NavigationHeader
                nextButtonText={() => {
                  if (sow.departments.length > currentDepartmentIndex + 2) {
                    return "Topology Diagram " + (Math.ceil((currentDepartmentIndex + 3) / 2))
                  }
                  return ""
                }}
                onNext={(screen, departmentIndex) => {
                  if (screen == Screens.StreamConnectBegin) {
                    if (sow.departments.length > currentDepartmentIndex + 2) {
                      AC.setCurrentDepartmentIndex(currentDepartmentIndex + 2);
                      setRefreshingNavigationHeader(true);
                    } else {
                      AC.setCurrentDepartmentIndex(0);
                      AC.setScreen(screen);
                    }
                  } else {
                    AC.setCurrentDepartmentIndex(departmentIndex);
                    AC.setScreen(screen);
                  }
                }}
                sow={sow} />
            </>
          )}
        </div>
      </div>
    </>
  );
}