


import React, { useRef, useState, useEffect } from 'react';
import './PopupNurseDeskSubtype.css';
import { SOW } from '../../../Model/SOW';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
interface PopupNurseDeskSubtypeProps {
	type: string;
	subtype: string;
	onSave: (type:string, subtype: string) => void;
	onDismiss: () => void;
}
export default function PopupNurseDeskSubtype({ type, subtype, onSave, onDismiss }: PopupNurseDeskSubtypeProps) {
	const [selectedType, setSelectedType] = useState("desk");
	const [selectedSubtype, setSelectedSubtype] = useState(subtype);

	const touchScreenSubtypes = [
		{ className: "wallMounted", label: "Wall Mounted Touchscreen" },
		{ className: "deskMounted", label: "Desk Mounted Touchscreen" },
		{ className: "wallMountedWithKeyboard", label: "Wall Mounted Touchscreen w/Keyboard" },
		{ className: "deskMountedWithKeyboard", label: "Desk Mounted Touchscreen w/Keyboard" },
		{ className: "keyboard", label: "Keyboard" },
		{ className: "noImage", label: "No Image" },
	]

	const deskSubTypes = [
		{ className: "largeLDesk", label: "Large \"L\" Desk" },
		{ className: "shortLDesk", label: "Short \"L\" Desk" },
		{ className: "straightDesk", label: "Straight Desk" },
		{ className: "narrowDesk", label: "Narrow Desk" },
		{ className: "extraNarrowDesk", label: "Extra Narrow Desk" },
		{ className: "noImage", label: "No Image" },
	]


	return (
		<div className={`PopupNurseDeskSubtype`}>
			<div className='backgroundDismiss' onClick={() => {
				onDismiss();
			}} />
			<div className='popup'>
				<div className='header'>
					<div className='buttonCancel' onClick={() => {
						onDismiss();
					}}>Cancel</div>
					<div className={`buttonSave ${selectedSubtype == "" ? "disabled" : ""}`} onClick={(e) => {
						e.stopPropagation();
						onSave(selectedType, selectedSubtype);
					}}>Done</div>
				</div>
				<div className='options'>
					<div className='title'>Nurse's Desks</div>
					{deskSubTypes.map((option, index) => (
						<div key={"desk" + index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedSubtype(option.className);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("desk", option.className);
						}}>
							<div className={`tile ${type} ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Touchscreens</div>
					{touchScreenSubtypes.map((option, index) => (
						<div key={"screen" + index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedSubtype(option.className);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("desk", option.className);
						}}>
							<div className={`tile ${type} ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}