import { icon } from "@fortawesome/fontawesome-svg-core";
import { SOW } from "../Model/SOW";
import { SceneObject } from "../Model/SceneObject";
import { blankSceneObject } from "./BlankObjects";
import { getDefaultRoomConfigSceneObjectSubType, getDefaultRoomConfigSceneObjectType } from "./GetDefaultRoomConfigSceneObjectType";

export default async function rebuildRoomConfiguratorSceneObjects(sow: SOW) {
	return new Promise((resolve: (sow: SOW) => void, reject) => {
		for (var i = 0; i < sow.configs.length; i++) {
			var config = sow.configs[i];
			//clearing out the labels for objects not added in the room
			for (var j = 0; j < config.roomLayout.sceneObjects.length; j++) {
				let sceneObject = config.roomLayout.sceneObjects[j];
				if (!sceneObject.addedInRoom) {
					sceneObject.labels = [];
				}
			}
			let foundLocations = [] as string[];
			let iconLabelCombos = {} as { [location: string]: ({ id: string, label: string })[] };
			for (var j = 0; j < config.video.inputs.length; j++) {
				let input = config.video.inputs[j];
				if (foundLocations.indexOf(input.signalOriginates) == -1) {
					foundLocations.push(input.signalOriginates);
				}
				let iconLabel = {
					id: input.id,
					label: input.inputsTPButtonText
				}
				if (iconLabelCombos[input.signalOriginates] == undefined) {
					iconLabelCombos[input.signalOriginates] = [iconLabel];
				} else {
					iconLabelCombos[input.signalOriginates].push(iconLabel);
				}
			}
			for (var j = 0; j < config.video.outputs.length; j++) {
				let output = config.video.outputs[j];
				if (foundLocations.indexOf(output.signalLocation) == -1) {
					foundLocations.push(output.signalLocation);
				}
				let iconLabel = {
					id: output.id,
					label: output.outputsButtonText
				};
				if (iconLabelCombos[output.signalLocation] == undefined) {
					iconLabelCombos[output.signalLocation] = [iconLabel];
				} else {
					iconLabelCombos[output.signalLocation].push(iconLabel);
				}
			}
			for (var j = 0; j < config.audio.inputs.length; j++) {
				let input = config.audio.inputs[j];
				if (foundLocations.indexOf(input.signalOriginates) == -1) {
					foundLocations.push(input.signalOriginates);
				}
				let iconLabel = {
					id: input.id,
					label: input.inputsTPButtonText
				}
				if (iconLabelCombos[input.signalOriginates] == undefined) {
					iconLabelCombos[input.signalOriginates] = [iconLabel];
				} else {
					iconLabelCombos[input.signalOriginates].push(iconLabel);
				}
			}
			for (var j = 0; j < config.audio.outputs.length; j++) {
				let output = config.audio.outputs[j];
				if (foundLocations.indexOf(output.signalLocation) == -1) {
					foundLocations.push(output.signalLocation);
				}
				let iconLabel = {
					id: output.id,
					label: output.outputsTPButtonText
				};
				if (iconLabelCombos[output.signalLocation] == undefined) {
					iconLabelCombos[output.signalLocation] = [iconLabel];
				} else {
					iconLabelCombos[output.signalLocation].push(iconLabel);
				}
			}

			for (var j = 0; j < foundLocations.length; j++) {
				let foundLocation = foundLocations[j];
				if (foundLocation == "" || foundLocation == undefined) {
					continue;
				}
				let iconLabel = iconLabelCombos[foundLocation];
				let sceneObject = config.roomLayout.sceneObjects.find((sceneObject) => {
					return sceneObject.name == foundLocation;
				});

				if (!sceneObject) {
					let newSceneObject: SceneObject = blankSceneObject();
					newSceneObject.name = foundLocation;
					newSceneObject.labels = iconLabel;
					newSceneObject.type = getDefaultRoomConfigSceneObjectType(foundLocation, iconLabel[0].id, iconLabel[0].label);
					newSceneObject.subtype = getDefaultRoomConfigSceneObjectSubType(newSceneObject.type, foundLocation, iconLabel[0].id, iconLabel[0].label);
					config.roomLayout.sceneObjects.push(newSceneObject);
				} else {
					sceneObject.labels = iconLabel;
				}

			}
			//remove scene objects that have no labels (and aren't added in the room)
			for (var j = config.roomLayout.sceneObjects.length - 1; j >= 0; j--) {
				let sceneObject = config.roomLayout.sceneObjects[j];
				if (sceneObject.labels.length == 0 && !sceneObject.addedInRoom) {
					config.roomLayout.sceneObjects.splice(j, 1);
				}
			}

			//sort so that all the type nones come last, but leave the order of the rest the same
			config.roomLayout.sceneObjects.sort((a, b) => {
				if (a.type == "none" && b.type != "none") {
					return 1;
				}
				if (a.type != "none" && b.type == "none") {
					return -1;
				}
				if (a.type == "any" && b.type != "any") {
					return 1;
				}
				if (a.type != "any" && b.type == "any") {
					return -1;
				}
				return 0;
			})

			//set the letter for each scene object based on the order
			let count = 0;
			config.roomLayout.sceneObjects.forEach((sceneObject, index) => {
				const letter = (count + 10).toString(36).toUpperCase();
				if (sceneObject.addedInRoom == true) {
					sceneObject.letter = "";
					return;
				}
				// if (sceneObject.name.toUpperCase() == "PATIENT LEFT" ||
				// 	sceneObject.name.toUpperCase() == "PATIENT RIGHT" ||
				// 	sceneObject.name.toUpperCase() == "PATIENT HEAD" ||
				// 	sceneObject.name.toUpperCase() == "PATIENT FOOT" ||
				// 	sceneObject.name.toUpperCase() == "CEILING MOUNTED" ||
				// 	sceneObject.name.toUpperCase() == "EQUIPMENT CLOSET" ||
				// 	sceneObject.name.toUpperCase() == "CONTROL ROOM" ||
				// 	sceneObject.name.toUpperCase() == "RACK") {
				// 	sceneObject.letter = "";
				// 	return;
				// }
				sceneObject.letter = letter;
				count++;
			});
		}




		resolve(sow);
	});
}
