import React, { FC, useEffect, useState, useRef } from 'react';

interface LineProps {
  percentLeft1: number;
  percentLeft2: number;
  percentTop1: number;
  percentTop2: number;
	strokeWidth?: number;
  color: string;
  isDotted: boolean;
}

const StrategyMapLine: FC<LineProps> = ({
  percentLeft1,
  percentLeft2,
  percentTop1,
  percentTop2,
  color,
	strokeWidth,
  isDotted,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (containerRef.current) {
      let { width, height } = containerRef.current.getBoundingClientRect();
			let scale = 1;

			let parent = (containerRef.current) as HTMLElement;
			while (parent) {
				let transform = "";
				const computedStyle = window.getComputedStyle(parent);
				if (computedStyle) {
					const computedTransform = computedStyle.transform;
					if (computedTransform) {
						transform = computedTransform;
					}
				}
				if (transform) {
					// parse the format 'matrix(0.5, 0, 0, 0.5, 0, 0)'
					const scaleMatch = transform.match(/matrix\((.*), 0, 0, (.*), 0, 0\)/);
					if (scaleMatch) {
						scale *= parseFloat(scaleMatch[1]);
					}
				}
				if (parent.parentElement) {
					parent = parent.parentElement!;
				} else {
					break;
				}
			}
			width /= scale;
			height /= scale;
      setDimensions({ width, height });
    }
  }, []);

  const { width, height } = dimensions;
  const x1 = percentLeft1 * width / 100;
  const y1 = percentTop1 * height / 100;
  const x2 = percentLeft2 * width / 100;
  const y2 = percentTop2 * height / 100;

  return (
    <div ref={containerRef} className='StrategyMapLine' style={{ position: 'absolute', width: '100%', height: '100%' }}>
      <svg style={{ position: 'absolute', top: 0, left: 0, width, height }}>
        <line
          x1={x1}
          y1={y1}
          x2={x2}
          y2={y2}
          stroke={color}
          strokeWidth={strokeWidth??"6"}
          strokeDasharray={isDotted ? strokeWidth?`${strokeWidth} ${strokeWidth}`:"6 6" : '0'}
        />
      </svg>
    </div>
  );
};

export default StrategyMapLine;
