export function getDefaultRoomConfigSceneObjectType(location: string,icon:string,label:string): string {
	//looking at the "LOCATIONS" array
	switch(location.toUpperCase()) {
		case "ANESTHESIA BOOM":
		case "PERFUSION BOOM":
		case "AUX BOOM":
			return "anesBoom";
		case "NURSE DESK":
			return "desk";
		case "NURSES STATION":
			return "desk";
		case "PATIENT LEFT":
			return "none";
		case "PATIENT RIGHT":
			return "none";
		case "PATIENT HEAD":
			return "none";
		case "PATIENT FOOT":
			return "none";
		case "BEHIND DISPLAY":
			return "display";
		case "BEHIND TOUCH PANEL":
			return "display";
		case "CEILING MOUNTED":
			return "none";
		case "CONTROL ROOM":
			return "none";
		case "DISPLAY WALL":
			return "display";
		case "EQUIPMENT BOOM":
			return "boom";
		case "EQUIPMENT BOOM 2":
			return "boom";
		case "EQUIPMENT CLOSET":
			return "none";
		case "FLOOR BOX":
			return "any";
		case "MOBILE DEVICE":
			return "any";
		case "MONITOR 1 CARRIER":
			return "spindle";
		case "MONITOR 2 CARRIER":
			return "spindle";
		case "MONITOR 3 CARRIER":
			return "spindle";
		case "MONITOR 4 CARRIER":
			return "spindle";
		case "RACK":
			return "none";
		case "SPRING ARM":
			return "any";
		case "WALL MOUNTED":
			return "display";
	}

	return "any";
}

export function getDefaultRoomConfigSceneObjectSubType(type:string, location: string,icon:string,label:string): string {
	switch(type){
		case "boom":{
			return "eqBoom";
		}
		case "anesBoom":{
			return "anesthesiaBoom";
		}
		case "display":{
			return "wallDisplay55";
		}
		case "rack":{
			return "rack1";
		}
		case "spindle":{
			return "oneLightOneMonitor";
		}
		case "bed":{
			return "patientOnBed1";
		}
		case "desk":{
			return "largeLDesk wallMounted";
		}
		case "boom":{
			if(location.toUpperCase() == "ANESTHESIA BOOM"){
				return "anesthesiaBoom";
			}
			return "boomMonLight";
		}

	}
	return "any";
}




// export const LOCATIONS = [
// 	"ANESTHESIA BOOM",
// 	"NURSE DESK",
// 	"NURSES STATION",
// 	"PATIENT LEFT",
// 	"PATIENT RIGHT",
// 	"WALL - PATIENT LEFT",
// 	"WALL - PATIENT RIGHT",
// 	"BEHIND DISPLAY",
// 	"BEHIND TOUCH PANEL",
// 	"CEILING MOUNTED",
// 	"CONTROL ROOM",
// 	"DISPLAY WALL",
// 	"EQUIPMENT BOOM",
// 	"EQUIPMENT BOOM 2",
// 	"EQUIPMENT CLOSET",
// 	"FLOOR BOX",
// 	"MOBILE DEVICE",
// 	"MONITOR 1 CARRIER",
// 	"MONITOR 2 CARRIER",
// 	"MONITOR 3 CARRIER",
// 	"MONITOR 4 CARRIER",
// 	"RACK",
// 	"SPRING ARM",
// 	"WALL MOUNTED",
// ]
