import exp from "constants";
import pathAux from "../Images/icons/ListVcrplayback_def.png";
import pathCommunication from "../Images/icons/ListComm_def.png";
import pathDeskDisplay from "../Images/icons/ListDisplaymonitor_def.png";
import pathDocumentation from "../Images/icons/ListAida_def.png";
import pathWallDisplay from "../Images/icons/ListDisplaypanel_def.png";
import pathProjector from "../Images/icons/ListProjector_def.png";
import pathPIP from "../Images/icons/ListPipSignal_def.png";
import pathQuad from "../Images/icons/ListQuadSignal_def.png";
import pathRoom from "../Images/icons/ListRoom_def.png";
import pathRoomToRoom from "../Images/icons/ListRoomConnections_def.png";
import pathStorzGUI from "../Images/icons/ListStorzgui_def.png";
import pathStreaming from "../Images/icons/ListStreamer_def.png";
import pathSurigcalDisplay from "../Images/icons/ListDisplayflat_def.png";
import pathTelestration from "../Images/icons/ListTelestration_def.png";

import pathAuxSvid from "../Images/icons/ListSVideo_def.png";
import pathAuxSDI from "../Images/icons/ListSDI_def.png";
import pathAuxVGA from "../Images/icons/ListVGA_def.png";
import pathBoomCamera from "../Images/icons/ListOverheadCamera_def.png";
import pathCarm from "../Images/icons/ListCarm_def.png";
import pathCD from "../Images/icons/ListCdplayer_def.png";
import pathComfortOR1 from "../Images/icons/ListComm_def.png";
import pathComputer from "../Images/icons/ListPC_def.png";
import pathEndocam from "../Images/icons/ListEndoscopiccamera_def.png";
import pathEndocamHD from "../Images/icons/ListEndoscopicCameraHD_def.png";
import pathEndocam4K from "../Images/icons/EndoscopicCamera_4K_act.png";
import pathEndocam3D from "../Images/icons/EndoscopicCamera_3D_act.png";
import pathEMR from "../Images/icons/ListHis_def.png";
import pathExternalRoom from "../Images/icons/ListRoom_def.png";
import pathGastroscope from "../Images/icons/ListGastroscope_def.png";
import pathHDRoomCamera from "../Images/icons/ListRoomCameraHD_def.png";
import pathInLightCamera from "../Images/icons/In-Light Camera.png";
import pathLaptop from "../Images/icons/ListLaptop_def.png";
import pathLaryngoscope from "../Images/icons/Laryng_def.png";
import pathMicroscope from "../Images/icons/ListMicroscope_def.png";	
import pathMultiView from "../Images/icons/ListQuadSignal_def.png";
import pathMusic from "../Images/icons/ListIpod_def.png";
import pathNavigation from "../Images/icons/ListNavigation_def.png";
import pathPACS from "../Images/icons/ListPacs_def.png";
import pathUltrasound from "../Images/icons/ListUltrasound_def.png";
import pathVCR from "../Images/icons/ListVcrinput_def.png";
import pathVitals from "../Images/icons/ListPatientvitals_def.png";

/**
 * List of available input options for the dropdown menu.
 *  * @type {string[]}
 */
export const INPUTS = [
	"4N1",
	"2N1",
	"4x 3G-SDI",
	"AIDA GUI",
	"AIDA PLAYBACK 1",
	"AIDA PLAYBACK 2",
	"ANES BOOM 2N1",
	"ANES BOOM 4N1",
	"ANES BOOM DVI",
	"ANES BOOM SYNCEASY",
	"BOOM CAMERA",
	"BUBBLE CAM",
	"C-ARM",
	"C-HUB",
	"CCU",
	"CHARTING PC",
	"COMPOSITE",
	"DVI PLATE",
	"EQ BOOM SDI",
	"EQ BOOM 2N1",
	"EQ BOOM 4N1",
	"EQ BOOM DVI",
	"EQ BOOM SYNCEASY",
	"ENDOCAM",
	"ENDOCAM 3D",
	"HEMODYNAMICS",
	"IMAGING PC",
	"IMAGE1 S",
	"IN-LIGHT CAMERA",
	"INTUBATION",
	"LIVE IMAGE",
	"MICROSCOPE",
	"MOBILE SOURCE",
	"NURSE PC",
	"PACS PC",
	"PATIENT MONITOR",
	"PT L 2N1 PLATE",
	"PT L 4N1 PLATE",
	"PT L SYNCEASY",
	"PT R 2N1 PLATE",
	"PT R 4N1 PLATE",
	"PT R SYNCEASY",
	"REFERENCE IMAGE",
	"ROOM CAMERA",
	"ROOM TO ROOM",
	"ROOM TO ROOM IN",
	"STREAMCONNECT",
	"STREAM IN",
	"SURGEON PC",
	"SYNCEASY",
	"TRACKER PC",
	"VGA",
	"VITALS",
];

export const INPUT_ICONS = [
	{name:"Aux Plate", icon:pathAux},
	{name:"Aux S-Vid", icon:pathAuxSvid},
	{name:"Aux SDI", icon:pathAuxSDI},
	{name:"Aux VGA", icon:pathAuxVGA},
	{name:"Boom Camera", icon:pathBoomCamera},
	{name:"C-Arm", icon:pathCarm},
	{name:"CD/DVD Player", icon:pathCD},
	{name:"ComfortOR1", icon:pathComfortOR1},
	{name:"Computer", icon:pathComputer},
	{name:"Documentation", icon:pathDocumentation},
	{name:"Endocam", icon:pathEndocam},
	{name:"HD Endocam", icon:pathEndocamHD},
	{name:"4K Endocam", icon:pathEndocam4K},
	{name:"3D Endocam", icon:pathEndocam3D},
	{name:"EMR", icon:pathEMR},
	{name:"External Room", icon:pathExternalRoom},
	{name:"Gastroscope", icon:pathGastroscope},
	{name:"HD Room Camera", icon:pathHDRoomCamera},
	{name:"In-Light Camera", icon:pathInLightCamera},
	{name:"Laptop", icon:pathLaptop},
	{name:"Laryngoscope", icon:pathLaryngoscope},
	{name:"Microscope", icon:pathMicroscope},
	{name:"MultiView", icon:pathMultiView},
	{name:"Music/iPod", icon:pathMusic},
	{name:"Navigation", icon:pathNavigation},
	{name:"PACS", icon:pathPACS},
	{name:"Room Camera", icon:pathRoom},
	{name:"Room to Room", icon:pathRoomToRoom},
	{name:"STORZ GUI", icon:pathStorzGUI},
	{name:"Streaming", icon:pathStreaming},
	{name:"Surgical Display", icon:pathSurigcalDisplay},
	{name:"Telestration", icon:pathTelestration},
	{name:"Ultrasound", icon:pathUltrasound},
	{name:"VCR", icon:pathVCR},
	{name:"Vitals", icon:pathVitals},
	
]


export const OUTPUT_ICONS = [
	{name:"Aux Plate", icon:pathAux},
	{name:"Communication", icon:pathCommunication},
	{name:"Desk Display", icon:pathDeskDisplay},
	{name:"Documentation", icon:pathDocumentation},
	{name:"Wall Display", icon:pathWallDisplay},
	{name:"Projector", icon:pathProjector},
	{name:"PIP", icon:pathPIP},
	{name:"Quad", icon:pathQuad},
	{name:"Room", icon:pathRoom},
	{name:"Room to Room", icon:pathRoomToRoom},
	{name:"STORZ GUI", icon:pathStorzGUI},
	{name:"Streaming", icon:pathStreaming},
	{name:"Surgical Display", icon:pathSurigcalDisplay},
	{name:"Telestration", icon:pathTelestration},
	// {name:"Wall Display", icon:pathWallDisplay}, // Duplicate
]

/**
 * Options for the OFE inputs dropdown.
 * @type {string[]}
 */
export const INPUTS_OFE = [
	"CCU",
	"CHARTING PC",
	"HEMODYNAMICS",
	"IMAGING PC",
	"IN-LIGHT CAMERA",
	"LIVE IMAGE",
	"NURSE PC",
	"PACS PC",
	"PATIENT MONITOR",
	"REFERENCE IMAGE",
	"SURGEON PC",
	"TRACKER PC",
	"VITALS",
]

/**
 * An array of available output options.
 * @type {string[]}
 */
export const OUTPUTS = [
	"AIDA CH. 1",
	"AIDA IN 1",
	"AIDA CH. 2",
	"AIDA IN 2",
	"AUX OUTPUT",
	"AUX VITALS",
	"CODEC",
	"COLLABORATOR",
	"COLLABORATOR #1",
	"COLLABORATOR #2",
	"COLLABORATOR #3",
	"COLLABORATOR #4",
	"DA VINCI TILEPRO",
	"DISPLAY #1",
	"DISPLAY #2",
	"DISPLAY #3",
	"DISPLAY #4",
	"DISPLAY #5",
	"DISPLAY #6",
	"EIZO #1",
	"EIZO #2",
	"EIZO #3",
	"EIZO #4",
	"EIZO #5",
	"EIZO #6",
	"HDMI OUT",
	"HYBRID IMAGE 1",
	"HYBRID IMAGE 2",
	"HYBRID IMAGE 3",
	"OFE DISPLAY",
	"NAV OUTPUT",
	"PHILIPS 1",
	"PHILIPS 2",
	"PHILIPS 3",
	"PHILIPS 4",
	"RECORDING CH.1",
	"RECORDING CH.2",
	"ROOM TO ROOM",
	"ROOM TO ROOM OUT",
	"STREAMCONNECT",
	"STREAMING",
	"WALL DISPLAY",
	"WALL DISPLAY 1",
	"WALL DISPLAY 2",
	"WALL DISPLAY 3",
];

/**
 * An array of strings representing the available options for the OFE output.
 * @type {string[]}
 */
export const OUTPUTS_OFE = [
	"AUX OUTPUT",
	"AUX VITALS",
	"HYBRID IMAGE 1",
	"HYBRID IMAGE 2",
	"HYBRID IMAGE 3",
	"OFE DISPLAY",
	"NAV OUTPUT",
	"PHILIPS 1",
	"PHILIPS 2",
	"PHILIPS 3",
	"PHILIPS 4",
]

export const LOCATIONS = [
	"AUX BOOM",
	"ANESTHESIA BOOM",
	"NURSE DESK",
	"NURSES STATION",
	"PATIENT LEFT",
	"PATIENT RIGHT",
	"PATIENT HEAD",
	"PATIENT FOOT",
	"CEILING MOUNTED",
	"CONTROL ROOM",
	"EQUIPMENT BOOM",
	"EQUIPMENT BOOM 2",
	"EQUIPMENT CLOSET",
	"FLOOR BOX",
	"MONITOR 1 CARRIER",
	"MONITOR 2 CARRIER",
	"MONITOR 3 CARRIER",
	"MONITOR 4 CARRIER",
	"RACK",
	"PERFUSION BOOM",
]

/**
 * An array of strings representing the available KITS.
 */
export const KITS = [
	"WUIS1109",
	"WUIS1281",
	"WUIS1336",
	"WUIS1358",
	"WUIS1405",
	"WUIS1518",
	"WUIS1566-01",
	"WUIS1567-01",
	"WUIS1568",
	"WUIS1569",
	"WUIS1594",
	"WUIS1605",
	"WUIS1825-01",
	"WUIS1945M00",
	"WUIS2335-01",
	"WUIS2336",
	"WUIS2337",
	"WUIS2338-01",
	"WUIS2339-01",
	"WUIS2340-01",
	"WUIS2343",
	"WUIS2346",
	"WUIS2352",
	"WUIS2353",
	"WUIS2354",
	"WUIS2355",
	"WUIS2369",
	"WUIS2391",
	"WUIS2471",
	"WUIS2473",
	"WUIS2503",
	"WUIS2506",
	"WUIS2507",
	"WUIS2508",
	"WUIS2545",
	"WUIS2546",
	"WUIS2548",
	"WUIS2549",
	"WUIS2557",
	"WUIS2560",
	"WUIS2592",
	"WUIS2595",
	"WUIS2642",
	"WUIS2645",
	"WUIS2646",
	"WUIS2647",
	"WUIS2648",
	"WUIS2649",
	"WUIS2650",
	"WUIS2651",
	"WUIS2652",
	"WUIS2653",
	"WUIS2654",
	"WUIS2656",
	"WUIS2657",
	"WUIS2658",
	"WUIS2659",
	"WUIS2660",
	"WUIS2661",
	"WUIS2662",
	"WUIS2663",
	"WUIS2664",
	"WUIS2666",
	"WUIS2668",
	"WUIS2669",
	"WUIS2671",
	"WUIS2672",
	"WUIS2673",
	"WUIS2675",
	"WUIS2677",
	"WUIS2678",
	"WUIS2679",
	"WUIS2681",
	"WUIS2682",
	"WUIS2683",
	"WUIS2684",
	"WUIS2686",
	"WUIS2687",
	"WUIS2688",
	"WUIS2689",
	"WUIS2690",
	"WUIS2691",
	"WUIS2692",
	"WUIS2693",
	"WUIS2694",
	"WUIS2695",
	"WUIS2696",
	"WUIS2697",
	"WUIS2718",
	"WUIS2725",
	"WUIS2728",
	"WUIS2764",
	"WUIS2765",
	"WUIS2776",
	"WUIS2792",
	"WUIS2807",
	"WUIS2863",
	"WUIS2882",
	"WUIS2890",
	"WUIS2891",
	"WUIS2899",
	"WUIS2906",
	"WUIS2907",
	"WUIS2908",
	"WUIS2910",
	"WUIS2912",
	"WUIS2913",
	"WUIS2925",
	"WUIS2926",
	"WUIS2929",
	"WUIS2933",
	"WUIS2934",
	"WUIS2935",
	"WUIS2948",
	"WUIS2949",
	"WUIS2950",
	"WUIS2951",
	"WUIS4240",
	"WUIS469",
	"WUIS470",
	"WUIS694",
	"WUIS786",
	"WUIS85",
	"WUIS86",
	"WUIS87",
	"WUIS926",
	"WUSR13",
	"WUSR54",
];


/**
 * List of available signal options.
 */
export const SIGNALS =[
	"COMPOSITE",
	"DISPLAYPORT",
	"DVI",
	"HDMI",
	"HD-SDI",
	"3G-SDI",
	"SVID",
	"VGA",
	"N/A",
	"12G-SDI"
]


/**
 * An array of strings representing the available audio input options.
 */
export const AUDIO_INPUTS = [
	"AUDIO PLATE",
	"BOOM MIC",
	"BLUETOOTH",
	"CEILING MIC",
	"CODEC",
	"DESK MIC",
	"DISPLAY MIC",
	"IMAGING PC",
	"NURSE PC",
	"PHONE (POTS)",
	"PHONE (VOIP)",
	"PUSH TO TALK MIC",
	"RECORDING",
	"ROOM TO ROOM",
	"STREAMCONNECT",
	"STREAM IN",
	"WIRELESS MIC",
	"PACS PC",
	"AIDA"
];

/**
 * An array of available audio output options.
 * @type {string[]}
 */
export const AUDIO_OUTPUTS = [
	"CODEC",
	"CTRL RM SPEAKERS",
	"REC PLAYBACK",
	"ROOM TO ROOM",
	"SPEAKERS",
	"STREAMCONNECT",
	"STREAMING",
	"STREAM OUT",
	"AIDA"
];

export const CHECKLIST_LOCATIONS = [
	"Checklist",
	"Biomed",
	"Utilization",
	"Check/Biomed",
	"Check/Util",
	"Biomed/Util",
	"Check/Bio/Util",
	"N/A"
]

export const MULTIVIEW_OPTIONS = [
	"PIP",
	"QUAD",
	"N/A"
];

export const CAPTURE_OPTIONS = [
	"Single",
	"Dual",
	"N/A"
];

export const TOMS_OPTIONS = [
	"In-Room",
	"Entrp",
	"N/A"
];

export const BOOM_VENDORS = [
	"None",
	"Draeger Ambia",
	"Stryker S-Series",
	"Steris HarmonyAIR",
	"Hillrom FCS",
	"Skytron 4CMC",
	"Skytron 4VBC",
	"Getinge Moduevo",
	"Amico",
];

export const SYSTEM_OPTIONS = [
	"NEO IP", 
	"AIR", 
	"4KBR"
]

export const WALL_DISPLAY_SIZES = [
	`49"`, 
	`55"`, 
	`65"`, 
	`75"`, 
	`85"`
]

export const SURGICAL_DISPLAY_SIZES = [
	`27"`,
	`32"`,
	`55"`
]
export const PURCHASE_UPGRADE = [
	"Purchase",
	"Upgrade",
	"Existing"
]

export const VOD_DEVICES = [
	"AIDA",
	"TELE PACK",
	"Both"
]