
import React, { useRef, useState, useEffect } from 'react';
import './StreamConnectEnterpriseStrategyMap.css';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { SOW } from '../../../Model/SOW';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StrategyMapDraggableScene } from '../../../Components/Components_StreamConnect/StrategyMapDraggableScene/StrategyMapDraggableScene';
import { SceneObject } from '../../../Model/SceneObject';
import StrategyMapLineBackground from '../../../Components/Components_StreamConnect/StrategyMapLineBackground/StrategyMapLineBackground';
import PopupAddStrategyMapEquipment from '../../../Components/Components_StreamConnect/PopupAddStrategyMapEquipment/PopupAddStrategyMapEquipment';
import { blankSceneObject } from '../../../Utils/BlankObjects';
interface StreamConnectEnterpriseStrategyMapProps {
	sow: SOW;
	currentDepartmentIndex: number;
	forPDF?: boolean;
}
export default function StreamConnectEnterpriseStrategyMap({ sow, currentDepartmentIndex, forPDF }: StreamConnectEnterpriseStrategyMapProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setCurrentDepartmentIndex: ActionCreators.setCurrentDepartmentIndex,
	}, dispatch);

	const [refreshingNavigationHeader, setRefreshingNavigationHeader] = useState<boolean>(false);
	const [showingStrategyMapPopup, setShowingStrategyMapPopup] = useState(false);
	let department = currentDepartmentIndex < sow.departments.length ? sow.departments[currentDepartmentIndex] : sow.leftOverDepartment;
	const [selectedDepartment, setSelectedDepartment] = useState(department);

	const [sceneObjects, setSceneObjects] = useState<SceneObject[]>(department.strategyMapSceneObjects);
	const [reloading, setReloading] = useState(false);
	const [showingElementPopup, setShowingElementPopup] = useState(false);

	useEffect(() => {
		if (reloading) {
			setTimeout(() => {
				setReloading(false);
			}, 100);
		}
	}, [reloading]);

	useEffect(() => {
		let department = currentDepartmentIndex < sow.departments.length ? sow.departments[currentDepartmentIndex] : sow.leftOverDepartment;
		setSelectedDepartment(department);
		let sceneObjects = department.strategyMapSceneObjects;
		setSceneObjects(sceneObjects);
	}, [currentDepartmentIndex, sow]);

	return (
		<>
			<div className='Screen StreamConnectEnterpriseStrategyMap'>

				<div className='leftBarTop' />
				<div className='verticalLabel'>Enterprise Strategy map</div>

				<div className='text1'>The map below is a simple illustration of your enterprise and long-term vision with StreamConnect<sup>®</sup>.</div>
				<div className={`text2 ${forPDF && "pos2"}`}>
					The details below tell you:
					<ol>
						<li>Which facilities are licensed for the modules you have purchase?</li>
						<li>What are long-term possibilities for your enterprise?</li>
					</ol>
				</div>

				<div className='legend'>
					Live<br />
					Planned Scope<br />
					Not in Scope
				</div>

				{reloading == false && <>
					<StrategyMapLineBackground
						sceneObjects={sceneObjects}
					/>
					<StrategyMapDraggableScene
						sceneObjects={sceneObjects}
						roomScale={1}
						canRotate={false}
						canScale={false}
						canFlip={false}
						canDrag={forPDF == false}
						canDelete={forPDF == false}
						canHide={false}
						canLock={false}
						onChange={(newSceneObjects: SceneObject[]) => {

							let department = sow.departments.find((department) => department.id === selectedDepartment.id);
							if (department) {
								let refreshing = department.strategyMapSceneObjects.length !== newSceneObjects.length;
								department.strategyMapSceneObjects = newSceneObjects;
								AC.setSOW(sow);
								if (refreshing) {
									setReloading(true);
								}
							}
							setSceneObjects(newSceneObjects);
						}}
						onSelectSceneObject={(sceneObjectID: string) => {

						}} />
				</>}
				{!forPDF && <>
					<div className="buttonAdd" onClick={() => {
						setShowingElementPopup(true);
					}}><div className='circle'><FontAwesomeIcon icon={faPlus} /></div> Add elements</div>
				</>}

				{!forPDF && !refreshingNavigationHeader && (
					<>
						<NavigationHeader
							nextButtonText={() => {
								if (sow.departments.length > currentDepartmentIndex + 4) {
									return "Enterprise Strategy Map " + (Math.ceil((currentDepartmentIndex + 5) / 4))
								}
								if (sow.departments.length % 4 == 0 && currentDepartmentIndex < sow.departments.length) {
									return "Enterprise Strategy Map Extra";
								}
								return "Topology Diagram"
							}}
							onNext={(screen, departmentIndex) => {
								if (screen == Screens.StreamConnectTopologyDiagram) {
									if (sow.departments.length > currentDepartmentIndex + 4) {
										AC.setCurrentDepartmentIndex(currentDepartmentIndex + 4);
										setRefreshingNavigationHeader(true);
									} else {
										if (selectedDepartment == sow.leftOverDepartment) {
											AC.setCurrentDepartmentIndex(0);
											AC.setScreen(screen);
										} else {
											if (sow.departments.length % 4 == 0 && currentDepartmentIndex < sow.departments.length) {
												AC.setCurrentDepartmentIndex(currentDepartmentIndex + 4);
												setRefreshingNavigationHeader(true);
											}else{
											AC.setCurrentDepartmentIndex(0);
											AC.setScreen(screen);
											}
										}
									}
								} else {
									AC.setCurrentDepartmentIndex(departmentIndex);
									AC.setScreen(screen);
								}
							}}
							sow={sow} />
					</>
				)}
			</div>

			{showingElementPopup && (
				<PopupAddStrategyMapEquipment
					onAddEquipment={equipment => {
						let department = sow.departments.find((department) => department.id === selectedDepartment.id);
						if (department) {
							equipment.forEach((item, i) => {
								let newSceneObject = blankSceneObject();
								newSceneObject.name = item.name;
								newSceneObject.letter = item.label;
								newSceneObject.type = item.type;
								newSceneObject.subtype = item.subtype;
								newSceneObject.scale = 1;
								newSceneObject.left = 50;
								newSceneObject.top = 50;
								department!.strategyMapSceneObjects.push(newSceneObject);

								if (item.type == "pad") {
									//add a label as well
									let newLabelSceneObject = blankSceneObject();
									newLabelSceneObject.parentID = newSceneObject.id;
									newLabelSceneObject.name = "Edit Name";
									newLabelSceneObject.letter = "Edit Text";
									newLabelSceneObject.type = "label";
									newLabelSceneObject.subtype = item.subtype;
									newLabelSceneObject.scale = 1;
									newLabelSceneObject.left = 40;
									newLabelSceneObject.top = 80;
									department!.strategyMapSceneObjects.push(newLabelSceneObject);
								}


								setSceneObjects(department!.strategyMapSceneObjects);
							})
							AC.setSOW(sow);
						}
						setShowingElementPopup(false);
						setReloading(true);
					}}
					onDismiss={() => {
						setShowingElementPopup(false);
					}} />
			)}

		</>
	);
}