
import './ScreenCoverPage.css';
interface ScreenCoverPageProps{
	hospitalName:string;
	leftText:string;
	title1:string;
	title2:string;
}
export default function ScreenCoverPage({ hospitalName, leftText, title1, title2 }: ScreenCoverPageProps) {
  
  return (
    <div className='Screen ScreenCoverPage'>
			<div className='verticalLabel pos2'><div className='smaller'>{hospitalName}</div>{leftText}</div>
	    <div className='storzLogo'></div>
			<div className='title1'>{title1}</div>
			<div className='title2'>{title2}</div>
    </div>
  );
}