
import React, { useRef, useState, useEffect } from 'react';
import './NavigationHeader.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { Screens } from '../../Redux/Reducers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Config, SOW } from '../../Model/SOW';
import Dropdown from '../Dropdown/Dropdown';
import StreamConnectSOWRows from '../../Utils/StreamConnectSOWRows';

interface NavigationHeaderProps {
	sow: SOW;
	configs?: Config[];
	selectedConfig?: Config;
	disableNext?: boolean;
	disablePDF?: boolean;
	disableNavigation?: boolean;
	hideConfigDropdown?: boolean;
	beforeNavigateAway?: () => void;
	nextButtonText?: () => string;
	onNext?: (screen: Screens, configIndex: number) => void;
}
export default function NavigationHeader({ sow, configs, selectedConfig, disableNext, disablePDF, disableNavigation, hideConfigDropdown, nextButtonText, beforeNavigateAway, onNext }: NavigationHeaderProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
		setSOW: ActionCreators.setSOW,
		setPrintingPDF: ActionCreators.setPrintingPDF,
	}, dispatch);

	const currentScreen = useSelector((state: { screen: Screens }) => state.screen)
	const currentConfigIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)


	const [showDropdown, setShowDropdown] = useState(false);

	const [dropdownItems, setDropdownItems] = useState<{ configIndex: number, screen: Screens, label: string, nextLabel?: string }[]>([]);
	const [nextDropdownItem, setNextDropdownItem] = useState<{ configIndex: number, screen: Screens, label: string, nextLabel?: string }>({ configIndex: 0, screen: Screens.ORConfigSetup, label: "Configuration Setup" });
	const [labelText, setLabelText] = useState<string>("");
	const [dropdownItemIndex, setDropdownItemIndex] = useState<number>(0);


	useEffect(() => {
		let dropdownItems: { configIndex: number, screen: Screens, label: string, nextLabel?: string }[] = []
		if (sow.type == "OR Room Integration") {
			dropdownItems = [
				{ configIndex: 0, screen: Screens.ORConfigSetup, label: "Configuration Setup" },
				{ configIndex: 0, screen: Screens.ORContents, label: "Contents", nextLabel: "Table of Contents" },
				{ configIndex: currentConfigIndex, screen: Screens.ORExecutiveSummary, label: "Executive Summary" },
				{ configIndex: currentConfigIndex, screen: Screens.ORProjectRoadMap, label: "Project Roadmap" },
			];

			for (let i = 0; i < sow.configs.length; i++) {
				if (i != currentConfigIndex) {
					continue;
				}
				dropdownItems.push({ configIndex: i, screen: Screens.ORRoomLayout, label: `Room Layout` });
			}

			for (let i = 0; i < sow.configs.length; i++) {
				if (i != currentConfigIndex) {
					continue;
				}
				if (sow.configs[i].video.system == "AIR" || sow.configs[i].video.system == "4KBR") {
					continue;
				}
				dropdownItems.push({ configIndex: i, screen: Screens.ORTouchscreenLayout, label: `Touchscreen Layout` });
			}

			for (let i = 0; i < sow.configs.length; i++) {
				if (i != currentConfigIndex) {
					continue;
				}
				dropdownItems.push({ configIndex: i, screen: Screens.ORInputOutputSchedule, label: `Input/Output Schedule` });
			}

			for (let i = 0; i < sow.configs.length; i++) {
				if (i != currentConfigIndex) {
					continue;
				}
				if (sow.configs[i].boomVendor != "None" &&
					sow.configs[i].boomVendor != "" &&
					sow.configs[i].boomVendor != undefined) {
					dropdownItems.push({ configIndex: i, screen: Screens.OREquipmentBoomLayout, label: `Equipment Boom Layout` });
				}
			}


			dropdownItems.push({ configIndex: 0, screen: Screens.ORBegin, label: "Done" })

			setDropdownItems(dropdownItems);

			setDropdownItems(dropdownItems);

			let dropdownItemIndex = dropdownItems.findIndex((item) => {
				return item.screen == currentScreen;
			});
			setDropdownItemIndex(dropdownItemIndex);
			let nextDropdownItem = dropdownItems[dropdownItemIndex + 1];

			setNextDropdownItem(nextDropdownItem);
		} else {

			const purchaseRows = StreamConnectSOWRows(sow, false);
			const upgradeRows = StreamConnectSOWRows(sow, true);

			dropdownItems = [
				{ configIndex: 0, screen: Screens.StreamConnectSystemSetup, label: "System Setup" },
				// { configIndex: 0, screen: Screens.StreamConnectProjectDates, label: "Project Dates" },
				{ configIndex: 0, screen: Screens.StreamConnectTableOfContents, label: "Contents", nextLabel: "Table of Contents" },
				{ configIndex: 0, screen: Screens.StreamConnectExecutiveSummary, label: "Executive Summary" },
			];

			if(purchaseRows.length > 0){
				dropdownItems.push({ configIndex: 0, screen: Screens.StreamConnectScopeOfWork, label: "Scope of Work" });
			}
			if(upgradeRows.length > 0){
				dropdownItems.push({ configIndex: 0, screen: Screens.StreamConnectScopeOfWorkUpgrade, label: "Scope of Work (Upgrades)" });
			}
			
			dropdownItems = dropdownItems.concat([
				{ configIndex: 0, screen: Screens.StreamConnectProjectRoadmap, label: "Project Roadmap" },
				{ configIndex: 0, screen: Screens.StreamConnectEnterperiseStrategyMap, label: "Enterprise Strategy Map" },
				{ configIndex: 0, screen: Screens.StreamConnectTopologyDiagram, label: "Topology Diagram" },
				{ configIndex: 0, screen: Screens.StreamConnectBegin, label: "Done" },
			]);

			setDropdownItems(dropdownItems);

			let dropdownItemIndex = dropdownItems.findIndex((item) => {
				return item.screen == currentScreen;
			});
			setDropdownItemIndex(dropdownItemIndex);
			let nextDropdownItem = dropdownItems[dropdownItemIndex + 1];

			setNextDropdownItem(nextDropdownItem);

		}



		let labelText = "";
		dropdownItems.forEach((item) => {
			if (item.screen == currentScreen) {
				labelText = item.label;
			}
		})
		setLabelText(labelText);

	}, [currentScreen, currentConfigIndex])


	return (
		<div className='NavigationHeader'>
			{dropdownItems.length > 0 && (
				<>
					{(dropdownItemIndex < dropdownItems.length - 1 && (!nextButtonText || nextButtonText()!="")) && (
						<div className={`buttonNext ${disableNext && "disabled"} ${disableNavigation ? "disabled" : ""}`} onClick={() => {
							beforeNavigateAway && beforeNavigateAway();
							if (onNext) {
								onNext(nextDropdownItem.screen, nextDropdownItem.configIndex);
							} else {

								AC.setCurrentConfigIndex(nextDropdownItem.configIndex);
								AC.setScreen(nextDropdownItem.screen);
							}
						}}>{nextButtonText?(<>{nextButtonText()}</>):<>{nextDropdownItem.nextLabel ?? nextDropdownItem.label}</>}</div>
					)}
					<div className={`buttonPDF ${(disablePDF || (!nextButtonText || nextButtonText()!="")) && "disabled"}`} onClick={(e) => {
						e.stopPropagation();
						AC.setPrintingPDF(true);
						if(currentScreen == Screens.StreamConnectTopologyDiagram){
							AC.setScreen(Screens.Landing);
						}
					}}></div>
					<div className='topLeftNav'>
						<div className={`buttonDropdown ${showDropdown ? "active" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setShowDropdown(true);
						}}><div className='chevrons'><FontAwesomeIcon icon={showDropdown ? faChevronUp : faChevronDown} /><FontAwesomeIcon icon={showDropdown ? faChevronUp : faChevronDown} /></div><div className="label">{labelText}</div></div>

						{!hideConfigDropdown && selectedConfig && (
							<Dropdown
								className='dropdownConfig'
								options={(configs ?? sow.configs).map((config, i) => { return config.configName??("Configuration " + (sow.configs.indexOf(sow.configs.find(config2 => config2.id == config.id)!) + 1)) })}
								defaultValue={selectedConfig.configName??(`Configuration ${sow.configs.indexOf(sow.configs.find(config => config.id == selectedConfig.id)!) + 1}`)}
								onSelect={(option,index) => {
									AC.setCurrentConfigIndex(index);
								}}></Dropdown>
						)}
					</div>
					{showDropdown && (
						<div className={`dismissDropdown`} onClick={(e) => {
							e.stopPropagation();
							setShowDropdown(false);
						}}></div>
					)}

					{showDropdown && (
						<div className='dropdown'>
							<div className='dropdownItem' onClick={() => {
								beforeNavigateAway && beforeNavigateAway();
								AC.setScreen(Screens.Landing);
							}}>Home</div>
							{dropdownItems.map((item, index) => {
								if(index == dropdownItems.length-1){
									return <></>
								}
								return <div
									key={index}
									className={`dropdownItem ${(item.screen == currentScreen) ? "selected" : ""} ${disableNavigation ? "disabled" : ""}`}
									onClick={() => {
										beforeNavigateAway && beforeNavigateAway();
										if (onNext) {
											onNext(item.screen, item.configIndex);
										} else {
											AC.setCurrentConfigIndex(item.configIndex);
											AC.setScreen(item.screen);
										}
										setShowDropdown(false);
									}}>{item.label}</div>
							})}
						</div>
					)}
				</>
			)}
		</div>
	);
}