import React, { useState, useEffect } from 'react';
import './NumberInput.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown, faChevronCircleUp, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface NumberInputProps {
  className?: string;
  defaultValue: number;
  onChange?: (value: number) => void;
}

const NumberInput: React.FC<NumberInputProps> = ({ className, defaultValue, onChange }) => {
  const [value, setValue] = useState<number >(defaultValue);

  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleIncrement = () => {
    const newValue = (value || 0) + 1;
    setValue(newValue);
    if(inputRef.current){
      inputRef.current!.value = newValue.toString();
    }
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleDecrement = () => {
    const newValue = (value || 0) - 1;
    if(newValue < 0) return;
    setValue(newValue);
    if(inputRef.current){
      inputRef.current!.value = newValue.toString();
    }
    if (onChange) {
      onChange(newValue);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Math.abs(parseInt(e.target.value, 10));
    if (!isNaN(newValue)) {
      setValue(newValue);
      if (onChange) {
        onChange(newValue);
      }
    }
  };

  return (
    <div className={`NumberInput ${className || ''}`}>
      <input
        type="text"
        ref={inputRef}
        defaultValue={value}
        onFocus={(e) => e.target.select()}
        onBlur={(e) => {
          const newValue = Math.abs(parseInt(e.target.value, 10));
          e.target.value = newValue.toString();
          if (isNaN(newValue)) {
            setValue(defaultValue);
            if (onChange) {
              onChange(defaultValue);
            }
          }
        }}
        onChange={handleInputChange}
      />
      <button className="up-button" onClick={handleIncrement}><FontAwesomeIcon icon={faChevronUp}/></button>
      <button className="down-button" onClick={handleDecrement}><FontAwesomeIcon icon={faChevronDown}/></button>
    </div>
  );
};

export default NumberInput;
