
import React, { useRef, useState, useEffect } from 'react';
import './ScreenOrProjectRoadmap.css';
import { SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';

import leftBar from '../../../Images/ui/left_bar@2x.png';
import leftBarTop from '../../../Images/ui/left_bar_top@2x.png';
import leftBarBottom from '../../../Images/ui/left_bar_bottom@2x.png';

interface ScreenOrProjectRoadmapProps {
	forPDF?: boolean;
	sow: SOW;
}
export default function ScreenOrProjectRoadmap({ sow, forPDF }: ScreenOrProjectRoadmapProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
	}, dispatch);
  const currentConfigIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)
	return (
		<>
			<div className='Screen ScreenOrProjectRoadmap'>
				<div className='roadmapBackground' />
				<div className='leftBarTop' />
				{/* <div className='youAreHere' /> */}
				<div className='verticalLabel'>Project Roadmap</div>
			</div>

			{!forPDF && (
				<NavigationHeader
					selectedConfig={sow.configs[currentConfigIndex]}
					sow={sow} />
			)}
		</>
	);
}