
import React, { useRef, useState, useEffect } from 'react';
import './ScreenORTouchscreenLayout.css';
import { Config, SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';

import leftBar from '../../../Images/ui/left_bar@2x.png';
import leftBarTop from '../../../Images/ui/left_bar_top@2x.png';
import leftBarBottom from '../../../Images/ui/left_bar_bottom@2x.png';
import ConfigHeader from '../../../Components/Components_OR/ConfigHeader/ConfigHeader';
import TouchscreenInputOutputRow from '../../../Components/Components_OR/TouchscreenInputOutputRow/TouchscreenInputOutputRow';

interface ScreenORTouchscreenLayoutProps {
	forPDF?: boolean;
	config?: Config;
	sow: SOW;
}
export default function ScreenORTouchscreenLayout({ sow, forPDF, config }: ScreenORTouchscreenLayoutProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
	}, dispatch);

	const [selectedConfig, setSelectedConfig] = useState(config ?? sow.configs[0]);
	return (
		<>
			<div className='Screen ScreenORTouchscreenLayout'>
				<div className='storzLogo'></div>
				<div className='monitorBG'></div>
				<div className='monitorBG2'></div>
				<div className='inputs'>
					{selectedConfig.video.inputs.map((input, index) => {
						if(index > 7){
							return <></>
						}
						return <TouchscreenInputOutputRow videoInput={input} key={"input"+index} />
					})}
				</div>
				<div className='outputs'>
					{selectedConfig.video.outputs.map((output, index) => {
						if(index > 4){
							return <></>
						}
						return <TouchscreenInputOutputRow videoOutput={output} key={"output"+index} />
					})}
				</div>
				
				{config?.video.chat && <div className='popup popup1' />}
				{["Checklist","Check/Biomed","Check/Util","Check/Bio/Util"].indexOf(config?.video.checklistDashboard!)!=-1 &&<div className='popup popup2' />}
				{config?.video.deviceControl && <div className='popup popup3' />}
				{config?.video.lightingControl && <div className='popup popup4' />}
				{config?.video.capture != "N/A" && <div className='popup popup5' />}
				{config?.video.toms != "N/A" && <div className='popup popup6' />}
				{(config?.video.roomToRoom || config?.video.conferencing )&& <div className='popup popup7' />}
				<div className='popup popup8' />
				<div className='popup popup9' />
				<div className='popup popup10' />
				<div className='popup popup11' />
				{config!.video.inputs.length > 0 && <div className='popup popup12' />}
				{config!.video.outputs.length > 0 && <div className='popup popup13' />}

				<div className='leftBarTop' />
				<div className='verticalLabel'>Touchscreen Layout</div>
				<ConfigHeader
					sow={sow}
					forPDF={forPDF??false}
					selectedConfig={selectedConfig} ></ConfigHeader>
			</div>
			{!forPDF && (
				<NavigationHeader
					selectedConfig={selectedConfig}
					sow={sow}  />
			)}
		</>
	);
}