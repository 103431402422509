
import React, { useRef, useState, useEffect } from 'react';
import './AdminUserRow.css';
import { KarlStorzUser } from '../../../Model/KarlStorzUser';
import { SalesRegion } from '../../../Model/SalesRegion';
import { doc, setDoc } from 'firebase/firestore';
import { sharedFirestore } from '../../../Utils/SharedFirebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
interface AdminUserRowProps {
	salesRegions: SalesRegion[];
	user: KarlStorzUser;
	editUserRegions: (user: KarlStorzUser) => void;
	onDelete: (user: KarlStorzUser) => void;
}
export default function AdminUserRow({ user, salesRegions, editUserRegions, onDelete }: AdminUserRowProps) {
	const [salesRegionID, setSalesRegionID] = useState(user.salesRegionID);
	const [isAdmin, setIsAdmin] = useState(user.isAdmin);
	const [userChanged, setUserChanged] = useState(false);

	useEffect(() => {
		if (userChanged) {
			//partial update doc
			if (user.isAdmin != isAdmin || user.salesRegionID != salesRegionID) {
				let docRef = doc(sharedFirestore, "Users", user.id);
				setDoc(docRef, {
					isAdmin: isAdmin,
					salesRegionID: salesRegionID,
				}, { merge: true });
				setUserChanged(false);
			}
		}
	}, [userChanged, isAdmin, salesRegionID, user]);

	return (
		<tr className={`AdminUserRow`}>
			<td>
				<input type='checkbox' checked={isAdmin} onChange={(event) => {
					setIsAdmin(event.target.checked);
					setUserChanged(true);
				}} />
			</td>
			<td className='email'>{user.email}</td>
			<td>
				<Tooltip title={user.salesRegionIDs.map((salesRegionID, index) => {
					let salesRegion = salesRegions.find((salesRegion) => salesRegion.id == salesRegionID);
					if (salesRegion) {
						return `${salesRegion.name}${index < user.salesRegionIDs.length - 1 ? ', ' : ''}`;
					}
					return "";
				})}>
					<div className='buttonRegions'
						onClick={() => {
							editUserRegions(user);
						}}>
						{user.salesRegionIDs.map((salesRegionID, index) => {
							let salesRegion = salesRegions.find((salesRegion) => salesRegion.id == salesRegionID);
							if (salesRegion) {
								return <span className='regionName'>{salesRegion.name}{index < user.salesRegionIDs.length - 1 ? ', ' : ''}</span>
							}
						})}
					</div>
				</Tooltip>
				<div className='buttonDelete'
					onClick={() => {
						onDelete(user);
					}}><FontAwesomeIcon icon={faTrash} /></div>
				{/* <select value={salesRegionID} onChange={(event) => {
					setSalesRegionID(event.target.value);
					setUserChanged(true);
				}}>
					<option value="">Unassigned</option>
					{salesRegions.map((salesRegion) => {
						return <option value={salesRegion.id}>{salesRegion.name}</option>
					})}
				</select> */}
			</td>
		</tr>
	);
}