export default function getDefaultLocation(input: string): string {

	input = input.toUpperCase();

  if (input.includes("PT L")) {
    return "PATIENT LEFT"; 
  }

  if (input.includes("AIDA")) {
    return "NURSE DESK";
  }

  if (input.includes("BELLA")) {
    return "NURSE DESK";
  }

  if (input.includes("PT R")) {
    return "PATIENT RIGHT";
  }

  if (input.includes("EQ BOOM")) {
    return "EQUIPMENT BOOM";
  }

  if (input.includes("ANES BOOM")) {
    return "ANESTHESIA BOOM";
  }

  if (input.includes("CCU")) {
    return "EQUIPMENT BOOM";
  }

  if (input.includes("IMAGE1")) {
    return "EQUIPMENT BOOM";
  }

  if (input.includes("ENDOCAM")) {
    return "EQUIPMENT BOOM";
  }

  if (input.includes("ROOM CAMERA")) {
    return "WALL MOUNTED";
  }

  if (input.includes("PATIENT MONITOR")) {
    return "ANESTHESIA BOOM";
  }

  if (input.includes("VITALS")) {
    return "ANESTHESIA BOOM";
  }

  if (input.includes("BOOM CAMERA")) {
    return "SPRING ARM";
  }

  if (input.includes("CHARTING")) {
    return "NURSE DESK";
  }

  if (input.includes("NURSE PC")) {
    return "NURSE DESK";
  }

  if (input.includes("PACS")) {
    return "NURSE DESK";
  }

  if (input.includes("PACS PC")) {
    return "NURSE DESK";
  }

  if (input.includes("PACS COMPUTER")) {
    return "NURSE DESK";
  }

  if (input.includes("PACS COMP")) {
    return "NURSE DESK"; 
  }

  if (input.includes("NURSE COMPUTER")) {
    return "NURSE DESK";
  }

  if (input.includes("NURSE COMP")) {
    return "NURSE DESK";
  }

  if (input.includes("ROOM TO ROOM")) {
    return "RACK";
  }

  if (input.includes("ROOM TO ROOM IN")) {
    return "RACK";
  }

  if (input.includes("STREAMCONNECT")) {
    return "RACK";
  }

  if (input.includes("STREAM IN")) {
    return "RACK";
  }

  return "";

}