

import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationVideoArea.css';
import { Config } from '../../../Model/SOW';
import ComboBox from '../../ComboBox/ComboBox';
import { CAPTURE_OPTIONS, CHECKLIST_LOCATIONS, LOCATIONS, MULTIVIEW_OPTIONS, SYSTEM_OPTIONS, TOMS_OPTIONS } from '../../../Model/DropdownOptions';
import ConfigurationVideoInputs from '../ConfigurationVideoInputs/ConfigurationVideoInputs';
import ConfigurationVideoOutputs from '../ConfigurationVideoOutputs/ConfigurationVideoOutputs';
import Dropdown from '../../Dropdown/Dropdown';
import Toggle from '../../Toggle/Toggle';
interface ConfigurationVideoAreaProps {
	config: Config;
	onChange: (config: Config) => void;
}
export default function ConfigurationVideoArea({ config, onChange }: ConfigurationVideoAreaProps) {

	const [userChanged, setUserChanged] = useState<boolean>(false);
	const [system, setSystem] = useState<string>(config.video.system);
	const [lightingControl, setLightingControl] = useState<boolean | undefined>(config.video.lightingControl);
	const [roomToRoom, setRoomToRoom] = useState<boolean | undefined>(config.video.roomToRoom);
	const [chat, setChat] = useState<boolean | undefined>(config.video.chat);
	const [deviceControl, setDeviceControl] = useState<boolean | undefined>(config.video.deviceControl);
	const [touchscreenLocation, setTouchscreenLocation] = useState<string>(config.video.touchscreenLocation);
	const [multiview, setMultiview] = useState<string>(config.video.multiview);
	const [capture, setCapture] = useState<string>(config.video.capture);
	const [streaming, setStreaming] = useState<boolean | undefined>(config.video.streaming);
	const [conferencing, setConferencing] = useState<boolean | undefined>(config.video.conferencing);
	const [toms, setToms] = useState<string>(config.video.toms);
	const [checklistDashboard, setChecklistDashboard] = useState<string>(config.video.checklistDashboard);

	useEffect(() => {
		if(userChanged){
		if (config.video.system !== system ||
			config.video.lightingControl !== lightingControl ||
			config.video.roomToRoom !== roomToRoom ||
			config.video.chat !== chat ||
			config.video.deviceControl !== deviceControl ||
			config.video.touchscreenLocation !== touchscreenLocation ||
			config.video.multiview !== multiview ||
			config.video.capture !== capture ||
			config.video.streaming !== streaming ||
			config.video.conferencing !== conferencing ||
			config.video.toms !== toms ||
			config.video.checklistDashboard !== checklistDashboard) {

			config.video = {
				...config.video,
				system: system,
				lightingControl: lightingControl,
				roomToRoom: roomToRoom,
				chat: chat,
				deviceControl: deviceControl,
				touchscreenLocation: touchscreenLocation,
				multiview: multiview,
				capture: capture,
				streaming: streaming,
				conferencing: conferencing,
				toms: toms,
				checklistDashboard: checklistDashboard,
			}
			onChange(config);
		}

		}
	}, [userChanged, system, lightingControl, roomToRoom, chat, deviceControl, touchscreenLocation, multiview, capture, streaming, conferencing, toms, checklistDashboard]);


	useEffect(() => {
		if(system == "AIR" || system == "4KBR") {
			setLightingControl(false);
			setRoomToRoom(false);
			setChat(false);
			setMultiview("PIP");
			setConferencing(false);
			setToms("N/A");
			setChecklistDashboard("N/A");
			setReloading(true);
		}
	}, [system]);

	let handleChange = (key: string, value: any) => {
		// onChange({
		// 	...config,
		// 	video: {
		// 		...config.video,
		// 		[key]: value
		// 	}
		// } as Config);
		// setReloading(true);
	}

	const [reloading, setReloading] = useState<boolean>(false);
	useEffect(() => {
		if (reloading) {
			setReloading(false);
		}
	}, [reloading]);

	useEffect(() => {
		setReloading(true);
	}, [config]);

	return (
		<div className={`ConfigurationVideoArea`}>
			<div className='header'>Video</div>
			{reloading == false && (

				<div className='videoInputs'>

					<div className='input'>
						<div className='label'>System</div>
						<ComboBox
							className='system'
							options={SYSTEM_OPTIONS}
							value={system}
							onChange={(system) => {
								setSystem(system);
								handleChange("system", system);
								setUserChanged(true);
							}}></ComboBox>
					</div>

					<div className='input'>
						<div className='label'>Lighting Control</div>
						<Toggle
							className={'lightingControl'}
							value={lightingControl}
							onChange={(value: boolean) => {
								setLightingControl(value);
								handleChange("lightingControl", value);
								setUserChanged(true);
							}}></Toggle>
					</div>

					<div className='input'>
						<div className='label'>Room-to-Room</div>
						<Toggle
							className='roomToRoom'
							value={roomToRoom}
							onChange={(value: boolean) => {
								setRoomToRoom(value);
								handleChange("roomToRoom", value);
								setUserChanged(true);
							}}></Toggle>
					</div>

					<div className='input'>
						<div className='label'>Chat</div>
						<Toggle
							className='chat'
							value={chat}
							onChange={(value: boolean) => {
								setChat(value);
								handleChange("chat", value);
								setUserChanged(true);
							}}></Toggle>
					</div>

					<div className='input'>
						<div className='label'>Device Control</div>
						<Toggle
							className='deviceControl'
							value={deviceControl}
							onChange={(value: boolean) => {
								setDeviceControl(value);
								handleChange("deviceControl", value);
								setUserChanged(true);
							}}></Toggle>

					</div>

					{/* <div className='input'>
						<div className='label'>Touchscreen Location</div>
						<ComboBox
							className='touchscreenLocation'
							options={LOCATIONS}
							value={touchscreenLocation}
							onChange={(option) => {
								setTouchscreenLocation(option);
								handleChange("touchscreenLocation", option);
							}}></ComboBox>
					</div> */}

					<table className='input'>
						<tbody>
							<tr className='row'>
								<td className='label'>Multiview</td>
								<td className='label'>Capture</td>
							</tr>
							<tr className='group'>
								<td>
									<ComboBox
										className='multiview'
										options={MULTIVIEW_OPTIONS}
										value={multiview}
										onChange={(option) => {
											setMultiview(option);
											handleChange("multiview", option);
											setUserChanged(true);
										}}></ComboBox>
								</td>
								<td>
									<ComboBox
										className='capture'
										options={CAPTURE_OPTIONS}
										value={capture}
										onChange={(option) => {
											setCapture(option);
											handleChange("capture", option);
											setUserChanged(true);
										}}></ComboBox>
								</td>
							</tr>
						</tbody>
					</table>

					<div className='input'>
						<div className='label'>Streaming</div>
						<Toggle
							className='streaming'
							value={streaming}
							onChange={(value: boolean) => {
								setStreaming(value);
								handleChange("streaming", value);
								setUserChanged(true);
							}}></Toggle>

					</div>

					<div className='input'>
						<div className='label'>Conferencing</div>
						<Toggle
							className='conferencing'
							value={conferencing}
							onChange={(value: boolean) => {
								setConferencing(value);
								handleChange("conferencing", value);
								setUserChanged(true);
							}}></Toggle>
					</div>

					<div className='input'>
						<div className='label'>TOMS</div>
						<ComboBox
							className='toms'
							options={TOMS_OPTIONS}
							value={toms}
							onChange={(option) => {
								setToms(option);
								handleChange("toms", option);
								setUserChanged(true);
							}}></ComboBox>
					</div>

					<div className='input'>
						<div className='label'>Checklist/Dashboard</div>
						<ComboBox
							className='checklistDashboard'
							options={CHECKLIST_LOCATIONS}
							value={checklistDashboard}
							onChange={(option) => {
								setChecklistDashboard(option);
								handleChange("checklistDashboard", option);
								setUserChanged(true);
							}}></ComboBox>
					</div>
				</div>
			)}

			<div className='separator'></div>
			<ConfigurationVideoInputs
				config={config}
				onChange={(updatedConfig => {
					config.video = {
						...config.video,
						inputs: updatedConfig.video.inputs,
						system,
						lightingControl,
						roomToRoom,
						chat,
						deviceControl,
						touchscreenLocation,
						multiview,
						capture,
						streaming,
						conferencing,
						toms,
						checklistDashboard,
					}
					onChange(config);
					setUserChanged(true);
				})}></ConfigurationVideoInputs>

			<div className='separator'></div>
			<ConfigurationVideoOutputs
				config={config}
				onChange={(updatedConfig => {
					config.video = {
						...config.video,
						outputs: updatedConfig.video.outputs,
						system,
						lightingControl,
						roomToRoom,
						chat,
						deviceControl,
						touchscreenLocation,
						multiview,
						capture,
						streaming,
						conferencing,
						toms,
						checklistDashboard,
					}
					onChange(config);
					setUserChanged(true);
				})}></ConfigurationVideoOutputs>

		</div>
	);
}