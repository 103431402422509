


import React, { useRef, useState, useEffect } from 'react';
import './PopupAddStrategyMapEquipment.css';
import { SOW } from '../../../Model/SOW';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { on } from 'events';
import { JsxElement } from 'typescript';
interface PopupAddStrategyMapEquipmentProps {
	onAddEquipment: (equipment: { type: string, subtype: string, name: string, label: string }[]) => void;
	onDismiss: () => void;
}
export default function PopupAddStrategyMapEquipment({ onAddEquipment, onDismiss }: PopupAddStrategyMapEquipmentProps) {
	const [selectedEquipment, setSelectedEquipment] = useState<{ type: string, subtype: string, name: string, label: string }[]>([]);

	const [searchString, setSearchString] = useState("");


	const options = [

		{ type: "streamConnect", category: "StreamConnect®", subtype: "blue", label: "Blue" },
		{ type: "streamConnect", category: "StreamConnect®", subtype: "green", label: "Green" },
		{ type: "streamConnect", category: "StreamConnect®", subtype: "red", label: "Red" },

		{ type: "pad", category: "Platforms", subtype: "blue", label: "Blue Large" },
		{ type: "pad", category: "Platforms", subtype: "green", label: "Green Large" },
		{ type: "pad", category: "Platforms", subtype: "red", label: "Red Large" },
		{ type: "pad", category: "Platforms", subtype: "blue small", label: "Blue" },
		{ type: "pad", category: "Platforms", subtype: "green small", label: "Green" },
		{ type: "pad", category: "Platforms", subtype: "red small", label: "Red" },

		{ type: "pin", category: "Live Icons", subtype: "live vod", label: "Video on Demand" },
		{ type: "pin", category: "Live Icons", subtype: "live ehrLinc Arch", label: "ehrLinc Archive" },
		{ type: "pin", category: "Live Icons", subtype: "live ehrLinc Worklist", label: "ehrLinc Worklist" },
		{ type: "pin", category: "Live Icons", subtype: "live ehrLinc ArchWorklist", label: "ehrLinc Arch + WL" },
		{ type: "pin", category: "Live Icons", subtype: "live liveStream", label: "Live Stream" },
		{ type: "pin", category: "Live Icons", subtype: "live roomToRoom", label: "Room to Room" },
		{ type: "pin", category: "Live Icons", subtype: "live toms", label: "TOMS" },
		{ type: "pin", category: "Live Icons", subtype: "live biomed", label: "Biomed Dashboard" },
		{ type: "pin", category: "Live Icons", subtype: "live deviceUtilization", label: "Device Utilization" },

		{ type: "pin", category: "In Scope Icons", subtype: "inScope vod", label: "Video on Demand" },
		{ type: "pin", category: "In Scope Icons", subtype: "inScope ehrLinc Arch", label: "ehrLinc Archive" },
		{ type: "pin", category: "In Scope Icons", subtype: "inScope ehrLinc Worklist", label: "ehrLinc Worklist" },
		{ type: "pin", category: "In Scope Icons", subtype: "inScope ehrLinc ArchWorklist", label: "ehrLinc Arch + WL" },
		{ type: "pin", category: "In Scope Icons", subtype: "inScope liveStream", label: "Live Stream" },
		{ type: "pin", category: "In Scope Icons", subtype: "inScope roomToRoom", label: "Room to Room" },
		{ type: "pin", category: "In Scope Icons", subtype: "inScope toms", label: "TOMS" },
		{ type: "pin", category: "In Scope Icons", subtype: "inScope biomed", label: "Biomed Dashboard" },
		{ type: "pin", category: "In Scope Icons", subtype: "inScope deviceUtilization", label: "Device Utilization" },

		{ type: "pin", category: "Out of Scope Icons", subtype: "outOfScope vod", label: "Video on Demand" },
		{ type: "pin", category: "Out of Scope Icons", subtype: "outOfScope ehrLinc Arch", label: "ehrLinc Archive" },
		{ type: "pin", category: "Out of Scope Icons", subtype: "outOfScope ehrLinc Worklist", label: "ehrLinc Worklist" },
		{ type: "pin", category: "Out of Scope Icons", subtype: "outOfScope ehrLinc ArchWorklist", label: "ehrLinc Arch + WL" },
		{ type: "pin", category: "Out of Scope Icons", subtype: "outOfScope liveStream", label: "Live Stream" },
		{ type: "pin", category: "Out of Scope Icons", subtype: "outOfScope roomToRoom", label: "Room to Room" },
		{ type: "pin", category: "Out of Scope Icons", subtype: "outOfScope toms", label: "TOMS" },
		{ type: "pin", category: "Out of Scope Icons", subtype: "outOfScope biomed", label: "Biomed Dashboard" },
		{ type: "pin", category: "Out of Scope Icons", subtype: "outOfScope deviceUtilization", label: "Device Utilization" },

		{ type: "pin", category: "No Status Icons", subtype: "noStatus vod", label: "Video on Demand" },
		{ type: "pin", category: "No Status Icons", subtype: "noStatus ehrLinc Arch", label: "ehrLinc Archive" },
		{ type: "pin", category: "No Status Icons", subtype: "noStatus ehrLinc Worklist", label: "ehrLinc Worklist" },
		{ type: "pin", category: "No Status Icons", subtype: "noStatus ehrLinc ArchWorklist", label: "ehrLinc Arch + WL" },
		{ type: "pin", category: "No Status Icons", subtype: "noStatus liveStream", label: "Live Stream" },
		{ type: "pin", category: "No Status Icons", subtype: "noStatus roomToRoom", label: "Room to Room" },
		{ type: "pin", category: "No Status Icons", subtype: "noStatus toms", label: "TOMS" },
		{ type: "pin", category: "No Status Icons", subtype: "noStatus biomed", label: "Biomed Dashboard" },
		{ type: "pin", category: "No Status Icons", subtype: "noStatus deviceUtilization", label: "Device Utilization" },


	];

	const distinctCategories:string[] = [];
	for(var i=0; i< options.length; i++){
		let option = options[i];
		if (!distinctCategories.includes(option.category.toString())) {
			distinctCategories.push(option.category.toString());
		}
	}

	return (
		<div className={`PopupAddStrategyMapEquipment`}>
			<div className='backgroundDismiss' onClick={() => {
				onDismiss();
			}} />
			<div className='popup'>
				<div className='header'>
					{selectedEquipment.length > 0 && (
						<div className='itemsSelected'>{selectedEquipment.length} ITEM(S) SELECTED</div>
					)}
					<div className='buttonCancel' onClick={() => {
						//writeCSS();
						onDismiss();
					}}>Cancel</div>
					<div className={`buttonSave ${selectedEquipment.length == 0 ? "disabled" : ""}`} onClick={(e) => {
						e.stopPropagation();
						onAddEquipment(selectedEquipment);
					}}>Done</div>
				</div>

				<div className='options'>
					{distinctCategories.map((category, index) => {
						let categoryOptions = options.filter((categoryOption) => {
							return categoryOption.category == category.toString();
						});
						if (categoryOptions.length == 0) {
							return <></>;
						}
						return (
							<div key={"category" + index} className='typeSection'>
								<div className='typeTitle'>{category}</div>
								{categoryOptions.map((option, index) => {
									return <div
										key={category.toString() + index}
										className={`option ${selectedEquipment.find(equipment => equipment.type == option.type && equipment.subtype == option.subtype) ? "selected" : ""}`}
										onDoubleClick={() => {
											let returnSelectedEquipment = [...selectedEquipment];
											let equipment = returnSelectedEquipment.find(equipment => equipment.type == option.type && equipment.subtype == option.subtype);
											if (equipment) {
												returnSelectedEquipment = returnSelectedEquipment.filter(equipment => equipment.type != option.type || equipment.subtype != option.subtype);
											} else {
												returnSelectedEquipment = [...returnSelectedEquipment, { type: option.type, subtype:option.subtype, name: "", label: option.label }];
											}
											onAddEquipment(returnSelectedEquipment);
										}}
										onClick={() => {
											let equipment = selectedEquipment.find(equipment => equipment.type == option.type && equipment.subtype == option.subtype);
											if (equipment) {
												setSelectedEquipment(selectedEquipment.filter(equipment => equipment.type != option.type || equipment.subtype != option.subtype));
											} else {
												setSelectedEquipment([...selectedEquipment, { type: option.type, subtype:option.subtype, name: "", label: option.label }]);
											}
										}}>
										<div className={`tile  ${option.type} ${option.subtype}`}></div>
										<div className='label'>{option.label}</div>
									</div>
								})}
							</div>
						)
					})}
				</div>
			</div>
		</div >
	);
}