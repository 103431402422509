import { collection, query, where, getDocs } from "firebase/firestore";
import { sharedFirestore } from "./SharedFirebase";
import { KarlStorzUser } from "../Model/KarlStorzUser";

export const GetKarlStorzUser = (email:string) => {
	return new Promise((resolve:(value:KarlStorzUser|null)=>void, reject) => {
		const personsCollectionRef = collection(sharedFirestore, "Users");

		console.log("grabbing users");

		const q = query(personsCollectionRef, where("email", "==", email.toLowerCase()));
		getDocs(q).then(async (querySnapshot) => {

			querySnapshot.forEach((userDoc) => {
				let userDocData = userDoc.data();
				let loggedInUser:KarlStorzUser = {
					id: userDoc.id,
					email: userDocData.email,
					isAdmin: userDocData.isAdmin,
					salesRegionID: userDocData.salesRegionID,
					salesRegionIDs: userDocData.salesRegionIDs?userDocData.salesRegionIDs:[userDocData.salesRegionID],
				}
				resolve(loggedInUser);
			});

			if(querySnapshot.size === 0) {
				resolve(null);
			}
		})
	});
}