import { red } from "@mui/material/colors";
import { Department, SOW } from "../Model/SOW";
import { blankSceneObject } from "./BlankObjects";


export default async function RebuildDepartmentStrategyMapSceneObjects(sow: SOW) {
	return new Promise((resolve: (sow: SOW) => void, reject) => {

		//every department should have 2 not in scope pads, and a pad for itself, and if exists the next department

		sow.departments.forEach((department, index) => {
			if (index % 4 != 0) {
				return;
			}


			let fourDepartments: Department[] = [];
			for (let i = index; (i < index + 4 && i < sow.departments.length); i++) {
				fourDepartments.push(sow.departments[i]);
			}


			//remove all red pads and their children
			let redPads = department.strategyMapSceneObjects.filter((sceneObject) => sceneObject.type == "pad" && sceneObject.subtype.includes("red"));
			redPads.forEach((redPad) => {
				let index = department.strategyMapSceneObjects.indexOf(redPad);
				department.strategyMapSceneObjects.splice(index, 1);
				department.strategyMapSceneObjects.forEach((sceneObject) => {
					if (sceneObject.parentID == redPad.id) {
						let index = department.strategyMapSceneObjects.indexOf(sceneObject);
						department.strategyMapSceneObjects.splice(index, 1);
					}
				});
			});

			let streamConnectUpgraded = false;
			let foundStreamConnect = false;

			fourDepartments.forEach((nextDepartment) => {

				let nextDepartmentUpgraded = false;
				if (nextDepartment.licensingDashboards == "Upgrade"  || nextDepartment.licensingDashboards == "Existing" ||
					nextDepartment.licensingEHRLINC == "Upgrade" || nextDepartment.licensingEHRLINC == "Existing" ||
					nextDepartment.licensingLive == "Upgrade" || nextDepartment.licensingLive == "Existing" ||
					nextDepartment.licensingRoomToRoom == "Upgrade" || nextDepartment.licensingRoomToRoom == "Existing" ||
					nextDepartment.licensingTOMS == "Upgrade" || nextDepartment.licensingTOMS == "Existing" ||
					nextDepartment.licensingVideoOnDemand == "Upgrade" || nextDepartment.licensingVideoOnDemand == "Existing") {
					nextDepartmentUpgraded = true;
				}
				streamConnectUpgraded = streamConnectUpgraded || nextDepartmentUpgraded;

				let foundThisPad = false;
				department.strategyMapSceneObjects.forEach((sceneObject, index) => {
					if (sceneObject.id == nextDepartment.id) {
						foundThisPad = true;
						if (nextDepartmentUpgraded) {
							sceneObject.subtype = "green";
						} else {
							sceneObject.subtype = "blue";
						}
					}
					if (sceneObject.parentID == nextDepartment.id) {
						if (sceneObject.type == "label") {
							sceneObject.name = nextDepartment.name;
						}
					}
				});

				if (foundThisPad == false) {
					let departmentPad = blankSceneObject();
					departmentPad.id = nextDepartment.id;
					departmentPad.type = "pad";
					departmentPad.subtype = nextDepartmentUpgraded ? "green" : "blue";
					department.strategyMapSceneObjects.push(departmentPad);

					let departmentPadLabel = blankSceneObject();
					departmentPadLabel.parentID = departmentPad.id;
					departmentPadLabel.type = "label";
					departmentPadLabel.subtype = departmentPad.subtype;
					departmentPadLabel.name = nextDepartment.name;
					departmentPadLabel.letter = "Edit Text";
					department.strategyMapSceneObjects.push(departmentPadLabel);
				}

			});

			//update all label scene objects to match the color of their parent
			department.strategyMapSceneObjects.forEach((sceneObject, index) => {
				if (sceneObject.type == "label") {
					let parent = department.strategyMapSceneObjects.find((parent) => parent.id == sceneObject.parentID);
					if (parent) {
						sceneObject.subtype = parent.subtype;
					}
				}
				if (sceneObject.type == 'streamConnect') {
					foundStreamConnect = true;
					if (sceneObject.subtype == "blue" && streamConnectUpgraded) {
						sceneObject.subtype = "green";
					}
					if (sceneObject.subtype == "green" && !streamConnectUpgraded) {
						sceneObject.subtype = "blue";
					}
				}
			});

			let count = Math.min((4 - fourDepartments.length), 2);
			while (count > 0) {
				let redPad = blankSceneObject();
				redPad.type = "pad";
				redPad.subtype = "red";
				department.strategyMapSceneObjects.push(redPad);

				let redLabel = blankSceneObject();
				redLabel.parentID = redPad.id;
				redLabel.type = "label";
				redLabel.subtype = "red";
				redLabel.name = "Edit Name";
				redLabel.letter = "Edit Text";
				department.strategyMapSceneObjects.push(redLabel);

				count--;
			}

			addPadPins(fourDepartments);

			arrangeDepartmentSceneObjects(department);


			if (foundStreamConnect == false) {
				let streamConnectPad = blankSceneObject();
				streamConnectPad.type = "streamConnect";
				streamConnectPad.subtype = streamConnectUpgraded ? "green" : "blue";
				department.strategyMapSceneObjects.push(streamConnectPad);
			}

		});

		//remove all red pads and their children
		let redPads = sow.leftOverDepartment.strategyMapSceneObjects.filter((sceneObject) => sceneObject.type == "pad" && sceneObject.subtype.includes("red"));
		redPads.forEach((redPad) => {
			let index = sow.leftOverDepartment.strategyMapSceneObjects.indexOf(redPad);
			sow.leftOverDepartment.strategyMapSceneObjects.splice(index, 1);
			sow.leftOverDepartment.strategyMapSceneObjects.forEach((sceneObject) => {
				if (sceneObject.parentID == redPad.id) {
					let index = sow.leftOverDepartment.strategyMapSceneObjects.indexOf(sceneObject);
					sow.leftOverDepartment.strategyMapSceneObjects.splice(index, 1);
				}
			});
		});

		if (sow.departments.length % 4 == 0) {
			let count = 2;
			while (count > 0) {
				let redPad = blankSceneObject();
				redPad.type = "pad";
				redPad.subtype = "red";
				sow.leftOverDepartment.strategyMapSceneObjects.push(redPad);

				let redLabel = blankSceneObject();
				redLabel.parentID = redPad.id;
				redLabel.type = "label";
				redLabel.subtype = "red";
				redLabel.name = "Edit Name";
				redLabel.letter = "Edit Text";
				sow.leftOverDepartment.strategyMapSceneObjects.push(redLabel);

				count--;
			}

			arrangeDepartmentSceneObjects(sow.leftOverDepartment);
		}


		resolve(sow);
	})
}

function addPadPins(departments: Department[]) {
	let department = departments[0];
	for (let i = 0; i < departments.length; i++) {
		let nextDepartment = departments[i];
		addSinglePin(department, "vod", "", nextDepartment.licensingVideoOnDemand, nextDepartment.id);
		if (department.licensingEHRLINC != "" && nextDepartment.ehrLINCArchive && nextDepartment.ehrLINCWorklist) {
			addSinglePin(department, "ehrLinc", "ArchWorklist", nextDepartment.licensingEHRLINC, nextDepartment.id);
		} else if (department.licensingEHRLINC != "" && nextDepartment.ehrLINCArchive) {
			addSinglePin(department, "ehrLinc", "Arch", nextDepartment.licensingEHRLINC, nextDepartment.id);
		} else {
			addSinglePin(department, "ehrLinc", "Worklist", nextDepartment.licensingEHRLINC, nextDepartment.id);
		}
		addSinglePin(department, "liveStream", "", (nextDepartment.licensingLive || nextDepartment.licensingRoomToRoom), nextDepartment.id);
		addSinglePin(department, "roomToRoom", "", nextDepartment.licensingRoomToRoom, nextDepartment.id);
		addSinglePin(department, "toms", "", nextDepartment.licensingTOMS, nextDepartment.id);
		addSinglePin(department, "biomed", "", nextDepartment.dashboardsBiomed ? nextDepartment.licensingDashboards : "", nextDepartment.id);
		addSinglePin(department, "deviceUtilization", "", nextDepartment.dashboardsDevice ? nextDepartment.licensingDashboards : "", nextDepartment.id);
	}
}

function addSinglePin(department: Department, subtype: string, subsubtype: string, licensing: string, parentID: string) {
	let pin = department.strategyMapSceneObjects.find((sceneObject) => sceneObject.type == "pin" && sceneObject.subtype.includes(subtype) && sceneObject.parentID == parentID);


	if (licensing != "") {
		if (!pin) {
			pin = blankSceneObject();
			pin.type = "pin";
			pin.subtype = "noStatus " + subtype;
			pin.parentID = parentID;
			department.strategyMapSceneObjects.push(pin);
		}
		pin.subsubtype = subsubtype;
		if (!pin.subtype.includes("outOfScope")) {
			if (licensing == "Upgrade" || licensing == "Existing") {
				pin.subtype = "live " + subtype;
			} else {
				pin.subtype = "inScope " + subtype;
			}
		}
	} else {
		///remove the pin
		if (pin) {
			pin.subsubtype = subsubtype;
			let index = department.strategyMapSceneObjects.indexOf(pin);
			department.strategyMapSceneObjects.splice(index, 1);
		}
	}
}



function arrangeDepartmentSceneObjects(department: Department) {
	let streamConnectPin = department.strategyMapSceneObjects.find((sceneObject) => sceneObject.type == "streamConnect");
	if (streamConnectPin) {
		streamConnectPin.left = 50;
		streamConnectPin.top = 55;
	}

	let posList = [
		{ left: 26, top: 30 },
		{ left: 20, top: 77 },
		{ left: 74, top: 30 },
		{ left: 80, top: 77 },
	]

	let posIndex = 0;
	department.strategyMapSceneObjects.forEach((sceneObject) => {
		if (sceneObject.type == "pad") {
			//check to see if there is already a pad near this position
			department.strategyMapSceneObjects.forEach((sceneObject2) => {
				if (sceneObject2.type == "pad") {
					if (posIndex < posList.length) {
						if (Math.abs(sceneObject2.left - posList[posIndex].left) < 10 && Math.abs(sceneObject2.top - posList[posIndex].top) < 10) {
							//move this pad to the next position
							posIndex++;
						}
					}
				}
			})
			if (posIndex < posList.length) {
				if (sceneObject.left == 50 && sceneObject.top == 50) {
					sceneObject.left = posList[posIndex].left;
					sceneObject.top = posList[posIndex].top;
					posIndex++;
				}
			}
		}
	});


	// let pinPosList = [
	// 	{left:8,top:-20},
	// 	{left:13,top:-20},
	// 	{left:-4,top:-15},
	// 	{left:18,top:-15},
	// 	{left:-10,top:-10},
	// 	{left:23,top:-10},
	// 	{left:5,top:-5},
	// ]

	let pinPosList = {
		"vod": { left: 0, top: -21 },
		"ehrLinc": { left: 12, top: -19 },
		"liveStream": { left: -10, top: -16 },
		"roomToRoom": { left: 2, top: -12 },
		"toms": { left: -11, top: -8 },
		"biomed": { left: 20, top: -10 },
		"deviceUtilization": { left: 11, top: 1 },
	}

	department.strategyMapSceneObjects.forEach((sceneObject) => {
		if (sceneObject.type == "pad") {
			department.strategyMapSceneObjects.forEach((sceneObject2) => {
				let key = sceneObject2.subtype.replace("noStatus", "").replace("inScope", "").replace("outOfScope", "").replace("live", "").replace(/ /g, "");
				key = key.replace("Stream","liveStream");
				if (sceneObject2.type == "pin" && sceneObject2.parentID == sceneObject.id) {
					if ((pinPosList as any)[key]) {
						sceneObject2.left = sceneObject.left + (pinPosList as any)[key].left;
						sceneObject2.top = sceneObject.top + (pinPosList as any)[key].top;
					}
				}
				if (sceneObject2.type == "label" && sceneObject2.parentID == sceneObject.id) {
					if (sceneObject2.left == 50 && sceneObject2.top == 50) {
						if (sceneObject.left < 50) {
							if (sceneObject.top < 50) {
								sceneObject2.left = sceneObject.left - 21
								sceneObject2.top = sceneObject.top + 11;
							} else {
								sceneObject2.left = sceneObject.left + 20;
								sceneObject2.top = sceneObject.top + 11;
							}
						} else {
							if (sceneObject.top < 50) {
								sceneObject2.left = sceneObject.left + 21;
								sceneObject2.top = sceneObject.top + 11;
							} else {
								sceneObject2.left = sceneObject.left - 20;
								sceneObject2.top = sceneObject.top + 11;
							}
						}
					}
				}
			});
		}
	});



}