import React, { useEffect, useRef, ReactNode } from 'react';

interface BackgroundImageLoaderProps {
	onImagesLoaded: () => void;
	children: ReactNode;
}

const BackgroundImageLoader: React.FC<BackgroundImageLoaderProps> = ({ onImagesLoaded, children }) => {
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const containerElement = containerRef.current;
		let imageURLs: string[] = [];
		let images: HTMLImageElement[] = [];
		let loadedCount = 0;

		const checkIfAllLoaded = () => {
			if (loadedCount === imageURLs.length) {
				//console.log("all images loaded", loadedCount, imageURLs.length);
				onImagesLoaded();
			}
		};

		if (containerElement) {
			const elements = containerElement.querySelectorAll('*');
			imageURLs = [];
			elements.forEach(el => {
				const style = window.getComputedStyle(el);
				const imageUrl = style.backgroundImage.slice(4, -1).replace(/['"]/g, '');
				if (imageUrl && imageUrl !== 'none' && imageUrl.indexOf("ar-gradient") == -1 && imageURLs.indexOf(imageUrl) == -1) {
					//console.log("loading image: " + imageUrl);
					imageURLs.push(imageUrl);
				}
			});
			elements.forEach(el => {
				const style = window.getComputedStyle(el);
				const imageUrl = style.backgroundImage.slice(4, -1).replace(/['"]/g, '');
				if (imageUrl && imageUrl !== 'none' && imageUrl.indexOf("ar-gradient") == -1) {
					const img = new Image();
					img.onload = img.onerror = () => {
						loadedCount++;
						checkIfAllLoaded();
					};
					img.src = imageUrl;
					images.push(img);
				}
			});

			// Immediate check in case images are cached and load event has already been missed
			//setTimeout(() => {
				checkIfAllLoaded();
			//}, 2000);
		}
	}, [children, onImagesLoaded]); // Depend on children and onImagesLoaded so effect runs when they change

	return <div ref={containerRef}>{children}</div>;
};

export default BackgroundImageLoader;
