
import React, { useRef, useState, useEffect } from 'react';
import './PopupConfirm.css';
import { Config } from '../../Model/SOW';
interface PopupConfirmProps {
	text: string|JSX.Element;
	cancelText?: string;
	confirmText?: string;
	className?: string;
	onCancel?: () => void;
	onConfirm?: () => void;
}
export default function PopupConfirm({ text, cancelText, confirmText, className, onCancel, onConfirm }: PopupConfirmProps) {
	return (
		<div className={`PopupConfirm ${className}`}>
			<div className='background'></div>
			<div className='popup'>
				<div className='text'>{text}</div>
				<div className='buttons'>
					{cancelText && (
						<div className='buttonCancel'
							onClick={() => {
								onCancel && onCancel();

							}}>{cancelText}</div>
					)}
					{confirmText && (
						<div className='buttonConfirm'
							onClick={() => {
								onConfirm && onConfirm();
							}}>{confirmText}</div>
					)}
				</div>
			</div>
		</div>
	);
}