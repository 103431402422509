
import React, { useRef, useState, useEffect } from 'react';
import './StreamConnectExecutiveSummary.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import { SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
interface StreamConnectExecutiveSummaryProps{
	sow: SOW;
  currentDepartmentIndex: number;
	forPDF?: boolean;
}
export default function StreamConnectExecutiveSummary({ sow, currentDepartmentIndex, forPDF }: StreamConnectExecutiveSummaryProps) {
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen:ActionCreators.setScreen,},dispatch); 

  const [bannerClassVoD, setBannerClassVoD] = useState('');
  const [bannerClassEHRLINC, setBannerClassEHRLINC] = useState('');
  const [bannerClassLive, setBannerClassLive] = useState('');
  const [bannerClassRoomToRoom, setBannerClassRoomToRoom] = useState('');
  const [bannerClassTOMS, setBannerClassTOMS] = useState('');
  const [bannerClassDashboards, setBannerClassDashboards] = useState('');

  useEffect(() => {
    let bannerClassVoD = '';
    let bannerClassEHRLINC = '';
    let bannerClassLive = '';
    let bannerClassRoomToRoom = '';
    let bannerClassTOMS = '';
    let bannerClassDashboards = '';

    sow.departments.forEach((department, index) => {
      if(department.licensingVideoOnDemand == "Purchase" && bannerClassVoD == '') {
        bannerClassVoD = 'purchase';
      }else if(department.licensingVideoOnDemand == "Upgrade"){
        bannerClassVoD = 'upgrade';
      }

      if(department.licensingEHRLINC == "Purchase" && bannerClassEHRLINC == '') {
        bannerClassEHRLINC = 'purchase';
      }else if(department.licensingEHRLINC == "Upgrade"){
        bannerClassEHRLINC = 'upgrade';
      }

      if(department.licensingLive == "Purchase" && bannerClassLive == '') {
        bannerClassLive = 'purchase';
      }else if(department.licensingLive == "Upgrade"){
        bannerClassLive = 'upgrade';
      }

      if(department.licensingRoomToRoom == "Purchase" && bannerClassRoomToRoom == '') {
        bannerClassRoomToRoom = 'purchase';
      }else if(department.licensingRoomToRoom == "Upgrade"){
        bannerClassRoomToRoom = 'upgrade';
      }

      if(department.licensingTOMS == "Purchase" && bannerClassTOMS == '') {
        bannerClassTOMS = 'purchase';
      }else if(department.licensingTOMS == "Upgrade"){
        bannerClassTOMS = 'upgrade';
      }

      if(department.licensingDashboards == "Purchase" && bannerClassDashboards == '') {
        bannerClassDashboards = 'purchase';
      }else if(department.licensingDashboards == "Upgrade"){
        bannerClassDashboards = 'upgrade';
      }

    });

    setBannerClassVoD(bannerClassVoD);
    setBannerClassEHRLINC(bannerClassEHRLINC);
    setBannerClassLive(bannerClassLive);
    setBannerClassRoomToRoom(bannerClassRoomToRoom);
    setBannerClassTOMS(bannerClassTOMS);
    setBannerClassDashboards(bannerClassDashboards);

  }, [sow]);


  return (
    <div className='Screen StreamConnectExecutiveSummary'>
      <div className='leftBarTop' />
      <div className='verticalLabel pos2'>Executive Summary<br/><div className='smaller'>The StreamConnect<sup>®</sup> modules you have purchased are indicated</div></div>

      <div className='text1'>
        This guide is designed to facilitate a successful implementation of your KARL STORZ StreamConnect<sup>®</sup> system. It also outlines the tools your clinicians will gain after a successful implementation.
      </div>
      <div className='text2'>
        The KARL STORZ StreamConnect<sup>®</sup> system is engineered with collaboration at its core. The system offers many tools for your clinicians, enabling this collaboration to come alive on your network, all within one system. The design is scalable and modular which allows you to add solutions as you go.
      </div>

      <div className='backgroundImage' />

      <div className={`banner vod left ${bannerClassVoD}`} />
      <div className={`banner ehr left ${bannerClassEHRLINC}`} />
      <div className={`banner live left ${bannerClassLive}`} />
      <div className={`banner rtr right ${bannerClassRoomToRoom}`} />
      <div className={`banner toms right ${bannerClassTOMS}`} />
      <div className={`banner dash right ${bannerClassDashboards}`} />
       

      {!forPDF && (
        <>
          <NavigationHeader
            sow={sow} />
        </>
      )}
    </div>
  );
}