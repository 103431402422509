
import React, { useRef, useState, useEffect } from 'react';
import './DepartmentScopeOfWorkRow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Department } from '../../../Model/SOW';
export interface DepartmentScopeOfWork {
	name: string;
	vodCheck: boolean;
	vodDevice: string;
	vodQty: string;
	ehrLincWorklistCheck: boolean;
	ehrLincArchiveCheck: boolean;
	liveCheck: boolean;
	liveEncoderQty: string;
	roomToRoomCheck: boolean;
	roomToRoomDecoderQty: string;
	tomsCheck: boolean;
	tomsCameraQty: string;
	tomsPlateQty: string;
	tomsPofEQty: string;
	dashboardsBiomedCheck: boolean;
	dashboardsDeviceCheck: boolean;
}
interface DepartmentScopeOfWorkRowProps {
	department: DepartmentScopeOfWork;
}
export default function DepartmentScopeOfWorkRow({ department }: DepartmentScopeOfWorkRowProps) {
	//let checkmark = <div className='checkmark' />;
	let checkmark = <FontAwesomeIcon icon={faCheck} />;
	return (
		<tr className={`DepartmentScopeOfWorkRow`}>
			<td>
				{department.name}
			</td>
			<td>
				{department.vodCheck && checkmark}
			</td>
			<td>
				{department.vodDevice}
			</td>
			<td>
				{department.vodQty}
			</td>
			<td>
				{department.ehrLincWorklistCheck && checkmark}
			</td>
			<td>
				{department.ehrLincArchiveCheck && checkmark}
			</td>
			<td>
				{department.liveCheck && checkmark}
			</td>
			<td>
				{department.liveEncoderQty}
			</td>
			<td>
				{department.roomToRoomCheck && checkmark}
			</td>
			<td>
				{department.roomToRoomDecoderQty}
			</td>
			<td>
				{department.tomsCheck && checkmark}
			</td>
			<td>
				{department.tomsCameraQty}
			</td>
			<td>
				{department.tomsPlateQty}
			</td>
			<td>
				{department.tomsPofEQty}
			</td>
			<td>
				{department.dashboardsBiomedCheck && checkmark}
			</td>
			<td>
				{department.dashboardsDeviceCheck && checkmark}
			</td>
		</tr>
	);
}