


import React, { useRef, useState, useEffect } from 'react';
import './PopupAddBoomEquipment.css';
import { SOW } from '../../../Model/SOW';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { on } from 'events';
interface PopupAddBoomEquipmentProps {
	onAddEquipment: (equipment: { type: string, name: string }[]) => void;
	onDismiss: () => void;
}
export default function PopupAddBoomEquipment({ onAddEquipment, onDismiss }: PopupAddBoomEquipmentProps) {
	const [selectedEquipment, setSelectedEquipment] = useState<{ type: string, name: string }[]>([]);

	const [searchString, setSearchString] = useState("");

	const [showArthrex, setShowArthrex] = useState(true);
	const [showStryker, setShowStryker] = useState(true);
	const [showKarlStorz, setShowKarlStorz] = useState(true);
	const [showOlympus, setShowOlympus] = useState(true);
	const [showOther, setShowOther] = useState(true);
	const [showFujiFilm, setShowFujiFilm] = useState(true);
	const [showSmithAndNephew, setShowSmithAndNephew] = useState(true);

	const options = [
		// Karl Storz options
		{ vendor: "Karl Storz", type: "Camera", category: "Camera", className: "tc201_4u", label: "TC201 + 4U" },
		{ vendor: "Karl Storz", type: "Camera", category: "Camera", className: "tc201", label: "TC201" },
		{ vendor: "Karl Storz", type: "Camera", category: "Camera", className: "_4u", label: "4u (4K Cameras)" },
		{ vendor: "Karl Storz", type: "Camera", category: "Camera", className: "d3", label: "D3 (3D Cameras)" },
		{ vendor: "Karl Storz", type: "Camera", category: "Camera", className: "x", label: "X (Flexible Video Scopes)" },
		{ vendor: "Karl Storz", type: "Camera", category: "Camera", className: "image1Hub", label: "Image1 Hub" },
		{ vendor: "Karl Storz", type: "Light Sources", category: "Light Source", className: "rubinaTL400", label: "Rubina TL400" },
		{ vendor: "Karl Storz", type: "Light Sources", category: "Light Source", className: "powerLEDTL300", label: "power LED TL300" },
		{ vendor: "Karl Storz", type: "Light Sources", category: "Light Source", className: "xenon300", label: "Xenon 300" },
		{ vendor: "Karl Storz", type: "Insufflators", category: "Insufflator", className: "ui500", label: "UI500" },
		{ vendor: "Karl Storz", type: "Insufflators", category: "Insufflator", className: "thermoflator", label: "Thermoflator" },
		{ vendor: "Karl Storz", type: "Pumps", category: "Pump", className: "up210", label: "UP210" },
		{ vendor: "Karl Storz", type: "Pumps", category: "Pump", className: "arthropump", label: "Arthropump" },
		{ vendor: "Karl Storz", type: "Generators", category: "Generator", className: "uh400", label: "UH400" },
		{ vendor: "Karl Storz", type: "Power / Shavers", category: "Power / Shaver", className: "unidriveSIII", label: "Unidrive S III" },
		{ vendor: "Karl Storz", type: "Image Capture / Routing", category: "Image Capture / Routing", className: "airWSmartsreen", label: "AIR w/Smartscreen" },
		{ vendor: "Karl Storz", type: "Image Capture / Routing", category: "Image Capture / Routing", className: "airWOSmartsreen", label: "AIR w/o Smartscreen" },
		{ vendor: "Karl Storz", type: "Image Capture / Routing", category: "Image Capture / Routing", className: "avm", label: "AVM" },
		{ vendor: "Karl Storz", type: "Image Capture / Routing", category: "Image Capture / Routing", className: "hdConnect", label: "HD Connect" },
		{ vendor: "Karl Storz", type: "Image Capture / Routing", category: "Image Capture / Routing", className: "aidaBellaWSmartscreen", label: "AIDA Bella w/Smartscreen" },

		// Stryker options
		{ vendor: "Stryker", type: "Camera", category: "Camera", className: "_1688", label: "1688" },
		{ vendor: "Stryker", type: "Camera", category: "Camera", className: "_1588", label: "1588" },
		{ vendor: "Stryker", type: "Camera", category: "Camera", className: "_1488", label: "1488" },
		{ vendor: "Stryker", type: "Camera", category: "Camera", className: "_1288", label: "1288" },
		{ vendor: "Stryker", type: "Camera", category: "Camera", className: "pinpoint", label: "Pinpoint" },
		{ vendor: "Stryker", type: "Light Sources", category: "Light Source", className: "l11", label: "L11" },
		{ vendor: "Stryker", type: "Light Sources", category: "Light Source", className: "l10", label: "L10" },
		{ vendor: "Stryker", type: "Light Sources", category: "Light Source", className: "l9000", label: "L9000" },
		{ vendor: "Stryker", type: "Insufflators", category: "Insufflator", className: "pneumoclear", label: "Pneumoclear" },
		{ vendor: "Stryker", type: "Insufflators", category: "Insufflator", className: "pneumoSure", label: "Pneumo Sure" },
		{ vendor: "Stryker", type: "Pumps", category: "Pump", className: "crossflow", label: "CrossFlow" },
		{ vendor: "Stryker", type: "Generators", category: "Generator", className: "crossfire", label: "Crossfire" },
		{ vendor: "Stryker", type: "Generators", category: "Generator", className: "malis", label: "Malis" },
		{ vendor: "Stryker", type: "Power / Shavers", category: "Power / Shaver", className: "core2Console", label: "Core 2 Console" },
		{ vendor: "Stryker", type: "Power / Shavers", category: "Power / Shaver", className: "coreConsole", label: "Core Console" },
		{ vendor: "Stryker", type: "Image Capture Routing", category: "Image Capture Routing", className: "orHub", label: "OR Hub" },
		{ vendor: "Stryker", type: "Image Capture Routing", category: "Image Capture Routing", className: "sdc3", label: "SDC3" },

		// Olympus options
		{ vendor: "Olympus", type: "Camera", category: "Camera", className: "viscera4k", label: "Viscera 4K UHD OTV-S400" },
		{ vendor: "Olympus", type: "Camera", category: "Camera", className: "eliteII", label: "Elite II (3D)- OTV-S300" },
		{ vendor: "Olympus", type: "Camera", category: "Camera", className: "exeraIII", label: "Exera III - CV-190" },
		{ vendor: "Olympus", type: "Light Sources", category: "Light Source", className: "clvS400", label: "CLV-S400" },
		{ vendor: "Olympus", type: "Light Sources", category: "Light Source", className: "clvS190", label: "CLV-S190" },
		{ vendor: "Olympus", type: "Insufflators", category: "Insufflator", className: "uhi4", label: "UHI-4" },
		{ vendor: "Olympus", type: "Pumps", category: "Pump", className: "ofp2", label: "OFP-2" },
		{ vendor: "Olympus", type: "Pumps", category: "Pump", className: "ofp", label: "OFP" },
		{ vendor: "Olympus", type: "Pumps", category: "Pump", className: "kv6", label: "KV-6" },
		{ vendor: "Olympus", type: "Pumps", category: "Pump", className: "lp100", label: "LP100" },
		{ vendor: "Olympus", type: "Generators", category: "Generator", className: "esg150", label: "ESG-150" },
		{ vendor: "Olympus", type: "Image Capture", category: "Image Capture", className: "nCare", label: "nCare" },
		{ vendor: "Olympus", type: "Image Capture", category: "Image Capture", className: "imh20", label: "IMH-20" },

		// Arthrex options
		{ vendor: "Arthrex", type: "Camera / Light Source/ Capture", category: "Camera / Light Source/ Capture", className: "synergyUHD", label: "Synergy UHD" },
		{ vendor: "Arthrex", type: "Insufflators", category: "Insufflator", className: "synergyInsufflator", label: "Synergy Insufflator" },
		{ vendor: "Arthrex", type: "Pumps", category: "Pump", className: "dualWaveFluidPump", label: "Dual Wave Fluid Pump" },
		{ vendor: "Arthrex", type: "Power / Shavers", category: "Power / Shaver", className: "synergyResection", label: "Synergy Resection" },
		{ vendor: "Arthrex", type: "Generators", category: "Generator", className: "synergyRF", label: "Synergy RF" },

		// FujiFilm options
		{ vendor: "FujiFilm", type: "Camera", category: "Camera", className: "eluxeoVP7000", label: "Eluxeo VP-7000" },
		{ vendor: "FujiFilm", type: "Camera", category: "Camera", className: "eluxeoVP6000", label: "Eluxeo VP-6000" },
		{ vendor: "FujiFilm", type: "Camera", category: "Camera", className: "fv10", label: "FV-10(HD)" },
		{ vendor: "FujiFilm", type: "Insufflators", category: "Insufflator", className: "gw100", label: "GW-100" },
		{ vendor: "FujiFilm", type: "Insufflators", category: "Insufflator", className: "fm13450L", label: "FM134 50L" },
		{ vendor: "FujiFilm", type: "Insufflators", category: "Insufflator", className: "f104", label: "F104" },
		{ vendor: "FujiFilm", type: "Light Sources", category: "Light Source", className: "flexiLux200300", label: "FlexiLux 200/300" },

		// Smith and Nephew options
		{ vendor: "Smith and Nephew", type: "Camera", category: "Camera", className: "lens4k", label: "lensIntegrated" },
		{ vendor: "Smith and Nephew", type: "Camera", category: "Camera", className: "lensIntegrated", label: "Lens Integrated" },
		{ vendor: "Smith and Nephew", type: "Camera", category: "Camera", className: "_560pHD", label: "560p HD" },
		{ vendor: "Smith and Nephew", type: "Insufflators", category: "Insufflator", className: "_500Insufflator", label: "500 Insufflator" },
		{ vendor: "Smith and Nephew", type: "Light Sources", category: "Light Source", className: "_500xl", label: "500XL" },
		{ vendor: "Smith and Nephew", type: "Light Sources", category: "Light Source", className: "led300", label: "LED 300" },
		{ vendor: "Smith and Nephew", type: "Capture", category: "Capture", className: "_660HDImageMgmt", label: "660 HD Image Mgmt" },
		{ vendor: "Smith and Nephew", type: "Pumps", category: "Pump", className: "goFloPump", label: "GoFlo Pump" },
		{ vendor: "Smith and Nephew", type: "Pumps", category: "Pump", className: "dyonicsFluidMgmt", label: "Dyonics Fluid Mgmt" },
		{ vendor: "Smith and Nephew", type: "Power / Shavers", category: "Power / Shaver", className: "dyonicsPowerII", label: "Dyonics Power II" },
		{ vendor: "Smith and Nephew", type: "Power / Shavers", category: "Power / Shaver", className: "dyonicsPower", label: "Dyonics Power" },
		{ vendor: "Smith and Nephew", type: "Generators", category: "Generator", className: "werewolfCoblation", label: "Werewolf Coblation" },

		// Other options
		{ vendor: "Other", type: "Electrosurgical Generators", category: "Electrosurgical Generator", className: "medtronicFT10", label: "Medtronic FT10" },
		{ vendor: "Other", type: "Electrosurgical Generators", category: "Electrosurgical Generator", className: "valleyLabsForceTriad", label: "ValleyLabs Force Triad" },
		{ vendor: "Other", type: "Electrosurgical Generators", category: "Electrosurgical Generator", className: "valleyLabsForceFX", label: "ValleyLabs Force FX" },
		{ vendor: "Other", type: "Electrosurgical Generators", category: "Electrosurgical Generator", className: "conmedSystem5000", label: "Conmed System 5000" },
		{ vendor: "Other", type: "Electrosurgical Generators", category: "Electrosurgical Generator", className: "conmedSystem2450", label: "Conmed System 2450" },
	];

	const writeCSS = () => {
		let css = "";
		let countToLoad = document.querySelectorAll(".tile").length;
		let countLoaded = 0;
		document.querySelectorAll(".tile").forEach((tile) => {
			let className = tile.className.replace("tile ", "").replaceAll(" ", "");
			//get the tiles calculated background image
			let backgroundImage = window.getComputedStyle(tile).backgroundImage;
			if (backgroundImage === undefined || backgroundImage == "none" || backgroundImage == "") {
				countLoaded++;
				return;
			}
			let image = new Image();
			image.onerror = (err) => {
				console.log("error loading image: " + backgroundImage + " - " + err);
			}
			image.onload = () => {
				css += `
				.Screen.ScreenOREquipmentBoomLayout .DraggableSceneObject.${className} {
					width: ${image.width}px;
					height: ${image.height}px;
				}
				`;
				countLoaded++;
				if (countLoaded == countToLoad) {
					console.log(css);
				} else {
					console.log(countLoaded + " / " + countToLoad);
				}
			}
			image.src = backgroundImage.replace(/url\((['"])?(.*?)\1\)/gi, '$2').split(',')[0];
		});
	}

	const [filteredOptions, setFilteredOptions] = useState(options);
	const [distinctVendors, setDistinctVendors] = useState<string[]>([]);
	const [distinctTypes, setDistinctTypes] = useState<string[]>([]);
	useEffect(() => {
		let filteredOptions: { vendor: string; type: string; category: string; className: string; label: string; }[] = [];
		options.forEach((option) => {
			if (searchString != "") {
				if (option.vendor.toLowerCase().includes(searchString.toLowerCase()) == false &&
					option.type.toLowerCase().includes(searchString.toLowerCase()) == false &&
					option.label.toLowerCase().includes(searchString.toLowerCase()) == false) {
					return;
				}
			}
			if (option.vendor == "Arthrex" && showArthrex == false) {
				return;
			}
			if (option.vendor == "Stryker" && showStryker == false) {
				return;
			}
			if (option.vendor == "Karl Storz" && showKarlStorz == false) {
				return;
			}
			if (option.vendor == "Olympus" && showOlympus == false) {
				return;
			}
			if (option.vendor == "Other" && showOther == false) {
				return;
			}
			if (option.vendor == "FujiFilm" && showFujiFilm == false) {
				return;
			}
			if (option.vendor == "Smith and Nephew" && showSmithAndNephew == false) {
				return;
			}
			filteredOptions.push(option);
		});
		let distinctVendors: string[] = [];
		let distinctTypes: string[] = [];
		filteredOptions.forEach((option) => {
			if (distinctVendors.includes(option.vendor) == false) {
				distinctVendors.push(option.vendor);
			}
			if (distinctTypes.includes(option.type) == false) {
				distinctTypes.push(option.type);
			}
		});
		setDistinctVendors(distinctVendors);
		setDistinctTypes(distinctTypes);
		setFilteredOptions(filteredOptions);
	}, [searchString, showArthrex, showFujiFilm, showKarlStorz, showOlympus, showOther, showSmithAndNephew, showStryker]);

	return (
		<div className={`PopupAddBoomEquipment`}>
			<div className='backgroundDismiss' onClick={() => {
				onDismiss();
			}} />
			<div className='popup'>
				<div className='header'>
					<div className='label'>Boom Equipment</div>
					{selectedEquipment.length > 0 && (
						<div className='itemsSelected'>{selectedEquipment.length} ITEM(S) SELECTED</div>
					)}
					<div className='buttonCancel' onClick={() => {
						//writeCSS();
						onDismiss();
					}}>Cancel</div>
					<div className={`buttonSave ${selectedEquipment.length == 0 ? "disabled" : ""}`} onClick={(e) => {
						e.stopPropagation();
						onAddEquipment(selectedEquipment);
					}}>Done</div>
				</div>
				<div className='search'>
					<input
						type='text'
						placeholder='Search'
						defaultValue={searchString}
						onChange={(e) => {
							setSearchString(e.target.value);
						}} />
				</div>
				<div className='filter'>
					<div className='buttonSelectAll'
						onClick={() => {
							if (showArthrex && showFujiFilm && showKarlStorz && showOlympus && showOther && showSmithAndNephew && showStryker) {
								setShowArthrex(false);
								setShowFujiFilm(false);
								setShowKarlStorz(false);
								setShowOlympus(false);
								setShowOther(false);
								setShowSmithAndNephew(false);
								setShowStryker(false);
							} else {
								setShowArthrex(true);
								setShowFujiFilm(true);
								setShowKarlStorz(true);
								setShowOlympus(true);
								setShowOther(true);
								setShowSmithAndNephew(true);
								setShowStryker(true);
							}
						}}>
						{(showArthrex && showFujiFilm && showKarlStorz && showOlympus && showOther && showSmithAndNephew && showStryker) ? "Deselect ALL" : "Select ALL"}
					</div>
					<div className='filterOptions'>
						<div className={`button storz ${showKarlStorz ? "selected" : ""}`} onClick={() => {
							setShowKarlStorz(!showKarlStorz);
						}}>Karl Storz</div>
						<div className={`button ${showArthrex ? "selected" : ""}`} onClick={() => {
							setShowArthrex(!showArthrex);
						}}>Arthrex</div>
						<div className={`button ${showStryker ? "selected" : ""}`} onClick={() => {
							setShowStryker(!showStryker);
						}}>Stryker</div>
						<div className={`button ${showOlympus ? "selected" : ""}`} onClick={() => {
							setShowOlympus(!showOlympus);
						}}>Olympus</div>
						<div className={`button ${showFujiFilm ? "selected" : ""}`} onClick={() => {
							setShowFujiFilm(!showFujiFilm);
						}}>FujiFilm</div>
						<div className={`button ${showSmithAndNephew ? "selected" : ""}`} onClick={() => {
							setShowSmithAndNephew(!showSmithAndNephew);
						}}>Smith &amp; Nephew</div>
						<div className={`button ${showOther ? "selected" : ""}`} onClick={() => {
							setShowOther(!showOther);
						}}>Other</div>

					</div>
				</div>
				<div className='options'>
					{distinctVendors.map((vendor, index) => {
						let vendorOptions = filteredOptions.filter((filteredOption) => {
							return filteredOption.vendor == vendor;
						});
						if (vendorOptions.length == 0) {
							return <></>;
						}
						return (
							<div key={"vendor" + index}>
								<div className='vendorSection'>
									<div className='vendorTitle'>{vendor}</div>
									{distinctTypes.map((type, index) => {
										let typeOptions = vendorOptions.filter((vendorOption) => {
											return vendorOption.type == type;
										});
										if (typeOptions.length == 0) {
											return <></>;
										}
										return (
											<div key={vendor + "type" + index} className='typeSection'>
												<div className='typeTitle'>{type}</div>
												{typeOptions.map((option, index) => {
													return <div
														key={vendor + type + index}
														className={`option ${selectedEquipment.find(equipment => equipment.type == option.className) ? "selected" : ""}`}
														onDoubleClick={() => {
															let returnSelectedEquipment = [...selectedEquipment];
															let equipment = returnSelectedEquipment.find(equipment => equipment.type == option.className);
															if (equipment) {
																returnSelectedEquipment = returnSelectedEquipment.filter(equipment => equipment.type != option.className);
															} else {
																returnSelectedEquipment = [...returnSelectedEquipment, { type: option.className, name: option.category }];
															}
															onAddEquipment(returnSelectedEquipment);
														}}
														onClick={() => {
															let equipment = selectedEquipment.find(equipment => equipment.type == option.className);
															if (equipment) {
																setSelectedEquipment(selectedEquipment.filter(equipment => equipment.type != option.className));
															} else {
																setSelectedEquipment([...selectedEquipment, { type: option.className, name: option.category }]);
															}
														}}>
														<div className={`tile  ${option.className}`}></div>
														<div className='label'>{option.label}</div>
													</div>
												})}
											</div>
										)
									})}
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	);
}