

import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationAudioArea.css';
import { Config } from '../../../Model/SOW';
import ConfigurationAudioInputs from '../ConfigurationAudioInputs/ConfigurationAudioInputs';
import ConfigurationAudioOutputs from '../ConfigurationAudioOutputs/ConfigurationAudioOutputs';
import Dropdown from '../../Dropdown/Dropdown';
import ComboBox from '../../ComboBox/ComboBox';
interface ConfigurationAudioAreaProps {
	config: Config;
	onChange: (config: Config) => void;
}
export default function ConfigurationAudioArea({ config, onChange }: ConfigurationAudioAreaProps) {
	const [userChanged, setUserChanged] = useState<boolean>(false);
	const [standardIntelligent, setStandardIntelligent] = useState(config.audio.standardIntelligent);
	const [videoSystem, setVideoSystem] = useState(config.video.system);
	const [refreshing, setRefreshing] = useState<boolean>(false);
	useEffect(() => {
		if (userChanged) {
			config.audio = {
				...config.audio,
				standardIntelligent
			}
			onChange(config);

		}
	}, [userChanged, standardIntelligent]);

	useEffect(() => {
		setVideoSystem(config.video.system);
		if (videoSystem == "AIR" || videoSystem == "4KBR") {
			setStandardIntelligent("Standard");
			setUserChanged(true);
			setRefreshing(true);
		}
	}, [config, config.video, config.video.system]);

	useEffect(() => {
		if(refreshing){
			setRefreshing(false);
		}
	},	[refreshing]);
	return (
		<div className={`ConfigurationAudioArea`}>
			<div className='header'>Audio</div>
			<div className='videoInputs'>


				<div className='input'>
					<div className='label'>Standard/Intelligence</div>
					{!refreshing && <>
						<ComboBox
							className='standardIntelligent'
							options={["Standard", "Intelligent"]}
							value={standardIntelligent}
							onChange={(option) => {
								setStandardIntelligent(option);
								setUserChanged(true);
							}}></ComboBox>
					</>}
				</div>

			</div>

			<div className='separator'></div>
			<ConfigurationAudioInputs
				config={config}
				onChange={(updatedConfig => {

					config.audio = {
						...config.audio,
						inputs: updatedConfig.audio.inputs,
						standardIntelligent,
					}
					onChange(config);
				})}></ConfigurationAudioInputs>

			<div className='separator'></div>
			<ConfigurationAudioOutputs
				config={config}
				onChange={(updatedConfig => {
					config.audio = {
						...config.audio,
						outputs: updatedConfig.audio.outputs,
						standardIntelligent,
					}
					onChange(config);
				})}></ConfigurationAudioOutputs>

		</div>
	);
}