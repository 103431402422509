



import React, { useRef, useState, useEffect } from 'react';
import './PopupOtherSubtype.css';
import { SOW } from '../../../Model/SOW';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
interface PopupOtherSubtypeProps {
	type?: string;
	subtype?: string;
	onSave: (type: string, subtype: string, label: string) => void;
	onDismiss: () => void;
}
export default function PopupOtherSubtype({ type, subtype, onSave, onDismiss }: PopupOtherSubtypeProps) {
	const [selectedType, setSelectedType] = useState(type??"");
	const [selectedSubtype, setSelectedSubtype] = useState(subtype??"");
	const [selectedLabel, setSelectedLabel] = useState("");


	const anesMachineSubtypes = [
		{ className: "anesthesiaMachine", label: "Anesthesia Machine" },
	]

	const bedSubtypes = [
		{ className: "patientOnBed1", label: "Patient on Bed" },
		{ className: "patientOnBed2", label: "Patient on Bed" },
		{ className: "emptyBed", label: "Empty Bed" },
		{ className: "angioBed", label: "Angio Bed" },
		{ className: "fixedFlouro", label: "Fixed Flouro" },
		
	];

	const rackSubTypes = [
		{ className: "rack1", label: "Rack 1" },
		{ className: "rack2", label: "Rack 2" },
	]

	const doorSubTypes = [
		{ className: "single", label: "Single Door" },
		{ className: "double", label: "Double Door" },
	]

	const robotSubTypes = [
		{ className: "surgeonConsole", label: "Surgeon Console" },
		{ className: "equipmentTower", label: "Equipment Tower" },
		{ className: "davinciLeft", label: "DaVinci Left" },
		{ className: "davinciRight", label: "DaVinci Right" },
		{ className: "davinciStraight", label: "DaVinci Straight" },
	]

	const anesBoomSubtypes = [
		{ className: "anesthesiaBoom", label: "Anesthesia Boom" },
		{ className: "straightDropAnesBoom", label: "Straight Drop Anes Boom" },
		{ className: "vpaBoom", label: "VPA Boom" },
	]

	const boomSubtypes = [
		{ className: "boomMonLight", label: "EQ Boom, w/Mon & Light" },
		{ className: "boomLight", label: "EQ Boom, w/Light" },
		{ className: "boomMon", label: "EQ Boom, w/Monitor" },
		{ className: "eqBoom", label: "Equipment Boom" },
		{ className: "vpaBoom", label: "VPA Boom" },
		{ className: "noShelves", label: "Equipment Boom No Shelves" },
	]

	const spindleSubtypes = [
		{ className: "oneLight", label: "1 Light" },
		{ className: "oneMonitor", label: "1 Monitor" },
		{ className: "oneLightOneMonitor", label: "1 Light & 1 Monitor" },
		{ className: "oneLightTwoMonitors", label: "1 Light & 2 Monitors" },
		{ className: "twoLightsOneMonitor", label: "2 Lights & 1 Monitor" },
		{ className: "twoLightsTwoMonitors", label: "2 Lights & 2 Monitors" },
		{ className: "standaloneLight", label: "Standalone Light" },
		{ className: "standaloneMonitor", label: "Standalone Monitor" },
		{ className: "lrgMonitorCarrier", label: "Large Monitor Carrier" },
	]

	const touchScreenSubtypes = [
		{ className: "wallMounted", label: "Wall Mounted Touchscreen" },
		{ className: "deskMounted", label: "Desk Mounted Touchscreen" },
		{ className: "wallMountedWithKeyboard", label: "Wall Mounted Touchscreen w/Keyboard" },
		{ className: "deskMountedWithKeyboard", label: "Desk Mounted Touchscreen w/Keyboard" },
		{ className: "keyboard", label: "Keyboard" },
	]
	const deskSubTypes = [
		{ className: "largeLDesk", label: "Large \"L\" Desk" },
		{ className: "shortLDesk", label: "Short \"L\" Desk" },
		{ className: "straightDesk", label: "Straight Desk" },
		{ className: "narrowDesk", label: "Narrow Desk" },
		{ className: "extraNarrowDesk", label: "Extra Narrow Desk" },
	]

	const displaySubTypes = [
		{ className: "wallDisplay55", label: "55\" Wall Display" },
		{ className: "wallDisplay65", label: "65\" Wall Display" },
		{ className: "wallDisplay75", label: "75\" Wall Display" },
		{ className: "wallDisplayWCamera55", label: "55\" Wall Display w/Camera" },
		{ className: "wallDisplayWCamera65", label: "65\" Wall Display w/Camera" },
		{ className: "wallDisplayWCamera75", label: "75\" Wall Display w/Camera" },
	]


	const ceilingEquipment = [
		{ className: "speaker", label: "Speaker" },
		{ className: "tomsCamera", label: "TOMS Camera" },
		{ className: "cameraPTZCeiling", label: "Camera-PTZ-Ceiling-Mount" },
	]


	const roomCameraSubTypes = [
		{ className: "roomCamera", label: "Camera-PTZ" },
		{ className: "cameraPTZCeiling", label: "Camera-PTZ-Ceiling-Mount" },
	]

	const computerSubtypes = [
		{ className: "AIDA", label: "AIDA" },
		{ className: "wallMounted", label: "Wall Mounted Computer" },
		{ className: "deskMounted", label: "Desk Mounted Computer w/Keyboard" },
		{ className: "wallMountedWithKeyboard", label: "Wall Mounted Computer w/Keyboard" },
		{ className: "keyboard", label: "Keyboard" },
	]


	const wallPlateSubTypes = [
		{ className: "data", label: "Wall Data" },
		{ className: "inputOther", label: "Wall Input Other" },
		{ className: "inputVideo", label: "Wall Input Video" },
		{ className: "powerDual", label: "Wall Power Dual" },
		{ className: "powerQuad", label: "Wall Power Quad" },
	];

	return (
		<div className={`PopupOtherSubtype`}>
			<div className='backgroundDismiss' onClick={() => {
				onDismiss();
			}} />
			<div className='popup'>
				<div className='header'>
					<div className='buttonCancel' onClick={() => {
						onDismiss();
					}}>Cancel</div>
					<div className={`buttonSave ${selectedSubtype == "" ? "disabled" : ""}`} onClick={(e) => {
						e.stopPropagation();
						onSave(selectedType, selectedSubtype, selectedLabel);
					}}>Done</div>
				</div>
				<div className='options'>
					<div className='title'>Anesthesia Machines</div>
					{anesMachineSubtypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("anesMachine");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("anesMachine", option.className, option.label);
						}}
						>
							<div className={`tile anesMachine ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Anesthesia Booms</div>
					{anesBoomSubtypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("anesBoom");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("anesBoom", option.className, option.label);
						}}
						>
							<div className={`tile anesBoom ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Equipent Booms</div>
					{boomSubtypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("boom");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("boom", option.className, option.label);
						}}
						>
							<div className={`tile boom ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Displays</div>
					{displaySubTypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("display");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("display", option.className, option.label);
						}}
						>
							<div className={`tile display ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Computers</div>
					{computerSubtypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("computer");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("computer", option.className, option.label);
						}}
						>
							<div className={`tile computer ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Monitor Carriers and Lights</div>
					{spindleSubtypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("spindle");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("spindle", option.className, option.label);
						}}
						>
							<div className={`tile spindle ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Robots</div>
					{robotSubTypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("robot");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("robot", option.className, option.label);
						}}
						>
							<div className={`tile robot ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Room Cameras</div>
					{roomCameraSubTypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("camera");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("camera", option.className, option.label);
						}}
						>
							<div className={`tile camera ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Beds</div>
					{bedSubtypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("bed");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e) => {
							e.stopPropagation();
							onSave("bed", option.className, option.label);
						}}
						>
							<div className={`tile bed ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Doors</div>
					{doorSubTypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("door");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e)=>{
							e.stopPropagation();
							onSave("door", option.className, option.label);
						}}>
							<div className={`tile door ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Racks</div>
					{rackSubTypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("rack");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e)=>{
							e.stopPropagation();
							onSave("rack", option.className, option.label);
						}}>
							<div className={`tile rack ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Desks</div>
					{deskSubTypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("desk");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e)=>{
							e.stopPropagation();
							onSave("desk", option.className, option.label);
						}}>
							<div className={`tile desk ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Touchscreens</div>
					{touchScreenSubtypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("desk");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e)=>{
							e.stopPropagation();
							onSave("desk", option.className, option.label);
						}}>
							<div className={`tile desk ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Ceiling Equipment</div>
					{ceilingEquipment.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("ceilingEquipment");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e)=>{
							e.stopPropagation();
							onSave("ceilingEquipment", option.className, option.label);
						}}>
							<div className={`tile ceilingEquipment ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
					<div className='title'>Wall Plates</div>
					{wallPlateSubTypes.map((option, index) => (
						<div key={index} className={`option ${selectedSubtype == option.className ? "selected" : ""}`} onClick={(e) => {
							e.stopPropagation();
							setSelectedType("wallPlate");
							setSelectedSubtype(option.className);
							setSelectedLabel(option.label);
						}}
						onDoubleClick={(e)=>{
							e.stopPropagation();
							onSave("wallPlate", option.className, option.label);
						}}>
							<div className={`tile wallPlate ${option.className}`} />
							<div className='label'>
								{option.label}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}