import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationRoomListItem.css';
import { Config, SOW } from '../../../Model/SOW';
import { SceneObject } from '../../../Model/SceneObject';
import PopupSelectSubtype from '../PopupSelectSubtype/PopupSelectSubtype';
interface ConfigurationRoomListItemProps {
	index: number;
	sceneObject: SceneObject | string;
	forPDF: boolean;
	selectedSceneObjectID: string;
	onSelectSubtype(sceneObject: SceneObject | string): void;
}
export default function ConfigurationRoomListItem({ index, sceneObject, forPDF, selectedSceneObjectID, onSelectSubtype }: ConfigurationRoomListItemProps) {
	let isDot = (typeof sceneObject != "string" && (sceneObject as SceneObject).type != "none" && (sceneObject as SceneObject).type != "any");
	let distinctSceneObjectLabels = (typeof sceneObject != "string")?sceneObject.labels.filter((label, index, self) => {
		return self.findIndex(l => l.label == label.label) === index
	}):null;
	return (
		<div className={`ConfigurationRoomListItem ${(sceneObject != "string" && selectedSceneObjectID == (sceneObject as SceneObject).id) ? "selected" : ""}`}>
			{!forPDF &&
				<div className='dot'
					style={{ backgroundColor: isDot ? "var(--app-color-blue2)" : "" }} />
			}

			<div className='leftSide'>
				{!forPDF && (typeof sceneObject == "string" || (sceneObject as SceneObject).type != "none") && (
					<div className='buttonSubtype' onClick={() => {
						onSelectSubtype(sceneObject);
					}}></div>
				)}
			</div>
			<div className='rightSide'>
				{typeof sceneObject == "string" && (
					<div className='name'>{sceneObject}</div>
				)}
				{typeof sceneObject != "string" && distinctSceneObjectLabels &&(
					<>
						<div className='name'><div className='letter'>{(sceneObject as SceneObject).type != "none" && (sceneObject as SceneObject).letter != "" && <>{(sceneObject as SceneObject).letter}.</>}</div>{sceneObject.name}</div>
						<div className='labels'>
							{distinctSceneObjectLabels.map((label, index) => {
								return (
									<div className={`label ${label.id == selectedSceneObjectID ? "selected" : ""}`} key={index}>
										<div className='letter'></div> {label.label}
									</div>
								);
							})}
						</div>
					</>
				)}
			</div>
		</div>
	);
}