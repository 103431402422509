
import React, { useRef, useState, useEffect } from 'react';
import './AudioOutputRow.css';
import { AudioInput, AudioOutput } from '../../../Model/SOW';
import { AUDIO_OUTPUTS, LOCATIONS } from '../../../Model/DropdownOptions';
import getDefaultLocation from '../../../Utils/GetDefaultLocation';
import ComboBox from '../../ComboBox/ComboBox';
import Dropdown from '../../Dropdown/Dropdown';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
interface AudioOutputRowProps {
  audioOutput: AudioOutput
  onChange: (audioOutput: AudioOutput) => void;
  onDelete: (audioOutput: AudioOutput) => void;
}
export default function AudioOutputRow({ audioOutput, onChange, onDelete }: AudioOutputRowProps) {
  const [userChanged, setUserChanged] = useState<boolean>(false);
  const [localSignalLocation, setLocalSignalLocation] = useState(audioOutput.signalLocation);
  const [localOutputsTPButtonText, setLocalOutputsTPButtonText] = useState(audioOutput.outputsTPButtonText);


  useEffect(() => {
    if (userChanged) {
      if (audioOutput.signalLocation != localSignalLocation ||
        audioOutput.outputsTPButtonText != localOutputsTPButtonText) {

        audioOutput.signalLocation = localSignalLocation;
        audioOutput.outputsTPButtonText = localOutputsTPButtonText;

        onChange(audioOutput);
      }

    }
  }, [userChanged, localSignalLocation, localOutputsTPButtonText]);

  return (
    <>
      <td>
        <input type='text' className='buttonLocation' disabled={true} value={audioOutput.buttonLocation} />
        <div className='dragIndicator'><FontAwesomeIcon icon={faBars} /></div>
      </td>
      <td>
        <ComboBox
          className='outputsTPButtonText'
          options={AUDIO_OUTPUTS}
          value={audioOutput.outputsTPButtonText}
          onChange={(value: string) => {
            let updatedVideoInput = { ...audioOutput, outputsTPButtonText: value };
            let defaultLocation = getDefaultLocation(value);
            if (defaultLocation != "") {
              updatedVideoInput.signalLocation = defaultLocation;
              setLocalSignalLocation(defaultLocation);
            }
            setLocalOutputsTPButtonText(value);
            setUserChanged(true);
          }} ></ComboBox>
      </td>
      <td>
        <ComboBox className='selectLocation'
          value={localSignalLocation}
          options={LOCATIONS}
          onChange={(value) => {
            setLocalSignalLocation(value);
            setUserChanged(true);
          }}></ComboBox>
        <div
          className='buttonDelete'
          onClick={() => {
            onDelete(audioOutput);
          }}></div>
      </td>
    </>
  );
}