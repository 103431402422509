
import React, { useRef, useState, useEffect } from 'react';
import './StreamConnectTableOfContents.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import { SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
interface StreamConnectTableOfContentsProps {
  sow: SOW;
  forPDF?: boolean;
}
export default function StreamConnectTableOfContents({ sow, forPDF }: StreamConnectTableOfContentsProps) {
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen: ActionCreators.setScreen,
    setSOW: ActionCreators.setSOW,
    setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
  }, dispatch);

  const currentConfigIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)



  const dropdownItemClicked = (index: number) => {
    switch (index) {
      case 0:
        AC.setScreen(Screens.StreamConnectExecutiveSummary);
        break;
      case 1:
        AC.setScreen(Screens.StreamConnectScopeOfWork);
        break;
      case 2:
        AC.setScreen(Screens.StreamConnectExecutiveSummary);
        break;
      case 3:
        AC.setScreen(Screens.StreamConnectProjectRoadmap);
        break;
      case 4:
        AC.setScreen(Screens.StreamConnectProjectRoadmap);
        break;
      case 5:
        AC.setScreen(Screens.StreamConnectTopologyDiagram);
        break;

    }
  };

  return (
    <div className='Screen StreamConnectTableOfContents'>
      <div className='contentsImage' />
      <div className='leftBar' />
      <div className='verticalLabel'>Table Of Contents</div>
      <div className='menu'>
        <div className='button executiveSummary' onClick={() => dropdownItemClicked(2)}></div>
        <div className='button scopeOfWork' onClick={() => dropdownItemClicked(3)}></div>
        <div className='button enterpriseStrategy' onClick={() => dropdownItemClicked(4)}></div>
        <div className='button projetRoadmap' onClick={() => dropdownItemClicked(5)}></div>
        <div className='button topology' onClick={() => dropdownItemClicked(6)}></div>

      </div>
      {!forPDF && (
        <>
          <NavigationHeader
            sow={sow} />
        </>
      )}
    </div>
  );
}