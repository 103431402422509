import React, { useEffect, useRef, useState } from 'react';
import "./DropdownWithIcons.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface Option {
  name: string;
  icon: string;
}

interface DropdownWithIconsProps {
  options: Option[];
  className?: string;
  onSelect?: (optionName: string) => void;
  defaultValue?: string;
  disabled?: boolean;
}

const DropdownWithIcons: React.FC<DropdownWithIconsProps> = ({ options, onSelect, defaultValue, className, disabled }) => {
  const defaultOption = defaultValue ? options.find(option => option.name === defaultValue) : undefined;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const toggleDropdown = () => {
    filterOptions("");
    if(inputSearchRef.current){
      inputSearchRef.current!.value = "";
      inputSearchRef.current!.focus();
    }
    setIsOpen(!isOpen);

  };

  useEffect(() => {
    if(inputSearchRef.current){
      inputSearchRef.current!.value = "";
      inputSearchRef.current!.focus();
    }
  }, [isOpen]);

  const handleSelect = (option: Option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelect && onSelect(option.name);
    if(headerRef.current){
      //check to see if headerref.current is in view
      if(headerRef.current.getBoundingClientRect().top < 160){
        headerRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
      }
    }
  };

  const inputSearchRef = useRef<HTMLInputElement>(null);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLDivElement>(null);

  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);
  const [showSearchBox, setShowSearchBox] = useState(false);




  const filterOptions = (searchText: string) => {
    let sortedOptions = [...options]
    sortedOptions.sort((a, b) => {
      if(a.name < b.name){
        return -1;
      }
      if(a.name > b.name){
        return 1;
      }
      return 0;
    });
    if(searchText.length == 0){
      setFilteredOptions(sortedOptions);
      return;
    }
    let filteredOptions = sortedOptions.filter((option) => {
      return option.name.toLowerCase().includes(searchText.toLowerCase());
    });
    
    setFilteredOptions(filteredOptions);
  }

  useEffect(() => {
    filterOptions("");
  }, [options]);


  const shouldShowListAbove = () => {
    //get the bounding rect of the headerref. if it is greater that 540px from the top, show the list above
    if(headerRef.current){
      let rect = headerRef.current.getBoundingClientRect();
      if(rect.top > 540){
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    if (isOpen) {
      if (headerRef.current) {
        headerRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    }
  },[isOpen,listRef]);



  return (
    <div className={`DropdownWithIcons ${className ?? ""} ${disabled ? "disabled" : ""}  ${selectedOption==undefined&&!disabled&&"unfilled"}`}>
      <div className="dropdown-header" onClick={toggleDropdown} ref={headerRef}>
        {selectedOption && (
          <>
          <img src={selectedOption!.icon} alt="icon" className="icon" />
          {selectedOption!.name}
        </>
        )}
        <span className="arrow"><FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} /></span>
      </div>
      <input type='text'
        onFocus={()=>{setIsOpen(true)}} 
        className='focusGetter' 
        onKeyDown={(e) =>{
        if(e.key == "Tab"){
          setIsOpen(false);
          return;
        }
        e.preventDefault();
      }}/>
      {isOpen && (
        <>
          <div className='dropdown-backdrop' onClick={toggleDropdown} onDrag={e =>e.stopPropagation()}></div>
        </>
      )}
      <div className={`dropdown-list ${shouldShowListAbove()?"above":""}`} ref={listRef} style={{ opacity: isOpen ? 1 : 0, pointerEvents: isOpen ? "all" : "none", height: isOpen ? "unset" : 0 }}>
        {isOpen &&  <input 
          type='text' 
          placeholder='Search' 
          className='searchBox' 
          ref={inputSearchRef} 
          onKeyDown={(e) => {
            if(e.key == "Enter"){
              if(filteredOptions.length > 0){
                handleSelect(filteredOptions[0]);
              }
            }
            if(e.key == "Tab"){
              setIsOpen(false);
            }
          }}
          onChange={(e) => {
          filterOptions(e.target.value);
        }} />}
        {filteredOptions.map((option, index) => (
          <div key={index} className={`row ${((selectedOption && option.name == selectedOption.name && filteredOptions.length == options.length)|| (index == 0 && filteredOptions.length != options.length)) ? "selected" : ""}`}>
            <div className={`dropdown-item`} onClick={() => handleSelect(option)}>
              <img src={option.icon} alt="icon" className="icon" />
              {option.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownWithIcons;
