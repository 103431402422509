import { DepartmentScopeOfWork } from "../Components/Components_StreamConnect/DepartmentScopeOfWorkRow/DepartmentScopeOfWorkRow";
import { SOW } from "../Model/SOW";

export default function StreamConnectSOWRows(sow:SOW, isUpgrade:boolean){
	let returnList:DepartmentScopeOfWork[] = [];
	sow.departments.forEach((department, index) => {
		let deptIsUpgrade = false;
		let deptIsPurchase = false;
		if (department.licensingDashboards == "Upgrade" ||
			department.licensingEHRLINC == "Upgrade" ||
			department.licensingLive == "Upgrade" ||
			department.licensingRoomToRoom == "Upgrade" ||
			department.licensingTOMS == "Upgrade" ||
			department.licensingVideoOnDemand == "Upgrade") {
				deptIsUpgrade = true;
		}
		if (department.licensingDashboards == "Purchase" ||
			department.licensingEHRLINC == "Purchase" ||
			department.licensingLive == "Purchase" ||
			department.licensingRoomToRoom == "Purchase" ||
			department.licensingTOMS == "Purchase" ||
			department.licensingVideoOnDemand == "Purchase") {
				deptIsPurchase = true;
		}
		if(isUpgrade && !deptIsUpgrade){
			return;
		}
		if(!isUpgrade && !deptIsPurchase){
			return;
		}

		let vodCheck = (isUpgrade && department.licensingVideoOnDemand == "Upgrade") || (!isUpgrade && department.licensingVideoOnDemand == "Purchase");
		let ehrLincWorklistCheck = (isUpgrade && department.licensingEHRLINC == "Upgrade" && department.ehrLINCWorklist) || (!isUpgrade && department.licensingEHRLINC == "Purchase" && department.ehrLINCWorklist);
		let ehrLincArchiveCheck = (isUpgrade && department.licensingEHRLINC == "Upgrade" && department.ehrLINCArchive) || (!isUpgrade && department.licensingEHRLINC == "Purchase" && department.ehrLINCArchive);
		let liveCheck = (isUpgrade && department.licensingLive == "Upgrade") || (!isUpgrade && department.licensingLive == "Purchase");
		let roomToRoomCheck = (isUpgrade && department.licensingRoomToRoom == "Upgrade") || (!isUpgrade && department.licensingRoomToRoom == "Purchase");
		let tomsCheck = (isUpgrade && department.licensingTOMS == "Upgrade") || (!isUpgrade && department.licensingTOMS == "Purchase");
		let dashboardsBiomedCheck = (isUpgrade && department.licensingDashboards == "Upgrade" && department.dashboardsBiomed) || (!isUpgrade && department.licensingDashboards == "Purchase" && department.dashboardsBiomed);
		let dashboardsDeviceCheck = (isUpgrade && department.licensingDashboards == "Upgrade" && department.dashboardsDevice) || (!isUpgrade && department.licensingDashboards == "Purchase" && department.dashboardsDevice);

		let row: DepartmentScopeOfWork = {
			name: department.name,
			vodCheck: vodCheck,
			vodDevice: (vodCheck)?`${department.vodQuantityAIDA>0?"AIDA":department.vodQuantityTP>0?"TELE PACK":""}`:"",
			vodQty: (vodCheck)?`${department.vodQuantityAIDA>0?department.vodQuantityAIDA:department.vodQuantityTP>0?department.vodQuantityTP:""}`:"",
			ehrLincWorklistCheck: ehrLincWorklistCheck,
			ehrLincArchiveCheck: ehrLincArchiveCheck,
			liveCheck: liveCheck,
			liveEncoderQty: liveCheck?`${department.liveEncoderQuantity}`:"",
			roomToRoomCheck: roomToRoomCheck,
			roomToRoomDecoderQty: roomToRoomCheck?`${department.roomToRoomDecoderQuantity}`:"",
			tomsCheck: tomsCheck,
			tomsCameraQty: tomsCheck?`${department.tomsCameraQuantity}`:"",
			tomsPlateQty: tomsCheck?`${department.tomsPlateQuantity}`:"",
			tomsPofEQty: tomsCheck?`${department.tomsPofEQuantity}`:"",
			dashboardsBiomedCheck: dashboardsBiomedCheck,
			dashboardsDeviceCheck: dashboardsDeviceCheck
		}
		returnList.push(row);

		if(department.vodQuantityAIDA > 0 && department.vodQuantityTP > 0 && vodCheck){
			let row:DepartmentScopeOfWork = {
				name: department.name,
				vodCheck: false,
				vodDevice: "TELE PACK",
				vodQty: `${department.vodQuantityTP}`,
				ehrLincWorklistCheck: false,
				ehrLincArchiveCheck: false,
				liveCheck: false,
				liveEncoderQty: "",
				roomToRoomCheck: false,
				roomToRoomDecoderQty: "",
				tomsCheck: false,
				tomsCameraQty: "",
				tomsPlateQty: "",
				tomsPofEQty: "",
				dashboardsBiomedCheck: false,
				dashboardsDeviceCheck: false
			}
			returnList.push(row);
		}

	})
	return returnList;
}