
import React, { useRef, useState, useEffect } from 'react';
import './DepartmentTab.css';
import { Config, Department } from '../../../Model/SOW';
interface DepartmentTabProps {
	department: Department;
	departmentNum: number;
	selected: boolean;
	onChange: (department: Department) => void;
	onSelect: (department: Department) => void;
	onDuplicate: (department: Department) => void;
}
export default function DepartmentTab({ department, departmentNum, selected, onChange, onSelect, onDuplicate }: DepartmentTabProps) {
	const [modifyingName, setModifyingName] = useState<boolean>(false);
	const inputRef = useRef<HTMLInputElement>(null);

	const [departmentName, setDepartmentName] = useState<string>(department.name);

	useEffect(() => {
		setDepartmentName(department.name);
	}, [department, department.name]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, [modifyingName])

	return (
		<>
			<div className={`DepartmentTab ${selected ? "selected" : ""}`}
				onDoubleClick={() => {
					setModifyingName(true);
				}}
				onClick={(e) => {
					e.stopPropagation();
					onSelect(department);
				}}>
				{modifyingName ? <>
					<input type='text'
						ref={inputRef}
						defaultValue={department.name} onBlur={(e) => {
							department.name = e.target.value;
							onChange(department)
							setModifyingName(false);
						}} onKeyDown={(e) => {
							if (e.key == "Enter") {
								e.preventDefault();
								e.stopPropagation();
								department.name = e.currentTarget.value;
								onChange(department)
								setModifyingName(false);
							}
						}} />
				</> : <>
					{departmentName}
				</>}
			</div>

			{selected && <>
				<div className='buttonDuplicate' onClick={(e) => {
					e.stopPropagation();
					onDuplicate(department);
				}} />
			</>}
		</>
	);
}