
import React, { useRef, useState, useEffect } from 'react';
import './VideoInputRow.css';
import { VideoInput } from '../../../Model/SOW';
import ComboBox from '../../ComboBox/ComboBox';
import { OUTPUT_ICONS, INPUTS, INPUTS_OFE, LOCATIONS, SIGNALS, INPUT_ICONS } from '../../../Model/DropdownOptions';
import getDefaultLocation from '../../../Utils/GetDefaultLocation';
import DropdownWithIcons from '../../DropdownWithIcons/DropdownWithIcons';
import Dropdown from '../../Dropdown/Dropdown';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toggle from '../../Toggle/Toggle';
import { sign } from 'crypto';
import { on } from 'events';
interface VideoInputRowProps {
  videoInput: VideoInput;
  hasOFESignalType: boolean;
  onChange: (videoInput: VideoInput) => void;
  onDelete: (videoInput: VideoInput) => void;
}
export default function VideoInputRow({ videoInput, hasOFESignalType, onChange, onDelete }: VideoInputRowProps) {

  const [userChanged, setUserChanged] = useState<boolean>(false);
  const [inputsTPButtonText, setInputsTPButtonText] = useState(videoInput.inputsTPButtonText);
  const [icon, setIcon] = useState(videoInput.icon);
  const [signalOriginates, setSignalOriginates] = useState(videoInput.signalOriginates);
  const [ofeSignalType, setOfeSignalType] = useState(videoInput.ofeSignalType);
  const [kvm, setKvm] = useState(videoInput.kvm);

  useEffect(() => {
    console.log("videoInput changed");
  }, [videoInput]);

  useEffect(() => {
    if (userChanged) {
      if (videoInput.inputsTPButtonText != inputsTPButtonText ||
        videoInput.icon != icon ||
        videoInput.signalOriginates != signalOriginates ||
        videoInput.ofeSignalType != ofeSignalType ||
        videoInput.kvm != kvm) {

        videoInput.inputsTPButtonText = inputsTPButtonText;
        videoInput.icon = icon;
        videoInput.signalOriginates = signalOriginates;
        videoInput.ofeSignalType = ofeSignalType;
        videoInput.kvm = kvm;

        console.log("videoInput changed");

        onChange(videoInput);
      }
      setUserChanged(false);
    }
  }, [userChanged, inputsTPButtonText, icon, signalOriginates, ofeSignalType, kvm]);



  return (
    <>
      <td>
        <input type='text' className='buttonLocation' disabled={true} value={videoInput.buttonLocation} />
        <div className='dragIndicator'><FontAwesomeIcon icon={faBars} /></div>
      </td>
      <td>
        <ComboBox
          options={INPUTS}
          className="inputsTPButtonText"
          value={inputsTPButtonText}
          onChange={(value: string) => {
            setInputsTPButtonText(value);
            let defaultLocation = getDefaultLocation(value);
            if (defaultLocation != "") {
              setSignalOriginates(defaultLocation);
            }
            setUserChanged(true);
          }} ></ComboBox>
      </td>
      <td>
        <DropdownWithIcons
          options={INPUT_ICONS}
          className='iconsDrop'
          defaultValue={icon}
          onSelect={(option: string) => {
            setIcon(option);
            setUserChanged(true);
          }}></DropdownWithIcons>
      </td>
      <td>
        <ComboBox className='selectLocation'
          value={signalOriginates}
          options={LOCATIONS}
          onChange={(value) => {
            setSignalOriginates(value);
            setUserChanged(true);
          }}></ComboBox>
      </td>
      {hasOFESignalType && <td>
        {(INPUTS_OFE.indexOf(inputsTPButtonText) != -1 || INPUTS.indexOf(inputsTPButtonText) == -1) &&
          <ComboBox
            className='signalType'
            options={SIGNALS}
            value={ofeSignalType}
            // disabled={INPUTS_OFE.indexOf(inputsTPButtonText) === -1}
            onChange={(optionName) => {
              setOfeSignalType(optionName);
              setUserChanged(true);
            }}></ComboBox>
        }
      </td>}
      <td>
        <Toggle
          value={kvm}
          onChange={(value: boolean) => {
            setKvm(value);
            setUserChanged(true);
          }}></Toggle>
        <div 
          className='buttonDelete' 
          onClick={() => {
            onDelete(videoInput);
          }}></div>
      </td>
    </>
  );
}