import { AnyAction } from "redux";
import { SOW } from "../Model/SOW";


export enum Screens{
	Splash,
	Landing,
	Admin,
	LoadSOW,
	ORBegin,
	ORConfigSetup,
	ORContents,
	ORExecutiveSummary,
	ORProjectRoadMap,
	ORRoomLayout,
	ORTouchscreenLayout,
	ORInputOutputSchedule,
	OREquipmentBoomLayout,
	StreamConnectBegin,
	StreamConnectSystemSetup,
	StreamConnectProjectDates,
	StreamConnectTableOfContents,
	StreamConnectExecutiveSummary,
	StreamConnectScopeOfWork,
	StreamConnectScopeOfWorkUpgrade,
	StreamConnectProjectRoadmap,
	StreamConnectEnterperiseStrategyMap,
	StreamConnectTopologyDiagram,
	PDFOutput
}

export function ScreenReducer(state = Screens.Splash, action:AnyAction){
  switch(action.type){
    case "setScreen":
      return  action.payload;
  }
  return state;
}
export function ConfigOrDepartmentIndexReducer(state = 0, action:AnyAction){
  switch(action.type){
    case "setCurrentConfigIndex":
      return  action.payload;
		case "setCurrentDepartmentIndex":
			return  action.payload;
  }
  return state;
}


export function SOWReducer(state = null, action:AnyAction){
	switch(action.type){
		case "setSOW":
			return  action.payload;
	}
	return state;
}

export function PrintingPDFReducer(state = false, action:AnyAction){
	switch(action.type){
		case "setPrintingPDF":
			return  action.payload;
	}
	return state;
}

export function LoggedInUserReducer(state = null, action:AnyAction){
  switch(action.type){
    case "setLoggedInUser":
      return  action.payload;
  }
  return state;
}




export default {
  ScreenReducer,
	SOWReducer,
	PrintingPDFReducer,
	ConfigOrDepartmentIndexReducer,
	LoggedInUserReducer
}