import { OUTPUT_ICONS } from "../Model/DropdownOptions";
import { AudioInput, AudioOutput, Config, Department, VideoInput, VideoOutput } from "../Model/SOW";
import { SceneObject } from "../Model/SceneObject";

const uuid = require('uuid');

export function blankConfig(){
	let config:Config = {
		id: uuid.v4(),
		operatingRoomNumbers: "",
		boomVendor: '',
		video: {
			system: '',
			lightingControl: undefined,
			roomToRoom: undefined,
			chat: undefined,
			deviceControl: undefined,
			touchscreenLocation: "",
			multiview: "",
			capture: "",
			streaming: undefined,
			conferencing: undefined,
			toms: "",
			checklistDashboard: "",
			inputs: [],
			outputs: []
		},
		audio: {
			standardIntelligent: '',
			inputs: [],
			outputs: []
		},
		roomLayout: {
			type: 'square',
			scale: 0.75,
			sceneObjects: []
		},
		equipmentBoomLayout: {
			type: '',
			sceneObjects: []
		}
	};

	return config;
}

export function blankDepartment(){
	let department:Department = {
		id: uuid.v4(),
		name: "",
		operatingRoomNumbers: "",
		licensingVideoOnDemand: "",
		licensingEHRLINC: "",
		licensingLive: "",
		licensingRoomToRoom: "",
		licensingTOMS: "",
		licensingDashboards: "",
		vodDevices: "",
		vodQuantityAIDA: 0,
		vodQuantityTP: 0,
		ehrLINCWorklist: false,
		ehrLINCArchive: false,
		liveEncoderQuantity: 0,
		roomToRoomDecoderQuantity: 0,
		tomsCameraQuantity: 0,
		tomsPlateQuantity: 0,
		tomsPofEQuantity: 0,
		dashboardsBiomed: false,
		dashboardsDevice: false,
		strategyMapSceneObjects: [],
	};

	return department;
}

export function blankVideoInput(){
	let videoInput:VideoInput = {
		id: uuid.v4(),
		buttonLocation: '',
		inputsTPButtonText: '',
		icon: "",
		signalOriginates: '',
		ofeSignalType: '',
		kvm: false
	};
	return videoInput;
}

export function blankVideoOutput(){
	let videoOutput:VideoOutput = {
		id: uuid.v4(),
		buttonLocation: '',
		outputsButtonText: '',
		icon: "",
		signalLocation: '',
		ofeSignalType: '',
		surgicalDisplaySize: '',
		displaySize: ''
	};
	return videoOutput;
}

export function blankAudioInput(){
	let audioInput:AudioInput = {
		id: uuid.v4(),
		buttonLocation: '',
		inputsTPButtonText: '',
		signalOriginates: ''
	};
	return audioInput;
}

export function blankAudioOutput(){
	let audioOutput:AudioOutput = {
		id: uuid.v4(),
		buttonLocation: '',
		outputsTPButtonText: '',
		signalLocation: ''
	};
	return audioOutput;
}

export function blankSceneObject(){
	let sceneObject:SceneObject = {
		id: uuid.v4(),
		type: '',
		subtype: '',
		letter:"",
		name: '',
		visible: true,
		locked: false,
		scale: 1,
		left: 50,
		top: 50,
		rotation: 0,
		flipped:false,
		addedInRoom: false,
		labels: [] as {id:string, label:string}[],
		children: [] as SceneObject[]
	};
	return sceneObject;
}