import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { readAndCompressImage } from 'browser-image-resizer';
const uuid = require('uuid');

const MAX_IMAGE_SIZE = 800

// Configuration for the image resizer
const resizeConfig = {
    quality: 0.7,
    maxWidth: MAX_IMAGE_SIZE,
    maxHeight: MAX_IMAGE_SIZE,
    autoRotate: true,
    debug: true,
};

// Function to upload an image to Firebase Storage
async function UploadImageToFirebase(file: File): Promise<{ url: string, height: number }> {
    return new Promise(async (resolve, reject) => {
        try {
            // Resize the image
            const resizedImage = await readAndCompressImage(file, resizeConfig);

            // Get a reference to the storage service and set the upload path
            const storage = getStorage();
            const storageRef = ref(storage, `room_backgrounds/${uuid.v4()}_${file.name}`);

            // Upload the resized image
            const snapshot = await uploadBytes(storageRef, resizedImage);

            // Get the download URL
            const downloadURL = await getDownloadURL(snapshot.ref);

            //get the image height from the resizedImage Blob
            const img = new Image();
            img.src = URL.createObjectURL(resizedImage);
            let resizedImageHeight = 0;
            img.onload = function () {
                if (img.height > img.width) {
                    resizedImageHeight = Math.min(MAX_IMAGE_SIZE, img.height / img.width * MAX_IMAGE_SIZE);
                } else {
                    resizedImageHeight = Math.min(MAX_IMAGE_SIZE, img.width / img.height * MAX_IMAGE_SIZE);
                }
                resolve({ url: downloadURL, height: resizedImageHeight });
            };

        } catch (error) {
            console.error('Error uploading image:', error);
            reject(error);
            throw error;
        }
    });
}

export default UploadImageToFirebase;
