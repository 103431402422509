
import React, { useRef, useState, useEffect } from 'react';
import './PDFOutput.css';
import { SOW } from '../../Model/SOW';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import BackgroundImageLoader from '../BackgroundImageLoader/BackgroundImageLoader';

var domtoimage = require('dom-to-image');

// Function to detect the browser
function getBrowser() {
  // UserAgent string
  const userAgent = navigator.userAgent;

  // Check for Safari
  if (userAgent.indexOf("Safari") != -1 && userAgent.indexOf("Chrome") == -1) {
      return "Safari";
  }
  // Check for Chrome
  else if (userAgent.indexOf("Chrome") != -1) {
      return "Chrome";
  }
  // Default to 'Other' if neither
  else {
      return "Other";
  }
}

export interface PDFOutputResults {
  pdfData: Blob;
  imageDataURLs: string[];
}

interface PDFOutputProps {
  readyToGenerate: boolean;
  children: React.ReactNode;
  onGenerationComplete: (pdfData: PDFOutputResults) => void;
}
export default function PDFOutput({ onGenerationComplete, children, readyToGenerate }: PDFOutputProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  const [buildingPDFData, setBuildingPDFData] = useState(false);
  const [percentComplete, setPercentComplete] = useState(0);

  let buildPDFData = async () => {
  }

  useEffect(() => {
    if (readyToGenerate == false) return
    if (!containerRef.current) return;

    if (buildingPDFData) return;
    setBuildingPDFData(true);

    buildPDFData = async () => {
      if (!containerRef.current) return;
      const childrenArray = Array.from(containerRef.current.children) as HTMLElement[];
      const pdf = new jsPDF('l', 'px', [1920, 1080]);
      const imageDataURLs: string[] = [];
      for (var i = 0; i < childrenArray.length; i++) {
        imageDataURLs.push("");
      }



      const browser = getBrowser();

      const convertToCanvas = (element: HTMLElement) => html2canvas(element);
      //const convertToCanvas = (element: HTMLElement) => htmlToImage.toPng(element);
      const convertToCanvasChrome = (element: HTMLElement) => htmlToImage.toJpeg(element,{quality: 0.75});
      //const convertToCanvas = (element: HTMLElement) => domtoimage.toPng(element);
      //const convertToCanvas = (element: HTMLElement) => domtoimage.toJpeg(element, 0.75);

      const processPages = async () => {
        try {
          for (let i = 0; i < childrenArray.length; i++) {
            setPercentComplete(Math.round((i / childrenArray.length) * 100));
            let imgData = "";
            
            if(browser === "Safari") {
              const canvas = await convertToCanvas(childrenArray[i]);
              imgData = canvas.toDataURL('image/jpeg', 0.75);
            }else{
              const canvas = await convertToCanvasChrome(childrenArray[i]);
              imgData = canvas as string;
            }

            //const imgData = await convertToCanvas(childrenArray[i]);
            imageDataURLs[i] = imgData;
            const imgProps = pdf.getImageProperties(imgData);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

            if (i > 0) {
              pdf.addPage();
            }

            pdf.addImage(imgData, 'JPG', 0, 0, pdfWidth, pdfHeight);

            //grab url last path component
            let url = childrenArray[i].baseURI;
            let urlParts = url.split("/");
            if (urlParts.length > 2) {
              let urlLastPart = urlParts[urlParts.length - 2];
              if (urlLastPart.toLocaleLowerCase().startsWith("v")) {
                //add the last path to the pdf page bottom right corner
                pdf.setFontSize(10);
                pdf.text(urlLastPart, pdfWidth - 20, pdfHeight - 10);
              }
            }
          }

          let pdfData = pdf.output('blob');
          onGenerationComplete({ pdfData, imageDataURLs });
        } catch (e) {
          console.log(e);
        }
      };

      processPages();
    };

    // setTimeout(() => {
    //   buildPDFData();
    // }, 4000);
  }, [onGenerationComplete, buildingPDFData, readyToGenerate]);


  const handleImagesLoaded = () => {
    //console.log('All background images are loaded!');
    buildPDFData();
    // setTimeout(() => {
    //   buildPDFData();
    // }, 10000);
  };

  return (
    <>
      <BackgroundImageLoader 
        onImagesLoaded={handleImagesLoaded}>
        <div ref={containerRef}>{children}</div>
      </BackgroundImageLoader>
      <div className='pdfOutputProgress'>
        <div className='label'>Building PDF...</div>
        <progress className='progressBar' value={percentComplete} max={100} />
      </div>
    </>
  );
};