import React, { useEffect, useRef, useState } from 'react';
import './ComboBox.css';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { KeyObject } from 'crypto';
const uuid = require('uuid');

interface ComboBoxProps {
  options: string[];
  value?: string;
  className?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

let clickingChild = false;
let clickTimeout: NodeJS.Timeout;

export default function ComboBox({ options, className, value, onChange, disabled }: ComboBoxProps) {
  const [inputValue, setInputValue] = useState(value ?? '');
  useEffect(() => {
    setInputValue(inputValue!="" ?inputValue:(value ?? ""));
  }, [value]);
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const inputSearchRef = useRef<HTMLInputElement>(null);

  const [filteredOptions, setFilteredOptions] = useState<string[]>([]);
  const [showSearchBox, setShowSearchBox] = useState(false);

  useEffect(() => {
    filterOptions("");
  }, [options]);

  const handleChange = (e: { target: { value: string }; }) => {
    setInputValue(e.target.value);
    filterOptions(e.target.value);
  };

  const handleSelect = (option: string) => {
    clickingChild = true;
    clickTimeout = setTimeout(() => {
      clickingChild = false;

      // scroll to the inputRef Element
    }, 500);

    //if the bottom of listref.current is off of the screen then scroll it into view
    if (listRef.current) {
      if (listRef.current.getBoundingClientRect().bottom > window.innerHeight) {
        if (inputRef.current) {
          if (inputRef.current.getBoundingClientRect().top < 160) {
            inputRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
          }
        }
      }
    }

    inputRef.current!.value = option;
    setInputValue(option);
    setIsOpen(false);
    onChange(option);
  };

  let uuidv4 = uuid.v4();


  const headerRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      if (inputRef.current) {
        inputRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    }
  },[isOpen,listRef]);

  const toggleDropdown = (e: { stopPropagation: () => void; }) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
    setShowSearchBox(false);
  };

  const filterOptions = (searchText: string) => {
    let sortedOptions = [...options]
    sortedOptions.sort((a, b) => {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    if (searchText.length == 0) {
      setFilteredOptions(sortedOptions);
      return;
    }
    let filteredOptions = sortedOptions.filter((option) => {
      return option.toLowerCase().includes(searchText.toLowerCase());
    });
    if (filteredOptions.length == 0) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
    setFilteredOptions(filteredOptions);
  }

  const shouldShowListAbove = () => {
    //get the bounding rect of the inputRef. if it is greater that 540px from the top, show the list above
    if(inputRef.current){
      let rect = inputRef.current.getBoundingClientRect();
      if(rect.top > 540){
        return true;
      }
    }
    return false;
  }

  return (
    <div className={`ComboBox ${disabled ? "disabled" : ""} ${className ?? ""} ${inputValue == "" && !disabled && "unfilled"}`}>
      <input
        type='text'
        ref={inputRef}
        value={inputValue}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            if (filteredOptions.length > 0) {
              handleSelect(filteredOptions[0]);
            }
          }
          if (e.key == "Tab") {
            setIsOpen(false);
          }
        }}
        onFocus={(e) => {
          //select the text
          e.target.select();
          filterOptions("");
          setIsOpen(true)
        }}
        onBlur={(e) => {

          setTimeout(() => {
            if (!clickingChild) {
              onChange(e.target.value);
            }
          }, 200);

        }}
        onChange={handleChange}
      />
      <div className='buttonArrow' onClick={() => {
        if (isOpen == false) {
          //inputRef.current?.focus();
          setTimeout(() => {
            inputSearchRef.current?.focus();
          }, 100);
        }
        filterOptions("");
        setShowSearchBox(true);
        setIsOpen(!isOpen);
      }}><FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} /></div>
      {isOpen && (
        <>
          <div className='dropdown-backdrop' onClick={toggleDropdown} onDrag={e =>e.stopPropagation()}></div>
        </>
      )}
      {filteredOptions.length > 0 && (
        <div className={`dropdown-list ${shouldShowListAbove()?"above":""}`}
          ref={listRef}
          style={{ opacity: isOpen ? 1 : 0, pointerEvents: isOpen ? "all" : "none", height: isOpen ? "unset" : 1 }}>
          {(showSearchBox && options.length > 5) && (
            <input type='text'
              placeholder='Search'
              className='searchBox'
              ref={inputSearchRef}
              onKeyDown={(e) => {
                if (e.key == "Enter") {
                  if (filteredOptions.length > 0) {
                    handleSelect(filteredOptions[0]);
                  }
                }
                if (e.key == "Tab") {
                  setIsOpen(false);
                }
              }}
              onChange={(e) => {
                filterOptions(e.target.value);
              }} />
          )}
          {filteredOptions.map((option, index) => (
            <div key={index}
              className={`dropdown-item ${(option == inputValue || (index == 0 && filteredOptions.length != options.length)) ? "selected" : ""}`}
              onClick={() => handleSelect(option)}>
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}