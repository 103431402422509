
import React, { useEffect, useRef, useState } from 'react';
import './ResetableToggle.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import { colors } from '@mui/material';

type ResetableToggleProps = {
  value?: string;
	options: string[];
	finalSelectsAll?: boolean;
  className?: string;
	forceOption?: boolean;
	optionColors?: string[];
  onChange: (newValue: string) => void;
};

const ResetableToggle: React.FC<ResetableToggleProps> = ({ options, optionColors, value, className, forceOption, finalSelectsAll, onChange }) => {
	const [localValue, setLocalValue] = useState<string|undefined>(value);

	const selectedOptionColors = optionColors??["#51C0EC", "#3187C2", "#1E4F8B", "#FFFF00", "#00FFFF", "#FF00FF", "#FFFFFF", "#000000"];

  const [sliderScale, setSliderScale] = useState<number>(0.5);

	const toggleRef = useRef<HTMLDivElement>(null);

	const [sliderLeft, setSliderLeft] = useState<number>(0);
	const [sliderWidth, setSliderWidth] = useState<number>(0);
	const [sliderColor, setSliderColor] = useState<string>("transparent");

	useEffect(() => {
		setLocalValue(value);
		if(value && value != ""){
			setSliderScale(1);
		}else{
			setSliderScale(0.5);
		}
	}, [value]);

	useEffect(() => {
		let found = false;
		if(localValue){
			let index = options.indexOf(localValue);
			let optionElements = toggleRef.current?.querySelectorAll(".option");
			if(optionElements){
				let optionElement = optionElements[index];
				if(optionElement && optionElement instanceof HTMLElement){
					setSliderLeft(optionElement.offsetLeft);
					setSliderWidth(optionElement.offsetWidth);
					setSliderColor(selectedOptionColors[index]);
					found = true;
				}
			}
		}
		if(!found){
			setSliderLeft(0);
			setSliderWidth(0);
			setSliderColor("transparent");
		}
		if(localValue != value){
			onChange(localValue?? "");
		}
	},[localValue, options, selectedOptionColors]);
	
  return (
    <div className={`ResetableToggle ${((localValue === undefined || localValue == "") && forceOption)? "unfilled":""} ${className??""}`} ref={toggleRef}>
      <div className={`slider`}
				style={{
					left:sliderLeft, 
					width:sliderWidth, 
					transform:`scale(${sliderScale})`,
					backgroundColor:sliderColor,
					opacity: (finalSelectsAll && localValue==options[options.length-1]) ? 0: sliderScale*sliderScale*sliderScale
				}}></div>
			{options.map((option, index) => {
				return <button
					key={index}
					onMouseDown={() => setSliderScale(0.5)}
					onMouseUp={() => setSliderScale(1)}
					onClick={(e) => setLocalValue(option)}
					className={`option ${option} ${localValue === option || (finalSelectsAll && localValue==options[options.length-1]) ? 'selected' : ''}`}
					style={finalSelectsAll?{backgroundColor:(localValue==options[options.length-1]?selectedOptionColors[index]:"transparent")}:{}}
				>{option}</button>
			})}
			{(localValue !== "" && !forceOption) && (
				<button
					onClick={(e) => {
						setLocalValue("");
						onChange("");
						setSliderScale(1);
					}}
					className={`option reset`}
				></button>
			)}
    </div>
  );
};

export default ResetableToggle;
