
import React, { useRef, useState, useEffect } from 'react';
import './ScreenSplash.css';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../Redux/ActionCreators';
import { Screens } from '../../Redux/Reducers';
interface ScreenSplashProps{
}
export default function ScreenSplash({  }: ScreenSplashProps) {
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen:ActionCreators.setScreen,},dispatch); 
	useEffect(() => {
		setTimeout(() => {
			AC.setScreen(Screens.Landing);
		}, 2000);
	}, []);

  return (
    <div className='Screen ScreenSplash'>
      <div className='background' />
      <div className='leftBarBottom' />
      <div className='storzLogo'></div>
    </div>
  );
}