
import React, { useRef, useState, useEffect } from 'react';
import './StreamConnectScopeOfWork.css';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import { SOW } from '../../../Model/SOW';
import DepartmentScopeOfWorkRow, { DepartmentScopeOfWork } from '../../../Components/Components_StreamConnect/DepartmentScopeOfWorkRow/DepartmentScopeOfWorkRow';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import StreamConnectSOWRows from '../../../Utils/StreamConnectSOWRows';
interface StreamConnectScopeOfWorkProps {
  sow: SOW;
  isScreenUpgrade: boolean;
  currentSowRowIndex: number;
  forPDF?: boolean;
}
export default function StreamConnectScopeOfWorkUpgrades({ sow, isScreenUpgrade, currentSowRowIndex, forPDF }: StreamConnectScopeOfWorkProps) {
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen: ActionCreators.setScreen,
    setCurrentDepartmentIndex: ActionCreators.setCurrentDepartmentIndex,
  }, dispatch);

  const [isUpgrade, setIsUpgrade] = useState<boolean>(isScreenUpgrade);
  const [scopeOfWorkRows, setScopeOfWOrkRows] = useState<DepartmentScopeOfWork[]>(StreamConnectSOWRows(sow, isUpgrade));
  useEffect(() => {
    setScopeOfWOrkRows(StreamConnectSOWRows(sow, isUpgrade));
  }, [isUpgrade])
  const [currentRowIndex, setCurrentRowIndex] = useState<number>(currentSowRowIndex);

  console.log("ScopeOfWorkScreen currentRowIndex: " + currentRowIndex, isScreenUpgrade)


  const [refreshingNavigationHeader, setRefreshingNavigationHeader] = useState<boolean>(false);
  useEffect(() => {
    if (refreshingNavigationHeader) {
      setTimeout(() => {
        setRefreshingNavigationHeader(false);
      }, 1000);
    }
  }, [refreshingNavigationHeader]);

  const [reloadingScreen, setReloadingScreen] = useState<boolean>(false);
  useEffect(() => {
    if (reloadingScreen) {
      setTimeout(() => {
        setReloadingScreen(false);
      }, 100);
    }
  }, [reloadingScreen]);

  return (
    <>
      {!reloadingScreen && <>

        <div className={`Screen StreamConnectScopeOfWork ${isUpgrade ? "upgrade" : ""}`}>
          <div className='leftBarTop' />
          <div className='verticalLabel'>Scope Of Work</div>
          {(currentRowIndex < 5) ? (
            <>
              <div className='text1'>
                The table below outlines the scope of work for the project.
              </div>
              <div className='text2'>
                It contains the following details:
                <ol>
                  <li>What licenses you have purchased? </li>
                  <li>What departments you are deploying to? </li>
                  <li>Type and quantity of devices you are networking? </li>
                </ol>
              </div>

              <div className='backgroundImage'></div>
              <table className='sowTable pos1'>
                <tbody>
                  {scopeOfWorkRows.map((department, index) => {
                    if (index > 4) {
                      return <></>
                    }
                    return <DepartmentScopeOfWorkRow department={department} key={"row" + index} />
                  })}
                </tbody>
              </table>

              <div className='text3'>
                <div className='leftSide'>
                  Acknowledgements:
                </div>
                <div className='rightSide'>
                  To ensure timely and fully functional setup of NEO IP systems, StreamConnect<sup>®</sup> Servers must be available to KARL STORZ for configuration, 60 days prior to installation date. Additional charges will apply if Servers are not available.
                </div>
              </div>
            </>
          ) : (
            <>

              <div className='text1'>
                The table below outlines the scope of work for the project.
              </div>


              <div className='backgroundImage pos2'></div>
              <table className='sowTable pos2'>
                <tbody>
                  {scopeOfWorkRows.map((department, index) => {
                    if (index < currentRowIndex || index > currentRowIndex + 10) {
                      return <></>
                    }
                    return <DepartmentScopeOfWorkRow department={department} key={"row" + index} />
                  })}
                </tbody>
              </table>
            </>
          )}

          {!forPDF && !refreshingNavigationHeader && (
            <>
              <NavigationHeader
                nextButtonText={() => {
                  let tempSOWPurchaseRows = StreamConnectSOWRows(sow, false);
                  let tempSOWUpgadeRows = StreamConnectSOWRows(sow, true);
                  let rows = tempSOWPurchaseRows;
                  if(isUpgrade){
                    rows = tempSOWUpgadeRows;
                  }
                  if (currentRowIndex == 0) {
                    if (rows.length > 5) {
                      return "Scope of Work (Cont)"
                    }
                  } else {
                    if (rows.length > currentRowIndex + 11) {
                      return "Scope of Work (Cont)"
                    }
                  }
                  if (!isUpgrade) {
                    if (tempSOWUpgadeRows.length > 0) {
                      return "Scope of Work (Upgrade)"
                    }
                  }
                  return "Project Roadmap"
                }}
                onNext={(screen, departmentIndex) => {
                  if (screen == Screens.StreamConnectProjectRoadmap || screen == Screens.StreamConnectScopeOfWorkUpgrade) {

                    let tempSOWPurchaseRows = StreamConnectSOWRows(sow, false);
                    let tempSOWUpgadeRows = StreamConnectSOWRows(sow, true);
                    let rows = tempSOWPurchaseRows;
                    if(isUpgrade){
                      rows = tempSOWUpgadeRows;
                    }
                    if (currentRowIndex == 0) {
                      if (rows.length > 5) {
                        setCurrentRowIndex(5);
                        setRefreshingNavigationHeader(true);
                        return;
                      }
                    } else {
                      if (rows.length > currentRowIndex + 11) {
                        setCurrentRowIndex(currentRowIndex + 11);
                        setRefreshingNavigationHeader(true);
                        return;
                      }
                    }
                    if(!isUpgrade){
                      if(tempSOWUpgadeRows.length > 0){
                        setIsUpgrade(true);
                        setCurrentRowIndex(0);
                        setRefreshingNavigationHeader(true);
                        return;
                      }
                    }
                    AC.setCurrentDepartmentIndex(0);
                    AC.setScreen(screen);

                    // if (currentRowIndex == 0) {
                    //   if (sow.departments.length > 5) {
                    //     setCurrentRowIndex(5);
                    //     setRefreshingNavigationHeader(true);
                    //   } else {
                    //     if (!isUpgrade) {
                    //       let tempSOWRows = StreamConnectSOWRows(sow, true);
                    //       if (tempSOWRows.length > 0) {
                    //         setIsUpgrade(true);
                    //         setCurrentRowIndex(0);
                    //         setRefreshingNavigationHeader(true);
                    //         return;
                    //       }
                    //     }
                    //     AC.setCurrentDepartmentIndex(0);
                    //     AC.setScreen(screen);
                    //   }
                    // } else {
                    //   if (scopeOfWorkRows.length > currentRowIndex + 11) {
                    //     setCurrentRowIndex(currentRowIndex + 11);
                    //     setRefreshingNavigationHeader(true);
                    //   } else {
                    //     AC.setCurrentDepartmentIndex(0);
                    //     AC.setScreen(screen);
                    //   }
                    // }
                  } else {
                    AC.setCurrentDepartmentIndex(departmentIndex);
                    AC.setScreen(screen);
                    setReloadingScreen(true);
                  }
                }}
                sow={sow} />
            </>
          )}
        </div>
      </>}
    </>
  );
}