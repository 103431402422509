
import React, { useRef, useState, useEffect, ReactNode } from 'react';
import './ConfigurationRoomList.css';
import { Config, SOW } from '../../../Model/SOW';
import { SceneObject } from '../../../Model/SceneObject';
import PopupSelectSubtype from '../PopupSelectSubtype/PopupSelectSubtype';
import ConfigurationRoomListItem from '../ConfigurationRoomListItem/ConfigurationRoomListItem';
import PopupNurseDeskSubtype from '../PopupNurseDeskSubtype/PopupNurseDeskSubtype';
import PopupOtherSubtype from '../PopupOtherSubtype/PopupOtherSubtype';
import { blankSceneObject } from '../../../Utils/BlankObjects';
import PopupOtherSubtype2 from '../PopupOtherSubtype/PopupOtherSubtype2';
interface ConfigurationRoomListProps {
	sceneObjects: SceneObject[];
	selectedSceneObjectID: string;
	forPDF: boolean;
	minLabel?: string;
	maxLabel?: string;
	minIndex?: number;
	maxIndex?: number;
	onUpdated(sceneObjects: SceneObject[]): void;
}
export default function ConfigurationRoomList({ sceneObjects, forPDF, selectedSceneObjectID, minLabel, maxLabel, minIndex, maxIndex, onUpdated }: ConfigurationRoomListProps) {
	const [showingPopup, setShowingPopup] = useState(false);
	const [selectedSceneObject, setSelectedSceneObject] = useState<SceneObject | null>(null);

	useEffect(() => {
		if (showingPopup == false) {
			setSelectedSceneObject(null);
		}
	}, [showingPopup])

	return (
		<>
			<div className={`ConfigurationRoomList`}>
				{(() => {
					let list:ReactNode[] = [];

					let index = 0;
					let sortedSceneObjects = [...sceneObjects];
					sortedSceneObjects.sort((a,b) => {
						if(a.subtype.includes("any") && !b.subtype.includes("any")){
							return 1;
						}
						if(!a.subtype.includes("any") && b.subtype.includes("any")){
							return -1;
						}
						if(a.letter == "" && b.letter != ""){
							return 1;
						}
						if(a.letter != "" && b.letter == ""){
							return -1;
						}
						if(a.letter < b.letter){
							return -1;
						}
						if(a.letter > b.letter){
							return 1;
						}
						return 0;
					});
					for(let i =0; i < sortedSceneObjects.length; i++){
						let sceneObject = sortedSceneObjects[i];
						if(sceneObject.addedInRoom){
							continue;
						}
						if(minLabel && (sceneObject.letter < minLabel)){
							continue;
						}
						if(maxLabel && (sceneObject.letter > maxLabel)){
							continue;
						}
						// if(sceneObject.addedInRoom){
						// 	index++;
						// 	continue;
						// }
						// if(minIndex && i < minIndex){
						// 	index++;
						// 	continue;
						// }
						// if(maxIndex && i > maxIndex){
						// 	break;
						// }
						list.push(<ConfigurationRoomListItem
							key={"list_item"+sceneObject.id}
							index={i}
							sceneObject={sceneObject}
							selectedSceneObjectID={selectedSceneObjectID}
							forPDF={forPDF}
							onSelectSubtype={(sceneObject: SceneObject) => {
								setSelectedSceneObject(sceneObject);
								setShowingPopup(true);
							}} />);
					}


					return list;
				})()}
				{(forPDF == false) && (
					<ConfigurationRoomListItem
						index={-1}
						selectedSceneObjectID={selectedSceneObjectID}
						sceneObject="Add Additional Equipment"
						forPDF={forPDF}
						onSelectSubtype={() => {
							setSelectedSceneObject(null);
							setShowingPopup(true);
						}} />
				)}
			</div>
			{showingPopup && selectedSceneObject && selectedSceneObject.type != "desk" && (
				<PopupSelectSubtype
					type={selectedSceneObject!.type}
					subtype={selectedSceneObject!.subtype}
					onSave={(subtype: string) => {
						let newSceneObjects = [...sceneObjects];
						let sceneObject = newSceneObjects.find((sceneObject) => sceneObject.id === selectedSceneObject?.id);
						if (sceneObject) {
							sceneObject.subtype = subtype;
							onUpdated(newSceneObjects);
						}
						setShowingPopup(false);
					}}
					onDismiss={() => {
						setShowingPopup(false);
					}} />
			)}
			{showingPopup && selectedSceneObject && selectedSceneObject.type == "desk" && (
				<PopupNurseDeskSubtype
					type={selectedSceneObject!.type}
					subtype={selectedSceneObject!.subtype}
					onSave={(type: string, subtype: string) => {
						let newSceneObjects = [...sceneObjects];
						let sceneObject = newSceneObjects.find((sceneObject) => sceneObject.id === selectedSceneObject?.id);
						if (sceneObject) {
							sceneObject.type = type;
							sceneObject.subtype = subtype;
							onUpdated(newSceneObjects);
						}
						setShowingPopup(false);
					}}
					onDismiss={() => {
						setShowingPopup(false);
					}} />
			)}
			{showingPopup && selectedSceneObject && selectedSceneObject.type == "any" && (
				<PopupOtherSubtype
					type={selectedSceneObject!.type}
					subtype={selectedSceneObject!.subtype}
					onSave={(type: string, subtype:string, label: string) => {
						let newSceneObjects = [...sceneObjects];
						let sceneObject = newSceneObjects.find((sceneObject) => sceneObject.id === selectedSceneObject?.id);
						if (sceneObject) {
							sceneObject.type = type;
							sceneObject.subtype = subtype;
							onUpdated(newSceneObjects);
						}
						setShowingPopup(false);
					}}
					onDismiss={() => {
						setShowingPopup(false);
					}} />
			)}
			{showingPopup && selectedSceneObject == null && (
				<PopupOtherSubtype
					onSave={(type: string, subtype: string, label: string) => {
						let newSceneObjects = [...sceneObjects];
						let sceneObject = blankSceneObject();
						sceneObject.type = type;
						sceneObject.subtype = subtype;
						sceneObject.name = label;
						sceneObject.addedInRoom = true;
						newSceneObjects.push(sceneObject);

						let count = 0;
						newSceneObjects.forEach((sceneObject,index) => {
							const letter = (count + 10).toString(36).toUpperCase();
							if(sceneObject.addedInRoom == true){
								return;
							}
							if(sceneObject.name.toUpperCase() == "PATIENT LEFT" || 
							sceneObject.name.toUpperCase() == "PATIENT RIGHT" || 
							sceneObject.name.toUpperCase() == "PATIENT HEAD" || 
							sceneObject.name.toUpperCase() == "PATIENT FOOT"){
								return;
							}
							sceneObject.letter = letter;
							count++;
						});
						onUpdated(newSceneObjects);
						setShowingPopup(false);
					}}
					onDismiss={() => {
						setShowingPopup(false);
					}} />

			)}
		</>
	);
}