
import React, { useRef, useState, useEffect } from 'react';
import './StreamConnectProjectRoadmap.css';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';
import { SOW } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
interface StreamConnectProjectRoadmapProps {
  sow: SOW;
  currentDepartmentIndex: number;
  forPDF?: boolean;
}
export default function StreamConnectProjectRoadmap({ sow, currentDepartmentIndex, forPDF }: StreamConnectProjectRoadmapProps) {
  const dispatch = useDispatch();
  const AC = bindActionCreators({
    setScreen: ActionCreators.setScreen,
  }, dispatch);

  return (
    <div className='Screen StreamConnectProjectRoadmap'>
      <div className='backgroundImage' />
      <div className='leftBarTop' />
      <div className='verticalLabel'>Project Roadmap</div>

      <div className='text1'>Step by step process to deploy the StreamConnect<sup>®</sup> system on your network, including a breakdown of roles and responsibilities.</div>
      {/* <div className='youAreHere' /> */}

      {/* <div className='date date1'>{sow.dateInitiate}</div>
      <div className='date date2'>{sow.dateDeploy}</div>
      <div className='date date3'>{sow.dateConfigure}</div>
      <div className='date date4'>{sow.dateActivate}</div>
      <div className='date date5'>{sow.dateTrain}</div>
      <div className='date date6'>{sow.dateSuccess}</div> */}
      <div className='date date1'>150 Days</div>
      <div className='date date2'>90-120 Days</div>
      <div className='date date3'>35 Days</div>
      <div className='date date4'>15 Days</div>
      <div className='date date5'>0 Days</div>
      <div className='date date6'>+7 Days</div>

      {!forPDF && (
        <>
          <NavigationHeader
            sow={sow} />
        </>
      )}
    </div>
  );
}