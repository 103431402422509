


import React, { useRef, useState, useEffect } from 'react';
import './DepartmentLicensingArea.css';
import { Config, Department } from '../../../Model/SOW';
import ComboBox from '../../ComboBox/ComboBox';
import { CAPTURE_OPTIONS, CHECKLIST_LOCATIONS, LOCATIONS, MULTIVIEW_OPTIONS, SYSTEM_OPTIONS, TOMS_OPTIONS, VOD_DEVICES } from '../../../Model/DropdownOptions';
import Dropdown from '../../Dropdown/Dropdown';
import Toggle from '../../Toggle/Toggle';
import ResetableToggle from '../ResetableToggle/ResetableToggle';
import NumberInput from '../NumberInput/NumberInput';
interface DepartmentLicensingAreaProps {
	department: Department;

	licensingVideoOnDemand: "Purchase" | "Upgrade"| "Existing" | "";
	licensingEHRLINC: "Purchase" | "Upgrade"| "Existing" | "";
	licensingLive: "Purchase" | "Upgrade"| "Existing" | "";
	licensingRoomToRoom: "Purchase" | "Upgrade"| "Existing" | "";
	licensingTOMS: "Purchase" | "Upgrade" | "Existing" | "";
	licensingDashboards: "Purchase" | "Upgrade"| "Existing" | "";

	onChange: (department: Department) => void;
}
export default function DepartmentLicensingArea({ department, licensingVideoOnDemand, licensingEHRLINC, licensingLive, licensingRoomToRoom, licensingTOMS, licensingDashboards, onChange }: DepartmentLicensingAreaProps) {

	const [userChanged, setUserChanged] = useState<boolean>(false);
	const [vodDevices, setVODDevices] = useState<string>(department.vodDevices);
	const [vodQuantityAIDA, setVODQuantityAIDA] = useState<number>(department.vodQuantityAIDA);
	const [vodQuantityTP, setVODQuantityTP] = useState<number>(department.vodQuantityTP);
	const [ehrLINCWorklist, setEHRLINCWorklist] = useState<boolean>(department.ehrLINCWorklist);
	const [ehrLINCArchive, setEHRLINCArchive] = useState<boolean>(department.ehrLINCArchive);
	const [liveEncoderQuantity, setLiveEncoderQuantity] = useState<number>(department.liveEncoderQuantity);
	const [roomToRoomDecoderQuantity, setRoomToRoomDecoderQuantity] = useState<number>(department.roomToRoomDecoderQuantity);
	const [tomsCameraQuantity, setTOMsCameraQuantity] = useState<number>(department.tomsCameraQuantity);
	const [tomsPlateQuantity, setTOMsPlateQuantity] = useState<number>(department.tomsPlateQuantity);
	const [tomsPofEQuantity, setTOMsPofEQuantity] = useState<number>(department.tomsPofEQuantity);
	const [dashboardsBiomed, setDashboardsBiomed] = useState<boolean>(department.dashboardsBiomed);
	const [dashboardsDevice, setDashboardsDevice] = useState<boolean>(department.dashboardsDevice);

	useEffect(() => {
		setVODDevices(department.vodDevices);
		setVODQuantityAIDA(department.vodQuantityAIDA);
		setVODQuantityTP(department.vodQuantityTP);
		setEHRLINCWorklist(department.ehrLINCWorklist);
		setEHRLINCArchive(department.ehrLINCArchive);
		setLiveEncoderQuantity(department.liveEncoderQuantity);
		setRoomToRoomDecoderQuantity(department.roomToRoomDecoderQuantity);
		setTOMsCameraQuantity(department.tomsCameraQuantity);
		setTOMsPlateQuantity(department.tomsPlateQuantity);
		setTOMsPofEQuantity(department.tomsPofEQuantity);
		setDashboardsBiomed(department.dashboardsBiomed);
		setDashboardsDevice(department.dashboardsDevice);
	}, [department]);

	useEffect(() => {
		if (userChanged) {
			department.vodDevices = vodDevices;
			department.vodQuantityAIDA = licensingVideoOnDemand!=""?vodQuantityAIDA:0;
			department.vodQuantityTP = licensingVideoOnDemand!=""?vodQuantityTP:0;
			department.ehrLINCWorklist = licensingEHRLINC!=""?ehrLINCWorklist:false;
			department.ehrLINCArchive = licensingEHRLINC!=""?ehrLINCArchive:false;
			department.liveEncoderQuantity = ((licensingLive)!=""||(licensingRoomToRoom)!="")?liveEncoderQuantity:0;
			department.roomToRoomDecoderQuantity = licensingRoomToRoom!=""?roomToRoomDecoderQuantity:0;
			department.tomsCameraQuantity = licensingTOMS!=""?tomsCameraQuantity:0;
			department.tomsPlateQuantity = licensingTOMS!=""?tomsPlateQuantity:0;
			department.tomsPofEQuantity = licensingTOMS!=""?tomsPofEQuantity:0;
			department.dashboardsBiomed = licensingDashboards!=""?dashboardsBiomed:false;
			department.dashboardsDevice = licensingDashboards!=""?dashboardsDevice:false;

			onChange(department);
		}
	}, [userChanged, vodDevices, vodQuantityAIDA, vodQuantityTP, ehrLINCWorklist, ehrLINCArchive, liveEncoderQuantity, roomToRoomDecoderQuantity, tomsCameraQuantity, tomsPlateQuantity, tomsPofEQuantity, dashboardsBiomed, dashboardsDevice, licensingVideoOnDemand, licensingEHRLINC, licensingLive, licensingRoomToRoom, licensingTOMS, licensingDashboards]);


	// export interface Department {
	// 	id: string;
	// 	name: string;
	// 	operatingRoomNumbers: string;
	// 	licensingVideoOnDemand: "Purchase"|"Upgrade"|"";
	// 	licensingEHRLINC: "Purchase"|"Upgrade"|"";
	// 	licensingLive: "Purchase"|"Upgrade"|"";
	// 	licensingRoomToRoom: "Purchase"|"Upgrade"|"";
	// 	licensingTOMS: "Purchase"|"Upgrade"|"";
	// 	licensingDashboards: "Purchase"|"Upgrade"|"";
	// 	vodDevices: string;
	// 	vodQuantity: number;
	// 	ehrLINCWorklist: boolean;
	// 	ehrLINCArchive: boolean;
	// 	liveEncoderQuantity: number;
	// 	roomToRoomDecoderQuantity: number;
	// 	tomsCameraQuantity: number;
	// 	tomsPlateQuantity: number;
	// 	tomsPofEQuantity: number;
	// 	dashboardsBiomed: boolean;
	// 	dashboardsDevice: boolean;
	// }

	useEffect(() => {
		if (userChanged) {

		}
	}, [userChanged]);


	let handleChange = (key: string, value: any) => {
		// onChange({
		// 	...config,
		// 	video: {
		// 		...config.video,
		// 		[key]: value
		// 	}
		// } as Config);
		// setReloading(true);
	}

	const [reloading, setReloading] = useState<boolean>(false);
	useEffect(() => {
		if (reloading) {
			setReloading(false);
		}
	}, [reloading]);

	useEffect(() => {
		setReloading(true);
	}, [department]);

	return (
		<div className={`DepartmentLicensingArea`}>
			{reloading == false && (
				<>
					{true && (//{licensingVideoOnDemand != "" && (
						<div className={`section ${(licensingVideoOnDemand)!=""?"":"disabled"}`}>
							<div className='header'>Video On Demand</div>
							{/* <div className={`type ${licensingVideoOnDemand}`}>{licensingVideoOnDemand == "Purchase" ? "NEW license" : licensingVideoOnDemand}</div> */}
							<div className='inputs'>

								<div className='input'>
									<div className='label'>
										AIDA Quantity
									</div>
									<NumberInput
										defaultValue={licensingVideoOnDemand!=""?vodQuantityAIDA:0}
										onChange={(newValue) => {
											setVODQuantityAIDA(newValue);
											setUserChanged(true);
										}} />
								</div>
								<div className='input'>
									<div className='label'>
										Tele Pack Quantity
									</div>
									<NumberInput
										defaultValue={licensingVideoOnDemand!=""?vodQuantityTP:0}
										onChange={(newValue) => {
											setVODQuantityTP(newValue);
											setUserChanged(true);
										}} />
								</div>
							</div>

						</div>
					)}
					{true && (//licensingEHRLINC != "" && (
						<div className={`section ${(licensingEHRLINC)!=""?"":"disabled"}`}>
							<div className='header'><i>ehr</i>LINC</div>
							{/* <div className={`type ${licensingEHRLINC}`}>{licensingEHRLINC == "Purchase" ? "NEW license" : licensingEHRLINC}</div> */}
							<div className='inputs'>
								<div className='input'>
									<div className={`checkbox ${(licensingEHRLINC!=""?ehrLINCWorklist:false) ? "selected" : ""}`} onClick={() => {
										setEHRLINCWorklist(!ehrLINCWorklist);
										setUserChanged(true);
									}}>Worklist</div>
								</div>
								<div className='input'>
									<div className={`checkbox ${(licensingEHRLINC!=""?ehrLINCArchive:false) ? "selected" : ""}`} onClick={() => {
										setEHRLINCArchive(!ehrLINCArchive);
										setUserChanged(true);
									}}>Archive</div>
								</div>
							</div>
						</div>
					)}
					{true && (//licensingLive != "" && (
						<div className={`section ${((licensingLive)!=""||(licensingRoomToRoom)!="")?"":"disabled"}`}>
							<div className='header'>Live</div>
							{/* <div className={`type ${licensingLive}`}>{licensingLive == "Purchase" ? "NEW license" : licensingLive}</div> */}
							<div className='inputs'>
								<div className='input'>
									<div className='label'>
										Encoder Quantity
									</div>
									<NumberInput
										defaultValue={((licensingLive)!=""||(licensingRoomToRoom)!="")?liveEncoderQuantity:0}
										onChange={(newValue) => {
											setLiveEncoderQuantity(newValue);
											setUserChanged(true);
										}} />
								</div>
							</div>
						</div>
					)}
					{true && (//licensingRoomToRoom != "" && (
						<div className={`section ${(licensingRoomToRoom)!=""?"":"disabled"}`}>
							<div className='header'>Room to Room</div>
							{/* <div className={`type ${licensingRoomToRoom}`}>{licensingRoomToRoom == "Purchase" ? "NEW license" : licensingRoomToRoom}</div> */}
							<div className='inputs'>
								<div className='input'>
									<div className='label'>
										Decoder Quantity
									</div>
									<NumberInput
										defaultValue={licensingRoomToRoom!=""?roomToRoomDecoderQuantity:0}
										onChange={(newValue) => {
											setRoomToRoomDecoderQuantity(newValue);
											setUserChanged(true);
										}} />
								</div>
							</div>
						</div>
					)}
					{true && (//licensingTOMS != "" && (
						<div className={`section ${(licensingTOMS)!=""?"":"disabled"}`}>
							<div className='header'>TOMs</div>
							{/* <div className={`type ${licensingTOMS}`}>{licensingTOMS == "Purchase" ? "NEW license" : licensingTOMS}</div> */}
							<div className='inputs'>
								<div className='input'>
									<div className='label'>
										Camera Quantity
									</div>
									<NumberInput
										defaultValue={licensingTOMS!=""?tomsCameraQuantity:0}
										onChange={(newValue) => {
											setTOMsCameraQuantity(newValue);
											setUserChanged(true);
										}} />
								</div>
								<div className='input'>
									<div className='label'>
										Plate Quantity
									</div>
									<NumberInput
										defaultValue={licensingTOMS!=""?tomsPlateQuantity:0}
										onChange={(newValue) => {
											setTOMsPlateQuantity(newValue);
											setUserChanged(true);
										}} />
								</div>
								<div className='input'>
									<div className='label'>
										PoE Quantity
									</div>
									<NumberInput
										defaultValue={licensingTOMS!=""?tomsPofEQuantity:0}
										onChange={(newValue) => {
											setTOMsPofEQuantity(newValue);
											setUserChanged(true);
										}} />
								</div>
							</div>
						</div>
					)}
					{true && (//licensingDashboards != "" && (
						<div className={`section ${(licensingDashboards)!=""?"":"disabled"}`}>
							<div className='header'>Dashboards Licenses</div>
							{/* <div className={`type ${licensingDashboards}`}>{licensingDashboards == "Purchase" ? "NEW license" : licensingDashboards}</div> */}

							<div className='inputs'>
								<div className='input'>
									<div className={`checkbox ${(licensingDashboards!=""?dashboardsBiomed:false) ? "selected" : ""}`} onClick={() => {
										setDashboardsBiomed(!dashboardsBiomed);
										setUserChanged(true);
									}}>Biomed Dashboard</div>
								</div>
								<div className='input'>
									<div className={`checkbox ${(licensingDashboards!=""?dashboardsDevice:false) ? "selected" : ""}`} onClick={() => {
										setDashboardsDevice(!dashboardsDevice);
										setUserChanged(true);
									}}>Device Utilization</div>
								</div>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	);
}