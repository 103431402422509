import { Dispatch } from "react"
import { Screens } from "./Reducers"
import { SOW } from "../Model/SOW"
import { saveSOW } from "../Utils/EmailSaveAndLoad"
import { KarlStorzUser } from "../Model/KarlStorzUser"

export type StringAction = {
	type: string,
	payload: string
}
export type BooleanAction = {
	type: string,
	payload: boolean
}
export type NumberAction = {
	type: string,
	payload: number
}
export type ScreenAction = {
	type: string,
	payload: Screens
}

export type SOWAction = {
	type: string,
	payload: SOW | null
}

export type UserAction = {
	type: string,
	payload: KarlStorzUser | null
}

export function setScreen(screen: Screens) {
	return (dispatch: Dispatch<ScreenAction>) => {
		dispatch({
			type: "setScreen",
			payload: screen
		})
	}
}

export function setLoggedInUser(user: KarlStorzUser | null) {
	return (dispatch: Dispatch<UserAction>) => {
		dispatch({
			type: "setLoggedInUser",
			payload: user
		})
	}
}

export function setCurrentConfigIndex(index: number) {
	return (dispatch: Dispatch<NumberAction>) => {
		dispatch({
			type: "setCurrentConfigIndex",
			payload: index
		})
	}
}
export function setCurrentDepartmentIndex(index: number) {
	return (dispatch: Dispatch<NumberAction>) => {
		dispatch({
			type: "setCurrentDepartmentIndex",
			payload: index
		})
	}
}

export function setPrintingPDF(printingPDF: boolean) {
	return (dispatch: Dispatch<BooleanAction>) => {
		dispatch({
			type: "setPrintingPDF",
			payload: printingPDF
		})
	}
}

var saveSOWTimeout: NodeJS.Timeout | null = null;
export function setSOW(sow: SOW) {
	if (saveSOWTimeout) {
		clearTimeout(saveSOWTimeout);
	}
	saveSOWTimeout = setTimeout(() => {
		saveSOW(sow);
	}, 1000);
	return (dispatch: Dispatch<SOWAction>) => {
		dispatch({
			type: "setSOW",
			payload: sow
		})
	}
}


export default {
	setScreen,
	setSOW,
	setCurrentConfigIndex,
	setCurrentDepartmentIndex,
	setPrintingPDF,
	setLoggedInUser
}