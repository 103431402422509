
import React, { useRef, useState, useEffect } from 'react';
import './AdminSalesRegionRow.css';
import { SalesRegion } from '../../../Model/SalesRegion';
import { deleteDoc, doc, setDoc } from 'firebase/firestore';
import { sharedFirestore } from '../../../Utils/SharedFirebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
interface AdminSalesRegionRowProps {
	salesRegion: SalesRegion;
	onDelete: (salesRegion: SalesRegion) => void;
}
export default function AdminSalesRegionRow({ salesRegion, onDelete }: AdminSalesRegionRowProps) {
	const [regionName, setRegionName] = useState(salesRegion.name);
	const [userChanged, setUserChanged] = useState(false);
	const [showingMenuPopup, setShowingMenuPopup] = useState(false);
	const [editing, setEditing] = useState(salesRegion.name == "");
	useEffect(() => {
		if (userChanged) {
			let docRef = doc(sharedFirestore, "SalesRegions", salesRegion.id);
			//partial update doc
			if(regionName != salesRegion.name){
				setDoc(docRef, {
					name: regionName,
				}, { merge: true });
				setUserChanged(false);
			}
		}
	}, [userChanged, regionName, salesRegion]);
	return (
		<div className={`AdminSalesRegionRow `}>
			{editing || (regionName == "") ? <>
				<input type='text' 
					value={regionName} 
					onChange={(event) => {
						setRegionName(event.target.value);
						setUserChanged(true);
					}}
					onKeyDown={(e) =>{
						if(e.key == 'Enter'){
							setEditing(false);
						}
					}}></input>
				<div className='buttonDone' onClick={() => {
					setEditing(false);
				}} >Done</div>
			</>:<>
				{regionName}
				<div className='buttonDots' onClick={() => {
					setShowingMenuPopup(!showingMenuPopup);
					//onDelete(salesRegion);
				}} ></div>
			</>}
			{showingMenuPopup && <>
				<div className='menuPopup'>
					<div className='option' onClick={() => {
						setEditing(true);
						setShowingMenuPopup(false);
					}}>Edit</div>
					<div className='option' onClick={() => {
						onDelete(salesRegion);
						setShowingMenuPopup(false);
					}}>Delete</div>
				</div>
			</>}
		</div>
	);
}