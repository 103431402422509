

import React, { useEffect, useRef, useState } from 'react';
import { SceneObject } from '../../../Model/SceneObject';
import './BoomDraggableSceneObject.css';
import { faAnchor, faArrows, faArrowsLeftRight, faBars, faCheck, faChevronDown, faCircleNodes, faEye, faEyeSlash, faHamburger, faLock, faPlus, faRotate, faTrash, faUnlock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface BoomDraggableSceneObjectProps {
	roomScale: number;
	sceneObject: SceneObject;
	selected: boolean;
	canRotate: boolean;
	canDrag: boolean;
	canHide: boolean;
	canDelete: boolean;
	canFlip: boolean;
	canLock: boolean;
	canDropDown: boolean;
	hidingHiddenObjects?: boolean;
	onDropDown: (id: string) => void;
	onSelect: (id: string) => void;
	onDelete: (id: string) => void;
	onChange: (id: string, changes: Partial<SceneObject>) => void;
}


const eventsToRemove: ((e: MouseEvent | TouchEvent) => void)[] = [];


export const BoomDraggableSceneObject: React.FC<BoomDraggableSceneObjectProps> = ({
	roomScale,
	sceneObject,
	selected,
	canRotate,
	canDropDown,
	canDrag,
	canDelete,
	canFlip,
	canHide,
	canLock,
	hidingHiddenObjects,
	onDropDown,
	onSelect,
	onDelete,
	onChange
}) => {

	const [interactionMode, setInteractionMode] = useState<'move' | 'rotate' | "">('');
	const [parentElement, setParentElement] = useState<HTMLElement | null>(null);
	const buttonRotateRef = useRef<HTMLButtonElement>(null);
	const buttonDeleteRef = useRef<HTMLButtonElement>(null);
	const draggableSceneObjectRef = useRef<HTMLDivElement>(null);

	var onMouseUp = (e: MouseEvent | TouchEvent) => {
	}
	var onDragStart = (e: MouseEvent | TouchEvent) => {
	}
	var onRotateStart = (e: MouseEvent | TouchEvent) => {
	}
	var onDrag = (e: MouseEvent | TouchEvent) => {

	}
	var onRotate = (e: MouseEvent | TouchEvent) => {
	}

	useEffect(() => {
		if (interactionMode == "") {
			setInteractionMode("move");
		}
	}, [selected]);


	useEffect(() => {
		if (draggableSceneObjectRef.current) {
			let parent = (draggableSceneObjectRef.current as HTMLElement).parentElement!;
			while (!parent.classList.contains('BoomDraggableScene')) {
				parent = parent.parentElement!;
			}
			setParentElement(parent);
		}
	}, [draggableSceneObjectRef.current]);


	const [startRotation, setStartRotation] = useState(0);
	const [startAngle, setStartAngle] = useState(0);
	const [lastAngle, setLastAngle] = useState(0);


	useEffect(() => {

		for (let i = 0; i < eventsToRemove.length; i++) {
			let event = eventsToRemove[i];
			parentElement?.removeEventListener('mousemove', event);
			parentElement?.removeEventListener('mousemove', event);
			parentElement?.removeEventListener('mouseup', event);
			draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
			parentElement?.removeEventListener('touchmove', event);
			parentElement?.removeEventListener('touchmove', event);
			parentElement?.removeEventListener('touchend', event);
			draggableSceneObjectRef.current?.removeEventListener('touchstart', event);
		}

		const getAngleIndDegrees = (e: MouseEvent | TouchEvent) => {
			const rect = draggableSceneObjectRef.current!.getBoundingClientRect();
			const centerX = rect.left + rect.width / 2;
			const centerY = rect.top + rect.height / 2;
			let clientX, clientY = 0;
			if ('touches' in e) {
				clientX = e.touches[0].clientX;
				clientY = e.touches[0].clientY;
			} else {
				clientX = e.clientX;
				clientY = e.clientY;
			}
			const diffX = clientX - centerX;
			const diffY = clientY - centerY;
			let angleInRad = Math.atan2(diffY, diffX);
			let angleInDeg = angleInRad * 180 / Math.PI;
			if (angleInDeg < 0) angleInDeg = 360 + angleInDeg;

			if (lastAngle != 0) {

				let lastRemainder = lastAngle % 360;
				let currentRemainder = angleInDeg % 360;

				if (Math.abs(lastRemainder - currentRemainder) > 180) {
					if (lastRemainder < currentRemainder) {
						angleInDeg = lastAngle - lastRemainder - (360 - currentRemainder);
					} else {
						angleInDeg = lastAngle + (360 - lastRemainder) + currentRemainder;
					}
				}

				if (Math.abs(lastAngle - angleInDeg) > 180) {
					if (lastAngle < angleInDeg) {
						angleInDeg = lastAngle - lastRemainder;
					} else {
						angleInDeg = lastAngle + currentRemainder;
					}
				}
			}
			return angleInDeg;
		}


		if (sceneObject.locked) {
			onDrag = (e: MouseEvent | TouchEvent) => {
				e.stopPropagation();
			}
			onDragStart = (e: MouseEvent | TouchEvent) => {
				e.stopPropagation();
			}
		} else {
			if (interactionMode === 'move') {
				onDrag = (e: MouseEvent | TouchEvent) => {
					e.stopPropagation();

					let clientX, clientY = 0;
					if ('touches' in e) {
						clientX = e.touches[0].clientX;
						clientY = e.touches[0].clientY;
					} else {
						clientX = e.clientX;
						clientY = e.clientY;
					}

					//if x and y are within the bounds of the buttonRotateRef then don't move. change to rotate and return
					if (buttonRotateRef.current) {
						const rect = buttonRotateRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							setInteractionMode("rotate");
							return;
						}
					}

					if (buttonDeleteRef.current) {
						const rect = buttonDeleteRef.current.getBoundingClientRect();
						if (clientX > rect.left && clientX < rect.right && clientY > rect.top && clientY < rect.bottom) {

							onDelete(sceneObject.id);
							return;
						}
					}

					// let x = clientX - parentElement!.offsetLeft;// - startX
					// let y = clientY - parentElement!.offsetTop;// - startY;

					let scale = 1;

					let parent = parentElement;
					while (parent) {
						let transform = "";
						const computedStyle = window.getComputedStyle(parent);
						if (computedStyle) {
							const computedTransform = computedStyle.transform;
							if (computedTransform) {
								transform = computedTransform;
							}
						}
						if (transform) {
							// parse the format 'matrix(0.5, 0, 0, 0.5, 0, 0)'
							const scaleMatch = transform.match(/matrix\((.*), 0, 0, (.*), 0, 0\)/);
							if (scaleMatch) {
								scale *= parseFloat(scaleMatch[1]);
							}
						}
						if (parent.parentElement) {
							parent = parent.parentElement!;
						} else {
							break;
						}
					}

					let x = clientX - parentElement!.offsetLeft * scale;// - startX
					let y = clientY - parentElement!.offsetTop * scale;// - startY;


					x = x / scale;
					y = y / scale;



					let percentX = x / parentElement!.clientWidth * 100;
					let percentY = y / parentElement!.clientHeight * 100;

					if (percentX > 100 || percentX < 0 || percentY > 100 || percentY < 0) {
						return;
					}

					if (sceneObject.minLeft) {
						percentX = Math.max(percentX, sceneObject.minLeft);
					}
					if (sceneObject.maxLeft) {
						percentX = Math.min(percentX, sceneObject.maxLeft);
					}
					if (sceneObject.minTop) {
						percentY = Math.max(percentY, sceneObject.minTop);
					}
					if (sceneObject.maxTop) {
						percentY = Math.min(percentY, sceneObject.maxTop);
					}

					percentX = Math.min(100, Math.max(0, percentX));
					percentY = Math.min(100, Math.max(0, percentY));

					onChange(sceneObject.id, { left: percentX, top: percentY });

				}
				onDragStart = (e: MouseEvent | TouchEvent) => {
					e.stopPropagation();
					parentElement!.addEventListener('mousemove', onDrag);
					parentElement!.addEventListener('mouseup', onMouseUp);
					parentElement!.addEventListener('touchmove', onDrag);
					parentElement!.addEventListener('touchend', onMouseUp);
				}
			} else if (interactionMode === 'rotate') {
				onDrag = (e: MouseEvent | TouchEvent) => { // Rotate
					e.stopPropagation();
					let angleInDeg = getAngleIndDegrees(e);
					setLastAngle(angleInDeg);
					if (startAngle == 0) {
						setStartAngle(angleInDeg);
					}

					let deltaAngle = (angleInDeg - startAngle);

					onChange(sceneObject.id, { rotation: startRotation + deltaAngle });
				}
				onDragStart = (e: MouseEvent | TouchEvent) => { // Rotate
					e.stopPropagation();
					setStartRotation(sceneObject.rotation)

					let angleInDeg = getAngleIndDegrees(e);

					setStartAngle(angleInDeg);
					setLastAngle(angleInDeg);
					parentElement!.addEventListener('mousemove', onDrag);
					parentElement!.addEventListener('mouseup', onMouseUp);
					parentElement!.addEventListener('touchmove', onDrag);
					parentElement!.addEventListener('touchend', onMouseUp);
				}
			}
		}


		onMouseUp = (e: MouseEvent | TouchEvent) => {
			parentElement?.removeEventListener('mousemove', onDrag);
			parentElement?.removeEventListener('mousemove', onRotate);
			parentElement?.removeEventListener('mouseup', onMouseUp);
			parentElement?.removeEventListener('touchmove', onDrag);
			parentElement?.removeEventListener('touchmove', onRotate);
			parentElement?.removeEventListener('touchend', onMouseUp);
			for (let i = 0; i < eventsToRemove.length; i++) {
				let event = eventsToRemove[i];
				parentElement?.removeEventListener('mousemove', event);
				parentElement?.removeEventListener('mouseup', event);
				parentElement?.removeEventListener('touchmove', event);
				parentElement?.removeEventListener('touchend', event);
				// draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
			}
		}


		eventsToRemove.push(onDrag);
		eventsToRemove.push(onRotate);
		eventsToRemove.push(onMouseUp);
		eventsToRemove.push(onDragStart);
		eventsToRemove.push(onRotateStart);


		if (selected && !sceneObject.locked && interactionMode != "") {
			draggableSceneObjectRef.current?.addEventListener('mousedown', onDragStart);
			draggableSceneObjectRef.current?.addEventListener('touchstart', onDragStart);
		}
		return () => {
			if (parentElement) {
				for (let i = 0; i < eventsToRemove.length; i++) {
					let event = eventsToRemove[i];
					parentElement?.removeEventListener('mousemove', event);
					parentElement?.removeEventListener('mousemove', event);
					parentElement?.removeEventListener('mouseup', event);
					draggableSceneObjectRef.current?.removeEventListener('mousedown', event);
					parentElement?.removeEventListener('touchmove', event);
					parentElement?.removeEventListener('touchmove', event);
					parentElement?.removeEventListener('touchend', event);
					draggableSceneObjectRef.current?.removeEventListener('touchstart', event);
				}
			}

		}
	}, [selected, draggableSceneObjectRef.current, sceneObject.locked, interactionMode, parentElement]);


	const handleSelect = (event: { stopPropagation: () => void; }) => {
		event.stopPropagation();
		if (!selected) {
			onSelect(sceneObject.id);
		}
	}

	const handleDelete = (event: { stopPropagation: () => void; }) => {
		event.stopPropagation();
		onDelete(sceneObject.id);
	}


	const toggleLocked = () => {
		onChange(sceneObject.id, { locked: !sceneObject.locked });
		setInteractionMode("");
	}
	const toggleVisible = () => {
		onChange(sceneObject.id, { visible: !sceneObject.visible });
	}

	const toggleFlipped = () => {
		onChange(sceneObject.id, { flipped: !flipped });
		setInteractionMode("");
	}

	const [flipped, setFlipped] = useState(sceneObject.flipped);
	useEffect(() => {
		setFlipped(sceneObject.flipped);
	}, [sceneObject.flipped]);

	return (
		<div
			className={`BoomDraggableSceneObject ${sceneObject.type} ${sceneObject.subtype} ${flipped ? 'flipped' : ''} ${selected ? 'selected' : ''} ${(selected && sceneObject.locked == false) && `${interactionMode == "move" ? "moving" : ""} ${interactionMode == "rotate" ? "rotating" : ""}`} ${(sceneObject.visible == false && hidingHiddenObjects) ? "hidden" : ""}`}
			onClick={handleSelect}
			ref={draggableSceneObjectRef}
			style={{
				transform: `translate(-50%, -50%) rotate(${sceneObject.rotation}deg) scale(${(flipped ? -1 : 1) * (1 + (roomScale * -0.5))} , ${1 + (roomScale * -0.5)})`,
				left: `${sceneObject.left}%`,
				top: `${sceneObject.top}%`,
				opacity: `${sceneObject.visible ? 1 : 0.25}`,
			}}>
			<div className="content">
				{sceneObject.name}
			</div>

			{selected &&
				<>
					{canDropDown && !sceneObject.locked && !sceneObject.noDropdown && <button className='buttonDropdown' onClick={() => { onDropDown(sceneObject.id) }}><FontAwesomeIcon icon={faChevronDown} /></button>}
					{canDrag && !sceneObject.locked && !sceneObject.noDrag && <button onClick={() => setInteractionMode("move")} className={`buttonMove ${interactionMode == "move" ? "selected" : ""}`}></button>}
					{canRotate && !sceneObject.locked && !sceneObject.noRotation && <button onClick={() => setInteractionMode("rotate")} className={`buttonRotate ${interactionMode == "rotate" ? "selected" : ""}`} ref={buttonRotateRef}></button>}
					{canFlip && <button className='buttonFlip' onClick={toggleFlipped}></button>}
					{canDelete && !sceneObject.locked && !sceneObject.noDelete && <button className='buttonDelete' onClick={handleDelete} ref={buttonDeleteRef}></button>}
					{canHide && !sceneObject.locked && !sceneObject.noHide && <button className='buttonHide' onClick={toggleVisible}>
						{sceneObject.visible ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
					</button>}
					{canLock && <button className='buttonLock' onClick={toggleLocked}>
						{sceneObject.locked ? <FontAwesomeIcon icon={faLock} /> : <FontAwesomeIcon icon={faUnlock} />}
					</button>}
				</>
			}
		</div>
	);
}