
import React, { useRef, useState, useEffect } from 'react';
import './ScreenORConfigSetup.css';
import { Config, SOW, getDuplicateConfig } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import ConfigurationTab from '../../../Components/Components_OR/ConfigurationTab/ConfigurationTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ConfigurationTabContent from '../../../Components/Components_OR/ConfigurationTabContent/ConfigurationTabContent';
import { saveSOW } from '../../../Utils/EmailSaveAndLoad';
import { blankConfig } from '../../../Utils/BlankObjects';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';

import leftBar from '../../../Images/ui/left_bar@2x.png';
import leftBarTop from '../../../Images/ui/left_bar_top@2x.png';
import leftBarBottom from '../../../Images/ui/left_bar_bottom@2x.png';
import rebuildRoomConfiguratorSceneObjects from '../../../Utils/RebuildRoomConfiguratorSceneObjects';
import PopupConfirm from '../../../Components/PopupConfirm/PopupConfirm';
import { JsxElement } from 'typescript';
import { INPUTS, INPUTS_OFE } from '../../../Model/DropdownOptions';

interface ScreenORConfigSetupProps {
	sow: SOW;
}

let confirmPopupCallback = () => {

}

export default function ScreenORConfigSetup({ sow }: ScreenORConfigSetupProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
	}, dispatch);

	const [localSOW, setLocalSOW] = useState<SOW>(sow);
	const currentConfigIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)


	const [showingUnfilled, setShowingUnfilled] = useState<boolean>(false);
	const [configs, setConfigs] = useState<Config[]>(localSOW.configs);
	const [selectedConfig, setSelectedConfig] = useState<Config>(configs[currentConfigIndex]);
	const [selectedConfigName, setSelectedConfigName] = useState<string | undefined>(selectedConfig?selectedConfig.configName??undefined:undefined);

	const [switchingConfigs, setSwitchingConfigs] = useState<boolean>(false);
	const [refreshingTabBar, setRefreshingTabBar] = useState<boolean>(false);

	const [showingConfirmPopup, setShowingConfirmPopup] = useState<boolean>(false);
	const [confirmPopupText, setConfirmPopupText] = useState<string | JSX.Element>("");
	const [confirmPopupCancelText, setConfirmPopupCancelText] = useState<string | undefined>(undefined);
	const [confirmPopupConfirmText, setConfirmPopupConfirmText] = useState<string | undefined>(undefined);

	const canNavigate = (configs: Config[]) => {
		let unfilled = false;
		configs.forEach(config => {
			if (config.operatingRoomNumbers == "") {
				unfilled = true;
			}
			if (config.boomVendor == "") {
				unfilled = true;
			}
			if (config.video.system == "") {
				unfilled = true;
			}
			if (config.video.lightingControl === undefined) {
				unfilled = true;
			}
			if (config.video.roomToRoom === undefined) {
				unfilled = true;
			}
			if (config.video.chat === undefined) {
				unfilled = true;
			}
			if (config.video.deviceControl === undefined) {
				unfilled = true;
			}
			if (config.video.multiview == "") {
				unfilled = true;
			}
			if (config.video.capture == "") {
				unfilled = true;
			}
			if (config.video.streaming === undefined) {
				unfilled = true;
			}
			if (config.video.conferencing === undefined) {
				unfilled = true;
			}
			if (config.video.toms == "") {
				unfilled = true;
			}
			if (config.video.checklistDashboard == "") {
				unfilled = true;
			}
			config.video.inputs.forEach(input => {
				if (input.inputsTPButtonText == "") {
					unfilled = true;
				}
				if (input.icon == "") {
					unfilled = true;
				}
				if (input.signalOriginates == "") {
					unfilled = true;
				}
				if (input.kvm === undefined) {
					unfilled = true;
				}

				if (INPUTS_OFE.includes(input.inputsTPButtonText) ||
					INPUTS.includes(input.inputsTPButtonText) == false) {
					if (input.ofeSignalType == "") {
						unfilled = true;
					}
				}

			});
			config.video.outputs.forEach(output => {
				if (output.outputsButtonText == "") {
					unfilled = true;
				}
				if (output.icon == "") {
					unfilled = true;
				}
				if (output.signalLocation == "") {
					unfilled = true;
				}
			});
			config.audio.inputs.forEach(input => {
				if (input.inputsTPButtonText == "") {
					unfilled = true;
				}
				if (input.signalOriginates == "") {
					unfilled = true;
				}
			});
			config.audio.outputs.forEach(output => {
				if (output.outputsTPButtonText == "") {
					unfilled = true;
				}
				if (output.signalLocation == "") {
					unfilled = true;
				}
			});
		});

		return !unfilled;
	}


	useEffect(() => {
		if (configs.length != localSOW.configs.length) {
			if (configs.length > 0 && !selectedConfig) {
				console.log("localSOW updated");
				setSelectedConfig(configs[0]);
			}
		}
	}, [localSOW, localSOW.configs]);
	useEffect(() => {
		console.log("configs updated")
		if (selectedConfig) {
			let found = configs.find(c => c.id == selectedConfig.id) !== undefined;
			if (!found) {
				setSelectedConfig(configs[0]);
			}
		}
	}, [configs, selectedConfig]);

	useEffect(() => {
		console.log("config selected")
		setSwitchingConfigs(true);
	}, [selectedConfig]);

	useEffect(() => {
		if (switchingConfigs) {
			setTimeout(() => {
				console.log("end switching configs")
				setSwitchingConfigs(false);
			}, 10);
		}
	}, [switchingConfigs]);

	useEffect(() => {
		if (refreshingTabBar) {
			setTimeout(() => {
				setRefreshingTabBar(false);
			}, 10);
		}
	}, [refreshingTabBar]);

	return (
		<>
			<div className={`Screen ScreenORConfigSetup ${showingUnfilled ? "showingUnfilled" : ""}`}>
				<div className='leftBar' />
				<div className='verticalLabel'>Configurations Setup</div>
				<div className='configSetupheader'>
					{sow.hospitalName}
					{!refreshingTabBar &&
						<div className='tabBar'>
							{configs.map((config, index) => {
								return <ConfigurationTab
									key={config.id}
									configNum={index + 1}
									config={config}
									canDuplicate={configs.length < 9}
									selected={config.id == selectedConfig?.id}
									onChange={(config) => {
										const index = localSOW.configs.findIndex(c => c.id == config.id);
										localSOW.configs[index] = config;
										saveSOW(localSOW).then(() => {
											setConfigs(localSOW.configs);
											setSwitchingConfigs(true);
											//setSelectedConfig(config);
										});
									}}
									onSelect={(config) => {
										AC.setCurrentConfigIndex(index);
										//setSelectedConfig(config);
									}}
									onDuplicate={config => {
										let duplicatedConfig = getDuplicateConfig(config);
										let newIndex = index + 1;
										localSOW.configs.splice(newIndex, 0, duplicatedConfig);
										saveSOW(localSOW).then(() => {
											setConfigs(localSOW.configs);
											AC.setCurrentConfigIndex(newIndex);
											//setSelectedConfig(duplicatedConfig);
										});
									}} />;
							})}
							{configs.length < 9 && <>
								<div className="buttonAddConfig"
									onClick={(e) => {
										e.stopPropagation();
										let config = blankConfig();
										localSOW.configs.push(config);
										saveSOW(localSOW).then(() => {
											setConfigs(localSOW.configs);
											AC.setCurrentConfigIndex(localSOW.configs.length - 1);
											//setSelectedConfig(config);
										});
									}} />
							</>}
						</div>}
				</div>
				{switchingConfigs == false && (
					<>
						<ConfigurationTabContent
							config={selectedConfig!}
							configNumber={configs.findIndex(c => c.id == selectedConfig?.id) + 1}
							onConfigChange={config => {
								const index = localSOW.configs.findIndex(c => c.id == config.id);

								if (config.configName != selectedConfigName) {
									setRefreshingTabBar(true);
									setSelectedConfigName(config.configName);
								}

								localSOW.configs[index] = config;
								rebuildRoomConfiguratorSceneObjects(localSOW).then((sow: SOW) => {
									setLocalSOW(sow);
									//AC.setSOW(sow);
									//setConfigs([...sow.configs]);
								});
							}}
							onConfigDeleted={(config) => {
								setConfirmPopupText(<>Delete <b>{config.configName??("Configuration " + configs.findIndex(c => c.id == config.id) + 1)}?</b></>);
								setConfirmPopupCancelText("Don't Delete");
								setConfirmPopupConfirmText("Yes, Delete");
								confirmPopupCallback = (() => {
									const index = localSOW.configs.findIndex(c => c.id == config.id);
									localSOW.configs.splice(index, 1);
									setLocalSOW(localSOW);
									//AC.setSOW(sow);
									setConfigs(localSOW.configs);
									let newIndex = localSOW.configs.length < index ? index : index - 1
									AC.setCurrentConfigIndex(newIndex);
									//setSelectedConfig(localSOW.configs.length < index?localSOW.configs[index]:localSOW.configs[index-1]);
								});
								setShowingConfirmPopup(true);
							}}></ConfigurationTabContent>
					</>
				)}
			</div>
			<NavigationHeader
				sow={localSOW}
				hideConfigDropdown={true}
				beforeNavigateAway={() => {
					AC.setSOW(localSOW);
				}}
				onNext={(screen?: Screens, configIndex?: number) => {
					if (canNavigate(localSOW.configs)) {
						if (screen !== undefined && configIndex !== undefined) {
							AC.setCurrentConfigIndex(configIndex);
							AC.setScreen(screen);
						}
						else {
							AC.setScreen(Screens.ORContents);
						}
					} else {
						setShowingUnfilled(true);
						setConfirmPopupText("Please fill out all entries")
						setConfirmPopupCancelText(undefined);
						setConfirmPopupConfirmText("Okay");
						confirmPopupCallback = (() => {
							setShowingConfirmPopup(false);
						});
						setShowingConfirmPopup(true);
					}
				}} />
			{showingConfirmPopup && (
				<PopupConfirm
					text={confirmPopupText}
					cancelText={confirmPopupCancelText}
					confirmText={confirmPopupConfirmText}
					onCancel={() => {
						setShowingConfirmPopup(false);
					}}
					onConfirm={() => {
						setShowingConfirmPopup(false);
						if (confirmPopupCallback) {
							confirmPopupCallback();
						}
					}} />
			)}
		</>
	);
}