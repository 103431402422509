
import React, { useRef, useState, useEffect } from 'react';
import './ConfigurationAudioOutputs.css';
import { AudioOutput, Config, VideoOutput } from '../../../Model/SOW';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { blankAudioInput, blankAudioOutput } from '../../../Utils/BlankObjects';
import AudioInputRow from '../AudioInputRow/AudioInputRow';
import AudioOutputRow from '../AudioOutputRow/AudioOutputRow';
import DraggableTableBody from '../../DraggableTableBody/DraggableTableBody';
interface ConfigurationAudioOutputsProps {
	config: Config;
	onChange: (config: Config) => void;
}
export default function ConfigurationAudioOutputs({ config, onChange }: ConfigurationAudioOutputsProps) {
	const [userChanged, setUserChanged] = useState<boolean>(false);
	const [outputsOrder, setOutputsOrder] = useState<string[]>([]);
	useEffect(() => {
		if (userChanged) {
			let newOrder: string[] = [];
			config.audio.outputs.forEach((output) => {
				newOrder.push(output.id);
			});
			setOutputsOrder(newOrder);

		}
	}, [userChanged, config.audio.outputs]);


	return (
		<div className={`ConfigurationAudioOutputs`}>
			<div className='header'>Audio Outputs</div>
			<table className='audioOutputsTable'>
				<thead>
					{outputsOrder.length > 0 && (
						<tr>
							<th>Button Location</th>
							<th>Audio Output (Button Text)</th>
							<th>Location</th>
						</tr>
					)}
				</thead>

				<DraggableTableBody
					childClassName={'AudioOutputRow'}
					keyForIndex={(index: number) => {
						return config.audio.outputs[index].id;
					}}
					orderedItems={outputsOrder}
					onOrderUpdated={(newOrderedItems) => {
						//newordereditems contains the new order for the ids of the outputs
						let newOutputs: AudioOutput[] = newOrderedItems.map((id, index) => {
							let input = config.audio.outputs.find((input) => {
								return input.id == id;
							})!;
							input.buttonLocation = (index + 1) + "";
							return input;
						});
						config.audio.outputs = newOutputs;
						setOutputsOrder(newOrderedItems);
						onChange(config);
						setUserChanged(true);
					}}>
					{config.audio.outputs.map((input, index) => {
						return <AudioOutputRow
							audioOutput={input}
							key={input.id}

							onDelete={(obj) => {
								let index = config.audio.outputs.indexOf(obj);
								config.audio.outputs.splice(index, 1);
								onChange(config);
								setUserChanged(true);

								let newOrder: string[] = [];
								config.audio.outputs.forEach((output) => {
									newOrder.push(output.id);
								});
								setOutputsOrder(newOrder);
							}}
							onChange={(audioOutput) => {
								config.audio.outputs[index] = audioOutput;
								onChange(config);
								setUserChanged(true);
							}}></AudioOutputRow>
					})}
				</DraggableTableBody>
			</table>
			<div className='buttonAdd'
				onClick={(e) => {
					if (config.audio.outputs.length < 6) { //set to max 6 per eric request
						let blankAudio = blankAudioOutput();
						blankAudio.buttonLocation = (config.audio.outputs.length + 1) + "";
						config.audio.outputs.push(blankAudio);
						onChange(config);

						let newOrder: string[] = [];
						config.audio.outputs.forEach((output) => {
							newOrder.push(output.id);
						});
						setOutputsOrder(newOrder);
						setUserChanged(true);
						(e.target as HTMLDivElement).scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
					}
				}}><FontAwesomeIcon icon={faPlusCircle} /> Add Audio Output</div>
		</div>
	);
}