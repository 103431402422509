import React, { useRef, useState, useEffect, ReactNode } from 'react';
import './ScreenORInputOutputSchedule.css';
import { AudioInput, AudioOutput, Config, SOW, VideoInput, VideoOutput } from '../../../Model/SOW';
import NavigationHeader from '../../../Components/NavigationHeader/NavigationHeader';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import ActionCreators from '../../../Redux/ActionCreators';
import { Screens } from '../../../Redux/Reducers';

import leftBar from '../../../Images/ui/left_bar@2x.png';
import leftBarTop from '../../../Images/ui/left_bar_top@2x.png';
import leftBarBottom from '../../../Images/ui/left_bar_bottom@2x.png';
import ConfigHeader from '../../../Components/Components_OR/ConfigHeader/ConfigHeader';

interface ScreenORInputOutputScheduleProps {
	forPDF?: boolean;
	config?: Config;
	rows?: (VideoInput|VideoOutput|AudioInput|AudioOutput)[];
	sow: SOW;
}
export default function ScreenORInputOutputSchedule({ sow, forPDF, config, rows }: ScreenORInputOutputScheduleProps) {
	const dispatch = useDispatch();
	const AC = bindActionCreators({
		setScreen: ActionCreators.setScreen,
		setSOW: ActionCreators.setSOW,
		setCurrentConfigIndex: ActionCreators.setCurrentConfigIndex,
	}, dispatch);
	const currentConfigIndex = useSelector((state: { currentConfigIndex: number }) => state.currentConfigIndex)


	const videoInputHeader = () =>{
		return <thead>
			<tr>
				<th>#</th>
				<th>Input</th>
				<th>Device Location</th>
				<th>KVM?</th>
			</tr>
		</thead>
	}

	const videoInputRow = ( input: VideoInput, inputIndex: number ) => {
		return <tr key={"videoInput" + inputIndex}>
			<td>{input.buttonLocation}</td>
			<td>{input.inputsTPButtonText}</td>
			<td>{input.signalOriginates}</td>
			<td>{input.kvm ? "Yes" : "No"}</td>
		</tr>
	}

	const videoOutputHeader = () =>{
		return <thead>
			<tr>
				<th>#</th>
				<th>Output</th>
				<th>Device Location</th>
			</tr>
		</thead>
	}

	const videoOutputRow = ( output: VideoOutput, outputIndex: number ) => {
		return <tr key={"videoOutput" + outputIndex}>
			<td>{output.buttonLocation}</td>
			<td>{output.outputsButtonText}</td>
			<td>{output.signalLocation}</td>
		</tr>
	}

	const audioInputHeader = () =>{
		return <thead>
			<tr>
				<th>#</th>
				<th>Input</th>
				<th>Device Location</th>
			</tr>
		</thead>
	}

	const audioInputRow = ( input: AudioInput, inputIndex: number ) => {
		return <tr key={"audioInput" + inputIndex}>
			<td>{input.buttonLocation}</td>
			<td>{input.inputsTPButtonText}</td>
			<td>{input.signalOriginates}</td>
		</tr>
	}

	const audioOutputHeader = () =>{
		return <thead>
			<tr>
				<th>#</th>
				<th>Output</th>
				<th>Device Location</th>
			</tr>
		</thead>
	}

	const audioOutputRow = ( output: AudioOutput, outputIndex: number ) => {
		return <tr key={"audioOutput" + outputIndex}>
			<td>{output.buttonLocation}</td>
			<td>{output.outputsTPButtonText}</td>
			<td>{output.signalLocation}</td>
		</tr>
	}

	const objectType = (obj: VideoInput|VideoOutput|AudioInput|AudioOutput) => {
		if((obj as VideoOutput).outputsButtonText != undefined){
			return "Video Output";
		}
		if((obj as VideoOutput).icon != undefined){
			return "Video Input";
		}
		if((obj as AudioInput).inputsTPButtonText != undefined){
			return "Audio Input";
		}
		if((obj as AudioOutput).outputsTPButtonText != undefined){
			return "Audio Output";
		}
		return "";
	}


	const [selectedConfig, setSelectedConfig] = useState(config ?? sow.configs[0]);
	return (
		<>
			<div className={`Screen ScreenORInputOutputSchedule ${forPDF?"forPDF":""}`}>
				<div className='leftBarTop' />
				<div className='verticalLabel'>Input/Output Schedule</div>

				{rows ? (
					<>
						{(() =>{
							let leftSide:ReactNode[] = [];
							let currentTable:ReactNode[] = [];

							let currentSide = leftSide;
							let currentSideCount = 0;

							let header_height = 75;
							let row_height = 27;

							let lastType = "";
							for(var i in rows){
								let row = rows[i];
								// check type of row
								let type = objectType(row);
								if(type != lastType ){
									if(lastType != ""){
										let wrappingBlock = "";
										let header = <thead></thead>;
										if(lastType == "Video Input"){
											header = videoInputHeader();
										}else if(lastType == "Video Output"){
											header = videoOutputHeader();
										}else if(lastType == "Audio Input"){
											header = audioInputHeader();
										}else{
											header = audioOutputHeader();
										}
										currentSide.push(<div className={`block ${wrappingBlock}`}><div className='header'>{lastType + "s"}</div><table className='table'>{header}<tbody>{currentTable}</tbody></table></div>);

										currentSideCount += header_height;
										currentTable = [];
									}
								}
								lastType = type;
								if(lastType == "Video Input"){
									currentTable.push(videoInputRow(row as VideoInput, parseInt(i)));
								}else if(lastType == "Video Output"){
									currentTable.push(videoOutputRow(row as VideoOutput, parseInt(i)));
								}else if(lastType == "Audio Input"){
									currentTable.push(audioInputRow(row as AudioInput, parseInt(i)));
								}else{
									currentTable.push(audioOutputRow(row as AudioOutput, parseInt(i)));
								}
								currentSideCount += row_height;
							}

							if(currentTable.length > 0){
								let header = <thead></thead>;
								if(lastType == "Video Input"){
									header = videoInputHeader();
								}else if(lastType == "Video Output"){
									header = videoOutputHeader();
								}else if(lastType == "Audio Input"){
									header = audioInputHeader();
								}else{
									header = audioOutputHeader();
								}
								currentSide.push(<div className='block'><div className='header'>{lastType + "s"}</div><table className='table'>{header}<tbody>{currentTable}</tbody></table></div>);
							}

							return <>
								<div className='leftSide forPDF'>
									{leftSide}
								</div>
							</>
						})()}
					</>
				):(
					<>
					<div className='leftSide'>
						<div className='header'>Video Inputs</div>
						<table className='videoInTable'>
							{videoInputHeader()}
							<tbody>
								{selectedConfig.video.inputs.map((input, inputIndex) => {
									return videoInputRow(input, inputIndex);
								})}
							</tbody>
						</table>
					</div>
	
					<div className='rightSide'>
						<div className='header'>Video Outputs</div>
						<table className='videoOutTable'>
							{videoOutputHeader()}
							<tbody>
								{selectedConfig.video.outputs.map((output, outputIndex) => {
									return videoOutputRow(output, outputIndex);
								})}
							</tbody>
						</table>
	
						<div className='header'>Audio Inputs</div>
						<table className='audioInTable'>
							{audioInputHeader()}
							<tbody>
								{selectedConfig.audio.inputs.map((input, inputIndex) => {
									return audioInputRow(input, inputIndex);
								})}
							</tbody>
						</table>
	
						<div className='header'>Audio Outputs</div>
						<table className='audioOutTable'>
							{audioOutputHeader()}
							<tbody>
								{selectedConfig.audio.outputs.map((output, inputIndex) => {
									return audioOutputRow(output, inputIndex);
								})}
							</tbody>
						</table>
					</div>
					</>
				)}


				<ConfigHeader
					sow={sow}
					forPDF={forPDF ?? false}
					selectedConfig={selectedConfig}></ConfigHeader>
			</div>
			{!forPDF && (
				// <NavigationHeader
				// 	selectedConfig={selectedConfig}
				// 	sow={sow} />

				<NavigationHeader
					sow={sow}
					selectedConfig={selectedConfig}
					nextButtonText={() => {
						if (sow.configs.length > currentConfigIndex + 1 && sow.configs[currentConfigIndex].boomVendor == "None") {
							return "Room Layout"
						}
						if(sow.configs[currentConfigIndex].boomVendor != "None"){
							return "Equipment Boom Layout"
						}
						return ""
					}}
					configs={sow.configs.filter((config) => (config.boomVendor !== undefined && config.boomVendor !== "" && config.boomVendor !== "None"))}
					onNext={(screen, configIndex) => {

						if (sow.configs.length > currentConfigIndex + 1 && sow.configs[currentConfigIndex].boomVendor == "None") {
								AC.setCurrentConfigIndex(currentConfigIndex + 1);
								AC.setScreen(Screens.ORRoomLayout);
						}else{
							AC.setCurrentConfigIndex(configIndex);
							AC.setScreen(screen);
						}
						// if (isEquipmentFloating() || selectedConfig.equipmentBoomLayout.sceneObjects.length == 0) {
						// 	setShowingConfirmPopup(true);
						// 	setCancelDropdownItem({ configIndex: configIndex, screen: screen, label: "" });
						// } else {
						// 	if (screen == Screens.ORBegin) {
						// 		AC.setCurrentConfigIndex(currentConfigIndex + 1);
						// 		AC.setScreen(Screens.ORRoomLayout);
						// 	} else {
						// 		AC.setCurrentConfigIndex(configIndex);
						// 		AC.setScreen(screen);
						// 	}
						//}
					}} />
			)}
		</>
	);
}